import { useState, useContext } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import UserAvatar from "../../../management/UserAvatar";
import firebase from "../../../../firebase";
import { UserContext } from "../../../../context";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 0,
        paddingBottom: 5,
    },
    avatar: {
        paddingTop: 10,
    },
    cancelButton: {
        flexGrow: 1,
        minWidth: 60,
        maxWidth: 60,
        minHeight: 25,
        maxHeight: 25,
        fontSize: 13,
        marginTop: 7,
        marginLeft: 0,
    },
    commentButton: {
        minWidth: 60,
        maxWidth: 60,
        minHeight: 25,
        maxHeight: 25,
        fontSize: 13,
        marginTop: 7,
        marginLeft: 5,
        marginRight: 14,
    },
    popover: {
        padding: theme.spacing(2),
        cursor: "pointer",
    },
    clockIcon: {
        padding: 8,
        // marginBottom: 5,
    },
}));

export default function ProjectCommentInput(props) {
    const classes = useStyles();

    const [text, setText] = useState("");
    // const [anchorEl, setAnchorEl] = useState(null);

    // const popoverOpen = Boolean(anchorEl);
    // const id = popoverOpen ? "simple-popover" : undefined;

    const { currentUserData } = useContext(UserContext);

    // function handlePopoverClose() {}

    // function handlePopoverClick() {
    //     // setAnchorEl(null);

    //     // Adds a link to the current video time
    //     if (props.videoPlayer) {
    //         let totalSeconds = Math.floor(
    //             props.videoPlayer.current.getCurrentTime()
    //         );

    //         // props.videoPlayer.current.currentTime // For React-Hls-Player

    //         if (totalSeconds < 60) {
    //             if (totalSeconds < 10) {
    //                 setText(text + "0:0" + totalSeconds + "@ ");
    //             } else {
    //                 setText(text + "0:" + totalSeconds + "@ ");
    //             }
    //         } else {
    //             let minutes = Math.floor(totalSeconds / 60);
    //             let seconds = Math.floor(totalSeconds % 60);

    //             if (seconds < 10) {
    //                 setText(text + minutes + ":0" + seconds + "@ ");
    //             } else {
    //                 setText(text + minutes + ":" + seconds + "@ ");
    //             }
    //         }
    //     }
    // }

    function handleTextInput(event) {
        let addedCharacter = getDifference(text, event.target.value);
        let deletedCharacter = getDifference(event.target.value, text);

        if (addedCharacter === "@" || deletedCharacter === "@") {
            return;
        }

        setText(event.target.value);

        // if (
        //     props.videoPlayer &&
        //     event.target.value.endsWith("@") &&
        //     event.target.value.length > text.length
        // ) {
        //     setAnchorEl(event.target);
        // }
    }

    function handleCancelClick() {
        setText("");
    }

    function getDifference(a, b) {
        var i = 0;
        var j = 0;
        var result = "";

        while (j < b.length) {
            if (a[i] !== b[j] || i === a.length) result += b[j];
            else i++;
            j++;
        }
        return result;
    }

    function handleCommentClick() {
        if (text !== "") {
            if (props.component && props.projectKey !== "") {
                firebase
                    .database()
                    .ref("projects")
                    .child(props.projectKey)
                    .child("components")
                    .child(props.component.key)
                    .child("comments")
                    .push({
                        text: text,
                        user: currentUserData.key,
                        userName: currentUserData.fullName,
                        date: dayjs().toString(),
                    });

                setText("");
            }
        }
    }

    function isButtonHidden() {
        if (props.isReply) {
            return false;
        }

        if (text === "") {
            return true;
        }
    }

    return (
        <Container fluid className={classes.root}>
            <Col>
                <Row>
                    <UserAvatar
                        className={classes.avatar}
                        userOrHub={currentUserData}
                        size={props.avatarSize}
                        path="users"
                    />
                    <Col>
                        <TextField
                            id="standard-multiline-flexible"
                            label={"Add a comment..."}
                            multiline
                            value={text}
                            fullWidth
                            onChange={handleTextInput}
                        />
                    </Col>
                </Row>
                <Row>
                    <span style={{ flex: 1 }}></span>
                    <Button
                        className={classes.cancelButton}
                        variant="contained"
                        color="default"
                        disableElevation
                        hidden={isButtonHidden()}
                        onClick={handleCancelClick}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.commentButton}
                        variant="contained"
                        color="primary"
                        disableElevation
                        hidden={isButtonHidden()}
                        onClick={handleCommentClick}
                        disabled={text === ""}
                    >
                        Comment
                    </Button>
                </Row>
            </Col>
        </Container>
    );
}
