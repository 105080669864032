import React from "react";

// Material UI
import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";

import { Typography } from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// My Files

import VideoViewerPage from "./VideoViewerPage";
import PDFViewerPage from "./PDFViewerPage";
import AudioViewerPage from "./AudioViewerPage";
import ImageViewerPage from "./ImageViewerPage";
import MiscFileViewerPage from "./MiscFileViewerPage";
import LinkViewerPage from "./LinkViewerPage";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileViewerDialog(props) {
    function handleClose() {
        props.onClose();
    }
    return (
        <Dialog
            fullScreen
            aria-labelledby="simple-dialog-title"
            maxWidth="lg"
            open={props.open}
            fullWidth={true}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">{props.fileName}</Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            {props.fileType === "Video" && (
                <VideoViewerPage
                    fileKey={props.fileKey}
                    onClose={props.onClose}
                />
            )}
            {props.fileType === "PDF" && (
                <PDFViewerPage
                    fileKey={props.fileKey}
                    onClose={props.onClose}
                />
            )}
            {props.fileType === "Audio" && (
                <AudioViewerPage
                    fileKey={props.fileKey}
                    onClose={props.onClose}
                />
            )}
            {props.fileType === "Image" && (
                <ImageViewerPage
                    fileKey={props.fileKey}
                    onClose={props.onClose}
                />
            )}
            {props.fileType === "Misc" && (
                <MiscFileViewerPage
                    fileKey={props.fileKey}
                    onClose={props.onClose}
                />
            )}
            {props.fileType === "Link" && (
                <LinkViewerPage
                    fileKey={props.fileKey}
                    onClose={props.onClose}
                />
            )}
        </Dialog>
    );
}
