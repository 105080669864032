import React from "react";

export default function ProjectImageFileComponent(props) {
    return (
        <>
            {props.file && (
                <img
                    style={{ maxWidth: props.contentWidth }}
                    src={props.file.url}
                    alt="animage"
                />
            )}
        </>
    );
}
