import { useState, useEffect, useReducer, Fragment } from "react";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { IconButton } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

// My Files

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        // paddingLeft: 10,
    },
    pageContainer: {
        // minHeight: window.innerHeight - 50,
        // minWidth: window.innerWidth - 50,
        // width: 1000,
        maxHeight: window.innerHeight - 100,
        overflow: "scroll",
    },
    title: {
        marginTop: 15,
    },
    toggleButton: {
        marginLeft: 20,

        width: 30,
        height: 30,
    },
    button: {
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 5,
    },
    tableContainer: {
        marginTop: 15,
        marginBottom: 10,
        maxHeight: window.innerHeight - 100,
        // overflow: "scroll",
    },
    starIcon: {
        color: theme.palette.primary.main,
    },
    labelListItem: {
        paddingLeft: 70,
    },
}));

export default function PlaylistFilterDialog(props) {
    const classes = useStyles();

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [codes, setCodes] = useState([]);
    const [groups, setGroups] = useState([]);
    const [ratings, setRatings] = useState([]);

    useEffect(() => {
        if (props.rows) {
            var codesArray = [];
            var groupsArray = props.groupsAfterFiltering;

            props.rows.forEach((row) => {
                if (
                    codesArray.filter((code) => code.code === row.name)
                        .length === 0
                ) {
                    codesArray.push({
                        code: row.name,
                        selected: !row.filteredOutByCode,
                    });
                }

                if (row.labels.length === 0) {
                    let matchingGroup = groupsArray.filter(
                        (g) => g.group === "(No Group)"
                    )[0];

                    if (matchingGroup) {
                        if (
                            matchingGroup.labels.filter(
                                (l) => l.text === "(No Detail)"
                            ).length === 0
                        ) {
                            matchingGroup.labels = [
                                {
                                    text: "(No Detail)",
                                    selected: true,
                                },
                                ...matchingGroup.labels,
                            ];
                        }
                    } else {
                        groupsArray = [
                            {
                                group: "(No Group)",
                                labels: [
                                    {
                                        text: "(No Detail)",
                                        selected: true,
                                    },
                                ],
                                selected: true,
                            },
                            ...groupsArray,
                        ];
                    }
                } else {
                    row.labels.forEach((label) => {
                        // Groups
                        if (label.group) {
                            let matchingGroup = groupsArray.filter(
                                (g) => g.group === label.group
                            )[0];

                            if (matchingGroup) {
                                if (
                                    matchingGroup.labels.filter(
                                        (l) => l.text === label.text
                                    ).length === 0
                                ) {
                                    matchingGroup.labels.push({
                                        text: label.text,
                                        selected: true,
                                    });
                                }
                            } else {
                                groupsArray.push({
                                    group: label.group,
                                    labels: [
                                        {
                                            text: label.text,
                                            selected: true,
                                        },
                                    ],
                                    selected: true,
                                });
                            }
                        } else {
                            let matchingGroup = groupsArray.filter(
                                (g) => g.group === "(No Group)"
                            )[0];

                            if (matchingGroup) {
                                if (
                                    matchingGroup.labels.filter(
                                        (l) => l.text === label.text
                                    ).length === 0
                                ) {
                                    matchingGroup.labels.push({
                                        text: label.text,
                                        selected: true,
                                    });
                                }
                            } else {
                                groupsArray = [
                                    {
                                        group: "(No Group)",
                                        labels: [
                                            {
                                                text: label.text,
                                                selected: true,
                                            },
                                        ],
                                        selected: true,
                                    },
                                    ...groupsArray,
                                ];
                            }
                        }
                    });
                }
            });

            codesArray.sort((a, b) =>
                a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1
            );

            setCodes(codesArray);
            setGroups(groupsArray);
            setRatings(props.ratings);
        }
        // eslint-disable-next-line
    }, [props.open]);

    function handleClose() {
        var newFilteredCodes = [];

        codes.forEach((code) => {
            if (code.selected) {
                newFilteredCodes.push(code.code);
            }
        });

        var newFilteredLabels = [];

        // labels.forEach((label) => {
        //     if (label.selected) {
        //         newFilteredLabels.push(label.text);
        //     }
        // });

        groups.forEach((group) => {
            group.labels.forEach((label) => {
                if (label.selected) {
                    newFilteredLabels.push(label.text);
                }
            });
        });

        var newFilteredRatings = [];

        ratings.forEach((rating) => {
            if (rating.selected) {
                newFilteredRatings.push(rating.stars);
            }
        });

        props.onClose(
            newFilteredCodes,
            newFilteredLabels,
            newFilteredRatings,
            groups
        );
    }

    const handleDoneClick = () => {
        handleClose();
    };

    const handleToggle = (object) => () => {
        object.selected = !object.selected;

        if (object.group) {
            object.labels.forEach((label) => {
                label.selected = object.selected;
            });
        }

        forceUpdate();
    };

    const handleToggleAll = (array) => () => {
        var firstObject = array[0];

        if (firstObject) {
            var newSelected = false;
            if (firstObject.group) {
                newSelected = !firstObject.selected;

                array.forEach((group) => {
                    group.selected = newSelected;
                    group.labels.forEach((label) => {
                        label.selected = newSelected;
                    });
                });
            } else {
                newSelected = !firstObject.selected;

                array.forEach((object) => {
                    object.selected = newSelected;
                });
            }

            forceUpdate();
        }
    };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={props.open}
            className={classes.root}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
        >
            <Container fluid className={classes.pageContainer}>
                <Row>
                    <Col lg={4} md={12} sm={12}>
                        <h4 className={classes.title}>Filter Rows</h4>
                        <Tooltip title="Toggle All">
                            <IconButton
                                onClick={handleToggleAll(codes)}
                                className={classes.toggleButton}
                                aria-label="toggleAllButtonPress"
                            >
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>
                        <TableContainer className={classes.tableContainer}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {codes.map((code) => {
                                        const id = `checkbox-list-label-${code.code}`;
                                        return (
                                            <ListItem
                                                key={code.code}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={handleToggle(code)}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={code.selected}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                id,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={id}
                                                    primary={code.code}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <h4 className={classes.title}>Filter Detail</h4>
                        <Tooltip title="Toggle All">
                            <IconButton
                                onClick={handleToggleAll(groups)}
                                className={classes.toggleButton}
                                aria-label="toggleAllButtonPress"
                            >
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>
                        <TableContainer className={classes.tableContainer}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {groups.map((group) => {
                                        const id = `checkbox-list-label-${group.group}`;
                                        return (
                                            <Fragment key={group.groups}>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={
                                                                group.selected
                                                            }
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                "aria-labelledby":
                                                                    id,
                                                            }}
                                                            onClick={handleToggle(
                                                                group
                                                            )}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        id={id}
                                                        primary={group.group}
                                                    />
                                                </ListItem>

                                                {group.labels.map((label) => (
                                                    <ListItem
                                                        className={
                                                            classes.labelListItem
                                                        }
                                                        key={label.text}
                                                        role={undefined}
                                                        dense
                                                        button
                                                        onClick={handleToggle(
                                                            label
                                                        )}
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={
                                                                    label.selected
                                                                }
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{
                                                                    "aria-labelledby":
                                                                        id,
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            id={id}
                                                            primary={label.text}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <h4 className={classes.title}>Filter Rating</h4>
                        <Tooltip title="Toggle All">
                            <IconButton
                                onClick={handleToggleAll(ratings)}
                                className={classes.toggleButton}
                                aria-label="toggleAllButtonPress"
                            >
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>
                        <TableContainer className={classes.tableContainer}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {ratings.map((rating) => {
                                        const id = `checkbox-list-label-${rating.stars}`;
                                        return (
                                            <ListItem
                                                key={rating.stars}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={handleToggle(rating)}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            rating.selected
                                                        }
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                id,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                {rating.stars === 0 && (
                                                    <div>
                                                        <StarBorderIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarBorderIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarBorderIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {rating.stars === 1 && (
                                                    <div>
                                                        <StarIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarBorderIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarBorderIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {rating.stars === 2 && (
                                                    <div>
                                                        <StarIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarBorderIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {rating.stars === 3 && (
                                                    <div>
                                                        <StarIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                        <StarIcon
                                                            className={
                                                                classes.starIcon
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </ListItem>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                </Row>
            </Container>

            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleDoneClick}
            >
                Done
            </Button>
        </Dialog>
    );
}
