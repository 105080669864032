import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// My Files
import { DatabaseContext, UserContext } from "../../../context";
import CommentInput from "./CommentInput";
import FileComment from "./FileComment";

const useStyles = makeStyles((theme) => ({
    root: { paddingBottom: 15 },
    commentCountText: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingBottom: 5,
        [theme.breakpoints.down("sm")]: { paddingLeft: 15 },
    },
}));

export default function CommentSection(props) {
    const classes = useStyles();

    const { comments, users } = useContext(DatabaseContext);

    const [videoComments, setVideoComments] = useState([]);

    const [userCanComment, setUserCanComment] = useState(false);

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        let array = [];

        for (let i = 0; i < comments.length; i++) {
            let comment = comments[i];

            if (comment.file === props.file.key && !comment.deleted) {
                if (comment.userObject === undefined) {
                    let user = users.filter((u) => u.key === comment.user)[0];

                    if (user !== undefined) {
                        comment.userObject = user;
                    }
                }

                comment.dateString = "";

                array.push(comment);
            }
        }

        array.sort((a, b) =>
            dayjs(a.date) < dayjs(b.date)
                ? 1
                : dayjs(b.date) < dayjs(a.date)
                ? -1
                : 0
        );

        setVideoComments(array);

        if (currentUserData && currentUserData.permissions) {
            if (currentUserData.permissions.comment) {
                setUserCanComment(true);
            } else {
                setUserCanComment(false);
            }
        }
    }, [comments, users, props, currentUserData]);

    return (
        <>
            <Container fluid className={classes.root}>
                <Row>
                    <h6 className={classes.commentCountText}>
                        {videoComments.length === 1
                            ? videoComments.length + " Comment"
                            : videoComments.length + " Comments"}
                    </h6>
                </Row>
                <Row>
                    {userCanComment && (
                        <CommentInput
                            file={props.file}
                            isReply={false}
                            avatarSize={"medium"}
                            videoPlayer={props.videoPlayer}
                            setIsTyping={props.setIsTyping}
                        />
                    )}
                </Row>

                {videoComments
                    .filter((comment) => comment.parent === undefined)
                    .map((comment) => (
                        <Row key={comment.key}>
                            <FileComment
                                file={props.file}
                                comment={comment}
                                avatarSize="medium"
                                videoPlayer={props.videoPlayer}
                                isReply={false}
                                setIsTyping={props.setIsTyping}
                                replies={videoComments
                                    .filter((c) => c.parent === comment.key)
                                    .reverse()}
                            />
                        </Row>
                    ))}
            </Container>
        </>
    );
}
