import { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";

// Material UI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControlLabel,
    FormGroup,
    IconButton,
    Input,
} from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from "@material-ui/icons/Group";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files

import { DatabaseContext, UserContext } from "../../../context";
import BackButton from "../../BackButton";
import firebase from "../../../firebase";
import LinkFileToEventDialog from "./LinkFileToEventDialog";
import FilePermissionsDialog from "./FilePermissionsDialog";
import FileNotificationsDialog from "./FileNotificationsDialog";
import { makeid, jsonFromArray } from "../../../utility";
import DialogWithMessage from "../../DialogWithMessage";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        paddingTop: 5,
        paddingBottom: 5,
        [theme.breakpoints.up("md")]: {
            height: 140,
        },
        [theme.breakpoints.down("sm")]: {
            height: 190,
        },
    },
    container: {
        // padding: 0,
        marginLeft: 15,
    },
    fileTypeIcon: {
        fontSize: 75,
        position: "relative",
        top: "15px",
    },
    icons: {
        paddingLeft: 10,
        paddingRight: 10,
        position: "relative",
        top: "15px",
        fontSize: 75,
        [theme.breakpoints.only("xs")]: {
            paddingLeft: 5,
            paddingRight: 5,
            position: "relative",
            top: "15px",
            fontSize: 50,
        },
    },
    switch: {
        marginTop: theme.spacing(1.0),
    },
}));

export default function NewLinkPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { users, calendars, folders, groups } = useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [userCanUpload, setUserCanUpload] = useState();

    const [url, setUrl] = useState("");
    const [displayName, setDisplayName] = useState("");

    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [events, setEvents] = useState([]);

    const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);

    const [notificationsDialogOpen, setNotificationsDialogOpen] =
        useState(false);

    const [allowSharing, setAllowSharing] = useState(false);
    const [allowComments, setAllowComments] = useState(true);

    const [managers, setManagers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [defaultUsers, setDefaultUsers] = useState([]);

    const [selectedGroups, setSelectedGroups] = useState([]);

    const [sendNotifications, setSendNotifications] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [availableCalendars, setAvailableCalendars] = useState([]);
    const [ancestorHubs, setAncestorHubs] = useState([]);

    const [dialogMessageIsOpen, setDialogMessageIsOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        // let userArray = [];
        let managersArray = [];

        // If Location.folder is set, the user came here from the File Navigator Page
        if (location.folder) {
            // Get managers of the current folder
            location.folder.managers.forEach((managerId) => {
                let manager = users.filter((u) => u.key === managerId)[0];

                if (manager) {
                    managersArray.push(manager);
                }
            });

            // Get default users of the current folder
            // Only use this for the option to add all users of the current hub
            // location.folder.users.forEach((userId) => {
            //     let user = users.filter((u) => u.key === userId)[0];

            //     if (user) {
            //         userArray.push(user);
            //     }
            // });

            // Get ancestor hubs, these are used for the groups and users
            let ancestors = folders.filter(
                (f) => f.hub && location.folder.ancestors.includes(f.key)
            );

            // Only include the ancestors that can be managed by the uploading user
            ancestors = ancestors.filter(
                (a) =>
                    a.managers.includes(currentUserData.key) ||
                    currentUserData.role === "System Digital Manager" ||
                    a.users.includes(currentUserData.key)
            );

            // If the current folder is a hub, add that too
            if (location.folder.hub) {
                ancestors.push(location.folder);
            }

            // Get the groups for these ancestors
            ancestors.forEach((ancestorHub) => {
                let matchingGroups = groups.filter(
                    (g) => g.hub === ancestorHub.key
                );

                // Reset the didSelect from the group, in case you are going in and out of uploadFilesPage
                // matchingGroups.forEach((group) => {
                //     group.didSelect = false;
                // });

                ancestorHub.groups = matchingGroups;
            });

            setAncestorHubs(ancestors);

            // Get calendars
            if (
                location.folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager" ||
                currentUserData.permissions.uploadEditOwnVideo
            ) {
                setUserCanUpload(true);

                // Find calendars that user can see
                let calendarsArray = [];

                calendars.forEach((calendar) => {
                    let shouldAdd = false;

                    // User should be able to see calendar if it's their personal one, or if they are uploading a file
                    // to the hub for that calendar
                    if (calendar.user === currentUserData.key) {
                        shouldAdd = true;
                    } else if (calendar.hub === location.folder.key) {
                        shouldAdd = true;
                    } else if (
                        location.folder.ancestors.includes(calendar.hub)
                    ) {
                        shouldAdd = true;
                    }

                    if (shouldAdd) {
                        calendarsArray.push(calendar);
                    }
                });

                setAvailableCalendars(calendarsArray);
            } else {
                setUserCanUpload(false);
            }
        } else {
            history.replace("/files");
        }

        // setDefaultUsers(userArray);
        setManagers(managersArray);

        if (
            managersArray.filter((m) => m.key === currentUserData.key)
                .length === 0
        ) {
            if (selectedUsers.length === 0) {
                setSelectedUsers([currentUserData]);
            }
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [
        location.folder,
        users,
        history,
        currentUserData,
        calendars,
        folders,
        groups,
    ]);

    function handleOKClick() {
        if (location.folder) {
            if (url.includes("https://") || url.includes("http://")) {
                addToFirebase(makeid(19));
            } else {
                setDialogMessage(
                    "Please include the full link, starting with http:// or https://"
                );
                setDialogMessageIsOpen(true);
            }
        }
    }

    function addToFirebase(key) {
        let eventsObject = {};
        for (let i = 0; i < events.length; i++) {
            let event = events[i];
            eventsObject[event.key] = true;
        }

        // Add to files
        var filesRef = firebase.database().ref("files");

        // If user didn't enter a display name, use the filename
        let displayNameForDatabase = displayName;

        if (displayNameForDatabase === "") {
            displayNameForDatabase = url;
        }

        // Managers array to JSON
        let managersObject = {};
        if (managers) {
            managers.forEach((manager) => {
                managersObject[manager.key] = true;
            });
        }

        // Ancestors array to JSON
        let ancestorsObject = {};

        location.folder.ancestors.forEach((ancestor) => {
            ancestorsObject[ancestor] = true;
        });

        ancestorsObject[location.folder.key] = true;

        var uploadedBy = "";
        if (currentUserData) {
            uploadedBy = currentUserData.key;
        }

        filesRef.child(key).set({
            filename: url,
            displayName: displayNameForDatabase,
            folder: location.folder.id,
            type: "link",
            allowDownloads: false,
            allowComments: allowComments,
            allowSharing: allowSharing,
            managers: managersObject,
            events: eventsObject,
            groups: jsonFromArray(selectedGroups),
            ancestors: ancestorsObject,
            uploadedBy: uploadedBy,
        });

        // Add to any linked events

        var eventsRef = firebase.database().ref("events");

        for (let i = 0; i < events.length; i++) {
            let event = events[i];

            let fileObject = {};
            fileObject[key] = true;
            eventsRef.child(event.key).child("files").update(fileObject);
        }

        let usersObjectForNotifications = {};

        // Fine users that have been added individually, add the file to users.files instead
        selectedUsers.forEach((user) => {
            usersObjectForNotifications[user.key] = true;
            if (!isUserInSelectedGroups(user)) {
                var usersRef = firebase.database().ref("users");

                let fileObject = {};
                fileObject[key] = true;

                usersRef.child(user.key).child("files").update(fileObject);
            }
        });

        // Add notifications

        if (sendNotifications) {
            var notificationsRef = firebase.database().ref("notifications");

            notificationsRef.push({
                file: key,
                title: displayNameForDatabase,
                type: "link",
                users: usersObjectForNotifications,
                managers: managersObject,
                message: notificationMessage,
                url: url,
                date: dayjs().toString(),
            });
        }

        console.log("File Added Successfully");
        history.goBack();
    }
    function isUserInSelectedGroups(user) {
        for (let i = 0; i < selectedGroups.length; i++) {
            let group = selectedGroups[i];
            for (let j = 0; j < group.users.length; j++) {
                let u = group.users[j];

                if (user.key === u.key) {
                    return true;
                }
            }
        }
        return false;
    }

    function handleChangeDisplayName(event) {
        setDisplayName(event.target.value);
    }

    function handleChangeUrl(event) {
        setUrl(event.target.value);
    }

    function handleEventClick() {
        setEventDialogOpen(true);
    }

    function handlePermissionsClick() {
        setPermissionsDialogOpen(true);
    }

    function handleNotificationsClick() {
        setNotificationsDialogOpen(true);
    }

    function handleChange(event) {
        if (event.target.name === "comments") {
            setAllowComments(event.target.checked);
        } else if (event.target.name === "sharing") {
            setAllowSharing(event.target.checked);
        }
    }

    function handleEventSelectionChange(selectedEvents) {
        setEvents(selectedEvents);
    }

    function handleCloseEventDialog(selectedEvents) {
        setEvents(selectedEvents);
        setEventDialogOpen(false);
    }

    function handleClosePermissionsDialog(users, groups) {
        setSelectedGroups(groups);
        setSelectedUsers(users);
        setPermissionsDialogOpen(false);
    }

    function handleCloseNotificationsDialog(settings) {
        setSendNotifications(settings.sendNotifications);
        setNotificationMessage(settings.message);

        setNotificationsDialogOpen(false);
    }

    function handleCloseDialogMessage() {
        setDialogMessageIsOpen(false);
    }

    return (
        <>
            {userCanUpload && (
                <div>
                    <BackButton />
                    <Paper elevation={1} className={classes.paper}>
                        <Container fluid className={classes.container}>
                            <Row>
                                <Col xl="6" lg="6" md="12" sm="12" xs="12">
                                    <Row>
                                        <Input
                                            value={url}
                                            onChange={handleChangeUrl}
                                            placeholder="URL"
                                            fullWidth
                                            disableUnderline
                                            endAdornment
                                        />
                                        <Input
                                            value={displayName}
                                            onChange={handleChangeDisplayName}
                                            placeholder="Display Name"
                                            fullWidth
                                            disableUnderline
                                            endAdornment
                                        />
                                    </Row>
                                </Col>
                                <Col xl="3" lg="3" md="6" sm="6" xs="6">
                                    <Tooltip title="Events">
                                        <IconButton
                                            className={classes.icons}
                                            onClick={handleEventClick}
                                        >
                                            <Badge
                                                badgeContent={events.length}
                                                color="primary"
                                            >
                                                <EventNoteIcon />
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Notifications">
                                        <IconButton
                                            className={classes.icons}
                                            onClick={handleNotificationsClick}
                                        >
                                            {/* <NotificationsIcon /> */}
                                            <Badge
                                                badgeContent={
                                                    sendNotifications ? (
                                                        <CheckIcon />
                                                    ) : (
                                                        <CloseIcon />
                                                    )
                                                }
                                                color="primary"
                                            >
                                                <NotificationsIcon />
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Users and Groups">
                                        <IconButton
                                            className={classes.icons}
                                            onClick={handlePermissionsClick}
                                        >
                                            <Badge
                                                badgeContent={
                                                    selectedUsers.length +
                                                    managers.length
                                                }
                                                color="primary"
                                            >
                                                <GroupIcon />
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                </Col>
                                <Col xl="3" lg="3" md="6" sm="6" xs="6">
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={allowComments}
                                                    onChange={handleChange}
                                                    name="comments"
                                                    color="primary"
                                                />
                                            }
                                            label={"Allow Comments"}
                                        />
                                    </FormGroup>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={allowSharing}
                                                    onChange={handleChange}
                                                    name="sharing"
                                                    color="primary"
                                                />
                                            }
                                            label={"Allow Sharing"}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                        <Button id="ok" onClick={handleOKClick} color="primary">
                            Add Link
                        </Button>
                    </Paper>
                    <LinkFileToEventDialog
                        open={eventDialogOpen}
                        onClose={handleCloseEventDialog}
                        selectionChanged={handleEventSelectionChange}
                        selectedEvents={events}
                        calendars={availableCalendars}
                    />
                    <FilePermissionsDialog
                        open={permissionsDialogOpen}
                        onClose={handleClosePermissionsDialog}
                        selectedUsers={selectedUsers}
                        selectedGroups={selectedGroups}
                        managers={managers}
                        ancestorHubs={ancestorHubs}
                    />
                    <FileNotificationsDialog
                        open={notificationsDialogOpen}
                        onClose={handleCloseNotificationsDialog}
                        message={notificationMessage}
                        send={sendNotifications}
                    />
                    <DialogWithMessage
                        open={dialogMessageIsOpen}
                        onClose={handleCloseDialogMessage}
                        title={"Invalid Link"}
                        message={dialogMessage}
                    />
                </div>
            )}
        </>
    );
}
