import {
    CallToAction,
    Content,
    Email,
    Header,
    Paragraph,
    Title,
} from "@rakered/email";

export default function ConsentFormEmail(props) {
    return (
        <Email>
            <Content>
                <Header logo="https://firebasestorage.googleapis.com/v0/b/nodiant-e20a8.appspot.com/o/180.png?alt=media&token=9821b2a9-1a70-4f63-a488-96377bb3a234" />
                <Title>Complete Registration</Title>

                <Paragraph>
                    {props.userName === ""
                        ? "You have been registered on the Nodiant Online Platform. To finalise the process, we need your consent."
                        : `${props.userName} has been registered on the Nodiant Online Platform. To finalise the process, we need your consent.`}
                </Paragraph>

                <CallToAction
                    href={
                        props.userName === ""
                            ? `https://nodiant.faw.cymru/${props.path}?key=${props.userKey}`
                            : `https://nodiant.faw.cymru/${props.path}?key=${props.userKey}`
                    }
                >
                    Complete Registration
                </CallToAction>
            </Content>
        </Email>
    );
}
