import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import ConversationList from "./ConversationList";
import ConversationListMobile from "./Mobile/ConversationListMobile";
import ConversationDetail from "./ConversationDetail";
import NewConversationDialog from "./NewConversationDialog";
import firebase from "firebase";
import NoPermissionsMessage from "../NoPermissionsMessage";
import { makeid } from "../../utility";

const useStyles = makeStyles((theme) => ({
    conversationList: {
        background: "rgb(230, 230, 230)",
    },
    conversationDetail: {
        background: "rgb(240, 240, 240)",
    },
    container: {
        // height: "100vh",
        marginTop: 0,
        display: "flex",
        background: "red",
        height: window.innerHeight - 125,
    },
    fullHeightRow: {
        height: "100%",
    },
    list: {
        // marginTop: 62,
        height: "100%",

        flex: 2,
        backgroundColor: "rgb(230, 230, 230)",
        overflow: "scroll",
    },
    detail: {
        // marginTop: 62,
        height: "100%",
        flex: 10,
        backgroundColor: "rgb(240, 240, 240)",
        overflow: "scroll",
    },
    topBar: {
        height: 100,
        marginLeft: 500,
    },
}));

export default function MessagesPage() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const { currentUserData } = useContext(UserContext);

    // Conversations and users from firebase
    const { conversations, users } = useContext(DatabaseContext);

    // Conversations involved current user
    const [userConversations, setUserConversations] = useState([]);

    const [selectedConversation, setSelectedConversation] = useState();

    const [newConversationDialogOpen, setNewConversationDialogOpen] =
        useState(false);

    const [mobileView, setMobileView] = useState(false);

    const [userHasPermissionToView, setUserHasPermissionToView] = useState();
    const [
        userHasPermissionToStartConversations,
        setUserHasPermissionToStartConversations,
    ] = useState();

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData) {
            if (currentUserData.permissions.openMessage === true) {
                setUserHasPermissionToView(true);
            } else {
                setUserHasPermissionToView(false);
            }

            if (currentUserData.permissions.personalMessage === true) {
                setUserHasPermissionToStartConversations(true);
            } else {
                setUserHasPermissionToStartConversations(false);
            }

            console.log("Refreshing Conversations");

            let conversationsInvolvingCurrentUser = conversations.filter(
                (c) =>
                    c.participants.filter((p) => p.key === currentUserData.key)
                        .length > 0
            );

            // Set the user object for the conversation. This should be the participant that is not the current user. We use this for the avatar on the conversation list.
            conversationsInvolvingCurrentUser.forEach((conversation) => {
                conversation.user = currentUserData;

                conversation.otherUsers = [];

                conversation.participants.forEach((participant) => {
                    if (participant.key !== currentUserData.key) {
                        let otherUser = users.filter(
                            (u) => u.key === participant.key
                        )[0];

                        if (otherUser) {
                            conversation.otherUsers.push(otherUser);

                            if (conversation.title === undefined) {
                                conversation.title = otherUser.fullName;
                            }
                        } else {
                            let key = makeid(19);
                            let dummyUserObect = {
                                key: key,
                                uid: key,
                                email: "",
                                phone: "",
                                firstName: "Deleted",
                                lastName: "User",
                                fullName: "Deleted User",
                            };
                            conversation.otherUsers.push(dummyUserObect);

                            if (conversation.title === undefined) {
                                conversation.title = dummyUserObect.fullName;
                            }
                        }
                    }
                });

                // Set unread message count for each conversation
                let unreadCount = 0;
                if (!conversation.deleted) {
                    if (conversation.messages) {
                        conversation.messages.forEach((message) => {
                            if (message.user !== currentUserData.key) {
                                if (
                                    !message.readBy.includes(
                                        currentUserData.key
                                    )
                                ) {
                                    if (!message.deleted) {
                                        unreadCount++;
                                    }
                                }

                                if (conversation.title === "Deleted User") {
                                    conversation.title = message.fullName;
                                }
                            }
                        });
                    }
                }

                conversation.unreadMessagesCount = unreadCount;

                if (selectedConversation) {
                    if (conversation.key === selectedConversation.key) {
                        setSelectedConversation(conversation);
                    }
                }
            });

            if (conversationsInvolvingCurrentUser.length > 0) {
                setUserConversations(
                    conversationsInvolvingCurrentUser.sort((a, b) =>
                        dayjs(a.lastMessage) < dayjs(b.lastMessage)
                            ? 1
                            : dayjs(b.lastMessage) < dayjs(a.lastMessage)
                            ? -1
                            : 0
                    )
                );

                if (!selectedConversation) {
                    setSelectedConversation(
                        conversationsInvolvingCurrentUser[0]
                    );
                }
            }
        }

        if (window.innerWidth < 576) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }

        function handleResize() {
            if (window.innerWidth < 576) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        window.addEventListener("resize", handleResize);

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [conversations, users, currentUserData, selectedConversation, history]);

    function handleConversationSelect(conv) {
        setSelectedConversation(conv);
    }

    function handleAddConversationClick() {
        setNewConversationDialogOpen(true);
    }

    function handleCloseNewConversationDialog(didAdd) {
        if (didAdd) {
            setSelectedConversation(null);
        }
        setNewConversationDialogOpen(false);
    }

    return (
        <>
            {userHasPermissionToView && (
                <div style={{ "&::-webkit-scrollbar": { display: "none" } }}>
                    {mobileView ? (
                        <>
                            <ConversationListMobile
                                conversations={userConversations}
                                handleAddClick={handleAddConversationClick}
                                canAdd={userHasPermissionToStartConversations}
                            />
                        </>
                    ) : (
                        <>
                            <div
                                className={classes.container}
                                style={{
                                    "&::-webkit-scrollbar": { display: "none" },
                                }}
                            >
                                <div className={classes.list}>
                                    <ConversationList
                                        conversations={userConversations}
                                        handleSelect={handleConversationSelect}
                                        canAdd={
                                            userHasPermissionToStartConversations
                                        }
                                        handleAddClick={
                                            handleAddConversationClick
                                        }
                                        selected={selectedConversation}
                                    />
                                </div>
                                <div className={classes.detail}>
                                    <ConversationDetail
                                        conversation={selectedConversation}
                                    />
                                </div>
                            </div>
                            <ToolbarWithLogo />
                        </>
                    )}
                    {userHasPermissionToStartConversations && (
                        <NewConversationDialog
                            open={newConversationDialogOpen}
                            onClose={handleCloseNewConversationDialog}
                        />
                    )}
                </div>
            )}
            {userHasPermissionToView === false && <NoPermissionsMessage />}
        </>
    );
}
