import { useState, useEffect } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    doneButton: {
        width: 60,
        margin: 10,
    },
    groupChip: {
        marginTop: 7.5,
    },
}));

export default function HubUsageFileTypeFilterDialog(props) {
    const classes = useStyles();

    const [includeVideo, setIncludeVideo] = useState(null);
    const [includeAudio, setIncludeAudio] = useState(null);
    const [includeImages, setIncludeImages] = useState(null);
    const [includePDFs, setIncludePDFs] = useState(null);
    const [includePresentations, setIncludePresentations] = useState(null);
    const [includeDocuments, setIncludeDocuments] = useState(null);

    useEffect(() => {
        if (includeVideo === null) {
            setIncludeVideo(props.includeVideo);
        }
        if (includeAudio === null) {
            setIncludeAudio(props.includeAudio);
        }
        if (includeImages === null) {
            setIncludeImages(props.includeImages);
        }
        if (includePDFs === null) {
            setIncludePDFs(props.includePDFs);
        }
        if (includePresentations === null) {
            setIncludePresentations(props.includePresentations);
        }
        if (includeDocuments === null) {
            setIncludeDocuments(props.includeDocuments);
        }
        // eslint-disable-next-line
    }, [
        includeVideo,
        includeAudio,
        includeImages,
        includePDFs,
        includePresentations,
        includeDocuments,
    ]);

    const handleClose = () => {
        props.onClose(
            includeVideo,
            includeAudio,
            includeImages,
            includePDFs,
            includePresentations,
            includeDocuments
        );
    };

    const handleToggle = (type) => () => {
        if (type === "Video") {
            setIncludeVideo(!includeVideo);
        } else if (type === "Audio") {
            setIncludeAudio(!includeAudio);
        } else if (type === "Images") {
            setIncludeImages(!includeImages);
        } else if (type === "PDF") {
            setIncludePDFs(!includePDFs);
        } else if (type === "Presentations") {
            setIncludePresentations(!includePresentations);
        } else if (type === "Documents") {
            setIncludeDocuments(!includeDocuments);
        }
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <Container fluid className={classes.container}>
                    <h4 className={classes.title}>File Type Filter</h4>
                    <List className={classes.list}>
                        <ListItem
                            role={undefined}
                            dense
                            button
                            onClick={handleToggle("Video")}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={includeVideo}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary="Video" />
                        </ListItem>
                        <ListItem
                            role={undefined}
                            dense
                            button
                            onClick={handleToggle("Audio")}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={includeAudio}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary="Audio" />
                        </ListItem>
                        <ListItem
                            role={undefined}
                            dense
                            button
                            onClick={handleToggle("Images")}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={includeImages}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary="Images" />
                        </ListItem>
                        <ListItem
                            role={undefined}
                            dense
                            button
                            onClick={handleToggle("PDF")}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={includePDFs}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary="PDF" />
                        </ListItem>
                        <ListItem
                            role={undefined}
                            dense
                            button
                            onClick={handleToggle("Presentations")}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={includePresentations}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary="Presentations" />
                        </ListItem>
                        <ListItem
                            role={undefined}
                            dense
                            button
                            onClick={handleToggle("Documents")}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={includeDocuments}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary="Documents" />
                        </ListItem>
                    </List>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        className={classes.doneButton}
                    >
                        Done
                    </Button>
                </Container>
            </Dialog>
        </>
    );
}
