import { useState } from "react";

// Material UI
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { makeStyles } from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
    position: {
        position: "fixed",
        zIndex: 1,
        top: "auto",
        bottom: 70,
        left: "auto",
        right: 10,
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function FileSystemSpeedDial(props) {
    const classes = useStyles();

    const [speedDialOpen, setSpeedDialOpen] = useState(false);

    const [folderNameDialogOpen, setFolderNameDialogOpen] = useState(false);

    const [folderName, setFolderName] = useState("");

    const handleSpeedDialClose = () => {
        setSpeedDialOpen(false);
    };

    const handleSpeedDialOpen = () => {
        setSpeedDialOpen(true);
    };

    const handleFolderNameDialogOpen = () => {
        setFolderName("");
        setFolderNameDialogOpen(true);
    };

    const handleFolderNameDialogClose = (key) => () => {
        setSpeedDialOpen(false);

        setFolderNameDialogOpen(false);

        if (key === "OK") {
            props.onFolderClick(folderName);
        }
    };

    const handleKeyboardInput = (e) => {
        setFolderName(e.target.value);
    };

    const handleClick = (key) => () => {
        if (key === "Folder") {
            props.onFolderClick();
        } else if (key === "File") {
            props.onFileClick();
        }
    };

    return (
        <div className={classes.position}>
            <SpeedDial
                ariaLabel="SpeedDial example"
                className={classes.speedDial}
                hidden={false}
                icon={<SpeedDialIcon />}
                onClose={handleSpeedDialClose}
                onOpen={handleSpeedDialOpen}
                open={speedDialOpen}
                direction="up"
            >
                <SpeedDialAction
                    key="Folder"
                    icon={<FolderIcon />}
                    tooltipTitle="Folder"
                    tooltipOpen
                    onClick={handleFolderNameDialogOpen}
                />
                <SpeedDialAction
                    key="File"
                    icon={<DescriptionIcon />}
                    tooltipTitle="File"
                    tooltipOpen
                    onClick={handleClick("File")}
                />
            </SpeedDial>
            <Dialog
                open={folderNameDialogOpen}
                onClose={handleFolderNameDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a name for the new folder.
                    </DialogContentText>
                    <TextField
                        value={folderName}
                        onChange={handleKeyboardInput}
                        autoFocus
                        margin="dense"
                        id="name"
                        label=""
                        type=""
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleFolderNameDialogClose("Cancel")}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleFolderNameDialogClose("OK")}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
