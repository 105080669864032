import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

export default function BackButton() {
    const history = useHistory();

    function handlePress() {
        history.goBack();
    }

    return (
        <>
            <IconButton onClick={handlePress} component="span">
                <ArrowBackIcon />
            </IconButton>
        </>
    );
}
