import { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";

import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";
import MessageIcon from "@material-ui/icons/Message";
import TodayIcon from "@material-ui/icons/Today";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
// import ViewCompactIcon from "@material-ui/icons/ViewCompact";
// import PollIcon from "@material-ui/icons/Poll";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import TheatersIcon from "@material-ui/icons/Theaters";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ListAltIcon from "@material-ui/icons/ListAlt";

// My Files
import { UserContext } from "../../context";

import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function HamburgerMenu() {
    const history = useHistory();

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const { currentUserData } = useContext(UserContext);

    const [userCanEditUsers, setUserCanEditUsers] = useState(false);
    const [userCanViewCalendar, setUserCanViewCalendar] = useState(false);
    const [userCanSeeManagement, setUserCanSeeManagement] = useState(false);
    const [userCanOpenMessages, setUserCanOpenMessages] = useState(false);

    useEffect(() => {
        if (currentUserData && currentUserData.permissions) {
            if (currentUserData.permissions.addEditUsers) {
                setUserCanEditUsers(true);
            } else {
                setUserCanEditUsers(false);
            }

            if (currentUserData.permissions.openMessage) {
                setUserCanOpenMessages(true);
            } else {
                setUserCanOpenMessages(false);
            }

            if (currentUserData.permissions.viewCalendar) {
                setUserCanViewCalendar(true);
            } else {
                setUserCanViewCalendar(false);
            }
            if (
                currentUserData.role !== "Player" &&
                currentUserData.role !== "FAW Player" &&
                currentUserData.role !== "Club Player" &&
                currentUserData.role !== "Coach Ed Student" &&
                currentUserData.role !== "FAW Coach Ed Student" &&
                currentUserData.role !== "Club Coach Ed Student" &&
                currentUserData.role !== "FAW Parent" &&
                currentUserData.role !== "Club Parent" &&
                currentUserData.role !== "Referee"
            ) {
                setUserCanSeeManagement(true);
            } else {
                setUserCanSeeManagement(false);
            }
        }
    }, [currentUserData]);

    function handleClickHome() {
        history.replace("/");
    }

    function handleClickUsers() {
        history.replace("/users");
    }

    function handleClickHubs() {
        history.replace("/hubs");
    }

    function handleClickFiles() {
        history.replace("/files");
    }

    function handleClickCalendar() {
        history.replace("/calendar");
    }

    function handleClickMessages() {
        history.replace("/messages");
    }

    function handleClickPlaylists() {
        history.replace("/playlists");
    }

    function handleClickSurveys() {
        history.replace("/surveys/s");
    }

    function handleClickProjects() {
        history.replace("/projects/s");
    }

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setIsOpen(open);
    };

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button onClick={handleClickHome}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={handleClickFiles}>
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Files" />
                </ListItem>
                {userCanOpenMessages && (
                    <ListItem button onClick={handleClickMessages}>
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Messages" />
                    </ListItem>
                )}
                {userCanViewCalendar && (
                    <ListItem button onClick={handleClickCalendar}>
                        <ListItemIcon>
                            <TodayIcon />
                        </ListItemIcon>
                        <ListItemText primary="Calendar" />
                    </ListItem>
                )}
                <ListItem button onClick={handleClickPlaylists}>
                    <ListItemIcon>
                        <TheatersIcon />
                    </ListItemIcon>
                    <ListItemText primary="Playlists" />
                </ListItem>
                <ListItem button onClick={handleClickSurveys}>
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Surveys" />
                </ListItem>
                <ListItem button onClick={handleClickProjects}>
                    <ListItemIcon>
                        <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Projects" />
                </ListItem>
            </List>
            {userCanSeeManagement && (
                <>
                    <Divider />
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Management" />
                        </ListItem>
                        <List component="div" disablePadding>
                            {userCanEditUsers && (
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={handleClickUsers}
                                >
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Users" />
                                </ListItem>
                            )}

                            <ListItem
                                button
                                className={classes.nested}
                                onClick={handleClickHubs}
                            >
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Hubs" />
                            </ListItem>
                        </List>
                    </List>
                </>
            )}
        </div>
    );

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                onClick={toggleDrawer(true)}
                className={classes.menuButton}
            >
                <MenuIcon />
                {/* <ArrowBackIcon /> */}
            </IconButton>
            <Drawer anchor={"left"} open={isOpen} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </>
    );
}
