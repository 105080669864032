import React, { useEffect } from "react";

export default function KeyDownHandler(props) {
    useEffect(() => {
        const handler = (e) => {
            props.keyWasPressed(e);
        };

        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [props]);

    return <></>;
}
