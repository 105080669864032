import { useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import axios from "axios";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { Button, Avatar } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";

// My Files
import { encodeImage } from "../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
    },
    chooseButton: {
        display: "flex",
        justifyContent: "center",
    },
    avatarRow: {
        margin: 0,
    },
    avatar: {
        height: 200,
        width: 200,
    },
    avatarIcon: {
        fontSize: 150,
    },
}));

export default function AvatarUploadButton(props) {
    const classes = useStyles();

    const [avatar, setAvatar] = useState(null);
    const [editor, setEditor] = useState();
    const [isEditing, setIsEditing] = useState(false);

    const [scale, setScale] = useState(1.0);

    useEffect(() => {
        const getImage = () => {
            if (props.recipient) {
                if (props.recipient.avatar) {
                    setAvatar(props.recipient.avatar);
                } else {
                    // Download the image from firebase
                    axios
                        .get(props.recipient.imageURL, {
                            responseType: "arraybuffer",
                        })
                        .then((response) => {
                            const buffer = Buffer.from(response.data, "base64");
                            const photo =
                                "data:image/png;base64," + encodeImage(buffer);

                            setAvatar(photo);
                            props.recipient.avatar = photo;
                        })
                        .catch((ex) => {
                            console.error(ex);
                        });
                }
            }
        };

        if (props.recipient) {
            props.recipient.avatarEditor = editor;
        }

        getImage();
    }, [props.path, props.recipient, editor]);

    // User selected an image
    const handleChoosePhotoClick = (e) => {
        const files = e.target.files;

        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                setAvatar(fr.result);
                if (props.didChangeAvatar) {
                    props.didChangeAvatar();
                }
            };
            fr.readAsDataURL(files[0]);
            setIsEditing(true);
        }
    };

    const handleScaleChange = (event, newValue) => {
        setScale(newValue);
    };

    const setEditorRef = (e) => setEditor(e);

    return (
        <Container fluid className={classes.root}>
            <Col>
                {isEditing ? (
                    <>
                        <Button
                            color="primary"
                            component="label"
                            className={classes.chooseButton}
                        >
                            Choose File
                            <input
                                type="file"
                                hidden
                                onChange={handleChoosePhotoClick}
                            />
                        </Button>
                        <Row>
                            <Row className={classes.avatarRow}>
                                <AvatarEditor
                                    ref={setEditorRef}
                                    width={300}
                                    height={300}
                                    image={avatar}
                                    scale={scale}
                                    border={35}
                                    borderRadius={200}
                                />
                            </Row>
                        </Row>
                        <Row>
                            <Slider
                                value={scale}
                                onChange={handleScaleChange}
                                aria-labelledby="continuous-slider"
                                min={0.0}
                                max={3.0}
                                step={0.01}
                            />
                        </Row>{" "}
                    </>
                ) : (
                    <>
                        <input
                            accept="image/*"
                            id="icon-button-photo"
                            onChange={handleChoosePhotoClick}
                            type="file"
                            hidden
                        />
                        <label htmlFor="icon-button-photo">
                            <IconButton component="span">
                                <Avatar
                                    alt="Profile Picture"
                                    className={classes.avatar}
                                    src={avatar}
                                >
                                    {props.path === "users" ? (
                                        <PersonIcon
                                            className={classes.avatarIcon}
                                        />
                                    ) : (
                                        <GroupIcon
                                            className={classes.avatarIcon}
                                        />
                                    )}
                                </Avatar>
                            </IconButton>
                        </label>
                    </>
                )}
            </Col>
        </Container>
    );
}
