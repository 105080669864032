import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Button from "@material-ui/core/Button";

// My Files
import { UserContext } from "../../../context";
import UserAvatar from "../UserAvatar";
import firebase from "../../../firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        // paddingLeft: 10,
    },
    appBar: {
        position: "relative",
    },
    title: {
        flexGrow: 1,
        marginTop: 10,
    },
    button: {
        marginBottom: 10,
        marginRight: 10,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DuplicateUserDialog(props) {
    const classes = useStyles();

    const { currentUserData } = useContext(UserContext);

    const history = useHistory();

    function handleBecomeAdminPress() {
        let userAdminsObject = {};

        props.user.admins.forEach((adminKey) => {
            userAdminsObject[adminKey] = true;
        });

        userAdminsObject[currentUserData.key] = true;

        let userRef = firebase.database().ref("users").child(props.user.key);
        userRef.update({ admins: userAdminsObject });
        history.replace("manageuser/" + props.user.key);
    }
    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            TransitionComponent={Transition}
        >
            <Container fluid className={classes.root}>
                <Col>
                    <Row>
                        <h4 className={classes.title}>Duplicate User</h4>
                    </Row>
                    <Row>
                        <span>
                            {"The email address " +
                                props.user.email +
                                " is already in use by the following user:"}
                        </span>
                    </Row>
                    <Row>
                        <div key={props.user.key} className={classes.listItem}>
                            <ListItem
                                alignItems="center"
                                // onClick={handleManageUserClick(user)}
                            >
                                <ListItemAvatar>
                                    <UserAvatar
                                        userOrHub={props.user}
                                        path="users"
                                        variant="circular"
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    className={classes.listText}
                                    primary={
                                        props.user.firstName +
                                        " " +
                                        props.user.lastName
                                    }
                                    secondary={props.user.customRoleName}
                                />
                            </ListItem>
                        </div>
                    </Row>
                    <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleBecomeAdminPress}
                    >
                        Become Administrator
                    </Button>
                </Col>
            </Container>
        </Dialog>
    );
}
