import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CloseIcon from "@material-ui/icons/Close";
import { ListSubheader } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

// My Files
// import "../calendar/Calendar.css";
import { DatabaseContext, UserContext } from "../../context";
import UserAvatar from "../management/UserAvatar";
import firebase from "../../firebase";
import { jsonFromArray } from "../../utility";

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
        padding: 0,
    },
    container: {
        height: "100%",
        margin: 0,
        paddingLeft: 15,
    },
    chip: {
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
    },
    nameTextField: {
        marginLeft: 15,
        marginRight: 15,
    },
    title: {
        flexGrow: 1,
    },
    sectionTitle: {
        marginLeft: 15,
        marginTop: 10,
    },
    notificationsSwitch: {
        marginLeft: 15,
        marginTop: 20,
    },
    notificationsMessage: {
        marginLeft: 25,
        marginRight: 15,
        marginTop: 10,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareTimelineDialog(props) {
    const classes = useStyles();

    const { users, groups, folders } = useContext(DatabaseContext);
    const { currentUserData } = useContext(UserContext);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [usernameFilter, setUsernameFilter] = useState("");

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [allHubs, setAllHubs] = useState([]);

    const [sendNotifications, setSendNotifications] = useState(false);
    const [notificationsMessage, setNotificationsMessage] = useState("");

    useEffect(() => {
        // Populate selected users and groups
        let selectedUsersArray = [];
        if (props.timeline.users) {
            props.timeline.users.forEach((userKey) => {
                let matchingUser = users.filter((u) => u.key === userKey)[0];
                if (matchingUser) {
                    selectedUsersArray.push(matchingUser);
                }
            });
        }

        setSelectedUsers(selectedUsersArray);

        let selectedGroupsArray = [];

        if (props.timeline.groups) {
            props.timeline.groups.forEach((groupKey) => {
                let matchingGroup = groups.filter((g) => g.key === groupKey)[0];
                if (matchingGroup) {
                    selectedGroupsArray.push(matchingGroup);
                }
            });
        }

        setSelectedGroups(selectedGroupsArray);

        // Get the groups and users that should be visible (all those that can see the video file)
        let hubsArray = [];
        if (props.file.groups && allHubs.length === 0) {
            props.file.groups.forEach((groupKey) => {
                let group = groups.filter((g) => g.key === groupKey)[0];
                if (group) {
                    // console.log(group);

                    let hub = hubsArray.filter((h) => h.key === group.hub)[0];

                    if (hub) {
                        if (group.name === "All Users") {
                            group.displayName = "All in '" + hub.name + "'";
                        } else {
                            group.displayName = group.name;
                        }

                        hub.groups.push(group);
                    } else {
                        let folder = folders.filter(
                            (f) => f.key === group.hub
                        )[0];
                        if (folder) {
                            if (group.name === "All Users") {
                                group.displayName =
                                    "All in '" + folder.name + "'";
                            } else {
                                group.displayName = group.name;
                            }

                            hubsArray.push({
                                key: group.hub,
                                name: folder.name,
                                groups: [group],
                            });
                        }
                    }

                    // groupsArray.push(group);
                }
            });
            setAllHubs(hubsArray);
        }

        // eslint-disable-next-line
    }, [props.open]);

    const handleUserSelect = (user) => () => {
        let selectedArray = [...selectedUsers];
        selectedArray.push(user);
        setSelectedUsers(selectedArray);
        setUsernameFilter("");
    };

    const handleGroupSelect = (group) => () => {
        // Get the users from this group and remove them from the users which can be selected
        let selectedUsersArray = [...selectedUsers];

        group.users.forEach((user) => {
            let matchingUsers = users.filter((u) => u.key === user.key);

            if (matchingUsers.length === 1) {
                let userToSelect = matchingUsers[0];

                // Only add the user if they hadn't already been selected as an individual
                if (
                    selectedUsersArray.filter((u) => u.key === userToSelect.key)
                        .length === 0
                ) {
                    selectedUsersArray.push(userToSelect);
                }

                setSelectedUsers(selectedUsersArray);
            }
        });

        // Now select the group
        let selectedArray = [...selectedGroups];
        selectedArray.push(group);
        setSelectedGroups(selectedArray);
    };

    const handleDeleteUser = (user) => () => {
        let selectedArray = selectedUsers.filter((u) => u.key !== user.key);

        setSelectedUsers(selectedArray);
    };

    const handleDeleteGroup = (group) => () => {
        // Remove the users that were added from this group, unless they are also in a still selected group

        let selectedUsersArray = [...selectedUsers];

        group.users.forEach((user) => {
            let shouldRemove = true;

            selectedGroups.forEach((selectedGroup) => {
                if (selectedGroup.key !== group.key) {
                    if (
                        selectedGroup.users.filter((u) => u.key === user.key)
                            .length > 0
                    ) {
                        shouldRemove = false;
                    }
                }
            });

            if (shouldRemove) {
                selectedUsersArray = selectedUsersArray.filter(
                    (u) => u.key !== user.key
                );
            }
        });

        setSelectedUsers(selectedUsersArray);

        //
        let selectedArray = selectedGroups.filter((u) => u.key !== group.key);
        setSelectedGroups(selectedArray);
    };

    function handleCancelPress() {
        setUsernameFilter("");
        setNotificationsMessage("");
        setSendNotifications(false);
        setSelectedGroups([]);
        setSelectedUsers([]);
        props.onClose();
    }

    function handleConfirmPress() {
        // Updates the users and groups array in the timeline
        let groupsObject = jsonFromArray(selectedGroups);

        let usersObject = {};

        selectedUsers.forEach((user) => {
            if (!isUserInSelectedGroups(user)) {
                usersObject[user.key] = true;
            }
        });

        let timelinesRef = firebase.database().ref("timelines");

        timelinesRef
            .child(props.timeline.key)
            .update({ users: usersObject, groups: groupsObject });

        let timelineInfoRef = firebase.database().ref("timelineinfo");

        timelineInfoRef
            .child(props.timeline.key)
            .update({ users: usersObject, groups: groupsObject });

        // Add record to file usage
        let ref = firebase.database().ref("fileusage");

        ref.push({
            file: props.file.key,
            fileName: props.file.displayName,
            fileType: props.file.type,
            user: currentUserData.key,
            action: "TimelineShare",
            usersSharedWith: usersObject,
            groupsSharedWith: groupsObject,
            date: dayjs().toString(),
        });

        if (sendNotifications) {
            var notificationsRef = firebase.database().ref("notifications");

            notificationsRef.child(props.timeline.key).set({
                file: props.timeline.video,
                title: props.timeline.name,
                type: "timeline",
                users: jsonFromArray(selectedUsers),
                message: notificationsMessage,
                date: dayjs().toString(),
            });
        }

        let userCount = selectedUsers.length;
        setUsernameFilter("");
        setNotificationsMessage("");
        setSendNotifications(false);
        setSelectedGroups([]);
        setSelectedUsers([]);
        props.onClose(userCount);
        setSelectedUsers([]);
    }

    const handleChangeFilterText = (event) => {
        setUsernameFilter(event.target.value);
    };

    function isUserInSelectedGroups(user) {
        for (let i = 0; i < selectedGroups.length; i++) {
            let group = selectedGroups[i];
            for (let j = 0; j < group.users.length; j++) {
                let u = group.users[j];

                if (user.key === u.key) {
                    return true;
                }
            }
        }
        return false;
    }

    function groupLabel(group) {
        if (group.displayName && group.displayName !== "") {
            if (group.users === 1) {
                return group.displayName + " (1 User)";
            } else {
                return (
                    group.displayName + " (" + group.users.length + " Users)"
                );
            }
        } else {
            if (group.users === 1) {
                return group.name + " (1 User)";
            } else {
                return group.name + " (" + group.users.length + " Users)";
            }
        }
    }

    function userShouldBeVisible(user) {
        if (currentUserData) {
            if (user.key === currentUserData.key) {
                return false;
            }

            var hasPermissionToSee = false;

            if (props.file.managers && props.file.managers.includes(user.key)) {
                hasPermissionToSee = true;
            }

            if (user.files.includes(props.file.key)) {
                hasPermissionToSee = true;
            }

            allHubs.forEach((hub) => {
                hub.groups.forEach((group) => {
                    if (
                        user.firstName === "Matthew" &&
                        user.lastName === "Parry"
                    ) {
                    }

                    if (
                        group.users.filter((u) => u.key === user.key).length > 0
                    ) {
                        hasPermissionToSee = true;
                    }
                });
            });

            if (hasPermissionToSee) {
                if (
                    selectedUsers.filter((u) => u.key === user.key).length === 0
                ) {
                    if (
                        user.fullName
                            .toLowerCase()
                            .includes(usernameFilter.toLowerCase())
                    ) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    function handleSwitchChange(event) {
        if (event.target.name === "send") {
            setSendNotifications(event.target.checked);
        }
    }

    const handleChangeMessageText = (event) => {
        setNotificationsMessage(event.target.value);
    };

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <Tooltip title="Cancel">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCancelPress}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h6" className={classes.title}>
                        Share Timeline
                    </Typography>
                    <Tooltip title="Confirm">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleConfirmPress}
                            aria-label="close"
                        >
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container fluid className={classes.container}>
                <Row>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendNotifications}
                                    onChange={handleSwitchChange}
                                    name="send"
                                    color="primary"
                                />
                            }
                            label={"Send Notifications"}
                            className={classes.notificationsSwitch}
                        />
                    </FormGroup>
                    {sendNotifications && (
                        <TextField
                            id="standard-multiline-flexible"
                            label="Notification Message"
                            variant="outlined"
                            maxRows={15}
                            value={notificationsMessage}
                            onChange={handleChangeMessageText}
                            className={classes.notificationsMessage}
                        />
                    )}
                </Row>
                <Divider style={{ marginTop: 10 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Selected</h3>
                </Row>
                <Row>
                    {selectedGroups.map((group) => (
                        <Chip
                            key={group.key}
                            label={groupLabel(group)}
                            className={classes.chip}
                            // color="secondary"
                            onDelete={handleDeleteGroup(group)}
                        />
                    ))}
                </Row>
                <Row>
                    {selectedUsers
                        .sort((a, b) =>
                            a.lastName.toLowerCase() > b.lastName.toLowerCase()
                                ? 1
                                : -1
                        )
                        .map(
                            (user) =>
                                !isUserInSelectedGroups(user) && (
                                    <Chip
                                        avatar={
                                            <Avatar
                                                alt={user.fullName}
                                                src={user.avatar}
                                            />
                                        }
                                        key={user.key}
                                        label={user.fullName}
                                        className={classes.chip}
                                        onDelete={
                                            user.key !== currentUserData.key &&
                                            handleDeleteUser(user)
                                        }
                                        // clickable
                                        // color="primary"
                                        variant="outlined"
                                    />
                                )
                        )}
                </Row>
                <Divider style={{ marginTop: 25 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Groups</h3>
                </Row>
                <Row style={{ margin: 0, padding: 0 }}>
                    <Row>
                        {allHubs.map(
                            (hub) =>
                                hub.groups &&
                                hub.groups.length > 0 && (
                                    <React.Fragment key={hub.key}>
                                        <ListSubheader>
                                            {hub.name}
                                        </ListSubheader>

                                        <ListItem
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                            }}
                                        >
                                            {hub.groups
                                                .filter(
                                                    (group) =>
                                                        !selectedGroups.includes(
                                                            group
                                                        )
                                                )
                                                .sort((a, b) => {
                                                    if (a.name > b.name)
                                                        return 1;
                                                    if (a.name < b.name)
                                                        return -1;
                                                    return 0;
                                                })
                                                .map((group) => (
                                                    <Chip
                                                        key={group.key}
                                                        label={groupLabel(
                                                            group
                                                        )}
                                                        className={classes.chip}
                                                        clickable
                                                        onClick={handleGroupSelect(
                                                            group
                                                        )}
                                                    />
                                                ))}
                                        </ListItem>
                                    </React.Fragment>
                                )
                        )}
                    </Row>
                </Row>
                <Divider style={{ marginTop: 25 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Users</h3>
                </Row>
                <Row>
                    <TextField
                        id="standard-basic"
                        label="Type User Name"
                        className={classes.nameTextField}
                        fullWidth
                        value={usernameFilter}
                        onChange={handleChangeFilterText}
                    ></TextField>
                </Row>

                <Row>
                    <List className={classes.list}>
                        {users
                            .filter((user) => userShouldBeVisible(user))
                            .map((user) => (
                                <div
                                    key={user.key}
                                    onClick={handleUserSelect(user)}
                                >
                                    <ListItem
                                        button
                                        alignItems="flex-start"
                                        // selected={selectedIndex === index}
                                    >
                                        <ListItemAvatar>
                                            <UserAvatar
                                                userOrHub={user}
                                                path="users"
                                                variant="circular"
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.fullName}
                                            secondary={user.customRoleName}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            ))}
                    </List>
                </Row>
            </Container>
        </Dialog>
    );
}
