import React, {
    useEffect,
    useContext,
    useState,
    useReducer,
    useCallback,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";

// import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from "react-player";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Tooltip, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import TheatersIcon from "@material-ui/icons/Theaters";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import BackButton from "../BackButton";
import firebase from "../../firebase";
import NoPermissionsMessage from "../NoPermissionsMessage";
import {
    jsonFromUserEnteredStringArray,
    makeid,
    shouldFileBeVisible,
    timeString,
    commaSeperatedStringFromArray,
    commaSeperatedStringFromLabels,
} from "../../utility";
import MarkOutDialog from "./MarkOutDialog";
import ToolbarWithLogo from "../ToolbarWithLogo";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import DialogWithTextInput from "../DialogWithTextInput";
import KeyDownHandler from "../KeyDownHandler";
import TimelineFilterDialog from "./TimelineFilterDialog";
import ShareTimelineDialog from "./ShareTimelineDialog";
import CopyTimelineDialog from "./CopyTimelineDialog";
import SavePlaylistDialog from "./SavePlaylistDialog";

const useStyles = makeStyles((theme) => ({
    videoPlayer: {
        marginTop: 0,
    },
    columnPadding: {
        [theme.breakpoints.down("sm")]: { paddingLeft: 0 },
        paddingRight: 0,
    },
    pageContainer: {
        paddingBottom: 65,
    },
    displayName: {
        flexGrow: 1,
        paddingTop: 10,
        fontSize: 24,
        [theme.breakpoints.down("sm")]: { paddingLeft: 15, fontSize: 19 },
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        textOverflow: "ellipsis",
    },
    titleRow: {
        marginRight: 0,
    },
    tableContainer: {
        marginTop: 15,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        overflow: "scroll",
    },
    starIcon: {
        color: theme.palette.primary.main,
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
        cursor: "pointer",
    },
    previewHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
        width: 50,
    },
    markInButton: { width: "100%" },
    filterButton: {
        position: "absolute",
        right: 0,
        left: "auto",
    },
}));

function createData(key, index, inTime, outTime, name, labels, notes, stars) {
    return {
        key: key,
        index: index,
        inTime: inTime,
        outTime: outTime,
        name: name,
        labels: labels,
        notes: notes,
        stars: stars,
        selected: true,
        filteredOutByCode: false,
        filteredOutByRating: false,
        filteredOutByLabels: false,
    };
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SimpleVideoAnalysisPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const { files, groups } = useContext(DatabaseContext);

    const [timeline, setTimeline] = useState();

    const [file, setFile] = useState();
    const playerRef = React.useRef();

    const { currentUserData } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);

    // const [playing, setPlaying] = useState(false);

    const [userHasPermission, setUserHasPermission] = useState();
    const [userCanUsePlaylists, setUserCanUsePlaylists] = useState(false);

    const [markButtonTitle, setMarkButtonTitle] = useState("Mark In");

    const [markInTime, setMarkInTime] = useState();
    const [markOutTime, setMarkOutTime] = useState();

    const [rows, setRows] = useState([]);

    const [markOutDialogVisible, setMarkOutDialogVisible] = useState(false);

    const [playing, setPlaying] = useState(false);

    const [rowToEdit, setRowToEdit] = useState();

    const [timelineNameDialogOpen, setTimelineNameDialogOpen] = useState(false);
    const [playlistNameDialogOpen, setPlaylistNameDialogOpen] = useState(false);
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [copyDialogTitle, setCopyDialogTitle] = useState("");
    const [copyDialogMessage, setCopyDialogMessage] = useState("");

    // const [editingTimeline, setEditingTimeline] = useState();

    const [timelineKey, setTimelineKey] = useState("");
    const [timelineName, setTimelineName] = useState("");

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const [currentClip, setCurrentClip] = useState(); // Used when previewing clips

    const [showDetailsColumn, setShowDetailsColumn] = useState(false);

    const [sortDirection, setSortDirection] = useState("Ascending");

    const [filterDialogVisible, setFilterDialogVisible] = useState(false);

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusIsVisible, setStatusIsVisible] = useState(false);

    const [isOwner, setIsOwner] = useState(false);

    const [ratings, setRatings] = useState([]); // Just use this to preserve whether groups are selected in the filter
    const [groupsAfterFiltering, setGroupsAfterFiltering] = useState([]); // Just use this to preserve whether groups are selected in the filter

    const getTimeline = useCallback(
        async (key) => {
            let timelineRef = firebase.database().ref("timelines").child(key);

            if (!timeline) {
                // !timeline
                console.log("Reading Timeline from Firebase");
                timelineRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    if (data) {
                        let instances = [];

                        if (data.instances) {
                            // Turn messages from a JSON object to an array

                            let clipKeys = Object.keys(data.instances);
                            let clipValues = Object.values(data.instances);

                            for (let i = 0; i < clipKeys.length; i++) {
                                // Labels
                                let instanceLabels = [];
                                if (clipValues[i].labels) {
                                    // Change to Object.values!
                                    let labelTextValues = Object.values(
                                        clipValues[i].labels
                                    );

                                    for (
                                        let i = 0;
                                        i < labelTextValues.length;
                                        i++
                                    ) {
                                        instanceLabels.push(labelTextValues[i]);
                                    }
                                }

                                // Notes
                                let instanceNotes = [];
                                if (clipValues[i].notes) {
                                    let noteTextValues = Object.values(
                                        clipValues[i].notes
                                    );

                                    for (
                                        let i = 0;
                                        i < noteTextValues.length;
                                        i++
                                    ) {
                                        instanceNotes.push(noteTextValues[i]);
                                    }
                                }

                                instances.push({
                                    key: clipKeys[i],
                                    index: clipValues[i].index,
                                    name: clipValues[i].name,
                                    labels: instanceLabels,
                                    notes: instanceNotes,
                                    note: clipValues[i].note,
                                    stars: clipValues[i].stars,
                                    inTime: clipValues[i].inTime,
                                    outTime: clipValues[i].outTime,
                                });
                            }
                        }

                        // Groups
                        let timelineGroups = [];
                        if (data.groups) {
                            let groupKeys = Object.keys(data.groups);

                            for (let i = 0; i < groupKeys.length; i++) {
                                timelineGroups.push(groupKeys[i]);
                            }
                        }

                        // Users
                        let timelineUsers = [];
                        if (data.users) {
                            let userKeys = Object.keys(data.users);

                            for (let i = 0; i < userKeys.length; i++) {
                                timelineUsers.push(userKeys[i]);
                            }
                        }

                        let timeline = {
                            key: key,
                            instances: instances,
                            name: data.name,
                            owner: data.owner,
                            lastOpened: data.lastOpened,
                            created: data.created,
                            video: data.video,
                            users: timelineUsers,
                            groups: timelineGroups,
                        };

                        setTimeline(timeline);
                    } else {
                        console.log("No Data");
                    }
                });
            } else {
                console.log("Already Read Timeline");
            }
        },
        [timeline]
    );

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData && rows.length === 0) {
            const urlParams = new URLSearchParams(location.search);
            const timelineKey = urlParams.get("key");
            const video = urlParams.get("video");

            let matchingFile = files.filter((f) => f.key === video)[0];

            //
            if (currentUserData.permissions.createOwnPlaylist === true) {
                setUserCanUsePlaylists(true);
            } else {
                setUserCanUsePlaylists(false);
            }

            //
            if (
                matchingFile &&
                shouldFileBeVisible(matchingFile, groups, currentUserData)
            ) {
                setUserHasPermission(true);
                setFile(matchingFile);
                var shouldShowDetailsColumn = false;

                getTimeline(timelineKey);

                if (timeline) {
                    setTimelineKey(timeline.key);
                    setTimelineName(timeline.name);

                    if (timeline.owner === currentUserData.key) {
                        setIsOwner(true);
                    } else {
                        setIsOwner(false);
                    }

                    let rowsArray = [];
                    timeline.instances
                        .sort((a, b) => {
                            if (a.index > b.index) return 1;
                            if (a.index < b.index) return -1;
                            return 0;
                        })
                        .forEach((instance) => {
                            if (instance.labels.length > 0) {
                                shouldShowDetailsColumn = true;
                            }

                            instance.labels.forEach((label) => {
                                label.filteredOutByLabel = false;
                            });

                            rowsArray.push(
                                createData(
                                    instance.key,
                                    instance.index,
                                    instance.inTime,
                                    instance.outTime,
                                    instance.name,
                                    instance.labels,
                                    instance.notes,
                                    instance.stars
                                )
                            );
                        });

                    setShowDetailsColumn(shouldShowDetailsColumn);
                    setRows(rowsArray);
                    setRatings([
                        { stars: 0, selected: true },
                        { stars: 1, selected: true },
                        { stars: 2, selected: true },
                        { stars: 3, selected: true },
                    ]);

                    // Update most recent opened date for the timeline
                    let timelinesRef = firebase.database().ref("timelines");
                    console.log("Updating Timeline Last Opened");
                    timelinesRef
                        .child(timeline.key)
                        .update({ lastOpened: dayjs().toString() });

                    let timelineInfoRef = firebase
                        .database()
                        .ref("timelineinfo");

                    timelineInfoRef
                        .child(timeline.key)
                        .update({ lastOpened: dayjs().toString() });
                }
            } else {
                setUserHasPermission(false);
            }
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [currentUserData, timeline]);

    // Called from KeyDownHandler component
    function keyWasPressed(e) {
        if (
            markOutDialogVisible ||
            playlistNameDialogOpen ||
            timelineNameDialogOpen ||
            confirmDeleteDialogOpen ||
            shareDialogOpen ||
            e.target.nodeName === "VIDEO"
        ) {
            return;
        }

        if (e.code === "Space") {
            e.preventDefault();

            if (playing === true) {
                handlePause();
            } else {
                handlePlay();
            }
        }
    }

    function handleMarkButtonPress() {
        if (!isOwner) {
            setCopyDialogTitle("Cannot edit shared timeline");
            setCopyDialogMessage(
                "Create your own copy of this timeline, which you can then edit?"
            );
            setCopyDialogOpen(true);

            return;
        }

        if (markButtonTitle === "Mark In") {
            // Opening an instance

            setMarkButtonTitle("Mark Out");
            setMarkInTime(playerRef.current.getCurrentTime());
        } else {
            // Closing An Instance

            setMarkOutTime(playerRef.current.getCurrentTime());
            setPlaying(false);

            setMarkOutDialogVisible(true);
        }
    }

    function handleAddNewRow(name, notes, stars) {
        if (name) {
            let timelinesRef = firebase.database().ref("timelines");

            let newInstanceKey = makeid(19);

            // Add instance to firebase
            timelinesRef
                .child(timelineKey)
                .child("instances")
                .child(newInstanceKey)
                .set({
                    index: rows.length,
                    inTime: markInTime,
                    outTime: markOutTime,
                    name: name,
                    notes: jsonFromUserEnteredStringArray(notes),
                    stars: stars,
                });

            // Add instance to local rows array
            setRows([
                ...rows,
                createData(
                    newInstanceKey,
                    rows.length,
                    markInTime,
                    markOutTime,
                    name,
                    [],
                    notes,
                    stars
                ),
            ]);
        }

        setPlaying(true);
        handleCloseMarkOutDialog();
    }

    function handleDeleteRow(row) {
        let timelinesRef = firebase.database().ref("timelines");

        setRows(rows.filter((r) => r.key !== row.key));

        timelinesRef
            .child(timelineKey)
            .child("instances")
            .child(row.key)
            .remove();

        handleCloseMarkOutDialog();
    }

    function handleEditRow(row) {
        // Edit the row in firebase (the local version has already been edited in MarkOutDialog component)

        let timelinesRef = firebase.database().ref("timelines");

        timelinesRef
            .child(timelineKey)
            .child("instances")
            .child(row.key)
            .update({
                inTime: row.inTime,
                outTime: row.outTime,
                name: row.name,
                notes: jsonFromUserEnteredStringArray(row.notes),
                stars: row.stars,
            });

        handleCloseMarkOutDialog();
    }

    function handlePlay() {
        setPlaying(true);
    }

    function handlePause() {
        setPlaying(false);
    }

    const handleRowSelect = (row) => () => {
        if (!isOwner) {
            setCopyDialogTitle("Cannot edit shared timeline");
            setCopyDialogMessage(
                "Create your own copy of this timeline, which you can then edit?"
            );
            setCopyDialogOpen(true);

            return;
        }

        setPlaying(false);
        setRowToEdit(row);
        setMarkOutDialogVisible(true);
    };

    const handlePreviewRowPressed = (row) => () => {
        setCurrentClip(row);
        playerRef.current.seekTo(row.inTime);
        setPlaying(true);
    };

    function handleCloseDeleteDialog(result) {
        if (result === true && currentUserData) {
            // Delete the timeline from firebase
            let timelinesRef = firebase.database().ref("timelines");

            timelinesRef.child(timelineKey).remove();

            let timelineInfoRef = firebase.database().ref("timelineinfo");

            timelineInfoRef.child(timelineKey).remove();

            history.goBack();
        }

        setConfirmDeleteDialogOpen(false);
    }

    function handleDeleteTimelineButtonPress() {
        handleMenuClose();
        setConfirmDeleteDialogOpen(true);
    }

    async function handleCreatePlaylistButtonPress() {
        handleMenuClose();

        setPlaylistNameDialogOpen(true);
        setPlaying(false);
    }

    function handleRenameTimelineButtonPress() {
        handleMenuClose();

        if (!isOwner) {
            setCopyDialogTitle("Cannot rename shared timeline");
            setCopyDialogMessage(
                "Create your own copy of this timeline, which you can then edit?"
            );
            setCopyDialogOpen(true);

            return;
        }

        setTimelineNameDialogOpen(true);
    }

    function handleCloseTimelineNameDialog(newName, okPressed) {
        if (newName !== "" && okPressed) {
            // Rename the timeline in firebase
            let timelinesRef = firebase.database().ref("timelines");

            timelinesRef.child(timelineKey).update({ name: newName });

            let timelineInfoRef = firebase.database().ref("timelineinfo");

            timelineInfoRef.child(timelineKey).update({ name: newName });

            // Rename locally

            setTimelineName(newName);
        }
        setTimelineNameDialogOpen(false);
    }

    function savePlaylist(newRows, name, includeNotes) {
        if (newRows && name) {
            // Add a record to file Usage
            let ref = firebase.database().ref("fileusage");

            ref.push({
                file: file.key,
                fileName: file.displayName,
                fileType: file.type,
                user: currentUserData.key,
                action: "PlaylistCreate",
                date: dayjs().toString(),
            });

            // Create the playlist
            let playlistsRef = firebase.database().ref("playlists");

            // /inTime, outTime, name, note, stars

            let clips = {};
            let clipsArray = [];

            var index = 0;
            newRows.forEach((row) => {
                if (row.selected && shouldShowInTable(row)) {
                    var note = "";

                    if (includeNotes) {
                        note = commaSeperatedStringFromArray(row.notes);
                    }

                    let clipObject = {
                        index: index,
                        inTime: row.inTime,
                        outTime: row.outTime,
                        name: row.name,
                        note: note,
                        labels: jsonFromUserEnteredStringArray(row.labels),
                        stars: row.stars,
                        video: file.key,
                    };

                    index++;
                    clips[row.key] = clipObject;
                    clipsArray.push(clipObject);
                }
            });

            let key = makeid(19);

            let firebasePlaylistObject = {
                key: key,
                name: name,
                owner: currentUserData.key,
                video: file.key,
                clips: clips,
                created: dayjs().toString(),
            };

            playlistsRef.child(key).set(firebasePlaylistObject);

            let playlistInfoRef = firebase.database().ref("playlistinfo");

            playlistInfoRef.child(key).set({
                key: key,
                name: name,
                owner: currentUserData.key,
                video: file.key,
                created: dayjs().toString(),
            });

            history.replace("/playlist?key=" + key);
        }
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function handleProgress(progress) {
        if (currentClip) {
            if (progress.playedSeconds > currentClip.outTime) {
                setPlaying(false);
                setCurrentClip(null);
            }
        }
    }

    function handleCloseMarkOutDialog() {
        setRowToEdit(null);
        setMarkButtonTitle("Mark In");
        setMarkInTime();
        setMarkOutDialogVisible(false);
    }

    const handleSelectRowCheckboxChange = (row) => () => {
        row.selected = !row.selected;

        setRows([...rows]);
    };

    function handleExportTimelineButtonPress() {
        exportTimelineToXML(timeline.instances, timeline.name);
        setAnchorEl(null);
    }

    function exportTimelineToXML(rows, filename) {
        var XML = require("xml");
        // console.clear();
        // instancesArray.push({ ID: "2", start: "45" });

        var uniqueCodes = [];

        var instancesArray = [];

        // Add the instances
        rows.sort((a, b) => {
            if (a.index > b.index) return 1;
            if (a.index < b.index) return -1;
            return 0;
        }).forEach((row) => {
            // console.log(row);

            if (!uniqueCodes.includes(row.name)) {
                uniqueCodes.push(row.name);
            }

            var instance = [
                { ID: row.index + 1 },
                { start: row.inTime },
                { end: row.outTime },
                { code: row.name },
            ];

            if (row.stars > 0) {
                instance.push({ stars: row.stars });
            }

            row.labels.forEach((label) => {
                let labelArray = [{ text: label.text }];

                if (label.group) {
                    labelArray.push({ group: label.group });
                }

                if (label.start) {
                    labelArray.push({ start: label.start });
                }
                instance.push({
                    label: labelArray,
                });
            });

            row.notes.forEach((note) => {
                instance.push({ free_text: note });
            });

            instancesArray.push({
                instance: instance,
            });
        });

        // Add the Rows at the end of the file

        var rowsArray = [];
        uniqueCodes.forEach((code) => {
            var row = [
                { code: code },
                { R: 0 },
                { G: 39321 },
                { B: 13107 },
                { red: 0.0 },
                { green: 0.6 },
                { blue: 0.2 },
                { fontRed: 1.0 },
                { fontGreen: 1.0 },
                { fontBlue: 1.0 },
            ];

            rowsArray.push({ row: row });
        });

        var xmlFile = [
            {
                file: [{ ALL_INSTANCES: instancesArray }, { ROWS: rowsArray }],
            },
        ];

        // console.log(XML(example3));
        //result: <toys><toy>Transformers</toy><toy>GI Joe</toy><toy>He-man</toy></toys>
        // console.log(XML(example3, true));
        let output = XML(xmlFile, true);

        // console.log(output);

        // Download the file
        const blob = new Blob([output], { type: "text/xml" });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.download = filename + ".xml";
        link.href = url;
        link.click();
    }

    function handleClosePlaylistNameDialog(name, okPressed, includeNotes) {
        if (name !== "" && okPressed) {
            savePlaylist(rows, name, includeNotes);
        }

        setPlaylistNameDialogOpen(false);
    }

    const handleChooseXMLClick = (e) => {
        handleMenuClose();

        if (!isOwner) {
            setCopyDialogTitle("Cannot edit shared timeline");
            setCopyDialogMessage(
                "Create your own copy of this timeline, which you can then edit?"
            );
            setCopyDialogOpen(true);
            return;
        }

        const files = e.target.files;

        if (FileReader && files && files.length) {
            var fr = new FileReader();

            var convert = require("xml-js");

            fr.onload = function () {
                let filesArray = [...files];

                for (let i = 0; i < files.length; i++) {
                    files[i].key = makeid(25);
                }

                if (filesArray.length === 1) {
                    var reader = new FileReader();
                    reader.readAsText(filesArray[0]);
                    reader.onloadend = function () {
                        var xmlData = reader.result;

                        var xmlJSON = convert.xml2json(xmlData, {
                            compact: true,
                            spaces: 4,
                        });

                        var xmlObject = JSON.parse(xmlJSON);

                        var instances = xmlObject.file.ALL_INSTANCES.instance;

                        var rowsToAdd = [];
                        instances.forEach((instance) => {
                            var code = instance.code._text;
                            var start = parseFloat(instance.start._text);
                            var end = parseFloat(instance.end._text);

                            var labelsToAdd = [];
                            var labelsFromFile = instance.label;

                            // Labels
                            if (labelsFromFile !== undefined) {
                                if (Array.isArray(labelsFromFile)) {
                                    // If there are multiple labels, then it's an array
                                    labelsFromFile.forEach((label) => {
                                        var labelObject = {};
                                        labelObject.text = label.text._text;
                                        labelObject.filteredOutByLabel = false;

                                        if (label.group) {
                                            labelObject.group =
                                                label.group._text;
                                        }
                                        if (label.start) {
                                            labelObject.start =
                                                label.start._text;
                                        }

                                        labelsToAdd.push(labelObject);
                                    });
                                } else {
                                    // If there is one label, it's an object
                                    var labelObject = {};

                                    labelObject.text =
                                        labelsFromFile.text._text;
                                    labelObject.filteredOutByLabel = false;

                                    if (labelsFromFile.group) {
                                        labelObject.group =
                                            labelsFromFile.group._text;
                                    }

                                    if (labelsFromFile.start) {
                                        labelObject.start =
                                            labelsFromFile.start._text;
                                    }

                                    labelsToAdd.push(labelObject);
                                }
                            }
                            // console.log(labelsToAdd);

                            // Notes
                            var notesToAdd = [];
                            var notesFromFile = instance.free_text;

                            if (notesFromFile !== undefined) {
                                if (Array.isArray(notesFromFile)) {
                                    notesFromFile.forEach((note) => {
                                        notesToAdd.push(note._text);
                                    });
                                } else {
                                    notesToAdd.push(notesFromFile._text);
                                }
                            }

                            rowsToAdd.push({
                                code: code,
                                start: start,
                                end: end,
                                labels: labelsToAdd,
                                notes: notesToAdd,
                            });
                        });

                        addRowsFromXML(rowsToAdd);
                    };
                }
            };

            fr.readAsDataURL(files[0]);
        }
    };

    function addRowsFromXML(rowsToAdd) {
        var newRows = rows;

        var index = rows.length;
        var shouldShowDetailsColumn = false;

        rowsToAdd.forEach((row) => {
            let timelinesRef = firebase.database().ref("timelines");

            let newInstanceKey = makeid(19);

            // Add instance to firebase
            timelinesRef
                .child(timelineKey)
                .child("instances")
                .child(newInstanceKey)
                .set({
                    index: index,
                    inTime: row.start,
                    outTime: row.end,
                    name: row.code,
                    labels: jsonFromUserEnteredStringArray(row.labels),
                    notes: jsonFromUserEnteredStringArray(row.notes),
                    stars: 0,
                });

            // Add instance to local rows array
            newRows = [
                ...newRows,
                createData(
                    newInstanceKey,
                    index,
                    row.start,
                    row.end,
                    row.code,
                    row.labels,
                    row.notes,
                    0
                ),
            ];

            if (row.labels.length > 0) {
                shouldShowDetailsColumn = true;
            }

            index += 1;
        });

        setShowDetailsColumn(shouldShowDetailsColumn);
        setRows(newRows);
    }

    function handleTimeColumnClick() {
        if (sortDirection === "Ascending") {
            setRows(rows.sort((a, b) => (a.inTime > b.inTime ? 1 : -1)));
            setSortDirection("Descending");
        } else {
            setRows(rows.sort((a, b) => (a.inTime < b.inTime ? 1 : -1)));
            setSortDirection("Ascending");
        }
    }

    function handleNameColumnClick() {
        if (sortDirection === "Ascending") {
            setRows(
                rows.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
            );
            setSortDirection("Descending");
        } else {
            setRows(
                rows.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                )
            );
            setSortDirection("Ascending");
        }
    }

    function handleDetailsColumnClick() {
        if (sortDirection === "Ascending") {
            setRows(
                rows.sort((a, b) =>
                    commaSeperatedStringFromArray(a.labels) >
                    commaSeperatedStringFromArray(b.labels)
                        ? 1
                        : -1
                )
            );
            setSortDirection("Descending");
        } else {
            setRows(
                rows.sort((a, b) =>
                    commaSeperatedStringFromArray(a.labels) <
                    commaSeperatedStringFromArray(b.labels)
                        ? 1
                        : -1
                )
            );
            setSortDirection("Ascending");
        }
    }

    function handleNoteColumnClick() {
        if (sortDirection === "Ascending") {
            setRows(
                rows.sort((a, b) =>
                    commaSeperatedStringFromArray(a.notes) >
                    commaSeperatedStringFromArray(b.notes)
                        ? 1
                        : -1
                )
            );
            setSortDirection("Descending");
        } else {
            setRows(
                rows.sort((a, b) =>
                    commaSeperatedStringFromArray(a.notes) <
                    commaSeperatedStringFromArray(b.notes)
                        ? 1
                        : -1
                )
            );
            setSortDirection("Ascending");
        }
    }

    function handleStarsColumnClick() {
        if (sortDirection === "Ascending") {
            setRows(rows.sort((a, b) => (a.stars > b.stars ? 1 : -1)));
            setSortDirection("Descending");
        } else {
            setRows(rows.sort((a, b) => (a.stars < b.stars ? 1 : -1)));
            setSortDirection("Ascending");
        }
    }

    function handleOpenFilterButtonPress() {
        setFilterDialogVisible(true);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function handleCloseFilterDialog(
        newFilteredCodes,
        newFilteredLabels,
        newFilteredRatings,
        groups
    ) {
        if (newFilteredCodes) {
            rows.forEach((row) => {
                if (newFilteredCodes.includes(row.name)) {
                    row.filteredOutByCode = false;
                } else {
                    row.filteredOutByCode = true;
                }
            });
        }

        setGroupsAfterFiltering(groups);

        if (newFilteredLabels) {
            rows.forEach((row) => {
                if (row.labels.length === 0) {
                    if (newFilteredLabels.includes("(No Detail)")) {
                        row.filteredOutByLabels = false;
                    } else {
                        row.filteredOutByLabels = true;
                    }
                } else {
                    row.labels.forEach((label) => {
                        if (newFilteredLabels.includes(label.text)) {
                            label.filteredOutByLabel = false;
                        } else {
                            label.filteredOutByLabel = true;
                        }
                    });
                }
            });
        }

        if (newFilteredRatings) {
            rows.forEach((row) => {
                if (newFilteredRatings.includes(row.stars)) {
                    row.filteredOutByRating = false;
                } else {
                    row.filteredOutByRating = true;
                }
            });
        }

        forceUpdate();

        setFilterDialogVisible(false);
    }

    function handleShareButtonPressed() {
        if (!isOwner) {
            setCopyDialogTitle("Cannot share timeline");
            setCopyDialogMessage(
                "Create your own copy of this timeline, which you can then share?"
            );
            setCopyDialogOpen(true);
            return;
        }

        handleMenuClose();

        setShareDialogOpen(true);
    }

    function handleCloseShareDialog(userCount) {
        if (userCount > 0) {
            if (userCount === 1) {
                setStatusMessage("Timeline Shared with 1 User");
            } else {
                setStatusMessage(
                    "Timeline Shared with " + userCount + " Users"
                );
            }

            setStatusType("success");
            setStatusIsVisible(true);
        }
        setShareDialogOpen(false);
    }

    function handleCloseCopyDialog(result) {
        if (result === true) {
            console.log("Creating Own Copy of Timeline");
            // Create Own Copy of the timeline for currentUser

            // console.log(timeline.instances);
            let timelinesRef = firebase.database().ref("timelines");

            var instancesJSON = {};

            timeline.instances.forEach((instanceObject) => {
                var instanceJSON = {
                    index: instanceObject.index,
                    inTime: instanceObject.inTime,
                    outTime: instanceObject.outTime,
                    name: instanceObject.name,
                    stars: instanceObject.stars,
                    labels: jsonFromUserEnteredStringArray(
                        instanceObject.labels
                    ),
                    notes: jsonFromUserEnteredStringArray(instanceObject.notes),
                };

                instancesJSON[instanceObject.key] = instanceJSON;
            });

            let newKey = makeid(19);

            timelinesRef.child(newKey).set({
                name: timelineName + " (My Copy)",
                owner: currentUserData.key,
                video: file.key,
                created: dayjs().toString(),
                instances: instancesJSON,
            });

            let timelineInfoRef = firebase.database().ref("timelineinfo");

            timelineInfoRef.child(newKey).set({
                name: timelineName + " (My Copy)",
                owner: currentUserData.key,
                video: file.key,
                created: dayjs().toString(),
            });

            setStatusMessage("Timeline Copied Successfully");
            setStatusType("success");
            setStatusIsVisible(true);
            setRows([]);

            setTimeline(null);
            history.replace(
                "/analysis?video=" + timeline.video + "&key=" + newKey
            );
        }

        setCopyDialogOpen(false);
    }

    function shouldShowInTable(row) {
        // console.log("Show?");
        // console.log(row);

        // return true;

        var filteredOut = false;
        if (row.filteredOutByCode === true) {
            filteredOut = true;
        }

        if (row.labels.length === 0) {
            if (row.filteredOutByLabels === true) {
                filteredOut = true;
            }
        } else {
            if (
                row.labels.filter((label) => label.filteredOutByLabel === false)
                    .length === 0
            ) {
                filteredOut = true;
            }
        }

        if (row.filteredOutByRating === true) {
            filteredOut = true;
        }

        if (filteredOut === true) {
            return false;
        }

        return true;
    }

    return (
        <>
            {userHasPermission && (
                <>
                    <BackButton />
                    <Tooltip title="Filter">
                        <IconButton
                            className={classes.filterButton}
                            component="span"
                            onClick={handleOpenFilterButtonPress}
                            aria-label="openFilterButtonPress"
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    <Container fluid className={classes.pageContainer}>
                        <Row>
                            <Col
                                className={classes.columnPadding}
                                lg={6}
                                md={12}
                                sm={12}
                            >
                                {file && (
                                    <>
                                        <ReactPlayer
                                            className={classes.videoPlayer}
                                            ref={playerRef}
                                            url={
                                                "https://vz-db0e7359-d70.b-cdn.net/" +
                                                file.key +
                                                "/playlist.m3u8"
                                            }
                                            // autoPlay={false}
                                            controls={true}
                                            playing={playing}
                                            onPlay={handlePlay}
                                            onPause={handlePause}
                                            onEnded={handlePause}
                                            onProgress={handleProgress}
                                            width="100%"
                                            // hlsConfig={{
                                            //     startPosition: -1,
                                            // }}
                                            height="auto"
                                        />
                                        <Row className={classes.titleRow}>
                                            <Col>
                                                <Typography
                                                    className={
                                                        classes.displayName
                                                    }
                                                >
                                                    {file.displayName +
                                                        " - " +
                                                        timelineName}
                                                </Typography>
                                            </Col>
                                            {userCanUsePlaylists && (
                                                <Tooltip title="Create Playlist">
                                                    <IconButton
                                                        onClick={
                                                            handleCreatePlaylistButtonPress
                                                        }
                                                        className={
                                                            classes.menuButton
                                                        }
                                                        aria-label="createPlaylistButtonPress"
                                                    >
                                                        <TheatersIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <IconButton
                                                onClick={handleMenuOpen}
                                                className={classes.menuButton}
                                                aria-label="moreButtonPress"
                                            >
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </Row>
                                    </>
                                )}
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleMarkButtonPress}
                                    className={classes.markInButton}
                                >
                                    {markButtonTitle}
                                </Button>

                                <TableContainer
                                    component={Paper}
                                    className={classes.tableContainer}
                                >
                                    <Table aria-label="simple table">
                                        <TableHead
                                            className={classes.tableHead}
                                        >
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.previewHeaderCell
                                                    }
                                                    align="center"
                                                ></TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableHeaderCell
                                                    }
                                                    align="center"
                                                    onClick={
                                                        handleTimeColumnClick
                                                    }
                                                >
                                                    Time
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableHeaderCell
                                                    }
                                                    align="center"
                                                    onClick={
                                                        handleNameColumnClick
                                                    }
                                                >
                                                    Name
                                                </TableCell>
                                                {showDetailsColumn && (
                                                    <TableCell
                                                        className={
                                                            classes.tableHeaderCell
                                                        }
                                                        align="center"
                                                        onClick={
                                                            handleDetailsColumnClick
                                                        }
                                                    >
                                                        Details
                                                    </TableCell>
                                                )}
                                                <TableCell
                                                    className={
                                                        classes.tableHeaderCell
                                                    }
                                                    align="center"
                                                    onClick={
                                                        handleNoteColumnClick
                                                    }
                                                >
                                                    Note
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableHeaderCell
                                                    }
                                                    align="center"
                                                    onClick={
                                                        handleStarsColumnClick
                                                    }
                                                >
                                                    Stars
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableHeaderCell
                                                    }
                                                    align="center"
                                                >
                                                    Playlist
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {rows.length === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    />
                                                    <TableCell
                                                        style={{
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    />
                                                    <TableCell
                                                        style={{
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    />
                                                    <TableCell />
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {rows
                                                    .filter(
                                                        (row) =>
                                                            shouldShowInTable(
                                                                row
                                                            ) === true
                                                    )
                                                    .map((row) => (
                                                        <TableRow
                                                            key={row.key}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <TableCell
                                                                onClick={handlePreviewRowPressed(
                                                                    row
                                                                )}
                                                                component="th"
                                                                scope="row"
                                                                align="center"
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgb(200,200,200)",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    onClick={handlePreviewRowPressed(
                                                                        row
                                                                    )}
                                                                >
                                                                    <PlayArrowIcon />
                                                                </IconButton>
                                                            </TableCell>

                                                            <TableCell
                                                                onClick={handleRowSelect(
                                                                    row
                                                                )}
                                                                component="th"
                                                                scope="row"
                                                                align="center"
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgb(200,200,200)",
                                                                }}
                                                            >
                                                                {timeString(
                                                                    row.inTime
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={handleRowSelect(
                                                                    row
                                                                )}
                                                                align="center"
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgb(200,200,200)",
                                                                }}
                                                            >
                                                                {row.name}
                                                            </TableCell>
                                                            {showDetailsColumn && (
                                                                <TableCell
                                                                    onClick={handleRowSelect(
                                                                        row
                                                                    )}
                                                                    align="center"
                                                                    style={{
                                                                        borderRight:
                                                                            "1px solid rgb(200,200,200)",
                                                                    }}
                                                                >
                                                                    {commaSeperatedStringFromLabels(
                                                                        row.labels
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            <TableCell
                                                                onClick={handleRowSelect(
                                                                    row
                                                                )}
                                                                align="center"
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgb(200,200,200)",
                                                                }}
                                                            >
                                                                {commaSeperatedStringFromArray(
                                                                    row.notes
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={handleRowSelect(
                                                                    row
                                                                )}
                                                                align="center"
                                                                style={{
                                                                    borderRight:
                                                                        "1px solid rgb(200,200,200)",
                                                                }}
                                                            >
                                                                {row.stars ===
                                                                    0 && (
                                                                    <div>
                                                                        <StarBorderIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarBorderIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarBorderIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {row.stars ===
                                                                    1 && (
                                                                    <div>
                                                                        <StarIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarBorderIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarBorderIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {row.stars ===
                                                                    2 && (
                                                                    <div>
                                                                        <StarIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarBorderIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {row.stars ===
                                                                    3 && (
                                                                    <div>
                                                                        <StarIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                        <StarIcon
                                                                            className={
                                                                                classes.starIcon
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <div>
                                                                    <Checkbox
                                                                        checked={
                                                                            row.selected
                                                                        }
                                                                        onChange={handleSelectRowCheckboxChange(
                                                                            row
                                                                        )}
                                                                        color="primary"
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleShareButtonPressed}>
                    Share Timeline
                </MenuItem>
                {isOwner ? (
                    <div>
                        <input
                            type="file"
                            id="xml-input"
                            onChange={handleChooseXMLClick}
                            hidden
                        />
                        <label htmlFor="xml-input">
                            <MenuItem htmlFor="xml-input">
                                Import XML Timeline
                            </MenuItem>
                        </label>
                    </div>
                ) : (
                    <MenuItem
                        htmlFor="xml-input"
                        onClick={handleChooseXMLClick}
                    >
                        Import XML Timeline
                    </MenuItem>
                )}

                <MenuItem onClick={handleExportTimelineButtonPress}>
                    Export XML Timeline
                </MenuItem>
                <MenuItem onClick={handleRenameTimelineButtonPress}>
                    Rename Timeline
                </MenuItem>
                {isOwner && (
                    <MenuItem onClick={handleDeleteTimelineButtonPress}>
                        Delete Timeline
                    </MenuItem>
                )}
            </Menu>
            <MarkOutDialog
                open={markOutDialogVisible}
                onClose={handleCloseMarkOutDialog}
                onAdd={handleAddNewRow}
                onEdit={handleEditRow}
                onDelete={handleDeleteRow}
                rowToEdit={rowToEdit}
            />
            {filterDialogVisible && (
                <TimelineFilterDialog
                    open={filterDialogVisible}
                    onClose={handleCloseFilterDialog}
                    rows={rows}
                    ratings={ratings}
                    groupsAfterFiltering={groupsAfterFiltering}
                />
            )}
            <DialogWithTextInput
                open={timelineNameDialogOpen}
                onClose={handleCloseTimelineNameDialog}
                title={"Rename Timeline"}
                message={"Enter a name for the timeline."}
                initialValue={timelineName}
            />
            <SavePlaylistDialog
                open={playlistNameDialogOpen}
                onClose={handleClosePlaylistNameDialog}
                title={"Save Playlist"}
                message={"Enter a name for the playlist."}
            />
            {confirmDeleteDialogOpen && (
                <ConfirmDeleteDialog
                    title="Delete Timeline"
                    message="Are you sure you want to delete this timeline?"
                    open={confirmDeleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                />
            )}
            {shareDialogOpen && (
                <ShareTimelineDialog
                    open={shareDialogOpen}
                    onClose={handleCloseShareDialog}
                    timeline={timeline}
                    file={file}
                />
            )}
            {copyDialogOpen && (
                <CopyTimelineDialog
                    open={copyDialogOpen}
                    onClose={handleCloseCopyDialog}
                    title={copyDialogTitle}
                    message={copyDialogMessage}
                />
            )}
            {userHasPermission === false && <NoPermissionsMessage />}
            <div>
                <Snackbar
                    open={statusIsVisible}
                    autoHideDuration={6000}
                    onClose={handleStatusClose}
                >
                    <Alert onClose={handleStatusClose} severity={statusType}>
                        {statusMessage}
                    </Alert>
                </Snackbar>
            </div>
            <ToolbarWithLogo />
            <KeyDownHandler keyWasPressed={keyWasPressed} />
        </>
    );
}
