import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Thumbnail from "react-webpage-thumbnail";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";

// My Files
import { DatabaseContext, UserContext } from "../../../../context";
import BackButton from "../../../BackButton";
import firebase from "../../../../firebase";
import CommentSection from "../CommentSection";
import ToolbarWithLogo from "../../../ToolbarWithLogo";
import NoPermissionsMessage from "../../../NoPermissionsMessage";
import FileEventsDialog from "../FileEventsDialog";
import EditFileDialog from "../EditFileDialog";
import FileUsageDialog from "../FileUsageDialog";
import { shouldFileBeVisible } from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    root: { paddingTop: 15, paddingBottom: 65 },
    displayName: {
        flexGrow: 1,
        paddingTop: 5,
    },
    commentsDisabled: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
    },
    menuButton: {
        width: 55,
        height: 55,
    },
    uploadedByText: {
        color: "grey",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LinkViewerPage(props) {
    const location = useLocation();
    const classes = useStyles();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);

    const [folder, setFolder] = useState();
    const [file, setFile] = useState();

    const { files, comments, notifications, events, users, folders, groups } =
        useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [userHasPermission, setUserHasPermission] = useState();
    const [userIsManager, setUserIsManager] = useState(false);

    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
        useState(false);

    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [usageDialogOpen, setUsageDialogOpen] = useState(false);

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    const [uploadedBy, setUploadedBy] = useState("");

    const [hasLoggedUsage, setHasLoggedUsage] = useState(false);

    const [showYouTubePreview, setShowYouTubePreview] = useState(false);
    const [youTubeEmbedURL, setYouTubeEmbedURL] = useState("");

    const [showThumbnail, setShowThumbnail] = useState(false);
    const [interactiveThumbnail, setInteractiveThumbnail] = useState(false);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        let fileKey = "";

        if (props.fileKey) {
            // If props.fileKey is set, then this page is being displayed inside a dialog
            // this happens when using the Files section of the site
            fileKey = props.fileKey;
        } else {
            fileKey = location.pathname.replace("/link/", "");
        }

        let file = files.filter((f) => f.key === fileKey)[0];

        if (file && currentUserData) {
            setFile(file);

            let folder = folders.filter((f) => f.key === file.folder)[0];

            if (folder) {
                setFolder(folder);
            }

            if (shouldFileBeVisible(file, groups, currentUserData)) {
                setUserHasPermission(true);
            } else {
                setUserHasPermission(false);
            }

            setUserIsManager(
                file.managers.includes(currentUserData.key) ||
                    currentUserData.role === "System Digital Manager" ||
                    file.uploadedBy === currentUserData.key
            );

            if (file.uploadedBy) {
                let uploadedByUser = users.filter(
                    (u) => u.key === file.uploadedBy
                )[0];
                if (uploadedByUser) {
                    setUploadedBy(
                        uploadedByUser.firstName + " " + uploadedByUser.lastName
                    );
                }
            }

            // Can show previews for certain link types, check the URL for these here
            if (file.filename.includes("youtube")) {
                if (file.filename.includes("embed")) {
                    setYouTubeEmbedURL(file.filename);
                } else {
                    let text1 = file.filename;
                    let text2 = "v=";

                    let substr = text1.substring(
                        0,
                        text1.indexOf(text2) + text2.length
                    );

                    let videoId = file.filename.replace(substr, "");

                    setYouTubeEmbedURL(
                        "https://www.youtube.com/embed/" + videoId
                    );
                }

                setShowYouTubePreview(true);
            } else if (file.filename.includes("docs.google.com")) {
                setShowThumbnail(true);
                setInteractiveThumbnail(true);
            }

            // Record File Access
            if (!hasLoggedUsage) {
                console.log("Log File Usage");

                let ref = firebase.database().ref("fileusage");

                ref.push({
                    file: file.key,
                    fileName: file.displayName,
                    fileType: file.type,
                    user: currentUserData.key,
                    action: "Open",
                    date: dayjs().toString(),
                });

                setHasLoggedUsage(true);
            }

            // Record File Access
            // let fileUsageRef = firebase.database().ref("fileusage");
            // fileUsageRef.push({
            //     file: file.key,
            //     user: currentUserData.key,
            //     opened: dayjs().toString(),
            // });
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [location.file, files, location.pathname, history, currentUserData]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function handleDeleteClick() {
        setAnchorEl(null);
        setConfirmDeleteDialogIsOpen(true);
    }

    function handleConfirmDeleteDialogClose() {
        setConfirmDeleteDialogIsOpen(false);
    }

    function handleEventDialogOpen() {
        setEventDialogOpen(true);
    }

    function handleCloseEventDialog() {
        setEventDialogOpen(false);
    }

    function handleConfirmDeletePressed() {
        if (userIsManager) {
            // Delete the comments
            comments.forEach((comment) => {
                if (comment.file === file.key) {
                    let commentRef = firebase
                        .database()
                        .ref("comments")
                        .child(comment.key);
                    commentRef.update({ deleted: true });
                }
            });

            // Delete any notifications for the file
            notifications.forEach((notification) => {
                if (notification.file === file.key) {
                    let notificationRef = firebase
                        .database()
                        .ref("notifications")
                        .child(notification.key);
                    notificationRef.remove();
                }
            });

            // // Delete the file in any events

            events.forEach((event) => {
                let filesToKeepArray = [];
                let needsChange = false;
                event.files.forEach((aFile) => {
                    if (aFile.key === file.key) {
                        needsChange = true;
                    } else {
                        filesToKeepArray.push(aFile.key);
                    }
                });

                if (needsChange) {
                    let eventRef = firebase
                        .database()
                        .ref("events")
                        .child(event.key);

                    // Change remaining files to JSON
                    let filesObject = {};

                    filesToKeepArray.forEach((aFile) => {
                        filesObject[aFile] = true;
                    });

                    eventRef.update({ files: filesObject });
                }
            });

            // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
            users.forEach((user) => {
                let filesToKeepArray = [];
                let needsChange = false;
                user.files.forEach((aFile) => {
                    if (aFile === file.key) {
                        needsChange = true;
                    } else {
                        filesToKeepArray.push(aFile);
                    }
                });

                if (needsChange) {
                    let userRef = firebase
                        .database()
                        .ref("users")
                        .child(user.key);

                    // Change remaining files to JSON
                    let filesObject = {};

                    filesToKeepArray.forEach((aFile) => {
                        filesObject[aFile] = true;
                    });

                    userRef.update({ files: filesObject });
                }
            });

            // Delete the file
            let fileRef = firebase.database().ref("files").child(file.key);

            fileRef.remove();

            if (props.fileKey === undefined) {
                history.goBack();
            } else {
                props.onClose();
            }
        }

        setConfirmDeleteDialogIsOpen(false);
    }

    function handleEditClick() {
        setAnchorEl(null);
        setEditDialogOpen(true);
    }

    function handleUsageClick() {
        setAnchorEl(null);
        setUsageDialogOpen(true);
    }

    function handleCloseEditDialog(outcome) {
        if (outcome === "saved") {
            showStatusMessage("File edited successfully", "success");
        }

        setEditDialogOpen(false);
    }

    function handleCloseUsageDialog() {
        setUsageDialogOpen(false);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    return (
        <>
            {userHasPermission && (
                <>
                    {props.fileKey === undefined && <BackButton />}
                    <Container fluid className={classes.root}>
                        {file && (
                            <>
                                {showThumbnail && (
                                    <Thumbnail
                                        url={file.filename}
                                        width={window.innerWidth - 100}
                                        height={window.innerHeight - 400}
                                        iframeWidth={window.innerWidth - 100}
                                        iframeHeight={window.innerHeight - 400}
                                        interactive={interactiveThumbnail}
                                    />
                                )}
                                {showYouTubePreview && (
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            <iframe
                                                width="100%"
                                                height={window.innerWidth / 4}
                                                src={youTubeEmbedURL}
                                                title={file.filename}
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                            ></iframe>
                                        </Col>
                                    </Row>
                                )}
                                <Col sm={6}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <Link
                                                    target="_blank"
                                                    className={
                                                        classes.displayName
                                                    }
                                                    variant="h4"
                                                    href={file.filename}
                                                >
                                                    {file.displayName}
                                                </Link>
                                            </div>

                                            {uploadedBy !== "" && (
                                                <span
                                                    className={
                                                        classes.uploadedByText
                                                    }
                                                >
                                                    Added By: {uploadedBy}
                                                </span>
                                            )}
                                        </Col>
                                        <IconButton
                                            onClick={handleEventDialogOpen}
                                            className={classes.menuButton}
                                            aria-label="moreVideoEditOptions"
                                        >
                                            <EventNoteIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={handleMenuOpen}
                                            className={classes.menuButton}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        {file.allowComments ? (
                                            <CommentSection file={file} />
                                        ) : (
                                            <span
                                                className={
                                                    classes.commentsDisabled
                                                }
                                            >
                                                Comments are disabled for this
                                                file
                                            </span>
                                        )}
                                    </Row>
                                </Col>
                            </>
                        )}
                    </Container>
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {userIsManager && (
                            <MenuItem onClick={handleEditClick}>
                                Edit File
                            </MenuItem>
                        )}
                        {userIsManager && (
                            <MenuItem onClick={handleDeleteClick}>
                                Delete File
                            </MenuItem>
                        )}
                        {userIsManager && (
                            <MenuItem onClick={handleUsageClick}>
                                File Usage Data
                            </MenuItem>
                        )}
                    </Menu>
                </>
            )}
            {confirmDeleteDialogIsOpen && (
                <Dialog
                    open={confirmDeleteDialogIsOpen}
                    onClose={handleConfirmDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Delete File
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this file?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleConfirmDeleteDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeletePressed}
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            {userHasPermission === false && <NoPermissionsMessage />}
            <ToolbarWithLogo />
            {eventDialogOpen && (
                <FileEventsDialog
                    open={eventDialogOpen}
                    onClose={handleCloseEventDialog}
                    file={file}
                />
            )}
            {editDialogOpen && (
                <EditFileDialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    file={file}
                    folder={folder}
                />
            )}
            {usageDialogOpen && (
                <FileUsageDialog
                    open={usageDialogOpen}
                    onClose={handleCloseUsageDialog}
                    file={file}
                />
            )}
        </>
    );
}
