import { Route, BrowserRouter } from "react-router-dom";
import { CleanConsole } from "@eaboy/clean-console";
import { SnackbarProvider } from "notistack";
import { isMobile } from "react-device-detect";

// Material UI Components
import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

// My Files
import { AuthProvider, DatabaseProvider, UserProvider } from "../context";
import NavigationBar from "./navbar/NavigationBar";
import HomePage from "./home/HomePage";
import SignInPage from "./signin/SignInPage";
import UserListPage from "./management/users/UserListPage";
import NewUserPage from "./management/users/NewUserPage";
import HubListPage from "./management/hubs/HubListPage";
// import HubNestedListPage from "./management/HubNestedListPage";
import NewHubPage from "./management/hubs/NewHubPage";
import UploadFilesPage from "./files/uploading/UploadFilesPage";
import NewLinkPage from "./files/uploading/NewLinkPage";
import FileNavigatorPage from "./files/viewing/FileNavigatorPage";
import FileFinderPage from "./files/viewing/finderPage/FileFinderPage";
import VideoViewerPage from "./files/viewing/viewerPages/VideoViewerPage";
import CalendarPage from "./calendar/CalendarPage";
import MessagesPage from "./messages/MessagesPage";
import PDFViewerPage from "./files/viewing/viewerPages/PDFViewerPage";
import ImageViewerPage from "./files/viewing/viewerPages/ImageViewerPage";
import AudioViewerPage from "./files/viewing/viewerPages/AudioViewerPage";
import MiscFileViewerPage from "./files/viewing/viewerPages/MiscFileViewerPage";
import LinkViewerPage from "./files/viewing/viewerPages/LinkViewerPage";
import MyAccountPage from "./myaccount/MyAccountPage";
import ParentConsentFormPage from "./management/consentforms/ParentConsentFormPage";
import UserConsentFormPage from "./management/consentforms/UserConsentFormPage";
import ClubUserConsentFormPage from "./management/consentforms/ClubUserConsentFormPage";
import ClubParentConsentFormPage from "./management/consentforms/ClubParentConsentFormPage";
import SimpleVideoAnalysisPage from "./analysis/SimpleVideoAnalysisPage";
import WatchPlaylistPage from "./playlists/WatchPlaylistPage";
import theme from "../theme";
import CoachEdConsentFormPage from "./management/consentforms/CoachEdConsentFormPage";
import PlaylistsAndTimelinesPage from "./playlists/PlaylistsAndTimelinesPage";
import SurveySelectPage from "./surveys/SurveySelectPage";
import SurveyEditPage from "./surveys/SurveyEditPage";
import SurveyResponsePage from "./surveys/SurveyResponsePage";
import SurveyResultsPage from "./surveys/SurveyResultsPage";
import ProjectSelectPage from "./projects/ProjectSelectPage";
import ProjectEditPage from "./projects/ProjectEditPage";
import ProjectViewPage from "./projects/ProjectViewPage";

// Stops browser opening a file if dropped on window
window.addEventListener(
    "dragover",
    function (e) {
        e.preventDefault();
    },
    false
);
window.addEventListener(
    "drop",
    function (e) {
        e.preventDefault();
    },
    false
);

function App() {
    CleanConsole.init(); // Silences Console
    localStorage.clear();
    sessionStorage.clear();

    function SignIn() {
        return <SignInPage />;
    }

    function Home() {
        return (
            <>
                <NavigationBar navBarTitle="" />
                <HomePage />
            </>
        );
    }

    function UserList() {
        return (
            <>
                <NavigationBar navBarTitle="| Management" />
                <UserListPage />
            </>
        );
    }

    function HubList() {
        return (
            <>
                <NavigationBar navBarTitle="| Management" />
                <HubListPage />
                {/* <HubNestedListPage /> */}
            </>
        );
    }

    function NewUser() {
        return (
            <>
                <NavigationBar navBarTitle="| Management" />
                <NewUserPage />
            </>
        );
    }

    function ParentConsentForm() {
        return (
            <>
                <ParentConsentFormPage />
            </>
        );
    }

    function UserConsentForm() {
        return (
            <>
                <UserConsentFormPage />
            </>
        );
    }

    function ClubParentConsentForm() {
        return (
            <>
                <ClubParentConsentFormPage />
            </>
        );
    }

    function ClubUserConsentForm() {
        return (
            <>
                <ClubUserConsentFormPage />
            </>
        );
    }

    function CoachEdConsentForm() {
        return (
            <>
                <CoachEdConsentFormPage />
            </>
        );
    }

    function NewHub() {
        return (
            <>
                <NavigationBar navBarTitle="| Management" />
                <NewHubPage />
            </>
        );
    }

    function FileNavigator() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <FileNavigatorPage />
            </>
        );
    }

    function Finder() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <FileFinderPage />
            </>
        );
    }

    function UploadFiles() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <UploadFilesPage />
            </>
        );
    }

    function AddLink() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <NewLinkPage />
            </>
        );
    }

    function VideoViewer() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <VideoViewerPage />
            </>
        );
    }

    function AudioViewer() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <AudioViewerPage />
            </>
        );
    }

    function PDFViewer() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <PDFViewerPage />
            </>
        );
    }

    function ImageViewer() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <ImageViewerPage />
            </>
        );
    }

    function MiscViewer() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <MiscFileViewerPage />
            </>
        );
    }

    function LinkViewer() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <LinkViewerPage />
            </>
        );
    }

    function Calendar() {
        return (
            <>
                <NavigationBar navBarTitle="| Calendar" />
                <CalendarPage />
            </>
        );
    }

    function MyAccount() {
        return (
            <>
                <NavigationBar navBarTitle="| My Account" />
                <MyAccountPage />
            </>
        );
    }

    function Messages() {
        return (
            <>
                <NavigationBar navBarTitle="| Messages" />
                <MessagesPage />
            </>
        );
    }

    function SimpleAnalysis() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <SimpleVideoAnalysisPage />
            </>
        );
    }

    function WatchPlaylist() {
        return (
            <>
                <NavigationBar navBarTitle="| Files" />
                <WatchPlaylistPage />
            </>
        );
    }

    function PlaylistsAndTimelines() {
        return (
            <>
                <NavigationBar navBarTitle="| Playlists & Timelines" />
                <PlaylistsAndTimelinesPage />
            </>
        );
    }

    function SurveySelect() {
        return (
            <>
                <NavigationBar navBarTitle="| Surveys" />
                <SurveySelectPage />
            </>
        );
    }

    function SurveyEdit() {
        return (
            <>
                <NavigationBar navBarTitle="| Surveys" />
                <SurveyEditPage />
            </>
        );
    }

    function SurveyResponse() {
        return (
            <>
                <NavigationBar navBarTitle="| Surveys" />
                <SurveyResponsePage />
            </>
        );
    }

    function SurveyAnswers() {
        return (
            <>
                <NavigationBar navBarTitle="| Surveys" />
                <SurveyResultsPage />
            </>
        );
    }

    function ProjectSelect() {
        return (
            <>
                <NavigationBar navBarTitle="| Projects" />
                <ProjectSelectPage />
            </>
        );
    }

    function ProjectEdit() {
        return (
            <>
                <NavigationBar navBarTitle="| Projects" />
                <ProjectEditPage />
            </>
        );
    }

    function ProjectView() {
        return (
            <>
                <NavigationBar navBarTitle="| Projects" />
                <ProjectViewPage />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nodiant</title>
                <meta
                    name="og:description"
                    content="FAW Performance Analysis."
                />
                <meta name="description" content="FAW Performance Analysis" />
            </Helmet>
            <AuthProvider>
                <DatabaseProvider>
                    <UserProvider>
                        <BrowserRouter>
                            <ThemeProvider theme={theme}>
                                <SnackbarProvider maxSnack={99}>
                                    <CssBaseline />
                                    <Route path="/" exact component={Home} />
                                    <Route path="/signin" component={SignIn} />
                                    <Route path="/hubs" component={HubList} />
                                    <Route path="/newhub" component={NewHub} />
                                    <Route
                                        path="/managehub"
                                        component={NewHub}
                                    />
                                    <Route path="/users" component={UserList} />
                                    <Route
                                        path="/newuser"
                                        component={NewUser}
                                    />
                                    <Route
                                        path="/manageuser"
                                        component={NewUser}
                                    />
                                    <Route
                                        path="/calendar"
                                        component={Calendar}
                                    />
                                    <Route
                                        path="/myaccount"
                                        component={MyAccount}
                                    />
                                    <Route
                                        path="/messages"
                                        component={Messages}
                                    />
                                    <Route
                                        path="/video"
                                        component={VideoViewer}
                                    />
                                    <Route
                                        path="/audio"
                                        component={AudioViewer}
                                    />
                                    <Route path="/pdf" component={PDFViewer} />
                                    <Route
                                        path="/image"
                                        component={ImageViewer}
                                    />
                                    <Route
                                        path="/file"
                                        component={MiscViewer}
                                    />
                                    <Route
                                        path="/link"
                                        component={LinkViewer}
                                    />
                                    <Route
                                        path="/consent"
                                        component={ParentConsentForm}
                                    />
                                    <Route
                                        path="/userconsent"
                                        component={UserConsentForm}
                                    />
                                    <Route
                                        path="/clubconsent"
                                        component={ClubParentConsentForm}
                                    />
                                    <Route
                                        path="/clubuserconsent"
                                        component={ClubUserConsentForm}
                                    />
                                    <Route
                                        path="/coachedconsent"
                                        component={CoachEdConsentForm}
                                    />
                                    <Route
                                        path="/upload"
                                        component={UploadFiles}
                                    />
                                    <Route
                                        path="/addlink"
                                        component={AddLink}
                                    />
                                    <Route
                                        path="/files"
                                        component={
                                            isMobile ? FileNavigator : Finder
                                        }
                                    />
                                    <Route
                                        path="/analysis"
                                        component={SimpleAnalysis}
                                    />
                                    <Route
                                        path="/playlist"
                                        component={WatchPlaylist}
                                    />
                                    <Route
                                        path="/playlists"
                                        component={PlaylistsAndTimelines}
                                    />
                                    <Route
                                        path="/surveys/s"
                                        component={SurveySelect}
                                    />
                                    <Route
                                        path="/surveys/e"
                                        component={SurveyEdit}
                                    />
                                    <Route
                                        path="/surveys/r"
                                        component={SurveyResponse}
                                    />
                                    <Route
                                        path="/surveys/a"
                                        component={SurveyAnswers}
                                    />
                                    <Route
                                        path="/projects/s"
                                        component={ProjectSelect}
                                    />
                                    <Route
                                        path="/projects/e"
                                        component={ProjectEdit}
                                    />
                                    <Route
                                        path="/projects/v"
                                        component={ProjectView}
                                    />
                                </SnackbarProvider>
                            </ThemeProvider>
                        </BrowserRouter>
                    </UserProvider>
                </DatabaseProvider>
            </AuthProvider>
        </>
    );
}

export default App;
