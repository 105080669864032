import { useState, useEffect, useContext } from "react";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Slide from "@material-ui/core/Slide";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import { Breadcrumbs } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// My Files
import UserAvatar from "../UserAvatar";
import { UserContext, DatabaseContext } from "../../../context";
import firebase from "../../../firebase";
import { jsonFromArray } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    descendantRow: {
        marginLeft: 50,
    },
    infoText: {
        marginLeft: 15,
        marginTop: 10,
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    container: {
        paddingBottom: 45,
    },
    avatar: {
        width: "50px",
        height: "50px",
    },
    listText: {
        paddingLeft: theme.spacing(1),
    },
    noHubsText: {
        marginTop: 15,
        marginLeft: 15,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Shows a list of hubs which can be added to a user
export default function ManageHubEnrolementDialog(props) {
    const classes = useStyles();

    const { currentUserData } = useContext(UserContext);

    const { folders, groups } = useContext(DatabaseContext);

    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: "Hubs", key: "Root" },
    ]); // Used to keep track of the parent groups as user navigates down the tree, then use this to implement the breadcrumb

    const [visibleHubs, setVisibleHubs] = useState([]); // Just the hubs that are visible at the moment

    const [noHubsAtThisLevel, setNoHubsAtThisLevel] = useState(false);

    const [selectedHubs, setSelectedHubs] = useState([]);

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    useEffect(() => {
        if (!props.open) {
            setSelectedHubs(props.selectedHubs);

            let potentiallyVisibleHubs = folders.filter(
                (f) => f.hub && f.parent === "Root"
            );

            potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
                a.name > b.name ? 1 : -1
            );

            if (potentiallyVisibleHubs.length === 0) {
                setNoHubsAtThisLevel(true);
            } else {
                setNoHubsAtThisLevel(false);
            }

            if (
                currentUserData &&
                currentUserData.role === "System Digital Manager"
            ) {
                setVisibleHubs(potentiallyVisibleHubs);
            } else {
                let visibleHubsArray = [];
                // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
                potentiallyVisibleHubs.forEach((hub) => {
                    folders
                        .filter(
                            (f) =>
                                f.hub &&
                                (f.ancestors.includes(hub.key) ||
                                    f.key === hub.key)
                        )
                        .forEach((descendantHub) => {
                            if (
                                descendantHub.managers.includes(
                                    currentUserData.key
                                )
                            ) {
                                if (!visibleHubsArray.includes(hub)) {
                                    visibleHubsArray.push(hub);
                                }
                            }
                        });
                });

                setVisibleHubs(visibleHubsArray);
            }
        }

        // Don't put props.SelectedHubs in the dependancies! causes them to be reset when breadcrumb is changed
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData, props.selectedHubs, folders, props.open]);

    const handleHubClick = (hub) => () => {
        setBreadcrumbs((prevValue) => {
            return [...prevValue, { name: hub.name, key: hub.key }];
        });

        let potentiallyVisibleHubs = folders.filter(
            (f) => f.hub && f.parent === hub.key
        );

        potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        if (potentiallyVisibleHubs.length === 0) {
            setNoHubsAtThisLevel(true);
        } else {
            setNoHubsAtThisLevel(false);
        }

        if (
            currentUserData &&
            currentUserData.role === "System Digital Manager"
        ) {
            setVisibleHubs(potentiallyVisibleHubs);
        } else {
            let visibleHubsArray = [];
            // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
            potentiallyVisibleHubs.forEach((hub) => {
                folders
                    .filter(
                        (f) =>
                            f.hub &&
                            (f.ancestors.includes(hub.key) || f.key === hub.key)
                    )
                    .forEach((descendantHub) => {
                        if (
                            descendantHub.managers.includes(currentUserData.key)
                        ) {
                            if (!visibleHubsArray.includes(hub)) {
                                visibleHubsArray.push(hub);
                            }
                        }
                    });
            });

            setVisibleHubs(visibleHubsArray);
        }
    };

    const handleBreadcrumbClick = (breadcrumb) => () => {
        // Remove breadcrumbs after (and including) the one that has been pressed
        let index = 0;

        for (index = 0; index < breadcrumbs.length; index++) {
            let hub = breadcrumbs[index];

            if (hub.key === breadcrumb.key) {
                break;
            }
        }

        var newBreadCrumbs = [...breadcrumbs];

        newBreadCrumbs.length = index + 1;

        setBreadcrumbs(newBreadCrumbs);

        // Set the current parent to the breadcrumb that has been selected

        //
        let potentiallyVisibleHubs = folders.filter(
            (f) => f.hub && f.parent === breadcrumb.key
        );

        potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        if (potentiallyVisibleHubs.length === 0) {
            setNoHubsAtThisLevel(true);
        } else {
            setNoHubsAtThisLevel(false);
        }

        if (
            currentUserData &&
            currentUserData.role === "System Digital Manager"
        ) {
            setVisibleHubs(potentiallyVisibleHubs);
        } else {
            let visibleHubsArray = [];
            // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
            potentiallyVisibleHubs.forEach((hub) => {
                folders
                    .filter(
                        (f) =>
                            f.hub &&
                            (f.ancestors.includes(hub.key) || f.key === hub.key)
                    )
                    .forEach((descendantHub) => {
                        if (
                            descendantHub.managers.includes(currentUserData.key)
                        ) {
                            if (!visibleHubsArray.includes(hub)) {
                                visibleHubsArray.push(hub);
                            }
                        }
                    });
            });

            setVisibleHubs(visibleHubsArray);
        }
    };

    const handleClose = () => {
        // !! MIRROR CHANGES HERE IN NewUserPage !!

        if (props.userKey !== "newuser") {
            // Save to Firebase on close
            folders.forEach((folder) => {
                if (folder.hub) {
                    let folderUsersObject = {};
                    folder.users.forEach((userKey) => {
                        if (userKey !== props.userKey) {
                            folderUsersObject[userKey] = true;
                        }
                    });

                    if (shouldUserBeInFolder(folder)) {
                        folderUsersObject[props.userKey] = true;
                    }

                    let folderRef = firebase
                        .database()
                        .ref("folders")
                        .child(folder.key);

                    folderRef.update({ users: folderUsersObject });

                    // Update All Users Objects
                    let allUsersGroup = groups.filter(
                        (g) => g.hub === folder.key && g.name === "All Users"
                    )[0];
                    if (allUsersGroup) {
                        let groupRef = firebase
                            .database()
                            .ref("groups")
                            .child(allUsersGroup.key);

                        groupRef.update({ users: folderUsersObject });
                    }
                }
            });

            // Edit groups
            folders
                .filter((f) => f.hub)
                .forEach((hub) => {
                    // Add or remove from  groups
                    if (selectedHubs.includes(hub)) {
                        if (hub.groups) {
                            hub.groups.forEach((group) => {
                                let groupRef = firebase
                                    .database()
                                    .ref("groups")
                                    .child(group.key);

                                group.users.forEach((user) => {
                                    if (user.key === "newuser") {
                                        user.key = props.userKey;
                                    }
                                });

                                groupRef.update({
                                    users: jsonFromArray(group.users),
                                });
                            });
                        }
                    } else {
                        // If the user isn't in the hub, make sure they are not in any of the groups in the hub
                        if (hub.groups) {
                            hub.groups.forEach((group) => {
                                let groupRef = firebase
                                    .database()
                                    .ref("groups")
                                    .child(group.key);
                                let newGroupObject = {};
                                if (group.users) {
                                    group.users.forEach((u) => {
                                        if (u.key !== props.userKey) {
                                            newGroupObject[u.key] = true;
                                        }
                                    });
                                }
                                groupRef.update({ users: newGroupObject });
                            });
                        }
                    }
                });
        }

        props.onClose(selectedHubs);
    };

    function shouldUserBeInFolder(folder) {
        if (selectedHubs.filter((h) => h.key === folder.key).length > 0) {
            return true;
        }

        // Don't use a forEach loop here, because then the loop continues even after the return keyword
        for (let index = 0; index < folder.ancestors.length; index++) {
            const ancestorKey = folder.ancestors[index];
            if (selectedHubs.filter((h) => h.key === ancestorKey).length > 0) {
                return true;
            }
        }

        return false;
    }

    const handleAddToHubClick = (addingHub) => () => {
        let childHubs = folders.filter(
            (f) => f.hub && f.ancestors.includes(addingHub.key)
        );

        let newSelectedHubs = [...selectedHubs, addingHub];

        childHubs.forEach((childHub) => {
            if (
                selectedHubs.filter((h) => h.key === childHub.key).length === 0
            ) {
                newSelectedHubs.push(childHub);
            }
        });

        setSelectedHubs(newSelectedHubs);
    };

    const handleRemoveFromHubClick = (removingHub) => () => {
        var shouldRemove = true;
        removingHub.ancestors.forEach((ancestorKey) => {
            if (selectedHubs.filter((h) => h.key === ancestorKey).length > 0) {
                shouldRemove = false;
            }
        });

        if (!shouldRemove) {
            showStatusMessage(
                "The user is a member of a hub higher in the hierarchy",
                "error"
            );

            return;
        }

        let childHubs = folders.filter(
            (f) => f.hub && f.ancestors.includes(removingHub.key)
        );

        let newSelectedHubs = [];

        selectedHubs.forEach((selectedHub) => {
            if (selectedHub.key !== removingHub.key) {
                if (
                    childHubs.filter((h) => h.key === selectedHub.key)
                        .length === 0
                ) {
                    newSelectedHubs.push(selectedHub);
                }
            }
        });

        setSelectedHubs(newSelectedHubs);
    };

    function shouldAddRemoveButtonBeDisabled(hub) {
        // If the user is not in the managers of the folder (and they are not a System Digital Manager)
        // The button should be disabled

        if (!hub.managers.includes(currentUserData.key)) {
            if (currentUserData.role !== "System Digital Manager") {
                return true;
            }
        }

        return false;
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setStatusIsVisible(false);
    };

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            maxWidth="lg"
            open={props.open}
            fullWidth={true}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <SaveIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.titleText}>
                        {selectedHubs.length === 1
                            ? selectedHubs.length + " Hub Selected"
                            : selectedHubs.length + " Hubs Selected"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <p className={classes.infoText}>
                Adding the user to a hub also adds them to any hubs lower in the
                hierarchy
            </p>
            <p className={classes.infoText}>
                The user cannot be removed from a hub if they are a member of a
                hub higher in the hierarchy
            </p>
            <div className={classes.container}>
                <Paper square>
                    <Breadcrumbs
                        separator="›"
                        aria-label="breadcrumb"
                        className={classes.text}
                        //maxItems="2"
                    >
                        {breadcrumbs.map((breadcrumb) => (
                            <Link
                                href="#"
                                onClick={handleBreadcrumbClick(breadcrumb)}
                                key={breadcrumb.key}
                            >
                                {breadcrumb.name}
                            </Link>
                        ))}
                    </Breadcrumbs>
                    {noHubsAtThisLevel && (
                        <h5 className={classes.noHubsText}>
                            There are no hubs at this level yet
                        </h5>
                    )}
                    <List className={classes.list}>
                        {visibleHubs.map((hub, index, array) => (
                            <React.Fragment key={hub.key}>
                                <ListItem button onClick={handleHubClick(hub)}>
                                    <ListItemAvatar>
                                        <UserAvatar
                                            userOrHub={hub}
                                            path="hubs"
                                            variant="rounded"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={hub.name}
                                        className={classes.listText}
                                        secondary={
                                            shouldAddRemoveButtonBeDisabled(
                                                hub
                                            ) &&
                                            "You are not a manager of this hub"
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        {selectedHubs.filter(
                                            (h) => h.key === hub.key
                                        ).length > 0 ? (
                                            <Tooltip title="Remove user from hub">
                                                <IconButton
                                                    onClick={handleRemoveFromHubClick(
                                                        hub
                                                    )}
                                                    color="secondary"
                                                    disabled={shouldAddRemoveButtonBeDisabled(
                                                        hub
                                                    )}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Add user to hub">
                                                <IconButton
                                                    onClick={handleAddToHubClick(
                                                        hub
                                                    )}
                                                    color="primary"
                                                    disabled={shouldAddRemoveButtonBeDisabled(
                                                        hub
                                                    )}
                                                >
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </ListItemSecondaryAction>
                                    {/* {(currentUserData.role ===
                                        "System Digital Manager" ||
                                        hub.managers.includes(
                                            currentUserData.key
                                        )) && (
                                        
                                    )} */}
                                </ListItem>
                                {index < array.length - 1 && (
                                    <Divider variant="inset" component="li" />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            </div>
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}
