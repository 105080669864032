import { useState, useEffect, useContext } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files
import { DatabaseContext } from "../../../context";
import { shouldFileBeVisibleToUser, downloadBlob } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    toolbarTitle: {
        // flexGrow: 1,
    },
    title: {
        marginTop: 15,
    },
    downloadButton: {
        position: "absolute",
        left: "auto",
        right: 10,
        top: 75,
    },
    tableContainer: {
        marginTop: 15,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        width: window.innerWidth,
        overflow: "scroll",
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
}));

export default function FileUsageDialog(props) {
    const classes = useStyles();
    const { fileUsage, getFileUsage, users, groups, comments } =
        useContext(DatabaseContext);

    // const { currentUserData } = useContext(UserContext);

    const [rows, setRows] = useState([]);
    const [isVideo, setIsVideo] = useState(false);

    useEffect(() => {
        if (props.file) {
            // Find all the users that can access the file, and compile the usage data for each
            let rowDataArray = [];
            console.log("Compiling Usage Data:");

            if (fileUsage.length === 0) {
                getFileUsage();
            }

            var totalViewCount = 0;
            var totalDownloadCount = 0;
            var totalCommentCount = 0;
            var totalTimelineCount = 0;
            var totalPlaylistCount = 0;
            var totalPlaylistShareCount = 0;

            users.forEach((user) => {
                if (
                    props.file.managers.includes(user.key) ||
                    shouldFileBeVisibleToUser(props.file, groups, user)
                ) {
                    // Now compile the data for each row, based on the file usage objects, comments, timelines
                    // and playlists

                    // Row data from file usage objects

                    var viewCount = 0;
                    var downloadCount = 0;
                    var timelineCount = 0;
                    var playlistCount = 0;
                    var playlistShareCount = 0;

                    let matchingFileUsages = fileUsage.filter(
                        (usage) =>
                            props.file.key === usage.file &&
                            user.key === usage.user
                    );

                    matchingFileUsages.forEach((usage) => {
                        if (usage.action === "Open") {
                            viewCount += 1;
                        } else if (usage.action === "Download") {
                            downloadCount += 1;
                        } else if (usage.action === "TimelineCreate") {
                            timelineCount += 1;
                        } else if (usage.action === "PlaylistCreate") {
                            playlistCount += 1;
                        } else if (usage.action === "PlaylistShare") {
                            var userKeysSharedTo = [];

                            usage.usersSharedWith.forEach((sharedUserKey) => {
                                userKeysSharedTo.push(sharedUserKey);
                            });

                            usage.groupsSharedWith.forEach((group) => {
                                let groupObject = groups.filter(
                                    (g) => g.key === group.key
                                )[0];

                                if (groupObject) {
                                    groupObject.users.forEach((groupUser) => {
                                        userKeysSharedTo.push(groupUser.key);
                                    });
                                }
                            });

                            playlistShareCount += userKeysSharedTo.length;
                        }
                    });

                    // Row data from comments
                    var commentCount = 0;

                    let matchingComments = comments.filter(
                        (comment) =>
                            props.file.key === comment.file &&
                            user.key === comment.user
                    );

                    commentCount = matchingComments.length;

                    // Create the row object
                    rowDataArray.push({
                        name: user.fullName,
                        viewCount: viewCount,
                        commentCount: commentCount,
                        downloadCount: downloadCount,
                        timelineCount: timelineCount,
                        playlistCount: playlistCount,
                        playlistShareCount: playlistShareCount,
                    });

                    // Update the totals
                    totalViewCount += viewCount;
                    totalCommentCount += commentCount;
                    totalDownloadCount += downloadCount;
                    totalTimelineCount += timelineCount;
                    totalPlaylistCount += playlistCount;
                    totalPlaylistShareCount += playlistShareCount;
                }
            });

            // Most file views at top of table

            rowDataArray = rowDataArray.sort((a, b) =>
                a.viewCount < b.viewCount ? 1 : -1
            );

            // Create total row
            rowDataArray.push({
                name: "Total",
                viewCount: totalViewCount,
                commentCount: totalCommentCount,
                downloadCount: totalDownloadCount,
                timelineCount: totalTimelineCount,
                playlistCount: totalPlaylistCount,
                playlistShareCount: totalPlaylistShareCount,
            });

            setRows(rowDataArray);

            if (props.isVideo) {
                setIsVideo(true);
            }
        }
        // eslint-disable-next-line
    }, [props, fileUsage]);

    const handleClose = () => {
        props.onClose();
    };

    function handleDownloadPress() {
        var csvString =
            "Shared With, Page View Count, Comment Count, Download Count, Timeline Count, Playlist Count, Playlist Share Count\n";

        rows.forEach((row) => {
            csvString += row.name.replace(",", "") + ", ";
            csvString += row.viewCount + ", ";
            csvString += row.commentCount + ", ";
            csvString += row.downloadCount + ", ";
            csvString += row.timelineCount + ", ";
            csvString += row.playlistCount + ", ";
            csvString += row.playlistShareCount + "\n";
        });

        downloadBlob(csvString, "export.csv", "text/csv;charset=utf-8;");
    }

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.toolbarTitle}
                        >
                            File Usage Data
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container fluid className={classes.container}>
                    <h4 className={classes.title}>{props.file.displayName}</h4>
                    <div>
                        <Button
                            onClick={handleDownloadPress}
                            className={classes.downloadButton}
                        >
                            Download Data
                        </Button>
                    </div>
                    <TableContainer
                        component={Paper}
                        className={classes.tableContainer}
                    >
                        <Table aria-label="simple table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Shared With
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Page View Count
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Comment Count
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Download Count
                                    </TableCell>
                                    {isVideo && (
                                        <>
                                            <TableCell
                                                className={
                                                    classes.tableHeaderCell
                                                }
                                                align="center"
                                            >
                                                Timeline Count
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.tableHeaderCell
                                                }
                                                align="center"
                                            >
                                                Playlist Count
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.tableHeaderCell
                                                }
                                                align="center"
                                            >
                                                Playlist Share Count
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.viewCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.commentCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.downloadCount}
                                        </TableCell>
                                        {isVideo && (
                                            <>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {row.timelineCount}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {row.playlistCount}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {row.playlistShareCount}
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Dialog>
        </>
    );
}
