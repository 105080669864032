import React, {
    useEffect,
    useContext,
    useState,
    useCallback,
    useReducer,
} from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import firebase from "../../firebase";
import ConfirmDialog from "../ConfirmDialog";
import BackButton from "../BackButton";
import ProjectTitleComponent from "./Components/ProjectTitleComponent";
import ProjectTextBoxComponent from "./Components/ProjectTextBoxComponent";
import ProjectPlaylistComponent from "./Components/ProjectPlaylistComponent";
import ProjectSelectPlaylistDialog from "./ProjectSelectPlaylistDialog";
import ShareProjectDialog from "./ShareProjectDialog";
import ProjectSelectFileDialog from "./ProjectSelectFileDialog";
import ProjectFileComponent from "./Components/ProjectFileComponent";
import NoPermissionsMessage from "../NoPermissionsMessage";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 150,
    },
    paper: {
        padding: 15,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    inputTextField: {
        // marginBottom: 15,
    },
    shareButton: { marginTop: 0, marginLeft: 0, marginBottom: 15 },
    appBar: {
        top: "auto",
        bottom: 65,
        height: 50,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    deleteButton: {
        marginBottom: 15,
    },
    grow: {
        flexGrow: 1,
    },
    anonymousSwitch: {
        marginBottom: 5,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ProjectEditPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();

    const { projectInfo, groups } = useContext(DatabaseContext);

    const [title, setTitle] = useState("");
    const [introduction, setIntroduction] = useState("");

    const [key, setKey] = useState("");

    const [project, setProject] = useState();
    const [projectInfoObject, setProjectInfoObject] = useState();

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusIsVisible, setStatusIsVisible] = useState(false);

    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const [canShareProjects, setCanShareProjects] = useState(false);

    const [deleteConfirmDialogIsOpen, setDeleteConfirmDialogIsOpen] =
        useState(false);

    const [selectPlaylistDialogOpen, setSelectPlaylistDialogOpen] =
        useState(false);

    const [selectExistingFileDialogOpen, setSelectExistingFileDialogOpen] =
        useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [userHasAccess, setUserHasAccess] = useState(false);
    const [userWasCreator, setUserWasCreator] = useState(false);

    const getProject = useCallback(
        async (key) => {
            let projectRef = firebase.database().ref("projects").child(key);

            if (!project) {
                console.log("Reading Project from Firebase");

                projectRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    let componentsArray = [];

                    if (data && data.components) {
                        let componentKeys = Object.keys(data.components);
                        let componentValues = Object.values(data.components);

                        for (let i = 0; i < componentKeys.length; i++) {
                            var commentsArray = [];
                            if (componentValues[i].comments) {
                                let commentKeys = Object.keys(
                                    componentValues[i].comments
                                );
                                let commentValues = Object.values(
                                    componentValues[i].comments
                                );

                                for (let j = 0; j < commentKeys.length; j++) {
                                    const comment = commentValues[j];
                                    comment.key = commentKeys[j];
                                    commentsArray.push(comment);
                                }
                            }

                            let componentObject = {
                                key: componentKeys[i],
                                row: componentValues[i].row,
                                type: componentValues[i].type,
                                fileType: componentValues[i].fileType,
                                text: componentValues[i].text,
                                linkKey: componentValues[i].linkKey,
                                addedBy: componentValues[i].addedBy,
                                addedByName: componentValues[i].addedByName,
                                addedOn: componentValues[i].addedOn,
                                allowComments: componentValues[i].allowComments,
                                comments: commentsArray,
                            };

                            componentsArray.push(componentObject);
                        }
                    }

                    let project = {
                        key: key,
                        components: componentsArray.sort((a, b) =>
                            a.row > b.row ? 1 : -1
                        ),
                    };

                    // console.log(project);
                    setProject(project);
                });
            } else {
                console.log("Already Read Project");
            }
        },
        [project]
    );
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const key = urlParams.get("key");

        let matchingInfo = projectInfo.filter((p) => p.key === key)[0];

        if (matchingInfo && currentUserData) {
            let hasAccess = false;

            // Check if user created project

            if (matchingInfo.createdBy === currentUserData.key) {
                hasAccess = true;
                setUserWasCreator(true);
            } else {
                setUserWasCreator(false);
            }

            // Check if user was added to project individually
            let matchingUser = matchingInfo.users.filter(
                (u) => u.key === currentUserData.key
            )[0];

            if (matchingUser) {
                if (matchingUser.canEdit) {
                    hasAccess = true;
                }
            }

            // Check if user is in a group that was added to the project
            matchingInfo.groups.forEach((projectGroup) => {
                let matchingGroup = groups.filter(
                    (g) => g.key === projectGroup.key
                )[0];
                if (matchingGroup) {
                    if (
                        matchingGroup.users.filter(
                            (u) => u.key === currentUserData.key
                        ).length > 0
                    ) {
                        if (projectGroup.canEdit) {
                            hasAccess = true;
                        }
                    }
                }
            });

            if (hasAccess) {
                // if (currentUserData.permissions.createSurveys) {
                //     setCanShareProjects(true);
                // }

                setCanShareProjects(true); // TEMP!!

                setProjectInfoObject(matchingInfo);
                setKey(matchingInfo.key);
                setTitle(matchingInfo.title);
                setIntroduction(matchingInfo.intro);
                getProject(key);
                setUserHasAccess(true);
            } else {
                setUserHasAccess(false);
            }

            if (project) {
                console.log("Have read project succesfully");
            }
        }

        // eslint-disable-next-line
    }, [currentUserData, projectInfo, project]);

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "projectTitle") {
            // Change in firebase
            firebase
                .database()
                .ref("projectinfo")
                .child(project.key)
                .child("title")
                .set(newValue);

            setTitle(newValue);
        } else if (event.target.id === "projectIntro") {
            // Change in firebase
            firebase
                .database()
                .ref("projectinfo")
                .child(project.key)
                .child("intro")
                .set(newValue);

            setIntroduction(newValue);
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddComponentClick = (event) => {
        setAnchorEl(event.target);
    };

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function handleShareProjectClicked() {
        setShareDialogOpen(true);
    }

    function handleCloseShareDialog(userCount, groupCount) {
        if (userCount > 0 || groupCount > 0) {
            setStatusMessage(
                "Project shared with " +
                    userCount +
                    " Users and " +
                    groupCount +
                    " Groups"
            );

            setStatusType("success");
            setStatusIsVisible(true);
        }

        setShareDialogOpen(false);
    }

    function handleDeleteProjectPressed() {
        setDeleteConfirmDialogIsOpen(true);
    }

    function handleCloseConfirmDeleteDialog(result) {
        if (result === true) {
            // Confirmed that we want to delete the project
            // Delete project Info Object
            firebase
                .database()
                .ref("projectinfo")
                .child(projectInfoObject.key)
                .remove();

            // Delete Project Object
            firebase
                .database()
                .ref("projects")
                .child(projectInfoObject.key)
                .remove();

            // Delete Responses

            history.replace("/projects/s");
        }

        setDeleteConfirmDialogIsOpen(false);
    }

    function handleComponentMoveUpPressed(component) {
        if (component.row === 0) {
            return;
        }

        let previousComponent = project.components[component.row - 1];

        firebase
            .database()
            .ref("projects")
            .child(key)
            .child("components")
            .child(previousComponent.key)
            .update({ row: component.row });

        firebase
            .database()
            .ref("projects")
            .child(key)
            .child("components")
            .child(component.key)
            .update({ row: component.row - 1 });
    }

    function handleComponentMoveDownPressed(component) {
        if (component.row === project.components.length - 1) {
            return;
        }

        let nextComponent = project.components[component.row + 1];

        firebase
            .database()
            .ref("projects")
            .child(key)
            .child("components")
            .child(nextComponent.key)
            .update({ row: component.row });

        firebase
            .database()
            .ref("projects")
            .child(key)
            .child("components")
            .child(component.key)
            .update({ row: component.row + 1 });
    }

    function handleDeleteComponentPressed(component) {
        // First make sure the row values for each of the remaining components is correct
        var index = 0;
        project.components
            .filter((c) => c.key !== component.key)
            .forEach((component) => {
                firebase
                    .database()
                    .ref("projects")
                    .child(key)
                    .child("components")
                    .child(component.key)
                    .update({ row: index });

                index += 1;
            });

        // Then remove the deleted component
        firebase
            .database()
            .ref("projects")
            .child(key)
            .child("components")
            .child(component.key)
            .remove();
    }

    function handleSelectPlaylistClose(selectedPlaylist) {
        if (selectedPlaylist) {
            let newComponent = {
                type: "Playlist",
                addedBy: currentUserData.key,
                addedByName: currentUserData.fullName,
                addedOn: dayjs().toString(),
                linkKey: selectedPlaylist.key,
                row: project.components.length,
            };

            project.components = [...project.components, newComponent];

            // Write new component to firebase

            let componentsRef = firebase
                .database()
                .ref("projects/" + key + "/components")
                .push();

            componentsRef.set(newComponent);

            forceUpdate();
        }

        setSelectPlaylistDialogOpen(false);
    }

    function handleSelectExistingFileClose(selectedFile) {
        if (selectedFile) {
            let newComponent = {
                type: "File",
                fileType: selectedFile.type,
                addedBy: currentUserData.key,
                addedByName: currentUserData.fullName,
                addedOn: dayjs().toString(),
                linkKey: selectedFile.key,
                row: project.components.length,
            };

            project.components = [...project.components, newComponent];

            // Write new component to firebase

            let componentsRef = firebase
                .database()
                .ref("projects/" + key + "/components")
                .push();

            componentsRef.set(newComponent);

            forceUpdate();
        }

        setSelectExistingFileDialogOpen(false);
    }

    function handleNewTitlePressed() {
        let newComponent = {
            type: "Title",
            text: "",
            addedBy: currentUserData.key,
            addedByName: currentUserData.fullName,
            addedOn: dayjs().toString(),
            //linkKey: "",
            row: project.components.length,
        };

        project.components = [...project.components, newComponent];

        // Write new component to firebase

        let componentsRef = firebase
            .database()
            .ref("projects/" + key + "/components")
            .push();

        componentsRef.set(newComponent);

        forceUpdate();

        setAnchorEl(null);
    }

    function handleNewTextBoxPressed() {
        let newComponent = {
            type: "Textbox",
            text: "",
            addedBy: currentUserData.key,
            addedByName: currentUserData.fullName,
            addedOn: dayjs().toString(),
            allowComments: false,
            row: project.components.length,
        };

        project.components = [...project.components, newComponent];

        // Write new component to firebase

        let componentsRef = firebase
            .database()
            .ref("projects/" + key + "/components")
            .push();

        componentsRef.set(newComponent);

        forceUpdate();

        setAnchorEl(null);
    }

    function handleNewPlaylistPressed() {
        setSelectPlaylistDialogOpen(true);

        setAnchorEl(null);
    }

    function handleNewExistingFilePressed() {
        setSelectExistingFileDialogOpen(true);

        setAnchorEl(null);
    }

    return (
        <>
            <BackButton />
            <Container className={classes.pageContainer}>
                {userHasAccess && (
                    <Paper className={classes.paper} variant="outlined">
                        <TextField
                            fullWidth
                            className={classes.inputTextField}
                            value={title}
                            onChange={handleTextFieldChange}
                            id="projectTitle"
                            label="Project Title"
                            variant="outlined"
                        />
                        <div className={classes.dividerDiv} />
                        <TextField
                            fullWidth
                            multiline
                            rows={5}
                            className={classes.inputTextField}
                            value={introduction}
                            onChange={handleTextFieldChange}
                            id="projectIntro"
                            label="Project Description"
                            variant="outlined"
                        />
                    </Paper>
                )}
                <div className={classes.dividerDiv} />
                {project &&
                    project.components.map((c) => (
                        <React.Fragment key={c.key}>
                            {c.type === "Title" && (
                                <ProjectTitleComponent
                                    projectKey={project.key}
                                    isEditing={true}
                                    component={c}
                                    moveUp={handleComponentMoveUpPressed}
                                    moveDown={handleComponentMoveDownPressed}
                                    delete={handleDeleteComponentPressed}
                                />
                            )}
                            {c.type === "Textbox" && (
                                <ProjectTextBoxComponent
                                    projectKey={project.key}
                                    isEditing={true}
                                    component={c}
                                    moveUp={handleComponentMoveUpPressed}
                                    moveDown={handleComponentMoveDownPressed}
                                    delete={handleDeleteComponentPressed}
                                />
                            )}
                            {c.type === "Playlist" && (
                                <ProjectPlaylistComponent
                                    projectKey={project.key}
                                    isEditing={true}
                                    component={c}
                                    moveUp={handleComponentMoveUpPressed}
                                    moveDown={handleComponentMoveDownPressed}
                                    delete={handleDeleteComponentPressed}
                                />
                            )}
                            {c.type === "File" && (
                                <ProjectFileComponent
                                    projectKey={project.key}
                                    isEditing={true}
                                    component={c}
                                    moveUp={handleComponentMoveUpPressed}
                                    moveDown={handleComponentMoveDownPressed}
                                    delete={handleDeleteComponentPressed}
                                />
                            )}
                            <div className={classes.dividerDiv} />
                        </React.Fragment>
                    ))}
                {canShareProjects && projectInfoObject && (
                    <ShareProjectDialog
                        open={shareDialogOpen}
                        onClose={handleCloseShareDialog}
                        projectInfo={projectInfoObject}
                    />
                )}
            </Container>
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            {userHasAccess && (
                <AppBar
                    position="fixed"
                    color="white"
                    className={classes.appBar}
                >
                    <Toolbar>
                        {canShareProjects && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.shareButton}
                                onClick={handleShareProjectClicked}
                            >
                                Share Project
                            </Button>
                        )}

                        <Tooltip title="Add Component">
                            <Fab
                                color="primary"
                                aria-label="add"
                                className={classes.fabButton}
                                onClick={handleAddComponentClick}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                        <div className={classes.grow} />
                        {userWasCreator && (
                            <Tooltip title="Delete Project">
                                <IconButton
                                    className={classes.deleteButton}
                                    edge="end"
                                    onClick={handleDeleteProjectPressed}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Toolbar>
                </AppBar>
            )}
            {deleteConfirmDialogIsOpen && (
                <ConfirmDialog
                    open={deleteConfirmDialogIsOpen}
                    onClose={handleCloseConfirmDeleteDialog}
                    title={"Confirm Delete"}
                    message={"Are you sure you want to delete this project?"}
                />
            )}

            {selectPlaylistDialogOpen && (
                <ProjectSelectPlaylistDialog
                    open={selectPlaylistDialogOpen}
                    onClose={handleSelectPlaylistClose}
                />
            )}
            {selectExistingFileDialogOpen && (
                <ProjectSelectFileDialog
                    open={selectExistingFileDialogOpen}
                    onClose={handleSelectExistingFileClose}
                />
            )}
            <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleNewTitlePressed}>Add Title</MenuItem>
                <MenuItem onClick={handleNewTextBoxPressed}>
                    Add Text Box
                </MenuItem>
                <MenuItem onClick={handleNewPlaylistPressed}>
                    Add Playlist
                </MenuItem>
                <MenuItem onClick={handleNewExistingFilePressed}>
                    Add Resource from Files
                </MenuItem>
            </Menu>
            {!userHasAccess && <NoPermissionsMessage />}
            <ToolbarWithLogo />
        </>
    );
}
