import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { tus } from "tus-js-client";
import * as tus from "tus-js-client";

import { BunnyCdnStream } from "bunnycdn-stream";
import SHA256 from "sha256-es";

// Material UI
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControlLabel,
    FormGroup,
    IconButton,
    Input,
} from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from "@material-ui/icons/Group";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext, DatabaseContext } from "../../../context";
import firebase from "../../../firebase";
import LinkFileToEventDialog from "./LinkFileToEventDialog";
import FilePermissionsDialog from "./FilePermissionsDialog";
import FileNotificationsDialog from "./FileNotificationsDialog";
import {
    jsonFromArray,
    bunnyVideoLibraryID,
    getAncestorHubs,
} from "../../../utility";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        paddingTop: 5,
        paddingBottom: 5,
        [theme.breakpoints.up("md")]: {
            height: 140,
        },
        [theme.breakpoints.down("sm")]: {
            height: 190,
        },
    },
    container: {
        padding: 0,
        marginLeft: 0,
    },
    fileTypeIcon: {
        fontSize: 75,
        position: "relative",
        top: "15px",
    },
    icons: {
        paddingLeft: 10,
        paddingRight: 10,
        position: "relative",
        top: "15px",
        fontSize: 75,
        [theme.breakpoints.only("xs")]: {
            paddingLeft: 5,
            paddingRight: 5,
            position: "relative",
            top: "15px",
            fontSize: 50,
        },
    },
    switch: {
        marginTop: theme.spacing(1.0),
    },
    pdfPreview: {
        marginLeft: 5,
        visibility: "hidden",
    },
    fileSizeText: {
        color: "red",
        marginLeft: 5,
    },
    removeButton: {
        position: "absolute",
        cursor: "pointer",
        right: 20,
        top: 0,
    },
}));

export default function FileForUpload(props) {
    // var tus = require("tus-js-client");

    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const { folders } = useContext(DatabaseContext);

    const [displayName, setDisplayName] = useState("");

    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [events, setEvents] = useState([]);

    const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);

    const [notificationsDialogOpen, setNotificationsDialogOpen] =
        useState(false);

    const [allowSharing, setAllowSharing] = useState(false);
    const [allowComments, setAllowComments] = useState(true);
    const [allowDownloads, setAllowDownloads] = useState(true);

    const [managers, setManagers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [selectedGroups, setSelectedGroups] = useState([]);

    const [sendNotifications, setSendNotifications] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [showFileSizeText, setShowFileSizeText] = useState(false);
    const [fileSizeText, setFileSizeText] = useState("");

    let uploadProgress = 0;

    props.biRef.filesForUpload = props.biRef.filesForUpload.filter(
        (f) => f.key !== props.file.key
    );

    props.biRef.filesForUpload.push({
        key: props.file.key,
        startUpload: startUpload,
        changeSettings: changeSettings,
    });

    useEffect(() => {
        setManagers(props.managers);

        if (
            props.managers.filter((m) => m.key === currentUserData.key)
                .length === 0
        ) {
            if (selectedUsers.length === 0) {
                setSelectedUsers([currentUserData]);
            }
        }

        // setDefaultUsers(props.defaultUsers);
        if (props.file && props.file.size > 5000000000) {
            // 5,000,000,000 == 5GB
            setShowFileSizeText(true);

            let rounded = (props.file.size / 1000000000).toFixed(2);
            setFileSizeText(rounded.toString() + "GB");

            props.showFileSizeMessage();
        }

        // eslint-disable-next-line
    }, [props.managers]);

    function startUpload() {
        if (props.file) {
            if (props.file.type.includes("video")) {
                // isVideo = true;
            }

            props.handleUploadStart(props.file);

            if (props.file.type.includes("video")) {
                // uploadVideo();
                uploadVideoTUS();
            } else {
                if (props.file.type === "application/pdf") {
                    uploadPDFThumbnail();
                } else {
                    uploadFile();
                }
            }
        }
    }

    // This is called from UploadFilesPage. If the file type is not a video.
    function uploadFile() {
        if (props.file === null || props.file.name === null) {
            console.log("File is undefined");
            return;
        }
        console.log("Starting File Upload");

        // Upload the file to firebase storage

        let storage = firebase.storage();

        let fileRef = storage
            .ref()
            .child("files/" + props.file.key + "/" + props.file.name);

        let uploadTask = fileRef.put(props.file);

        //  Listening for progress/state changes
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            function (snapshot) {
                uploadProgress = Math.floor(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                props.handleUploadProgress(props.file, uploadProgress);
            }
        );

        // Listening for upload complete
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
            complete: function () {
                console.log("upload complete!");
                // closeSnackbar(props.file.key);
                fileRef.getDownloadURL().then((url) => {
                    props.file.url = url;
                    addToFirebase(props.file.key);
                });
            },
        });
    }

    function uploadPDFThumbnail() {
        console.log("Uploading PDF Thumbnail");
        const importPDFCanvas = document.querySelector(
            ".import-pdf-page-" + props.file.key + " canvas"
        );

        let blob = dataURItoBlob(importPDFCanvas.toDataURL("image/png"));

        let storage = firebase.storage();

        let fileRef = storage
            .ref()
            .child("files/" + props.file.key + "/thumbnail");

        let thumbnailUploadTask = fileRef.put(blob);

        // Listening for upload complete
        thumbnailUploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
            complete: function () {
                console.log("thumbnail upload complete!");
                // closeSnackbar(props.file.key);

                fileRef.getDownloadURL().then((url) => {
                    props.file.thumbnail = url;
                    uploadFile();
                });
            },
        });
    }

    function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    // New method using Bunny's TUS Resumable Uploads

    var tusUpload;

    function uploadVideoTUS() {
        console.clear();
        if (props.file === null || props.file.name === null) {
            console.log("File is undefined");
            return;
        }

        // First call a firebase function to get the Bunny API Key

        var getBunnyStreamAPIKey = firebase
            .functions()
            .httpsCallable("getBunnyStreamAPIKey");

        getBunnyStreamAPIKey()
            .then((result) => {
                const apiKey = result.data.key;

                let stream = new BunnyCdnStream({
                    videoLibrary: bunnyVideoLibraryID,
                    apiKey: apiKey,
                });

                stream
                    .createVideo({ title: props.file.name })
                    .then((createVideoResult) => {
                        // let expiration_time = 1660738198; // UNIX TIME STAMP, NEED TO GENERATE THIS, ONE DAY FROM UPLOAD BEGIN
                        // https://www.unixtimestamp.com/

                        // const myBitArray = sjcl.hash.sha256.hash(
                        //     bunnyVideoLibraryID +
                        //         apiKey +
                        //         expiration_time +
                        //         createVideoResult.guid
                        // );

                        let expiration_time =
                            Math.floor(new Date() / 1000) + 86400;

                        const hashString =
                            bunnyVideoLibraryID +
                            apiKey +
                            expiration_time +
                            createVideoResult.guid;

                        const myHash = SHA256.hash(hashString);
                        // const myHash = sjcl.codec.hex.fromBits(myBitArray);

                        tusUpload = new tus.Upload(props.file, {
                            endpoint: "https://video.bunnycdn.com/tusupload",
                            retryDelays: [
                                1, 1000, 2000, 3000, 4000, 5000, 6000, 7000,
                                8000, 9000, 10000, 11000, 12000, 13000, 14000,
                                15000, 16000, 17000, 18000, 19000, 20000, 25000,
                                30000, 40000, 50000, 60000, 70000, 80000, 90000,
                                100000,
                            ],
                            headers: {
                                AuthorizationSignature: myHash, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                                AuthorizationExpire: expiration_time, // Expiration time as in the signature,
                                VideoId: createVideoResult.guid, // The guid of a previously created video object through the Create Video API call
                                LibraryId: bunnyVideoLibraryID,
                            },
                            metadata: {
                                filetype: props.file.type,
                                title: props.file.name,
                                filename: props.file.name,
                            },
                            onError: function (error) {
                                console.log(error);
                            },
                            onProgress: function (bytesUploaded, bytesTotal) {
                                // console.log(
                                //     bytesUploaded + " out of  " + bytesTotal
                                // );

                                uploadProgress = Math.floor(
                                    (bytesUploaded / bytesTotal) * 100
                                );

                                props.handleUploadProgress(
                                    props.file,
                                    uploadProgress,
                                    tusUpload
                                );
                            },
                            onSuccess: function () {
                                console.log("Success");
                                // Now add the entry to Firebase
                                addToFirebase(createVideoResult.guid);
                            },
                            onShouldRetry: function (
                                err,
                                retryAttempt,
                                options
                            ) {
                                console.log("Error", err);
                                console.log("Request", err.originalRequest);
                                console.log("Response", err.originalResponse);

                                var status = err.originalResponse
                                    ? err.originalResponse.getStatus()
                                    : 0;
                                // Do not retry if the status is a 403.
                                if (status === 403) {
                                    return false;
                                }

                                // For any other status code, we retry.
                                return true;
                            },
                        });

                        tusUpload.start();
                    });
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");

                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });
    }

    // Upload using regular non-resumable upload
    // function uploadVideo() {
    //     if (props.file === null || props.file.name === null) {
    //         console.log("File is undefined");
    //         return;
    //     }

    //     setUploading(true);

    //     // First call a firebase function to get the Bunny API Key

    //     var getBunnyStreamAPIKey = firebase
    //         .functions()
    //         .httpsCallable("getBunnyStreamAPIKey");

    //     getBunnyStreamAPIKey()
    //         .then((result) => {
    //             // Read result of the Cloud Function.

    //             const apiKey = result.data.key;

    //             // console.log("Starting Upload to Bunny: " + props.file.name);

    //             // Upload to Bunny CDN

    //             // Have to create the video first, Bunny API will return a video identifier
    //             // If this is successful. Can then upload using this identifier

    //             const url =
    //                 "https://video.bunnycdn.com/library/" +
    //                 bunnyVideoLibraryID +
    //                 "/videos";
    //             const options = {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/*+json",
    //                     AccessKey: apiKey,
    //                 },
    //                 body: '{"title":"' + props.file.name + '"}',
    //             };

    //             fetch(url, options)
    //                 .then((res) => res.json())
    //                 .then((json) => {
    //                     // Video has been created on Bunny, now upload it using the guid
    //                     let guid = json.guid;

    //                     // console.log("Video Created with guid: " + guid);
    //                     console.log("Starting Video Upload");

    //                     const url =
    //                         "https://video.bunnycdn.com/library/" +
    //                         bunnyVideoLibraryID +
    //                         "/videos/" +
    //                         guid;
    //                     const options = {
    //                         method: "PUT",
    //                         headers: {
    //                             "Content-Type": "application/*+json",
    //                             AccessKey: apiKey,
    //                         },
    //                         body: props.file,
    //                         onUploadProgress: (progressEvent) => {
    //                             let progressPercentage = Math.round(
    //                                 (progressEvent.loaded /
    //                                     progressEvent.total) *
    //                                     100
    //                             );

    //                             setProgress(progressPercentage);

    //                             let loadedMB =
    //                                 Math.round(progressEvent.loaded / 100000) /
    //                                 10;
    //                             let totalMB =
    //                                 Math.round(progressEvent.total / 100000) /
    //                                 10;

    //                             uploadProgress = Math.floor(
    //                                 (loadedMB / totalMB) * 100
    //                             );

    //                             enqueueSnackbar(
    //                                 "Uploading: " + props.file.name,
    //                                 {
    //                                     variant: "default",
    //                                     action,
    //                                     persist: true,
    //                                     key: props.file.key,
    //                                     preventDuplicate: true,
    //                                 }
    //                             );

    //                             setProgressMessage(
    //                                 loadedMB + "MB of " + totalMB + "MB"
    //                             );
    //                         },
    //                     };

    //                     axios.put(url, props.file, options).then((response) => {
    //                         console.log(
    //                             "Video Uploaded Successfully, adding entry to database"
    //                         );

    //                         // Now add the entry to Firebase
    //                         addToFirebase(guid);

    //                         // closeSnackbar(props.file.key);
    //                     });
    //                 })
    //                 .catch((err) => {
    //                     setProgressMessage("Upload error, please try again");
    //                     console.error("error:" + err);
    //                 });
    //         })
    //         .catch((error) => {
    //             // Getting the Error details.
    //             console.log("Error");
    //             setProgressMessage("Upload error, please try again");
    //             console.log(error.code);
    //             console.log(error.message);
    //             console.log(error.details);
    //         });
    // }

    function addToFirebase(key) {
        let eventsObject = {};
        for (let i = 0; i < events.length; i++) {
            let event = events[i];
            eventsObject[event.key] = true;
        }

        // Add to files
        var filesRef = firebase.database().ref("files");

        // If user didn't enter a display name, use the filename
        let displayNameForDatabase = displayName;

        if (displayNameForDatabase === "") {
            displayNameForDatabase = props.file.name;
        }

        // Have to convert the arrays to JSON objects for firebase storage

        // Users array to JSON

        // let usersObject = {};

        // Managers array to JSON
        let managersObject = {};
        if (managers) {
            managers.forEach((manager) => {
                managersObject[manager.key] = true;
            });
        }

        // Ancestors array to JSON
        let ancestorsObject = {};

        props.folder.ancestors.forEach((ancestor) => {
            ancestorsObject[ancestor] = true;
        });

        ancestorsObject[props.folder.key] = true;

        let url = "";
        if (props.file.url) {
            url = props.file.url;
        }

        let thumbnail = "";
        if (props.file.thumbnail) {
            thumbnail = props.file.thumbnail;
        }

        var uploadedBy = "";
        if (currentUserData) {
            uploadedBy = currentUserData.key;
        }

        filesRef.child(key).set({
            filename: props.file.name,
            displayName: displayNameForDatabase,
            folder: props.folder.id,
            type: props.file.type,
            allowDownloads: allowDownloads,
            allowComments: allowComments,
            allowSharing: allowSharing,
            managers: managersObject,
            events: eventsObject,
            groups: jsonFromArray(selectedGroups),
            ancestors: ancestorsObject,
            url: url,
            thumbnail: thumbnail,
            uploadedBy: uploadedBy,
        });

        // Add to any linked events

        var eventsRef = firebase.database().ref("events");

        for (let i = 0; i < events.length; i++) {
            let event = events[i];

            let fileObject = {};
            fileObject[key] = true;
            eventsRef.child(event.key).child("files").update(fileObject);
        }

        let usersObjectForNotifications = {};

        // Fine users that have been added individually, add the file to users.files instead
        selectedUsers.forEach((user) => {
            usersObjectForNotifications[user.key] = true;
            if (!isUserInSelectedGroups(user)) {
                var usersRef = firebase.database().ref("users");

                let fileObject = {};
                fileObject[key] = true;

                usersRef.child(user.key).child("files").update(fileObject);
            }
        });

        // Add notifications

        if (sendNotifications) {
            var notificationsRef = firebase.database().ref("notifications");

            var fileType = "file";

            if (props.file.type.includes("video")) {
                fileType = "video";
            } else if (props.file.type === "application/pdf") {
                fileType = "pdf";
            } else if (props.file.type.includes("audio")) {
                fileType = "audio";
            } else if (props.file.type.includes("image")) {
                fileType = "image";
            }

            let notificationObject = {
                file: key,
                title: displayNameForDatabase,
                type: fileType,
                users: usersObjectForNotifications,
                managers: managersObject,
                message: notificationMessage,
                url: url,
                thumbnail: thumbnail,
                date: dayjs().toString(),
            };

            let nearestHub = getAncestorHubs(
                props.file,
                props.folder,
                folders,
                currentUserData
            ).reverse()[0];

            if (nearestHub !== undefined) {
                notificationObject.hubName = nearestHub.name;
            }

            notificationsRef.push(notificationObject);
        }

        console.log("File Added Successfully");
        uploadProgress = 100;
    }

    function isUserInSelectedGroups(user) {
        for (let i = 0; i < selectedGroups.length; i++) {
            let group = selectedGroups[i];
            for (let j = 0; j < group.users.length; j++) {
                let u = group.users[j];

                if (user.key === u.key) {
                    return true;
                }
            }
        }
        return false;
    }

    function handleChangeDisplayName(event) {
        setDisplayName(event.target.value);
    }

    function handleEventClick() {
        setEventDialogOpen(true);
    }

    function handleCopyClick() {
        const settings = {
            events: events,
            allowComments: allowComments,
            allowSharing: allowSharing,
            allowDownloads: allowDownloads,
            selectedUsers: selectedUsers,
            selectedGroups: selectedGroups,
            managers: managers,
            sendNotifications: sendNotifications,
            notificationMessage: notificationMessage,
        };

        props.handleCopyClick(settings);
    }

    // Allows us to change the settings from elsewhere, this is used when copying settings of one file to another
    function changeSettings(settings) {
        settings.events.forEach((event) => {
            event.files.push(props.file.key);
        });

        setSendNotifications(settings.sendNotifications);
        setNotificationMessage(settings.notificationMessage);
        setEvents(settings.events);
        setAllowComments(settings.allowComments);
        setAllowDownloads(settings.allowDownloads);
        setAllowSharing(settings.allowSharing);
        setSelectedUsers(settings.selectedUsers);
        setSelectedGroups(settings.selectedGroups);
        setManagers(settings.managers);
    }

    function handlePermissionsClick() {
        setPermissionsDialogOpen(true);
    }

    function handleNotificationsClick() {
        setNotificationsDialogOpen(true);
    }

    function handleChange(event) {
        if (event.target.name === "downloads") {
            setAllowDownloads(event.target.checked);
        } else if (event.target.name === "comments") {
            setAllowComments(event.target.checked);
        } else if (event.target.name === "sharing") {
            setAllowSharing(event.target.checked);
        }
    }

    function handleEventSelectionChange(selectedEvents) {
        setEvents(selectedEvents);
    }

    function handleCloseEventDialog(selectedEvents) {
        setEvents(selectedEvents);
        setEventDialogOpen(false);
    }

    function handleClosePermissionsDialog(users, groups) {
        setSelectedGroups(groups);
        setSelectedUsers(users);
        setPermissionsDialogOpen(false);
    }

    function handleCloseNotificationsDialog(settings) {
        setSendNotifications(settings.sendNotifications);
        setNotificationMessage(settings.message);

        setNotificationsDialogOpen(false);
    }

    function handlePressRemoveButton() {
        props.handleRemoveClick(props.file);
    }

    return (
        <>
            <Paper elevation={1} className={classes.paper}>
                <Container fluid className={classes.container}>
                    <Row>
                        <Col
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            className={classes.red}
                        >
                            <Document
                                file={props.file}
                                className={classes.pdfPreview}
                                loading={<></>}
                                error={<></>}
                                noData={<></>}
                            >
                                <Page
                                    pageNumber={1}
                                    className={
                                        "import-pdf-page-" + props.file.key
                                    }
                                    height={112}
                                    width={200}
                                />
                            </Document>
                        </Col>
                        <Col
                            xl="5"
                            lg="5"
                            md="10"
                            sm="10"
                            xs="10"
                            className={classes.blue}
                        >
                            <Row>
                                <h5>{props.file.name}</h5>
                                {showFileSizeText && (
                                    <span className={classes.fileSizeText}>
                                        {fileSizeText}
                                    </span>
                                )}
                            </Row>
                            <Row>
                                <Input
                                    value={displayName}
                                    onChange={handleChangeDisplayName}
                                    placeholder="Display Name"
                                    fullWidth
                                    disableUnderline
                                    endAdornment
                                />
                            </Row>
                        </Col>
                        <Col
                            xl="3"
                            lg="3"
                            md="6"
                            sm="6"
                            xs="6"
                            className={classes.red}
                        >
                            <Tooltip title="Events">
                                <IconButton
                                    className={classes.icons}
                                    onClick={handleEventClick}
                                >
                                    <Badge
                                        badgeContent={events.length}
                                        color="primary"
                                    >
                                        <EventNoteIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Notifications">
                                <IconButton
                                    className={classes.icons}
                                    onClick={handleNotificationsClick}
                                >
                                    <Badge
                                        badgeContent={
                                            sendNotifications ? (
                                                <CheckIcon />
                                            ) : (
                                                <CloseIcon />
                                            )
                                        }
                                        color="primary"
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Users and Groups">
                                <IconButton
                                    className={classes.icons}
                                    onClick={handlePermissionsClick}
                                >
                                    <Badge
                                        badgeContent={
                                            selectedUsers.length +
                                            managers.length
                                        }
                                        color="primary"
                                    >
                                        <GroupIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Copy Settings To All">
                                <IconButton
                                    className={classes.icons}
                                    onClick={handleCopyClick}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Col>
                        <Col
                            className={classes.green}
                            xl="3"
                            lg="3"
                            md="6"
                            sm="6"
                            xs="6"
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={allowDownloads}
                                            onChange={handleChange}
                                            name="downloads"
                                            color="primary"
                                        />
                                    }
                                    label={"Allow Downloads"}
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={allowComments}
                                            onChange={handleChange}
                                            name="comments"
                                            color="primary"
                                        />
                                    }
                                    label={"Allow Comments"}
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={allowSharing}
                                            onChange={handleChange}
                                            name="sharing"
                                            color="primary"
                                        />
                                    }
                                    label={"Allow Sharing"}
                                />
                            </FormGroup>
                            <CloseIcon
                                className={classes.removeButton}
                                onClick={handlePressRemoveButton}
                            />
                        </Col>
                    </Row>
                </Container>
            </Paper>

            <LinkFileToEventDialog
                open={eventDialogOpen}
                onClose={handleCloseEventDialog}
                selectionChanged={handleEventSelectionChange}
                selectedEvents={events}
                calendars={props.calendars}
            />
            <FilePermissionsDialog
                open={permissionsDialogOpen}
                onClose={handleClosePermissionsDialog}
                selectedUsers={selectedUsers}
                selectedGroups={selectedGroups}
                managers={managers}
                ancestorHubs={props.ancestorHubs}
            />
            <FileNotificationsDialog
                open={notificationsDialogOpen}
                onClose={handleCloseNotificationsDialog}
                message={notificationMessage}
                send={sendNotifications}
            />
        </>
    );
}
