import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// My Files
import ToolbarWithLogo from "../ToolbarWithLogo";
import { UserContext } from "../../context";
import firebase from "../../firebase";
import UserFeed from "./UserFeed";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 80,
        [theme.breakpoints.down("sm")]: {
            marginLeft: "2%",
            marginRight: "2%",
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: "30%",
            marginRight: "30%",
        },
    },
}));

export default function HomePage() {
    const classes = useStyles();
    const history = useHistory();

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                history.replace("/signin");
            }
        });

        return () => unsubscribe();
    }, [currentUserData, history]);

    return (
        <div className={classes.root}>
            <UserFeed />
            <ToolbarWithLogo />
        </div>
    );
}
