import { useState, useEffect } from "react";

// Material UI
import Dialog from "@material-ui/core/Dialog";

import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function SavePlaylistDialog(props) {
    const [text, setText] = useState("");
    const [includeNotes, setIncludeNotes] = useState(true);

    useEffect(() => {
        if (props.initialValue) {
            setText(props.initialValue);
        }
    }, [props.initialValue]);

    function handleCancelClick() {
        props.onClose(text, false, includeNotes);
        if (props.initialValue) {
            setText(props.initialValue);
        } else {
            setText("");
        }
    }

    function handleOKClick() {
        props.onClose(text, true, includeNotes);
        setText("");
    }

    const handleKeyboardInput = (e) => {
        setText(e.target.value);
    };

    function handleIncludeNotesToggle() {
        setIncludeNotes(!includeNotes);
    }

    return (
        <>
            <Dialog
                onClose={handleCancelClick}
                aria-labelledby="simple-dialog-title"
                open={props.open}
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.message}</DialogContentText>
                    <TextField
                        value={text}
                        onChange={handleKeyboardInput}
                        autoFocus
                        margin="dense"
                        id="name"
                        label=""
                        type=""
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeNotes}
                                onChange={handleIncludeNotesToggle}
                                name="checkboxFour"
                                color="primary"
                            />
                        }
                        label="Include Notes"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        id="cancel"
                        onClick={handleCancelClick}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button id="ok" onClick={handleOKClick} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
