import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { LinearProgress } from "@material-ui/core";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext } from "../../../context";
import Canvas from "./SignatureBox";
import firebase from "../../../firebase";

const useStyles = makeStyles((theme) => ({
    headerImage: {
        height: 100,
        marginTop: 15,
    },
    headerText: {
        height: 100,
        padding: theme.spacing(3, 2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    bodyText: {
        margin: 15,
    },
    sectionTitle: {
        marginLeft: 15,
    },
    checkBox: {
        marginLeft: 15,
    },
    choosePasswordButton: {
        marginLeft: 15,
    },
    signatureBox: {
        border: "0.0625rem solid #9c9c9c",
        borderRadius: "0.25rem",
    },
    progress: {
        marginLeft: 10,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ClubParentConsentFormPage() {
    const location = useLocation();

    const classes = useStyles();

    const { userConsent } = useContext(DatabaseContext);

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");

    const [checkboxOneState, setCheckboxOneState] = useState(false);
    const [checkboxTwoState, setCheckboxTwoState] = useState(false);
    const [checkboxThreeState, setCheckboxThreeState] = useState(false);

    const [user, setUser] = useState();
    const [userName, setUserName] = useState("");

    const [completed, setCompleted] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        // let key = location.search.replace("?key=", "");
        // console.log(key);
        const urlParams = new URLSearchParams(location.search);

        const key = urlParams.get("key");

        // const domain = urlParams.get("domain");
        // const oobCode = "oobCode=" + urlParams.get("oobCode");
        // const apiKey = "apiKey=" + urlParams.get("apiKey");
        // const continueUrl = "continueUrl=" + urlParams.get("continueUrl");
        // const lang = "lang=" + urlParams.get("lang");

        // setPasswordLink(
        //     domain +
        //         "&" +
        //         oobCode +
        //         "&" +
        //         apiKey +
        //         "&" +
        //         continueUrl +
        //         "&" +
        //         lang
        // );

        let matchingUserConsent = userConsent.filter((u) => u.key === key)[0];

        if (matchingUserConsent) {
            setUser(matchingUserConsent);
            setUserName(matchingUserConsent.name);
        }
    }, [userConsent, location]);

    async function handleConfirmPress(signatureImageData, sketchingTime) {
        if (checkboxOneState && checkboxTwoState && checkboxThreeState) {
            if (sketchingTime === 0) {
                setStatusMessage("Please sign in the white box");
                setStatusIsVisible(true);
            } else {
                setInProgress(true);

                if (user) {
                    let ref = firebase.database().ref("userconsent");

                    await uploadSignature(user.key, signatureImageData).then(
                        () => {
                            ref.child(user.key).update({ hasConsent: true });
                            console.log("Verified");
                            setCompleted(true);
                            setInProgress(false);
                        }
                    );
                }
            }
        } else {
            setStatusMessage("Please select all checkboxes");
            setStatusIsVisible(true);
        }
    }

    function uploadSignature(userKey, signatureImageData) {
        return new Promise((resolve, reject) => {
            let storage = firebase.storage();

            let filename = "signature.png";

            if (userName !== "") {
                filename = userName + ".png";
            }

            // Upload the new file
            let signatureRef = storage
                .ref()
                .child("signatures/" + userKey + "/" + filename);

            signatureRef
                .putString(signatureImageData.split(",")[1], "base64", {
                    contentType: "image/png",
                })
                .then(() => {
                    console.log("Upload Successful");
                    resolve();
                })
                .catch((error) => {
                    let errorCode = error.code;
                    let errorMessage = error.message;
                    console.log(errorCode + ":" + errorMessage);
                    // setIsInProgress(false);
                    setStatusMessage(error.message);
                    setStatusIsVisible(true);
                    reject();
                });
        });
    }

    const handleCheckboxChange = (event) => {
        if (event.target.name === "checkboxOne") {
            setCheckboxOneState(event.target.checked);
        } else if (event.target.name === "checkboxTwo") {
            setCheckboxTwoState(event.target.checked);
        } else if (event.target.name === "checkboxThree") {
            setCheckboxThreeState(event.target.checked);
        }
    };

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    return (
        <>
            <Container fluid className={classes.root}>
                <Col>
                    {completed ? (
                        <>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    Registration Completed, Thank You.
                                </Typography>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <img
                                    className={classes.headerImage}
                                    src="images/behind_the_line.png"
                                    alt="behind_the_line_image"
                                />
                                <Typography
                                    className={classes.headerText}
                                    variant="h3"
                                    gutterBottom
                                >
                                    Are You Onside?
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    Nodiant Online is an online player
                                    management system made available to the Club
                                    by the Football Association of Wales (FAW).
                                    It is built around the sharing of files in a
                                    secure environment so that team members can
                                    login to their own personalised website,
                                    view/download these files and then comment
                                    around them to encourage self-reflection,
                                    analysis of performance together with
                                    communication and development away from
                                    direct contact.  Nodiant will be used for
                                    some or all of the following purposes:
                                    <p></p>
                                    <ul>
                                        <li>
                                            Sharing of video files amongst the
                                            squad relating to team and/or
                                            individual performances in matches
                                        </li>
                                        <li>
                                            Sharing of video files amongst the
                                            squad relating to team and/or
                                            individual performances in training
                                        </li>
                                        <li>
                                            Sharing of video files relating to
                                            opposition analysis
                                        </li>
                                        <li>
                                            Closed dialogue between staff
                                            members and individual players
                                            relating to performance
                                        </li>
                                        <li>
                                            Open dialogue between staff members
                                            and groups of players and/or the
                                            whole squad relating to performance
                                        </li>
                                        <li>
                                            Collection, storage and where
                                            appropriate sharing of player data
                                            relating to tactical, technical,
                                            physical and psychological aspects
                                            of performance
                                        </li>
                                        <li>
                                            Collection, storage and where
                                            appropriate sharing of player’s
                                            medical data
                                        </li>
                                        <li>
                                            Sharing of administrative data
                                            amongst the squad
                                        </li>
                                    </ul>
                                    We all have a responsibility to ensure that
                                    everyone involved in football finds it to be
                                    a fun, safe and positive experience.
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    A copy of the Club's safeguarding polich
                                    should be available from your club. A copy
                                    of the FAW Safeguarding Policy is available
                                    from{" "}
                                    <a
                                        style={{
                                            display: "inline",
                                        }}
                                        href="https://www.safeguarding.cymru/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://www.safeguarding.cymru/
                                    </a>
                                    .
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    The FAW and end user clubs and academies
                                    will take all steps to ensure that images /
                                    videos of players and support staff are used
                                    solely for their intended purposes.  If you
                                    become aware that these images are being
                                    used inappropriately, please contact the
                                    Club and the FAW Safeguarding Department on
                                    02920 435830 immediately.
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.sectionTitle}
                                    variant="h5"
                                    gutterBottom
                                >
                                    ICT Acceptable Use
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    The Club understands the importance of
                                    technology for children and young people and
                                    their development.  However, we also
                                    recognise the need for vital safeguards to
                                    be in place to ensure player welfare remains
                                    paramount whilst online or using social
                                    media.   We ask that all players, support
                                    staff and parents/guardians take the time to
                                    read through this document and discuss the
                                    policy with their child/children.
                                    <p></p>
                                    <ul>
                                        <li>
                                            I will be responsible for my
                                            behaviour when using resources
                                            provided by the Club and/or the FAW
                                            and their affiliated partners and
                                            when accessing online platforms or
                                            portals.
                                        </li>
                                        <li>
                                            I will not deliberately browse,
                                            download or access material that
                                            could be considered offensive. If I
                                            accidentally come across such
                                            material I will immediately report
                                            this to the Club.
                                        </li>
                                        <li>
                                            I will not use social media or the
                                            internet to send anyone material
                                            that could be considered
                                            threatening, offensive, bullying or
                                            illegal.
                                        </li>
                                        <li>
                                            I will not give out my personal
                                            information such as name, age,
                                            address or telephone number to
                                            strangers.
                                        </li>
                                        <li>
                                            I will not share my log-on details
                                            with anybody.
                                        </li>
                                        <li>
                                            I will not arrange to meet anybody
                                            unless accompanied by a member of
                                            the Club, the FAW or a
                                            parent/guardian.
                                        </li>
                                        <li>
                                            I understand that these rules are
                                            designed to ensure my safety and if
                                            they are not followed my
                                            parent/guardian may be contacted and
                                            I could be subject to disciplinary
                                            action.
                                        </li>
                                    </ul>
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.sectionTitle}
                                    variant="h5"
                                    gutterBottom
                                >
                                    Consent
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    Please tick the boxes below:
                                </Typography>
                            </Row>
                            <Row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.checkBox}
                                            checked={checkboxOneState}
                                            onChange={handleCheckboxChange}
                                            name="checkboxOne"
                                            color="primary"
                                        />
                                    }
                                    label="I give my consent for my / my child’s details, medical information, football related images and videos to be securely stored on the Nodiant Online platform and understand that this information may be shared with relevant and essential personnel."
                                />
                            </Row>
                            <Row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.checkBox}
                                            checked={checkboxTwoState}
                                            onChange={handleCheckboxChange}
                                            name="checkboxTwo"
                                            color="primary"
                                        />
                                    }
                                    label="I give my consent for relevant and essential personnel to communicate with myself / my child in relation to my / their football related activities, performance and progress."
                                />
                            </Row>
                            <Row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.checkBox}
                                            checked={checkboxThreeState}
                                            onChange={handleCheckboxChange}
                                            name="checkboxThree"
                                            color="primary"
                                        />
                                    }
                                    label=" I hereby grant the Club, the FAW and its’ affiliated partners the right to use the photograph(s) and any reproductions or adaptations of the photograph(s) for all general purposes in relation to the Club’s, the FAW’s and its’ affiliated partners work including, without limitation, the right to use them in publicity materials, books, newspapers, magazine articles, and on the Club’s, the FAW’s and its’ affiliated partners’ websites and social media outlets."
                                />
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    The Club and the FAW is committed to
                                    safeguarding the privacy of the personal
                                    data you have supplied. The supplied data
                                    will be held in accordance with data
                                    protection legislation and principles and as
                                    per the provisions of the UK General Data
                                    Protection Regulation (UK GDPR). The
                                    information that we are obliged to provide
                                    you in accordance with the provisions of the
                                    UK GDPR is contained in the Club’s Privacy
                                    Notice (this should be made available to you
                                    by your club). By signing this form, you
                                    confirm that you have read and understood
                                    the Club’s Privacy Notice.
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body1"
                                    gutterBottom
                                >
                                    If you are using this platform as a member
                                    of a Cymru Premier club or academy, or an
                                    Adran Premier club, please consult your club
                                    officials regarding the club / academy GDPR
                                    policies. By signing this form, you confirm
                                    that you have read and understood your club
                                    / academy Privacy Notices.
                                </Typography>
                            </Row>
                            <Row>
                                <Typography
                                    className={classes.bodyText}
                                    variant="body2"
                                >{`Signed By: ${userName}`}</Typography>
                            </Row>
                            <Row>
                                <Canvas imageData={handleConfirmPress} />
                            </Row>
                        </>
                    )}
                </Col>
            </Container>
            {inProgress && <LinearProgress />}
            <div>
                <Snackbar
                    open={statusIsVisible}
                    autoHideDuration={6000}
                    onClose={handleStatusClose}
                >
                    <Alert onClose={handleStatusClose} severity={"error"}>
                        {statusMessage}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}
