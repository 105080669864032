import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import EventNoteIcon from "@material-ui/icons/EventNote";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from "@material-ui/icons/Group";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { FormControlLabel, FormGroup, IconButton } from "@material-ui/core";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files
import firebase from "../../../firebase";
import { DatabaseContext, UserContext } from "../../../context";
import FilePermissionsDialog from "../uploading/FilePermissionsDialog";
import FileNotificationsDialog from "../uploading/FileNotificationsDialog";
import LinkFileToEventDialog from "../uploading/LinkFileToEventDialog";
import {
    jsonFromArray,
    shouldFileBeVisibleToUser,
    getAncestorHubs,
} from "../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    icons: {
        marginTop: 15,
    },
    saveButton: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
}));

export default function EditFileDialog(props) {
    const classes = useStyles();
    const { users, calendars, notifications, groups, folders } =
        useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [linkedEvents, setLinkedEvents] = useState([]);
    const [displayName, setDisplayName] = useState("");
    const [allowComments, setAllowComments] = useState(true);
    const [allowSharing, setAllowSharing] = useState(false);
    const [allowDownloads, setAllowDownloads] = useState(true);
    const [sendNotifications, setSendNotifications] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationsFromDate, setNotificationsFromDate] = useState(dayjs());

    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [defaultUsers, setDefaultUsers] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [managers, setManagers] = useState([]);
    const [availableCalendars, setAvailableCalendars] = useState([]);
    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
    const [notificationsDialogOpen, setNotificationsDialogOpen] =
        useState(false);

    const [ancestorHubs, setAncestorHubs] = useState([]);

    useEffect(() => {
        if (props.file && props.folder) {
            if (displayName === "") {
                // If the user has made changes to the display name but hasn't saved it yet,
                // This check stops the change being lost when useEffect runs again
                setDisplayName(props.file.displayName);
            }
            setAllowComments(props.file.allowComments);
            setAllowDownloads(props.file.allowDownloads);
            setAllowSharing(props.file.allowSharing);
            setLinkedEvents(props.file.events);

            let usersArray = [];
            let managersArray = [];

            // Get user objects from database
            users.forEach((user) => {
                // Reset this value in case previously set
                if (props.file.managers.includes(user.key)) {
                    managersArray.push(user);
                } else if (
                    shouldFileBeVisibleToUser(props.file, groups, user)
                ) {
                    usersArray.push(user);
                }
            });

            setManagers(managersArray);

            // Get ancestor hubs, these are used for the groups
            let ancestors = getAncestorHubs(
                props.file,
                props.folder,
                folders,
                currentUserData
            );

            let selectedGroupsArray = [];

            // Get the groups for these ancestors
            ancestors.forEach((ancestorHub) => {
                let matchingGroups = groups.filter(
                    (g) => g.hub === ancestorHub.key
                );

                // Set the selected groups
                matchingGroups.forEach((group) => {
                    if (
                        props.file.groups.filter((g) => g === group.key)
                            .length > 0
                    ) {
                        group.didSelect = true;
                        selectedGroupsArray.push(group);
                    } else {
                        group.didSelect = false;
                    }
                });

                ancestorHub.groups = matchingGroups;
            });

            setSelectedGroups(selectedGroupsArray);
            setAncestorHubs(ancestors);
            setSelectedUsers(usersArray);

            // Find calendars that user can see
            let calendarsArray = [];

            calendars.forEach((calendar) => {
                let shouldAdd = false;

                // User should be able to see calendar if it's their personal one, or if they are uploading a file
                // to the hub for that calendar
                if (calendar.user === currentUserData.key) {
                    shouldAdd = true;
                } else if (calendar.hub === props.folder.key) {
                    shouldAdd = true;
                } else if (props.folder.ancestors.includes(calendar.hub)) {
                    shouldAdd = true;
                }

                if (shouldAdd) {
                    calendarsArray.push(calendar);
                }
            });

            setAvailableCalendars(calendarsArray);
        }
        // eslint-disable-next-line
    }, [currentUserData, calendars, props.file]);

    const handleClose = () => {
        props.onClose();
    };

    function handleSaveClick() {
        if (
            props.file.managers.includes(currentUserData.key) ||
            currentUserData.role === "System Digital Manager" ||
            props.file.uploadedBy === currentUserData.key
        ) {
            let ref = firebase.database().ref("files").child(props.file.key);

            // Need managers object for notifications
            let managersObject = {};
            let managersArray = [];
            managers.forEach((manager) => {
                managersObject[manager.key] = true;
                managersArray.push(manager.key);
            });

            props.file.displayName = displayName;

            // Update the file record in firebase
            ref.update({
                displayName: displayName,
            });

            // Change the file name in existing notifications

            notifications.forEach((notification) => {
                if (notification.file === props.file.key) {
                    var notificationRef = firebase
                        .database()
                        .ref("notifications")
                        .child(notification.key);

                    notificationRef.update({ title: props.file.displayName });
                }
            });

            // Send Notifications
            if (sendNotifications) {
                console.log("Send Notifications");
                var notificationsRef = firebase.database().ref("notifications");

                var fileType = "file";

                if (props.file.type.includes("video")) {
                    fileType = "video";
                } else if (props.file.type === "application/pdf") {
                    fileType = "pdf";
                } else if (props.file.type.includes("audio")) {
                    fileType = "audio";
                } else if (props.file.type.includes("image")) {
                    fileType = "image";
                } else if (props.file.type === "link") {
                    fileType = "link";
                }

                let notificationObject = {
                    file: props.file.key,
                    title: props.file.displayName,
                    type: fileType,
                    users: jsonFromArray(selectedUsers),
                    managers: managersObject,
                    message: notificationMessage,
                    date: dayjs().toString(),
                    fromDate: notificationsFromDate.toString(),
                };

                if (props.file.url !== undefined) {
                    notificationObject.url = props.file.url;
                }

                if (props.file.thumbnail !== undefined) {
                    notificationObject.thumbnail = props.file.thumbnail;
                }

                let nearestHub = ancestorHubs.reverse()[0];
                if (nearestHub !== undefined) {
                    notificationObject.hubName = nearestHub.name;
                }

                notificationsRef.push(notificationObject);
            }
            // Dismiss the dialog
            props.onClose();
        }
    }

    function handleChangeDisplayName(event) {
        setDisplayName(event.target.value);
    }

    function handleChangeSwitch(event) {
        let ref = firebase.database().ref("files").child(props.file.key);

        if (event.target.name === "downloads") {
            setAllowDownloads(event.target.checked);

            ref.update({ allowDownloads: event.target.checked });
        } else if (event.target.name === "comments") {
            setAllowComments(event.target.checked);

            ref.update({ allowComments: event.target.checked });
        } else if (event.target.name === "sharing") {
            setAllowSharing(event.target.checked);

            ref.update({ allowSharing: event.target.checked });
        }
    }

    function handleEventsClick() {
        setEventDialogOpen(true);
    }

    function handlePermissionsClick() {
        setPermissionsDialogOpen(true);
    }

    function handleClosePermissionsDialog(users, groups) {
        setSelectedGroups(groups);
        setSelectedUsers(users);
        setPermissionsDialogOpen(false);
    }

    function handleNotificationsClick() {
        setNotificationsDialogOpen(true);
    }

    function handleCloseNotificationsDialog(settings) {
        setSendNotifications(settings.sendNotifications);
        setNotificationMessage(settings.message);
        setNotificationsFromDate(settings.fromDate);

        setNotificationsDialogOpen(false);
    }

    function handleEventSelectionChange(selectedEvents) {
        setLinkedEvents(selectedEvents);
    }

    function handleCloseEventDialog(selectedEvents) {
        setLinkedEvents(selectedEvents);
        setEventDialogOpen(false);
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <Container fluid className={classes.container}>
                    <h4 className={classes.title}>Edit File</h4>
                    <TextField
                        id="standard-multiline-flexible"
                        value={displayName}
                        onChange={handleChangeDisplayName}
                        placeholder="Display Name"
                        label="Display Name"
                        fullWidth
                        multiline
                    />
                    <Tooltip title="Events">
                        <IconButton
                            className={classes.icons}
                            onClick={handleEventsClick}
                        >
                            <Badge
                                badgeContent={linkedEvents.length}
                                color="primary"
                            >
                                <EventNoteIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Notifications">
                        <IconButton
                            className={classes.icons}
                            onClick={handleNotificationsClick}
                        >
                            <Badge
                                badgeContent={
                                    sendNotifications ? (
                                        <CheckIcon />
                                    ) : (
                                        <CloseIcon />
                                    )
                                }
                                color="primary"
                            >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Users">
                        <IconButton
                            className={classes.icons}
                            onClick={handlePermissionsClick}
                        >
                            <Badge
                                badgeContent={
                                    selectedUsers.length + managers.length
                                }
                                color="primary"
                            >
                                <GroupIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {!props.file.type.includes("link") && (
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={allowDownloads}
                                        onChange={handleChangeSwitch}
                                        name="downloads"
                                        color="primary"
                                    />
                                }
                                label={"Allow Downloads"}
                            />
                        </FormGroup>
                    )}
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={allowComments}
                                    onChange={handleChangeSwitch}
                                    name="comments"
                                    color="primary"
                                />
                            }
                            label={"Allow Comments"}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={allowSharing}
                                    onChange={handleChangeSwitch}
                                    name="sharing"
                                    color="primary"
                                />
                            }
                            label={"Allow Sharing"}
                        />
                    </FormGroup>
                    <Button
                        className={classes.saveButton}
                        variant="contained"
                        color="primary"
                        onClick={handleSaveClick}
                    >
                        Done
                    </Button>
                </Container>
            </Dialog>
            <LinkFileToEventDialog
                open={eventDialogOpen}
                onClose={handleCloseEventDialog}
                selectionChanged={handleEventSelectionChange}
                selectedEvents={linkedEvents}
                file={props.file}
                calendars={availableCalendars}
            />
            <FilePermissionsDialog
                open={permissionsDialogOpen}
                onClose={handleClosePermissionsDialog}
                // defaultUsers={defaultUsers}
                selectedUsers={selectedUsers}
                managers={managers}
                ancestorHubs={ancestorHubs}
                selectedGroups={selectedGroups}
                file={props.file}
            />
            <FileNotificationsDialog
                open={notificationsDialogOpen}
                onClose={handleCloseNotificationsDialog}
                message={notificationMessage}
                send={sendNotifications}
            />
        </>
    );
}
