import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function ProjectAudioFileComponent(props) {
    return (
        <>
            {props.file && (
                <AudioPlayer autoPlay={false} src={props.file.url} />
            )}
        </>
    );
}
