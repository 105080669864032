import { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { TextField } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// My Files
import UserAvatar from "../UserAvatar";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: "100%",
    },
    container: {
        height: "100%",
    },
    chip: {
        marginLeft: 15,
        marginTop: 15,
    },
    nameTextField: {
        marginLeft: 70,
        marginRight: 15,
        marginTop: 5,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Shows a list of users which can be added to a hub
export default function ManagerManagerEnrolementDialog(props) {
    //const theme = useTheme();

    // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const { onClose, open } = props;

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [otherUsers, setOtherUsers] = useState([]);
    const [selectionHasChanged, setSelectionHasChanged] = useState(false);
    const [usernameFilter, setUsernameFilter] = useState("");

    useEffect(() => {
        if (!open) {
            setSelectedUsers(props.selectedUsers);
            setOtherUsers(props.otherUsers);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.otherUsers, props.selectedUsers]);

    const handleClose = () => {
        onClose(selectedUsers, otherUsers, selectionHasChanged);
    };

    const handleDelete = (user) => () => {
        let selectedArray = [...selectedUsers]; // make a separate copy of the array
        let index = selectedArray.indexOf(user);
        if (index !== -1) {
            selectedArray.splice(index, 1);
            setSelectedUsers(selectedArray);
        }

        let otherArray = [...otherUsers];
        otherArray.push(user);
        setOtherUsers(otherArray);
        setSelectionHasChanged(true);
    };

    const handleSelect = (user) => () => {
        let otherArray = [...otherUsers]; // make a separate copy of the array
        let index = otherArray.indexOf(user);
        if (index !== -1) {
            otherArray.splice(index, 1);
            setOtherUsers(otherArray);
        }

        let selectedArray = [...selectedUsers];
        selectedArray.push(user);
        setSelectedUsers(selectedArray);
        setSelectionHasChanged(true);
    };

    const handleChangeFilterText = (event) => {
        setUsernameFilter(event.target.value);
    };

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth="lg"
            fullWidth={true}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <SaveIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.titleText}>
                        {"Edit Managers"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container fluid className={classes.container}>
                <Row>
                    {selectedUsers
                        .sort((a, b) =>
                            a.lastName.toLowerCase() > b.lastName.toLowerCase()
                                ? 1
                                : -1
                        )
                        .map((user) => (
                            <Chip
                                avatar={
                                    <Avatar
                                        alt={user.fullName}
                                        src={user.avatar}
                                    />
                                }
                                key={user.key}
                                label={user.fullName}
                                className={classes.chip}
                                onDelete={
                                    !user.cannotRemoveAsManager &&
                                    handleDelete(user)
                                }
                                // clickable
                                // color="primary"
                                variant="outlined"
                            />
                        ))}
                </Row>
                <Row>
                    <TextField
                        id="standard-basic"
                        label="Type User Name"
                        className={classes.nameTextField}
                        fullWidth
                        value={usernameFilter}
                        onChange={handleChangeFilterText}
                    ></TextField>
                </Row>
                <Row>
                    <List className={classes.list}>
                        {otherUsers
                            .filter(
                                (user) =>
                                    selectedUsers.filter(
                                        (u) => u.key === user.key
                                    ).length === 0
                            )
                            .filter((user) =>
                                user.fullName.includes(usernameFilter)
                            )
                            .sort((a, b) =>
                                a.lastName.toLowerCase() >
                                b.lastName.toLowerCase()
                                    ? 1
                                    : -1
                            )
                            .map((user) => (
                                <div
                                    key={user.key}
                                    onClick={handleSelect(user)}
                                >
                                    <ListItem
                                        button
                                        alignItems="flex-start"
                                        // selected={selectedIndex === index}
                                    >
                                        <ListItemAvatar>
                                            <LazyLoad overflow>
                                                <UserAvatar
                                                    userOrHub={user}
                                                    path="users"
                                                    variant="circular"
                                                />
                                            </LazyLoad>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.fullName}
                                            secondary={user.customRoleName}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            ))}
                    </List>
                </Row>
            </Container>

            {/* <List className={classes.list}>
                {selectedUsers.map((user) => (
                    <Chip
                        avatar={
                            <Avatar alt={user.fullName} src={user.avatar} />
                        }
                        key={user.key}
                        label={user.fullName}
                        onDelete={handleDelete(user)}
                        onClick={handleDelete(user)}
                        clickable
                        color="primary"
                    />
                ))}
            </List>
            <Divider orientation="horizontal" />
            <TextField
                className={classes.searchField}
                id="input-with-icon-textfield"
                placeholder="Search"
                fullWidth
                onChange={handleChangeFilterText}
                value={usernameFilter}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <List className={classes.list}>
                {otherUsers
                    .filter((user) => user.fullName.includes(usernameFilter))
                    .map((user) => (
                        <Chip
                            avatar={
                                <Avatar alt={user.fullName} src={user.avatar} />
                            }
                            key={user.key}
                            label={user.fullName}
                            variant="outlined"
                            onClick={handleSelect(user)}
                            clickable
                        />
                    ))}
            </List> */}
        </Dialog>
    );
}
