import React from "react";
import FolderIcon from "@material-ui/icons/Folder";
import StarIcon from "@material-ui/icons/Star";
export default function MyFolderIcon(props) {
    if (props.folderKey === "faves_fggh2973jgbvbiu") {
        return <StarIcon style={{ fontSize: 30, padding: 0, marginLeft: 5 }} />;
    } else {
        return (
            <FolderIcon style={{ fontSize: 30, padding: 0, marginLeft: 5 }} />
        );
    }
}
