import React, { useState, useContext, useEffect } from "react";
import LazyLoad from "react-lazyload";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

// My Files
import "../../calendar/Calendar.css";
import { DatabaseContext, UserContext } from "../../../context";
import UserAvatar from "../../management/UserAvatar";
import { ListSubheader } from "@material-ui/core";
import firebase from "../../../firebase";
import { jsonFromArray } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
        padding: 0,
    },
    container: {
        height: "100%",
        margin: 0,
        paddingLeft: 15,
    },
    chip: {
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
    },
    nameTextField: {
        marginLeft: 15,
        marginRight: 15,
    },
    title: {
        flexGrow: 1,
    },
    sectionTitle: {
        marginLeft: 15,
        marginTop: 10,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilePermissionsDialog(props) {
    const classes = useStyles();

    const { users } = useContext(DatabaseContext);
    const { currentUserData } = useContext(UserContext);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [managers, setManagers] = useState([]);

    const [usernameFilter, setUsernameFilter] = useState("");

    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        // if (
        //     props.managers.filter((m) => m.key === currentUserData.key)
        //         .length === 0
        // ) {
        //     if (
        //         props.selectedUsers.filter((u) => u.key === currentUserData.key)
        //             .length === 0
        //     ) {
        //         setSelectedUsers([...props.selectedUsers, currentUserData]);
        //     } else {
        //         setSelectedUsers(props.selectedUsers);
        //     }
        // } else {
        //     setSelectedUsers(props.selectedUsers);
        // }
        setSelectedUsers(props.selectedUsers);
        setManagers(props.managers);

        setSelectedGroups(props.selectedGroups);

        // Set hubName property for "All Users" groups
        // let selectedGroupsArray = [];
        props.ancestorHubs.forEach((hub) => {
            hub.groups.forEach((group) => {
                if (group.name === "All Users") {
                    group.displayName = "All in '" + hub.name + "'";
                }
            });
        });

        // eslint-disable-next-line
    }, [props.open]);

    const handleUserSelect = (user) => () => {
        let selectedArray = [...selectedUsers];
        selectedArray.push(user);
        setSelectedUsers(selectedArray);
        setUsernameFilter("");
    };

    const handleGroupSelect = (group) => () => {
        // Get the users from this group and remove them from the users which can be selected
        let selectedUsersArray = [...selectedUsers];

        group.users.forEach((user) => {
            let matchingUsers = users.filter((u) => u.key === user.key);

            if (matchingUsers.length === 1) {
                let userToSelect = matchingUsers[0];

                // Only add the user if they hadn't already been selected as an individual
                if (
                    selectedUsersArray.filter((u) => u.key === userToSelect.key)
                        .length === 0
                ) {
                    selectedUsersArray.push(userToSelect);
                }

                setSelectedUsers(selectedUsersArray);
            }
        });

        // Now select the group
        let selectedArray = [...selectedGroups];
        selectedArray.push(group);
        setSelectedGroups(selectedArray);
    };

    const handleDeleteUser = (user) => () => {
        let selectedArray = selectedUsers.filter((u) => u.key !== user.key);

        setSelectedUsers(selectedArray);
    };

    const handleDeleteGroup = (group) => () => {
        // Remove the users that were added from this group, unless they are also in a still selected group

        let selectedUsersArray = [...selectedUsers];

        group.users.forEach((user) => {
            let shouldRemove = true;

            selectedGroups.forEach((selectedGroup) => {
                if (selectedGroup.key !== group.key) {
                    if (
                        selectedGroup.users.filter((u) => u.key === user.key)
                            .length > 0
                    ) {
                        shouldRemove = false;
                    }
                }
            });

            if (shouldRemove) {
                selectedUsersArray = selectedUsersArray.filter(
                    (u) => u.key !== user.key
                );
            }
        });

        setSelectedUsers(selectedUsersArray);

        //
        let selectedArray = selectedGroups.filter((u) => u.key !== group.key);
        setSelectedGroups(selectedArray);
    };

    const handleClose = () => {
        setUsernameFilter("");
        if (props.file) {
            // props.file is only set if editing an existing file (not when uploading a new file). In this case save to firebase on close
            if (
                props.file.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager" ||
                props.file.uploadedBy === currentUserData.key
            ) {
                let ref = firebase
                    .database()
                    .ref("files")
                    .child(props.file.key);

                // Update the groups
                let groupKeysArray = [];
                selectedGroups.forEach((group) => {
                    groupKeysArray.push(group.key);
                });
                props.file.groups = groupKeysArray;

                // Update the file record in firebase
                ref.update({
                    groups: jsonFromArray(selectedGroups),
                });

                // Update the users.files records if needed, for users not in the hub
                // let usersObjectForNotifications = {};

                // console.log("Saving Users: " + selectedUsers.length);
                // console.log(selectedUsers);

                selectedUsers.forEach((user) => {
                    // usersObjectForNotifications[user.key] = true;

                    if (!isUserInSelectedGroups(user)) {
                        // User has been added individually, add the file to users.files instead

                        let userRef = firebase
                            .database()
                            .ref("users")
                            .child(user.key);

                        let userFilesObject = {};

                        user.files.forEach((file) => {
                            userFilesObject[file] = true;
                        });

                        userFilesObject[props.file.key] = true;

                        // console.log(
                        //     "Adding User: " +
                        //         user.firstName +
                        //         " " +
                        //         user.lastName
                        // );

                        userRef.update({ files: userFilesObject });

                        user.files.push(props.file.key);
                    }
                });

                // Update the user.files for removed users that aren't in the hub
                users.forEach((user) => {
                    if (
                        selectedUsers.filter((u) => u.key === user.key)
                            .length === 0
                    ) {
                        // console.log(user);
                        let userRef = firebase
                            .database()
                            .ref("users")
                            .child(user.key);

                        let userFilesObject = {};

                        user.files.forEach((file) => {
                            if (file !== props.file.key) {
                                userFilesObject[file] = true;
                            }
                        });

                        userRef.update({ files: userFilesObject });
                    }
                });
            }
        }
        props.onClose(selectedUsers, selectedGroups);
    };

    const handleChangeFilterText = (event) => {
        setUsernameFilter(event.target.value);
    };

    function isUserInSelectedGroups(user) {
        for (let i = 0; i < selectedGroups.length; i++) {
            let group = selectedGroups[i];
            for (let j = 0; j < group.users.length; j++) {
                let u = group.users[j];

                if (user.key === u.key) {
                    return true;
                }
            }
        }
        return false;
    }

    function groupLabel(group) {
        if (group.displayName && group.displayName !== "") {
            if (group.users === 1) {
                return group.displayName + " (1 User)";
            } else {
                return (
                    group.displayName + " (" + group.users.length + " Users)"
                );
            }
        } else {
            if (group.users === 1) {
                return group.name + " (1 User)";
            } else {
                return group.name + " (" + group.users.length + " Users)";
            }
        }
    }

    function userShouldBeVisible(user) {
        if (user.key === currentUserData.key) {
            return false;
        }

        var hasPermissionToSee = false;
        if (
            user.admins.includes(currentUserData.key) ||
            user.createdBy === currentUserData.key ||
            currentUserData.role === "System Digital Manager"
        ) {
            hasPermissionToSee = true;
        } else {
            for (let index = 0; index < props.ancestorHubs.length; index++) {
                const ancestorHub = props.ancestorHubs[index];
                if (ancestorHub.users.includes(user.key)) {
                    hasPermissionToSee = true;
                }
            }
        }

        if (hasPermissionToSee) {
            if (selectedUsers.filter((u) => u.key === user.key).length === 0) {
                if (managers.filter((u) => u.key === user.key).length === 0) {
                    if (
                        user.fullName
                            .toLowerCase()
                            .includes(usernameFilter.toLowerCase())
                    ) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={props.open}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        {props.file ? <SaveIcon /> : <CloseIcon />}
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Users and Groups
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container fluid className={classes.container}>
                <Row>
                    <h3 className={classes.sectionTitle}>Selected</h3>
                </Row>
                <Row>
                    {managers
                        .sort((a, b) =>
                            a.lastName.toLowerCase() > b.lastName.toLowerCase()
                                ? 1
                                : -1
                        )
                        .map((manager) => (
                            <Chip
                                avatar={
                                    <Avatar
                                        alt={manager.fullName}
                                        src={manager.avatar}
                                    />
                                }
                                key={manager.key}
                                label={manager.fullName}
                                className={classes.chip}
                                color="primary"
                            />
                        ))}
                </Row>
                <Row>
                    {selectedGroups.map((group) => (
                        <Chip
                            key={group.key}
                            label={groupLabel(group)}
                            className={classes.chip}
                            // color="secondary"
                            onDelete={handleDeleteGroup(group)}
                        />
                    ))}
                </Row>
                <Row>
                    {selectedUsers
                        .sort((a, b) =>
                            a.lastName.toLowerCase() > b.lastName.toLowerCase()
                                ? 1
                                : -1
                        )
                        .map(
                            (user) =>
                                !isUserInSelectedGroups(user) && (
                                    <Chip
                                        avatar={
                                            <Avatar
                                                alt={user.fullName}
                                                src={user.avatar}
                                            />
                                        }
                                        key={user.key}
                                        label={user.fullName}
                                        className={classes.chip}
                                        onDelete={
                                            user.key !== currentUserData.key &&
                                            handleDeleteUser(user)
                                        }
                                        // clickable
                                        // color="primary"
                                        variant="outlined"
                                    />
                                )
                        )}
                </Row>
                <Divider style={{ marginTop: 25 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Groups</h3>
                </Row>
                <Row style={{ margin: 0, padding: 0 }}>
                    <Row>
                        {props.ancestorHubs
                            .sort((a, b) => {
                                if (a.ancestors.length > b.ancestors.length)
                                    return 1;
                                if (a.ancestors.length < b.ancestors.length)
                                    return -1;
                                return 0;
                            })
                            .map(
                                (hub) =>
                                    hub.groups.length > 0 && (
                                        <React.Fragment key={hub.key}>
                                            <ListSubheader>
                                                {hub.name}
                                            </ListSubheader>

                                            <ListItem
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                            >
                                                {hub.groups
                                                    .filter(
                                                        (group) =>
                                                            !selectedGroups.includes(
                                                                group
                                                            )
                                                    )
                                                    .sort((a, b) => {
                                                        if (a.name > b.name)
                                                            return 1;
                                                        if (a.name < b.name)
                                                            return -1;
                                                        return 0;
                                                    })
                                                    .map((group) => (
                                                        <Chip
                                                            key={group.key}
                                                            label={groupLabel(
                                                                group
                                                            )}
                                                            className={
                                                                classes.chip
                                                            }
                                                            clickable
                                                            onClick={handleGroupSelect(
                                                                group
                                                            )}
                                                        />
                                                    ))}
                                            </ListItem>
                                        </React.Fragment>
                                    )
                            )}
                    </Row>
                </Row>
                <Divider style={{ marginTop: 25 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Users</h3>
                </Row>
                <Row>
                    <TextField
                        id="standard-basic"
                        label="Type User Name"
                        className={classes.nameTextField}
                        fullWidth
                        value={usernameFilter}
                        onChange={handleChangeFilterText}
                    ></TextField>
                </Row>

                <Row>
                    <List className={classes.list}>
                        {users
                            .filter((user) => userShouldBeVisible(user))
                            .sort((a, b) =>
                                a.lastName.toLowerCase() >
                                b.lastName.toLowerCase()
                                    ? 1
                                    : -1
                            )
                            .map((user) => (
                                <div
                                    key={user.key}
                                    onClick={handleUserSelect(user)}
                                >
                                    <ListItem
                                        button
                                        alignItems="flex-start"
                                        // selected={selectedIndex === index}
                                    >
                                        <ListItemAvatar>
                                            <LazyLoad overflow>
                                                <UserAvatar
                                                    userOrHub={user}
                                                    path="users"
                                                    variant="circular"
                                                />
                                            </LazyLoad>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.fullName}
                                            secondary={user.customRoleName}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            ))}
                    </List>
                </Row>
            </Container>
        </Dialog>
    );
}
