import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1%",
        marginLeft: "2%",
        marginRight: "2%",
        marginBottom: 5,
    },
    closeButton: {
        cursor: "pointer",
        position: "absolute",
        left: "auto",
        right: 0,
        paddingTop: 5,
    },
}));

export default function SurveyNotificationCard(props) {
    const classes = useStyles();
    const history = useHistory();

    const handleFileClick = (n) => () => {
        props.updateSeenBy(props.notification);
        history.push("/surveys/r?key=" + props.notification.key);
    };

    function handleDismissPressed() {
        props.updateSeenBy(props.notification);
    }

    return (
        <Card className={classes.card} variant="outlined">
            <CardActionArea>
                {props.popupFeed && (
                    <Tooltip title="Dismiss">
                        <CloseIcon
                            className={classes.closeButton}
                            onClick={handleDismissPressed}
                        />
                    </Tooltip>
                )}
                {/* <CardMedia
                    component="img"
                    alt="Video thumbnail"
                    height="250"
                    image={
                        "https://vz-db0e7359-d70.b-cdn.net/" +
                        props.notification.file +
                        "/thumbnail.jpg"
                    }
                    title={props.notification.title}
                    onClick={handleFileClick(props.notification)}
                /> */}
                <CardContent onClick={handleFileClick(props.notification)}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.notification.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {props.notification.message}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
