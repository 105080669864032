import React, { useEffect, useContext, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import firebase from "../../firebase";
import BackButton from "../BackButton";
import ProjectTitleComponent from "./Components/ProjectTitleComponent";
import ProjectTextBoxComponent from "./Components/ProjectTextBoxComponent";
import ProjectPlaylistComponent from "./Components/ProjectPlaylistComponent";
import ProjectFileComponent from "./Components/ProjectFileComponent";
import NoPermissionsMessage from "../NoPermissionsMessage";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 150,
    },
    paper: {
        padding: 15,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    inputTextField: {
        // marginBottom: 15,
    },
    shareButton: { marginTop: 0, marginLeft: 0, marginBottom: 15 },
    appBar: {
        top: "auto",
        bottom: 65,
        height: 50,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    deleteButton: {
        marginBottom: 15,
    },
    grow: {
        flexGrow: 1,
    },
    anonymousSwitch: {
        marginBottom: 5,
    },
}));

export default function ProjectViewPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const location = useLocation();

    const { projectInfo, groups } = useContext(DatabaseContext);

    const [title, setTitle] = useState("");
    const [introduction, setIntroduction] = useState("");

    const [project, setProject] = useState();

    const [canEdit, setCanEdit] = useState(false);
    const [userHasAccess, setUserHasAccess] = useState(false);

    const getProject = useCallback(
        async (key) => {
            let projectRef = firebase.database().ref("projects").child(key);

            if (!project) {
                console.log("Reading Project from Firebase");

                projectRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    let componentsArray = [];

                    if (data && data.components) {
                        let componentKeys = Object.keys(data.components);
                        let componentValues = Object.values(data.components);

                        for (let i = 0; i < componentKeys.length; i++) {
                            var commentsArray = [];
                            if (componentValues[i].comments) {
                                let commentKeys = Object.keys(
                                    componentValues[i].comments
                                );
                                let commentValues = Object.values(
                                    componentValues[i].comments
                                );

                                for (let j = 0; j < commentKeys.length; j++) {
                                    const comment = commentValues[j];
                                    comment.key = commentKeys[j];
                                    commentsArray.push(comment);
                                }
                            }

                            let componentObject = {
                                key: componentKeys[i],
                                row: componentValues[i].row,
                                type: componentValues[i].type,
                                fileType: componentValues[i].fileType,
                                text: componentValues[i].text,
                                linkKey: componentValues[i].linkKey,
                                addedBy: componentValues[i].addedBy,
                                addedByName: componentValues[i].addedByName,
                                addedOn: componentValues[i].addedOn,
                                allowComments: componentValues[i].allowComments,
                                comments: commentsArray,
                            };

                            componentsArray.push(componentObject);
                        }
                    }

                    let project = {
                        key: key,
                        components: componentsArray.sort((a, b) =>
                            a.row > b.row ? 1 : -1
                        ),
                    };

                    // console.log(project);
                    setProject(project);
                });
            } else {
                console.log("Already Read Project");
            }
        },
        [project]
    );
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const key = urlParams.get("key");

        let matchingInfo = projectInfo.filter((p) => p.key === key)[0];

        if (matchingInfo && currentUserData) {
            let hasAccess = false;

            // Check if user created project

            if (matchingInfo.createdBy === currentUserData.key) {
                hasAccess = true;
                setCanEdit(true);
            }

            // Check if user was added to project individually
            let matchingUser = matchingInfo.users.filter(
                (u) => u.key === currentUserData.key
            )[0];

            if (matchingUser) {
                hasAccess = true;
                if (matchingUser.canEdit) {
                    setCanEdit(true);
                }
            }

            // Check if user is in a group that was added to the project
            matchingInfo.groups.forEach((projectGroup) => {
                let matchingGroup = groups.filter(
                    (g) => g.key === projectGroup.key
                )[0];
                if (matchingGroup) {
                    if (
                        matchingGroup.users.filter(
                            (u) => u.key === currentUserData.key
                        ).length > 0
                    ) {
                        hasAccess = true;

                        if (projectGroup.canEdit) {
                            setCanEdit(true);
                        }
                    }
                }
            });

            if (hasAccess) {
                setTitle(matchingInfo.title);
                setIntroduction(matchingInfo.intro);
                getProject(key);
                setUserHasAccess(true);
            } else {
                setUserHasAccess(false);
            }

            if (project) {
                console.log("Have read project succesfully");
            }
        }

        // eslint-disable-next-line
    }, [currentUserData, projectInfo, project]);

    return (
        <>
            <BackButton />
            <Container className={classes.pageContainer}>
                {userHasAccess && (
                    <>
                        <Paper className={classes.paper} variant="outlined">
                            <TextField
                                fullWidth
                                className={classes.inputTextField}
                                value={title}
                                id="projectTitle"
                                label="Project Title"
                                variant="outlined"
                            />
                            <div className={classes.dividerDiv} />
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                className={classes.inputTextField}
                                value={introduction}
                                id="projectIntro"
                                label="Project Description"
                                variant="outlined"
                            />
                        </Paper>
                        <div className={classes.dividerDiv} />
                        {project &&
                            project.components.map((c) => (
                                <React.Fragment key={c.key}>
                                    {c.type === "Title" && (
                                        <ProjectTitleComponent
                                            projectKey={project.key}
                                            isEditing={false}
                                            component={c}
                                        />
                                    )}
                                    {c.type === "Textbox" && (
                                        <ProjectTextBoxComponent
                                            projectKey={project.key}
                                            isEditing={false}
                                            canEdit={canEdit}
                                            component={c}
                                        />
                                    )}
                                    {c.type === "Playlist" && (
                                        <ProjectPlaylistComponent
                                            projectKey={project.key}
                                            isEditing={false}
                                            component={c}
                                        />
                                    )}
                                    {c.type === "File" && (
                                        <ProjectFileComponent
                                            projectKey={project.key}
                                            isEditing={false}
                                            component={c}
                                        />
                                    )}
                                    <div className={classes.dividerDiv} />
                                </React.Fragment>
                            ))}
                    </>
                )}
            </Container>
            {!userHasAccess && <NoPermissionsMessage />}
            <ToolbarWithLogo />
        </>
    );
}
