import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    position: {
        position: "fixed",
        zIndex: 1,
        top: "auto",
        bottom: 70,
        left: "auto",
        right: 10,
    },
}));

export default function FloatingAddButton(props) {
    const classes = useStyles();

    return (
        <Fab
            color="primary"
            aria-label="add"
            className={classes.position}
            onClick={props.onClick}
        >
            <AddIcon />
        </Fab>
    );
}
