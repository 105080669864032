import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Droppable } from "react-beautiful-dnd";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import { Divider } from "@material-ui/core";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext, DatabaseContext } from "../../../../context";
import firebase from "../../../../firebase";

import ArchiveFolderListItem from "./ArchiveFolderListItem";
import ArchiveVideoListItem from "./ArchiveVideoListItem";
import ArchivePDFListItem from "./ArchivePDFListItem";
import ArchiveImageListItem from "./ArchiveImageListItem";
import ArchiveAudioListItem from "./ArchiveAudioListItem";
import ArchiveMiscFileListItem from "./ArchiveMiscFileListItem";

const useStyles = makeStyles((theme) => ({
    columnTitle: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 5,
        marginLeft: 10,
        padding: 0,
        textAlign: "left",
        cursor: "pointer",
        fontSize: 18,
        overflowWrap: "anywhere",
        width: "99%",
        minWidth: "99%",
        height: 25,
        maxHeight: 25,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        overflow: "hidden",
    },
    addButton: {
        margin: "0 auto",
        cursor: "pointer",
        marginTop: 11,
        display: "flex",
    },
    titleRowPadding: { margin: 0, padding: 0 },
}));

export default function ArchiveFinderColumn(props) {
    const classes = useStyles();
    const history = useHistory();

    const [columnHeight, setColumnHeight] = useState(0);

    const [folderCount, setFolderCount] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [pdfCount, setPDFCount] = useState(0);
    const [imageCount, setImageCount] = useState(0);
    const [audioCount, setAudioCount] = useState(0);

    const { currentUserData } = useContext(UserContext);
    const { files, folders } = useContext(DatabaseContext);

    useEffect(() => {
        setColumnHeight(window.innerHeight - 145);

        // Set the counts of each type so they get the correct index
        setFolderCount(props.column.folders.length);

        let videoCount_ = 0;
        let pdfCount_ = 0;
        let imageCount_ = 0;
        let audioCount_ = 0;

        if (props.column.files) {
            props.column.files.forEach((file) => {
                if (file.type.includes("video")) {
                    videoCount_++;
                } else if (file.type.includes("audio")) {
                    audioCount_++;
                } else if (file.type.includes("image")) {
                    imageCount_++;
                } else if (file.type === "application/pdf") {
                    pdfCount_++;
                }
            });

            setVideoCount(videoCount_);
            setAudioCount(audioCount_);
            setImageCount(imageCount_);
            setPDFCount(pdfCount_);
        }

        function handleResize() {
            setColumnHeight(window.innerHeight - 145);
        }

        window.addEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData]);

    function handleFolderClick(folderIndex) {
        console.log(props.index);
        if (props.index > 0) {
            props.column.selectedFolderIndex = folderIndex;
            props.handleFolderSelect(props.index, folderIndex);
        }

        // console.clear();
        // console.log(props.column.folders[folderIndex].createdBy);
        // console.log(props.column.folders[folderIndex].users);
        // console.log("Folder: " + props.column.folders[folderIndex].name);
        // console.log("Parent: " + props.column.folders[folderIndex].parent);
        // console.log("Ancestors:");
        // console.log(props.column.folders[folderIndex].ancestors);
    }

    function deleteFolder(folder) {
        let shouldDelete = true;

        folders.forEach((f) => {
            if (f.parent === folder.key) {
                shouldDelete = false;
            }
        });

        files.forEach((f) => {
            if (f.folder === folder.key) {
                shouldDelete = false;
            }
        });

        if (shouldDelete) {
            let folderRef = firebase
                .database()
                .ref("folders")
                .child(folder.key);

            let selectedFolder =
                props.column.folders[props.column.selectedFolderIndex];

            if (selectedFolder && selectedFolder.key === folder.key) {
                // If deleting the selected folders, select the parent in the UI
                history.push(`/files/${selectedFolder.parent}`);
            }

            folderRef.remove();

            props.showStatusMessage("Folder deleted successfully", "success");
        } else {
            props.showStatusMessage(
                "Can't delete folders that contain subfolders or files",
                "error"
            );
        }
    }

    function folderIsSelected(folder) {
        // console.log("HERE");
        // console.log(props.selectedFolders);
        // console.log(folder);

        if (
            props.selectedFolders.filter((f) => f.key === folder.key).length > 0
        ) {
            return true;
        } else {
            return false;
        }

        // console.log("---------");
    }

    return (
        <Droppable droppableId={props.column.id}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={
                        snapshot.isDraggingOver
                            ? {
                                  minHeight: columnHeight,
                                  border: "2px solid rgb(0, 149, 61)",
                                  color: "rgba(0,0,0,0.5)",
                              }
                            : {
                                  minHeight: columnHeight,
                                  borderRight: "1px solid lightgrey",
                              }
                    }
                >
                    <Row className={classes.titleRowPadding}>
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            className={classes.titleRowPadding}
                        >
                            <span className={classes.columnTitle}>
                                {props.column.name}
                            </span>
                        </Col>
                    </Row>

                    <Divider />
                    {props.column.folders.map((folder, index) => (
                        <ArchiveFolderListItem
                            key={folder.key}
                            folder={folder}
                            index={index}
                            handleClick={handleFolderClick}
                            handleFolderChecked={props.handleFolderChecked}
                            handleFolderUnchecked={props.handleFolderUnchecked}
                            deleteFolder={deleteFolder}
                            archiveHub={props.archiveHub}
                            showStatusMessage={props.showStatusMessage}
                            selected={folderIsSelected(folder)}
                            selectedColumn={
                                props.selectedColumnIndex === props.index
                            }
                            unselected={
                                props.column.selectedFolderIndex === index
                            }
                            green={
                                props.column.selectedFolderIndex === index &&
                                props.selectedColumnIndex === props.index
                            }
                            grey={
                                props.column.selectedFolderIndex === index &&
                                props.selectedColumnIndex !== props.index &&
                                props.selectedColumnIndex >= props.index
                            }
                            white={
                                props.column.selectedFolderIndex !== index ||
                                props.selectedColumnIndex < props.index
                            }
                        />
                    ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("video"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <ArchiveVideoListItem
                                    key={file.key}
                                    file={file}
                                    folder={props.column.folder}
                                    index={index + folderCount}
                                    selected={props.selectedFiles.includes(
                                        file
                                    )}
                                    showStatusMessage={props.showStatusMessage}
                                    handleFileSelect={props.handleFileSelect}
                                    handleFileDeselect={
                                        props.handleFileDeselect
                                    }
                                />
                            ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type === "application/pdf")
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <ArchivePDFListItem
                                    key={file.key}
                                    file={file}
                                    folder={props.column.folder}
                                    index={index + folderCount + videoCount}
                                    selected={props.selectedFiles.includes(
                                        file
                                    )}
                                    showStatusMessage={props.showStatusMessage}
                                    handleFileSelect={props.handleFileSelect}
                                    handleFileDeselect={
                                        props.handleFileDeselect
                                    }
                                />
                            ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("image"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <ArchiveImageListItem
                                    key={file.key}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount
                                    }
                                    selected={props.selectedFiles.includes(
                                        file
                                    )}
                                    showStatusMessage={props.showStatusMessage}
                                    handleFileSelect={props.handleFileSelect}
                                    handleFileDeselect={
                                        props.handleFileDeselect
                                    }
                                />
                            ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("audio"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <ArchiveAudioListItem
                                    key={file.key}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount +
                                        imageCount
                                    }
                                    selected={props.selectedFiles.includes(
                                        file
                                    )}
                                    showStatusMessage={props.showStatusMessage}
                                    handleFileSelect={props.handleFileSelect}
                                    handleFileDeselect={
                                        props.handleFileDeselect
                                    }
                                />
                            ))}

                    {props.column.files &&
                        props.column.files
                            .filter(
                                (file) =>
                                    !file.type.includes("video") &&
                                    !file.type.includes("audio") &&
                                    !file.type.includes("image") &&
                                    file.type !== "application/pdf"
                            )
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <ArchiveMiscFileListItem
                                    key={file.key}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount +
                                        imageCount +
                                        audioCount
                                    }
                                    selected={props.selectedFiles.includes(
                                        file
                                    )}
                                    showStatusMessage={props.showStatusMessage}
                                    handleFileSelect={props.handleFileSelect}
                                    handleFileDeselect={
                                        props.handleFileDeselect
                                    }
                                />
                            ))}

                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}
