import { useState, useContext, useEffect, useReducer } from "react";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import ListIcon from "@material-ui/icons/List";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext } from "../../context";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        position: "sticky",
        top: 0,
        minWidth: 400,
        width: "100%",
        // height: 55,
        // background: "rgb(40, 40, 40)",
        zIndex: 1,
        padding: 0,
    },

    title: {
        textAlign: "center",
        margin: 5,
        // color: "white",
    },
    uploadLabel: {
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        height: "100%",
    },
    noUploadsMessage: {
        marginTop: 10,
        marginBottom: 10,
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        width: "100%",
        display: "flex",
        color: "rgb(150, 150, 150)",
    },
    uploadProgress: { margin: 5 },
    columnPadding: {
        padding: 0,
    },
    rowPadding: {
        padding: 0,
    },
}));

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function UploadsMenu(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { currentUserData } = useContext(UserContext);

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [uploads, setUploads] = useState([]);

    useEffect(() => {
        if (currentUserData) {
            setUploads(props.uploads);
        }
    }, [currentUserData, props]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function uploadCount() {
        return uploads.filter((u) => u.show === true && u.progress < 100)
            .length;
    }

    const handleDismissUploadPressed = (upload) => () => {
        let matchingUpload = uploads.filter(
            (u) => u.file.key === upload.file.key
        )[0];

        if (matchingUpload) {
            if (matchingUpload.progress === 100) {
                matchingUpload.show = false;
            } else if (upload.file.type.includes("video")) {
                if (matchingUpload.aborted === true) {
                    matchingUpload.show = false;
                } else if (upload.tusUpload) {
                    upload.tusUpload.abort();
                    matchingUpload.aborted = true;
                }
            }
        }

        forceUpdate();
    };

    const shouldShowClearButton = (upload) => {
        if (upload.progress === 100) {
            return true;
        } else if (upload.progress >= 0) {
            if (upload.file.type.includes("video")) {
                return true;
            }
        }

        return false;
    };

    return (
        <div>
            <div onClick={handleMenu} className={classes.avatarDiv}>
                <Tooltip title="Files">
                    <Badge
                        badgeContent={uploadCount()}
                        color="primary"
                        overlap="circular"
                    >
                        <IconButton aria-label="delete">
                            <ListIcon />
                        </IconButton>
                    </Badge>
                </Tooltip>
            </div>
            <Menu
                id="menu-appbar"
                style={{ zIndex: 35001 }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    disablePadding: true,
                }}
            >
                <div className={classes.toolbar}>
                    <h4 className={classes.title}>Uploads</h4>
                    <Divider />
                    {uploads
                        .filter((u) => u.show === true)
                        .map((u) => {
                            return (
                                <Container>
                                    <Row className={classes.rowPadding}>
                                        <Col className={classes.columnPadding}>
                                            <span
                                                className={classes.uploadLabel}
                                            >
                                                {u.file.name}
                                            </span>
                                        </Col>
                                        <CircularProgressWithLabel
                                            className={classes.uploadProgress}
                                            variant="determinate"
                                            color="primary"
                                            value={u.progress}
                                        />
                                        <IconButton
                                            // className={classes.icons}
                                            onClick={handleDismissUploadPressed(
                                                u
                                            )}
                                            disabled={!shouldShowClearButton(u)}
                                        >
                                            <CloseIcon />
                                        </IconButton>

                                        {/* <LinearProgress
                                    className={classes.uploadProgress}
                                    variant="determinate"
                                    color="primary"
                                    value={u.progress}
                                /> */}
                                    </Row>
                                    <Divider />
                                </Container>
                            );
                        })}
                    {uploads.filter((u) => u.show === true).length === 0 && (
                        <span className={classes.noUploadsMessage}>
                            No Current Uploads
                        </span>
                    )}
                </div>
            </Menu>
        </div>
    );
}
