import { useState, useEffect } from "react";

import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const useStyles = makeStyles((theme) => ({
    margins: {
        marginLeft: theme.spacing(0.75),
        marginTop: theme.spacing(0.5),
    },
}));

export default function UserPermissionsDialog(props) {
    const classes = useStyles();

    const { onClose, open } = props;

    const [permissions, setPermissions] = useState({
        addEditUsers: false,
        uploadEditOwnVideo: true,
        comment: true,
        openMessage: true,
        personalMessage: false,
        viewCalendar: true,
        editCalendar: false,
        exportCalendar: true,
        createOwnPlaylist: true,
        analysis: true,
        createSurveys: false,
        shareFiles: false,
        createProjects: true,
    });

    useEffect(() => {
        setPermissions(props.permissions);
    }, [props.permissions]);

    const handleClose = () => {
        onClose(permissions);
    };

    const handleChange = (event) => {
        setPermissions({
            ...permissions,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth="lg"
            fullWidth={true}
        >
            <Container fluid>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.addEditUsers}
                                    onChange={handleChange}
                                    name="addEditUsers"
                                    color="primary"
                                />
                            }
                            label="Add/Edit Users"
                            className={classes.margins}
                        />
                    </Col>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.uploadEditOwnVideo}
                                    onChange={handleChange}
                                    name="uploadEditOwnVideo"
                                    color="primary"
                                />
                            }
                            label="Upload/Edit Files"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.comment}
                                    onChange={handleChange}
                                    name="comment"
                                    color="primary"
                                />
                            }
                            label="Comment on Files"
                            className={classes.margins}
                        />
                    </Col>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.openMessage}
                                    onChange={handleChange}
                                    name="openMessage"
                                    color="primary"
                                />
                            }
                            label="Open Message"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.personalMessage}
                                    onChange={handleChange}
                                    name="personalMessage"
                                    color="primary"
                                />
                            }
                            label="Personal Message"
                            className={classes.margins}
                        />
                    </Col>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.viewCalendar}
                                    onChange={handleChange}
                                    name="viewCalendar"
                                    color="primary"
                                />
                            }
                            label="View Team / League Calander"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.editCalendar}
                                    onChange={handleChange}
                                    name="editCalendar"
                                    color="primary"
                                />
                            }
                            label="Edit Calendar"
                            className={classes.margins}
                        />
                    </Col>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.exportCalendar}
                                    onChange={handleChange}
                                    name="exportCalendar"
                                    color="primary"
                                />
                            }
                            label="Export Calendar"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.createOwnPlaylist}
                                    onChange={handleChange}
                                    name="createOwnPlaylist"
                                    color="primary"
                                />
                            }
                            label="Create Own Playlists"
                            className={classes.margins}
                        />
                    </Col>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.analysis}
                                    onChange={handleChange}
                                    name="analysis"
                                    color="primary"
                                />
                            }
                            label="Analysis Function"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.createSurveys}
                                    onChange={handleChange}
                                    name="createSurveys"
                                    color="primary"
                                />
                            }
                            label="Create Polls / Surveys"
                            className={classes.margins}
                        />
                    </Col>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.shareFiles}
                                    onChange={handleChange}
                                    name="shareFiles"
                                    color="primary"
                                />
                            }
                            label="Share Files"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={permissions.createProjects}
                                    onChange={handleChange}
                                    name="createProjects"
                                    color="primary"
                                />
                            }
                            label="Create Projects"
                            className={classes.margins}
                        />
                    </Col>
                </Row>
            </Container>
        </Dialog>
    );
}
