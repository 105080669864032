import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import { Scrollbars } from "react-custom-scrollbars-2";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// My Files
import { DatabaseContext, UserContext } from "../../../../context";
import ToolbarWithLogo from "../../../ToolbarWithLogo";
import firebase from "../../../../firebase";
import FinderColumn from "./FinderColumn";
import {
    jsonFromArray,
    makeid,
    shouldFileBeVisible,
} from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    columnsContainer: {
        display: "flex",
        flexDirection: "row",
        // overflow: "auto", // Makes scroll bar appear beneath rows
    },
    column: {
        minWidth: "24%",
        maxWidth: "24%",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FileFinderPage() {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    // Files and folders from firebase
    const { files, folders, groups } = useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [foldersFlat, setFoldersFlat] = useState([]);

    const [columns, setColumns] = useState([]);

    const [selectedColumnIndex, setSelectedColumnIndex] = useState([]); // Just used to show visually which is selected

    const scrollRef = useRef();

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        console.log("FinderPage useEffect");

        if (currentUserData) {
            // console.log("Refreshing Folders");
            let flatFolderArrayFiltered = getFlatFolderArray();

            // flatArrayFiltered now contains all the folders that the user should have access to. Now make a nested folder structure from this.

            let nestedFolderArray = createNestedFolderStructure(
                flatFolderArrayFiltered
            );

            // Get the current folder key from the browser url
            let currentFolderKey = location.pathname.replace("/files/", "");

            if (currentFolderKey === "/files") {
                let newColumns = [
                    {
                        id: "Root",
                        name: "Everything",
                        folders: nestedFolderArray,
                        folder: { name: "Root", id: "root" },
                    },
                ];

                setColumns(newColumns);
            } else {
                let flatFolderArrayFiltered = getFlatFolderArray();

                // flatArrayFiltered now contains all the folders that the user should have access to. Now make a nested folder structure from this.

                let nestedFolderArray = createNestedFolderStructure(
                    flatFolderArrayFiltered
                );

                // A folder is already selected (so user loaded this page with a folder key in the url)
                // Have to open the UI to this folder

                let currentFolder = flatFolderArrayFiltered.filter(
                    (f) => f.id === currentFolderKey
                )[0];

                if (currentFolder) {
                    // Add the root folder first as a column
                    let newColumns = [
                        {
                            id: "Root",
                            name: "Everything",
                            folders: nestedFolderArray,
                            folder: { name: "Root", id: "root" },
                        },
                    ];

                    // Then get all the folder between the selected one and the root,
                    // add them as columns
                    let parents = [];
                    getParents(currentFolder, parents);

                    parents.reverse().forEach((parent) => {
                        let matchingFolder = flatFolderArrayFiltered.filter(
                            (f) => f.id === parent.id
                        )[0];

                        if (matchingFolder) {
                            newColumns.push({
                                id: matchingFolder.id,
                                name: matchingFolder.name,
                                folders: matchingFolder.folders,
                                folder: matchingFolder,
                            });
                        }
                    });

                    // Then add the selected folder as a column

                    newColumns.push({
                        id: currentFolder.id,
                        name: currentFolder.name,
                        folders: currentFolder.folders,
                        folder: currentFolder,
                    });

                    // Then add the files for each column
                    newColumns.forEach((column) => {
                        let filesForColumn = [];

                        files
                            .filter((file) => file.folder === column.id)
                            .forEach((file) => {
                                if (
                                    shouldFileBeVisible(
                                        file,
                                        groups,
                                        currentUserData
                                    )
                                ) {
                                    filesForColumn.push(file);
                                }
                            });

                        column.files = filesForColumn;
                    });

                    // Now Set the selected folder index for each column, so the UI displays correctly
                    newColumns.forEach((column) => {
                        column.folders.forEach((folder, folderIndex) => {
                            if (
                                currentFolder.ancestors.includes(folder.id) ||
                                currentFolder.id === folder.id ||
                                currentFolder.parent === folder.id
                            ) {
                                column.selectedFolderIndex = folderIndex;
                            }
                        });
                    });

                    // console.log("Current Folder");
                    // console.log(currentFolder);

                    setSelectedColumnIndex(newColumns.length - 2);
                    setColumns(newColumns);
                    scrollRef.current.scrollToRight();
                }
            }

            setFoldersFlat(flatFolderArrayFiltered);
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folders, files, currentUserData]);

    function getParents(folderNode, parentNodeTrail) {
        let parentNode = folderNode.parentNode;

        if (parentNode === undefined || parentNode.id === undefined) {
            return;
        } else {
            parentNodeTrail.push({
                id: parentNode.id,
            });
            getParents(parentNode, parentNodeTrail);
        }
    }

    function getFlatFolderArray() {
        const foldersClone = folders.map((folder) => ({
            key: folder.key,
            id: folder.key,
            name: folder.name,
            hub: folder.hub,
            parent: folder.parent,
            imageURL: folder.imageURL,
            parentNode: {},
            folders: [],
            groups: folder.groups,
            managers: folder.managers,
            users: folder.users,
            ancestors: folder.ancestors,
            createdBy: folder.createdBy,
        }));

        // Filter out folders the user should not have access to. To see a folder, the user should either be included in folder.users, or the folder should be an ancestor of one which does contain the user.

        let flatFolderArrayFiltered = [];

        // For managers, add the folder if they are in the managers array or a digital manager
        // For users only add the folder if it is a hub and they are in the hub.users object, or if they created the folder themselves.
        foldersClone.forEach((folder) => {
            let shouldAdd = false;
            if (
                folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                shouldAdd = true;
            } else if (
                folder.hub &&
                folder.users.includes(currentUserData.key)
            ) {
                shouldAdd = true;
            } else if (
                folder.createdBy &&
                currentUserData &&
                folder.createdBy === currentUserData.key
            ) {
                shouldAdd = true;
            }
            if (shouldAdd) {
                flatFolderArrayFiltered.push(folder);

                // Need to find the ancestors of the folder and add them so that the user can navigate to the folders they are members of
                folder.ancestors.forEach((ancestorKey) => {
                    let ancestor = foldersClone.filter(
                        (f) => f.key === ancestorKey
                    );

                    if (ancestor[0]) {
                        flatFolderArrayFiltered.push(ancestor[0]);
                    }
                });
            }
        });

        // For users, add a folder if some descendant contains a file that should be visible
        files.forEach((file) => {
            if (shouldFileBeVisible(file, groups, currentUserData)) {
                // Need to find the ancestors of the file and add them so that the user can navigate to the file
                file.ancestors.forEach((ancestorKey) => {
                    let ancestor = foldersClone.filter(
                        (f) => f.key === ancestorKey
                    );

                    if (ancestor[0]) {
                        flatFolderArrayFiltered.push(ancestor[0]);
                    }
                });
            }
        });

        // Remove duplicates from the folders array

        flatFolderArrayFiltered = Array.from(new Set(flatFolderArrayFiltered));

        // // Sort the flat array alphabetically
        flatFolderArrayFiltered.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        // Add a folder for the users favourite files at index 0
        let favesFolder = {
            id: "faves_fggh2973jgbvbiu",
            key: "faves_fggh2973jgbvbiu",
            name: "Favourites",
            parent: "Root",
            ancestors: [],
            folders: [],
            files: [],
            hub: false,
            parentNode: {},
            users: [],
            managers: [],
        };

        flatFolderArrayFiltered.unshift(favesFolder);

        return flatFolderArrayFiltered;
    }

    function createNestedFolderStructure(flatFolderArray) {
        let nestedFolderArray = [];

        for (let i = 0; i < flatFolderArray.length; i++) {
            let folder = flatFolderArray[i];

            if (folder.parent === "Root") {
                nestedFolderArray.push(folder);
            } else {
                let parentFolder = flatFolderArray.filter(
                    (anotherFolder) => anotherFolder.key === folder.parent
                )[0];

                if (parentFolder !== undefined) {
                    parentFolder.folders.push(folder);
                    folder.parentNode = parentFolder;
                }
            }
        }

        return nestedFolderArray;
    }

    function handleFolderSelect(columnIndex, folderIndex) {
        let selectedColumn = columns[columnIndex];
        let selectedFolder = selectedColumn.folders[folderIndex];

        setSelectedColumnIndex(columnIndex);

        // Set columns for UI
        let newColumns = [];
        for (let i = 0; i < columnIndex + 1; i++) {
            newColumns.push(columns[i]);
        }

        newColumns.push({
            id: selectedFolder.id,
            name: selectedFolder.name,
            folders: selectedFolder.folders,
            folder: selectedFolder,
            selectedFolderIndex: 0,
        });

        // Have to add files to each column
        newColumns.forEach((column) => {
            let filesForColumn = [];

            files
                .filter((file) => file.folder === column.id)
                .forEach((file) => {
                    if (shouldFileBeVisible(file, groups, currentUserData)) {
                        filesForColumn.push(file);
                    }
                });

            column.files = filesForColumn;
        });

        history.push(`/files/${selectedFolder.id}`);

        // Add an empty column for scrolling purposes
        newColumns.push({
            id: makeid(15),
            name: "",
            folders: [],
            selectedFolderIndex: 0,
        });

        setColumns(newColumns);

        scrollRef.current.scrollToRight();
    }

    function onDragEnd(result) {
        let column = columns.filter(
            (c) => c.id === result.source.droppableId
        )[0];

        if (column) {
            // Determine if we are dragging a file or folder
            if (
                column.folders.filter((f) => f.key === result.draggableId)
                    .length > 0
            ) {
                didFinishDraggingFolder(result);
            } else if (
                column.files.filter((f) => f.key === result.draggableId)
                    .length > 0
            ) {
                didFinishDraggingFile(result);
            }
        }
    }

    function didFinishDraggingFile(result) {
        const { destination, source } = result;

        // Do some checks first to whether the drop should take place
        if (!destination) {
            return;
        }

        let destinationColumnId = destination.droppableId;
        let sourceColumnId = source.droppableId;

        if (
            destinationColumnId === sourceColumnId &&
            destination.index === source.index
        ) {
            return;
        }

        if (destinationColumnId === "Root") {
            showStatusMessage("Can't move a file here", "error");
            return;
        }

        let destinationFolder = foldersFlat.filter(
            (f) => f.key === destinationColumnId
        )[0];

        if (!destinationFolder) {
            return;
        }

        if (!destinationFolder.managers.includes(currentUserData.key)) {
            if (currentUserData.role !== "System Digital Manager") {
                showStatusMessage(
                    "Cannot move the file here, you are not a manager of the destination folder",
                    "error"
                );

                return;
            }
        }

        let file = files.filter((f) => f.key === result.draggableId)[0];

        if (!file.managers.includes(currentUserData.key)) {
            if (currentUserData.role !== "System Digital Manager") {
                showStatusMessage(
                    "You are not a manager of this file",
                    "error"
                );

                return;
            }
        }

        // End checks

        if (file && destinationFolder) {
            let fileRef = firebase.database().ref("files").child(file.key);

            let newAncestors = jsonFromArray(destinationFolder.ancestors);
            newAncestors[destinationFolder.id] = true;

            // Update the files parents and ancestors in firebase
            fileRef.update({
                folder: destinationFolder.id,
                ancestors: newAncestors,
                managers: jsonFromArray(destinationFolder.managers),
            });
        }
    }

    function didFinishDraggingFolder(result) {
        const { destination, source } = result;

        // Do some checks first to whether the drop should take place
        if (!destination) {
            return;
        }

        let draggedFolderId = result.draggableId;
        let sourceColumnId = source.droppableId;
        let destinationColumnId = destination.droppableId;

        if (
            destinationColumnId === sourceColumnId &&
            destination.index === source.index
        ) {
            return;
        }

        if (destinationColumnId === "Root") {
            showStatusMessage("Can't move a folder here", "error");
            return;
        }

        if (draggedFolderId === destinationColumnId) {
            showStatusMessage("Can't move a folder into itself", "error");

            return;
        }

        let destinationFolder = foldersFlat.filter(
            (f) => f.key === destinationColumnId
        )[0];

        if (!destinationFolder) {
            return;
        }

        if (!destinationFolder.managers.includes(currentUserData.key)) {
            if (currentUserData.role !== "System Digital Manager") {
                showStatusMessage(
                    "Cannot move the folder here, you are not a manager of the destination folder",
                    "error"
                );

                return;
            }
        }

        let sourceFolder = foldersFlat.filter(
            (f) => f.key === sourceColumnId
        )[0];

        let draggedFolder = foldersFlat.filter(
            (f) => f.key === draggedFolderId
        )[0];

        if (!draggedFolder.managers.includes(currentUserData.key)) {
            if (currentUserData.role !== "System Digital Manager") {
                showStatusMessage(
                    "You are not a manager of this folder",
                    "error"
                );

                return;
            }
        }

        if (draggedFolder.hub) {
            if (!destinationFolder.hub) {
                showStatusMessage("Can't move a hub into a folder", "error");
                return;
            }
        }
        // End checks

        if (destinationFolder && sourceFolder && draggedFolder) {
            draggedFolder.parent = destinationFolder.key;
            draggedFolder.ancestors = [...destinationFolder.ancestors];
            draggedFolder.managers = [...destinationFolder.managers];
            // draggedFolder.users = [...destinationFolder.users];

            // For any folder that is a child of the dragged folder, thier parent remains the same, but we need to
            //  work out their ancestors
            // Start by getting all folders that are below dragged folder in the hierachy, and sort them by number
            // of ancestors i.e. they are sorted in their order in the file system
            let childFolders = foldersFlat
                .filter((f) => f.ancestors.includes(draggedFolder.key))
                .sort((a, b) => {
                    if (a.ancestors.length > b.ancestors.length) return 1;
                    if (a.ancestors.length < b.ancestors.length) return -1;
                    return 0;
                });

            childFolders.forEach((childFolder, index) => {
                if (index === 0) {
                    childFolder.ancestors = [
                        ...draggedFolder.ancestors,
                        draggedFolder.parent,
                        childFolder.parent,
                    ];

                    childFolder.managers = draggedFolder.managers;
                    // childFolder.users = draggedFolder.users;
                } else if (index > 0) {
                    let previousFolder = childFolders[index - 1];
                    if (previousFolder) {
                        if (childFolder.parent === previousFolder.parent) {
                            childFolder.ancestors = [
                                ...previousFolder.ancestors,
                            ];
                            childFolder.managers = previousFolder.managers;
                            // childFolder.users = previousFolder.users;
                        } else {
                            let parentOfChild = childFolders.filter(
                                (f) => f.key === childFolder.parent
                            )[0];
                            if (parentOfChild) {
                                childFolder.ancestors = [
                                    ...parentOfChild.ancestors,
                                    childFolder.parent,
                                ];

                                childFolder.managers = parentOfChild.managers;
                                // childFolder.users = parentOfChild.users;
                            }
                        }
                    }
                }
            });

            // All the child folders ancestors have been set, now write to firebase

            var foldersRef = firebase.database().ref("folders");

            // Child Folders to firebase

            childFolders.forEach((childFolder) => {
                // Update the childFolder ancestors in firebase
                foldersRef
                    .child(childFolder.id)

                    .update({
                        ancestors: jsonFromArray(childFolder.ancestors),
                        managers: jsonFromArray(childFolder.managers),
                        // users: jsonFromArray(childFolder.users),
                    });

                // Files in this child folder

                files
                    .filter((f) => f.folder === childFolder.id)
                    .forEach((file) => {
                        file.ancestors = [
                            ...childFolder.ancestors,
                            childFolder.key,
                        ];
                        let fileRef = firebase
                            .database()
                            .ref("files")
                            .child(file.key);

                        fileRef.update({
                            ancestors: jsonFromArray(file.ancestors),
                            managers: jsonFromArray(childFolder.managers),
                        });
                    });
            });

            let draggedFolderNewAncestors = jsonFromArray(
                destinationFolder.ancestors
            );
            draggedFolderNewAncestors[destinationFolder.key] = true;

            // Dragged folder to firebase
            foldersRef.child(draggedFolderId).update({
                parent: destinationFolder.key,
                ancestors: draggedFolderNewAncestors,
                managers: jsonFromArray(destinationFolder.managers),
                // users: jsonFromArray(destinationFolder.users),
            });

            // Files in the dragged folder
            files
                .filter((f) => f.folder === draggedFolderId)
                .forEach((file) => {
                    file.ancestors = [
                        ...destinationFolder.ancestors,
                        destinationFolder.key,
                        draggedFolder.key,
                    ];
                    let fileRef = firebase
                        .database()
                        .ref("files")
                        .child(file.key);

                    fileRef.update({
                        ancestors: jsonFromArray(file.ancestors),
                        managers: jsonFromArray(destinationFolder.managers),
                    });
                });
        }
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    return (
        <div
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 0,
                marginRight: 0,
                paddingTop: 15,
                paddingBottom: 65,
                "&::WebkitScrollbar": { display: "none" },
            }}
        >
            <Scrollbars
                className={classes.scrollbar}
                style={{
                    width: window.innerWidth,
                    height: window.innerHeight - 145,
                }}
                ref={scrollRef}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className={classes.columnsContainer}>
                        {columns.map((column, index) => (
                            <div className={classes.column} key={column.id}>
                                <FinderColumn
                                    key={column.id}
                                    column={column}
                                    index={index}
                                    handleFolderSelect={handleFolderSelect}
                                    selectedColumnIndex={selectedColumnIndex}
                                    showStatusMessage={showStatusMessage}
                                />
                            </div>
                        ))}
                    </div>
                </DragDropContext>
            </Scrollbars>
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            <ToolbarWithLogo />
        </div>
    );
}
