// Material UI
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    message: {
        padding: theme.spacing(2, 2, 0),
    },
}));

export default function NoPermissionsMessage() {
    const classes = useStyles();

    return (
        <h5 className={classes.message}>
            You don't have permission to view this page
        </h5>
    );
}
