import React, { useEffect, useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

// My Files
import { makeid, downloadBlob } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: 30,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        // width: window.innerWidth,
        overflow: "scroll",
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
}));

export default function SurveyResultsTable(props) {
    const classes = useStyles();
    const [rows, setRows] = useState();
    const [columns, setColumns] = useState([]); // These contain the questions

    useEffect(() => {
        setRows(props.rows);
        setColumns(props.columns);
    }, [props]);

    function handleDownloadPress() {
        var csvString = "";

        if (props.anonymous) {
            csvString = "Timestamp, ";
        } else {
            csvString = "User, Timestamp, ";
        }

        columns.forEach((column) => {
            csvString += column.text.replace(",", "");
            csvString += ", ";
        });

        csvString = csvString.substring(0, csvString.length - 2);
        csvString += "\n";

        rows.forEach((row) => {
            if (props.anonymous === false) {
                csvString += row.user.replace(",", "") + ", ";
            }

            csvString += row.submissionDate.replace(",", "") + ", ";
            row.answers.forEach((answer) => {
                csvString += answer.replace(",", "") + ", ";
            });

            csvString = csvString.substring(0, csvString.length - 2);

            csvString += "\n";
        });

        downloadBlob(csvString, "export.csv", "text/csv;charset=utf-8;");
    }

    return (
        <Container className={classes.pageContainer}>
            <Button
                onClick={handleDownloadPress}
                className={classes.downloadButton}
            >
                Download Data
            </Button>
            <TableContainer
                component={Paper}
                className={classes.tableContainer}
            >
                <Table aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {props.anonymous === false && (
                                <TableCell
                                    className={classes.tableHeaderCell}
                                    align="center"
                                >
                                    User
                                </TableCell>
                            )}

                            <TableCell
                                className={classes.tableHeaderCell}
                                align="center"
                            >
                                Timestamp
                            </TableCell>
                            {columns &&
                                columns.map((column) => (
                                    <TableCell
                                        key={column.key}
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        {column.text}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows &&
                            rows.map((row) => (
                                <TableRow
                                    key={row.key}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    {props.anonymous === false && (
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.user}
                                        </TableCell>
                                    )}
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                        }}
                                    >
                                        {row.submissionDate}
                                    </TableCell>
                                    {row.answers &&
                                        row.answers.map((answer) => (
                                            <TableCell
                                                key={makeid(5)}
                                                component="th"
                                                scope="row"
                                                align="center"
                                                style={{
                                                    borderRight:
                                                        "1px solid rgb(200,200,200)",
                                                }}
                                            >
                                                {answer}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}
