import React, { useState, useEffect, useContext } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// My Files
import { DatabaseContext, UserContext } from "../../../../context";
import firebase from "../../../../firebase";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: 120,
        cursor: "pointer",
    },
    label: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: 3,
        marginLeft: 5,
        width: 190,
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tile: {
        marginTop: 10,
        marginLeft: 20,
        width: 200,
        height: 185,
        cursor: "pointer",
    },
    thumbnail: { marginTop: 5, width: 200, height: 112 },
}));

export default function ImageIconWithLabel(props) {
    const classes = useStyles();

    const [clickLocation, setClickLocation] = useState({
        mouseX: null,
        mouseY: null,
    });

    const { comments, notifications, events, users } =
        useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const [fileNameDialogOpen, setFileNameDialogOpen] = useState(false);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        setFileName(props.file.displayName);
    }, [props]);

    const handleClick = (event) => {
        event.preventDefault();

        // Checks that the current user is a manager for the folder or hub
        if (
            props.file.managers.includes(currentUserData.key) ||
            currentUserData.role === "System Digital Manager"
        ) {
            setClickLocation({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
        }
    };

    const handleClose = (event) => {
        if (event.target.id === "renamefile") {
            handleFileRenameDialogOpen();
        } else if (event.target.id === "deletefile") {
            handleConfirmDeleteDialogOpen();
        }

        setClickLocation({
            mouseX: null,
            mouseY: null,
        });
    };

    const handleKeyboardInput = (e) => {
        setFileName(e.target.value);
    };

    const handleFileRenameDialogOpen = () => {
        setFileNameDialogOpen(true);
    };

    const handleFileRenameDialogClose = (key) => () => {
        setFileNameDialogOpen(false);

        if (key === "OK") {
            if (
                props.file.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                let ref = firebase
                    .database()
                    .ref("files")
                    .child(props.file.key);
                ref.update({ displayName: fileName });

                props.file.displayName = fileName;
            }
        }
    };

    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true);
    };

    const handleConfirmDeleteDialogClose = (key) => () => {
        setConfirmDeleteDialogOpen(false);

        if (key === "OK") {
            if (
                props.file.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                deleteFile();
            }
        }
    };

    async function deleteFile() {
        // Delete the comments
        comments.forEach((comment) => {
            if (comment.file === props.file.key) {
                let commentRef = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);
                commentRef.update({ deleted: true });
            }
        });

        // Delete any notifications for the file
        notifications.forEach((notification) => {
            if (notification.file === props.file.key) {
                let notificationRef = firebase
                    .database()
                    .ref("notifications")
                    .child(notification.key);
                notificationRef.remove();
            }
        });

        // // Delete the file in any events

        events.forEach((event) => {
            let filesToKeepArray = [];
            let needsChange = false;
            event.files.forEach((aFile) => {
                if (aFile.key === props.file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile.key);
                }
            });

            if (needsChange) {
                let eventRef = firebase
                    .database()
                    .ref("events")
                    .child(event.key);

                // Change remaining files to JSON
                let filesObject = {};

                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });

                eventRef.update({ files: filesObject });
            }
        });

        // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
        users.forEach((user) => {
            let filesToKeepArray = [];
            let needsChange = false;
            user.files.forEach((aFile) => {
                if (aFile === props.file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile);
                }
            });

            if (needsChange) {
                let userRef = firebase.database().ref("users").child(user.key);

                // Change remaining files to JSON
                let filesObject = {};

                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });

                userRef.update({ files: filesObject });
            }
        });

        // Delete the file from firebase storage
        let storageRef = firebase
            .storage()
            .ref("files")
            .child(props.file.key)
            .child(props.file.filename);
        storageRef.delete();

        // Delete the file
        let fileRef = firebase.database().ref("files").child(props.file.key);

        fileRef.remove();
    }

    return (
        <>
            <div
                className={classes.tile}
                onClick={props.handleClick}
                onContextMenu={handleClick}
            >
                <Avatar
                    variant="rounded"
                    src={props.file.url}
                    className={classes.thumbnail}
                >
                    <InsertDriveFileIcon />
                </Avatar>
                <p className={classes.label}>{props.file.displayName}</p>
            </div>
            <Menu
                keepMounted
                open={clickLocation.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    clickLocation.mouseY !== null &&
                    clickLocation.mouseX !== null
                        ? {
                              top: clickLocation.mouseY,
                              left: clickLocation.mouseX,
                          }
                        : undefined
                }
            >
                <div>
                    <MenuItem id="renamefile" onClick={handleClose}>
                        Rename File
                    </MenuItem>
                    <MenuItem id="deletefile" onClick={handleClose}>
                        Delete File
                    </MenuItem>
                </div>
            </Menu>
            <Dialog
                open={fileNameDialogOpen}
                onClose={handleFileRenameDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Rename File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a new name for the file.
                    </DialogContentText>
                    <TextField
                        value={fileName}
                        onChange={handleKeyboardInput}
                        autoFocus
                        margin="dense"
                        id="name"
                        label=""
                        type=""
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleFileRenameDialogClose("Cancel")}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleFileRenameDialogClose("OK")}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Delete File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmDeleteDialogClose("Cancel")}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDeleteDialogClose("OK")}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
