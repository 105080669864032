import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files
import { DatabaseContext } from "../../../../context";
import HubUsageUserFilterDialog from "./filters/HubUsageUserFilterDialog";
import HubUsageGroupFilterDialog from "./filters/HubUsageGroupFilterDialog";
import HubUsageDateFilterDialog from "./filters/HubUsageDateFilterDialog";
import { downloadBlob } from "../../../../utility";

// import { shouldFileBeVisibleToUser } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
    },
    toolbarTitle: {
        // flexGrow: 1,
    },
    title: {
        marginTop: 15,
    },
    filterButton: {
        marginTop: 15,
        marginLeft: 5,
        marginRight: 5,
    },
    downloadButton: {
        position: "absolute",
        left: "auto",
        right: 10,
        top: 80,
    },
    tableContainer: {
        marginTop: 15,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        width: window.innerWidth,
        overflow: "scroll",
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
}));

export default function HubUserUsageDialog(props) {
    const classes = useStyles();
    const { fileUsage, users, files, userLogins, groups, getFileUsage } =
        useContext(DatabaseContext);

    // const { currentUserData } = useContext(UserContext);
    const [userFilterDialogIsOpen, setUserFilterDialogIsOpen] = useState(false);
    const [groupFilterDialogIsOpen, setGroupFilterDialogIsOpen] =
        useState(false);
    const [dateFilterDialogIsOpen, setDateFilterDialogIsOpen] = useState(false);

    const [rows, setRows] = useState([]);

    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    // const [filteredGroups, setFilteredGroups] = useState(null);
    const [hubUsers, setHubUsers] = useState([]);
    const [hubGroups, setHubGroups] = useState([]);

    const [
        excludedUserKeysFromUsersFilter,
        setExcludedUserKeysFromUsersFilter,
    ] = useState([]); // Excluded Based on User and Group Filters

    const [
        excludedUserKeysFromGroupsFilter,
        setExcludedUserKeysFromGroupsFilter,
    ] = useState([]); // Excluded Based on User and Group Filters

    const [filterIsActive, setFilterIsActive] = useState(false);

    useEffect(() => {
        if (props.hub) {
            // Find all the users that can access the file, and compile the usage data for each
            let rowDataArray = [];
            console.log("Compiling Hub User Usage Data:");

            if (fileUsage.length === 0) {
                getFileUsage();
            }
            let filesInHub = files.filter((file) =>
                file.ancestors.includes(props.hub.key)
            );

            setHubGroups(
                groups.filter(
                    (g) => g.hub === props.hub.key && g.name !== "All Users"
                )
            );

            let allUserKeys = [...props.hub.users, ...props.hub.managers];

            var hubUsersArray = [];

            // Create the rows for the users
            allUserKeys.forEach((userKey) => {
                // Determine if the user should be included in the table based on the user and group filters
                // Create the table row from usage data
                var lastLogin = "";
                var viewCount = 0;
                var downloadCount = 0;
                var timelineCount = 0;
                var playlistCount = 0;
                var playlistShareCount = 0;

                let user = users.filter((u) => u.key === userKey)[0];
                if (user) {
                    // Last Login

                    hubUsersArray.push(user);

                    if (
                        excludedUserKeysFromUsersFilter.includes(user.key) ||
                        excludedUserKeysFromGroupsFilter.includes(user.key)
                    ) {
                        user.isIncludedInFilters = false;
                    } else {
                        user.isIncludedInFilters = true;

                        let matchingLogin = userLogins.filter(
                            (l) => l.key === user.key
                        )[0];

                        if (matchingLogin) {
                            lastLogin = matchingLogin.lastLogin;
                        }

                        // File usage
                        filesInHub.forEach((file) => {
                            let matchingFileUsages = fileUsage.filter(
                                (usage) =>
                                    file.key === usage.file &&
                                    user.key === usage.user
                            );

                            matchingFileUsages.forEach((usage) => {
                                if (
                                    filterStartDate === null ||
                                    dayjs(filterStartDate).isSame(
                                        dayjs(usage.date),
                                        "day"
                                    ) ||
                                    dayjs(filterStartDate).isBefore(
                                        dayjs(usage.date)
                                    )
                                ) {
                                    if (
                                        filterEndDate === null ||
                                        dayjs(filterEndDate).isSame(
                                            dayjs(usage.date),
                                            "day"
                                        ) ||
                                        dayjs(filterEndDate).isAfter(
                                            dayjs(usage.date)
                                        )
                                    ) {
                                        if (usage.action === "Open") {
                                            viewCount += 1;
                                        } else if (
                                            usage.action === "Download"
                                        ) {
                                            downloadCount += 1;
                                        } else if (
                                            usage.action === "TimelineCreate"
                                        ) {
                                            timelineCount += 1;
                                        } else if (
                                            usage.action === "PlaylistCreate"
                                        ) {
                                            playlistCount += 1;
                                        } else if (
                                            usage.action === "PlaylistShare"
                                        ) {
                                            var userKeysSharedTo = [];

                                            usage.usersSharedWith.forEach(
                                                (sharedUserKey) => {
                                                    userKeysSharedTo.push(
                                                        sharedUserKey
                                                    );
                                                }
                                            );

                                            usage.groupsSharedWith.forEach(
                                                (group) => {
                                                    let groupObject =
                                                        groups.filter(
                                                            (g) =>
                                                                g.key ===
                                                                group.key
                                                        )[0];

                                                    if (groupObject) {
                                                        groupObject.users.forEach(
                                                            (groupUser) => {
                                                                userKeysSharedTo.push(
                                                                    groupUser.key
                                                                );
                                                            }
                                                        );
                                                    }
                                                }
                                            );

                                            playlistShareCount +=
                                                userKeysSharedTo.length;
                                        }
                                    }
                                }
                            });
                        });

                        // Create the row
                        rowDataArray.push({
                            name: user.fullName,
                            lastLogin: lastLogin,
                            viewCount: viewCount,
                            downloadCount: downloadCount,
                            timelineCount: timelineCount,
                            playlistCount: playlistCount,
                            playlistShareCount: playlistShareCount,
                        });
                    }
                }
            });

            rowDataArray = rowDataArray.sort((a, b) =>
                a.viewCount < b.viewCount ? 1 : -1
            );

            setHubUsers(hubUsersArray);
            setRows(rowDataArray);
        }
        // eslint-disable-next-line
    }, [
        props,
        fileUsage,
        filterStartDate,
        filterEndDate,
        excludedUserKeysFromUsersFilter,
        excludedUserKeysFromGroupsFilter,
    ]);

    function handleUserFilterClick() {
        setUserFilterDialogIsOpen(true);
    }

    function handleCloseUserFilterDialog(newExcludedUserKeys) {
        // setFilteredUsers(newFilteredUsers);

        setExcludedUserKeysFromUsersFilter(newExcludedUserKeys);

        setUserFilterDialogIsOpen(false);
        setFilterIsActive(true);
    }

    function handleGroupFilterClick() {
        setGroupFilterDialogIsOpen(true);
    }

    function handleCloseGroupFilterDialog(newIncludedGroups) {
        var newExcludedUserKeys = [];
        hubUsers.forEach((user) => {
            var isInAGroup = false;
            newIncludedGroups.forEach((group) => {
                if (group.users.filter((u) => u.key === user.key).length > 0) {
                    isInAGroup = true;
                }
            });

            if (!isInAGroup && !newExcludedUserKeys.includes(user.key)) {
                newExcludedUserKeys.push(user.key);
            }
        });

        setExcludedUserKeysFromGroupsFilter(newExcludedUserKeys);
        setGroupFilterDialogIsOpen(false);
        setFilterIsActive(true);
    }

    function handleDateFilterClick() {
        setDateFilterDialogIsOpen(true);
    }

    function handleCloseDateFilterDialog(startDate, endDate) {
        setFilterStartDate(startDate);
        setFilterEndDate(endDate);
        setDateFilterDialogIsOpen(false);
        setFilterIsActive(true);
    }

    function handleDownloadPress() {
        var csvString =
            "Name, Last Login, Files Accessed, Downloads, Timelines Created, Playlists Created, Playlists Shared\n";

        rows.forEach((row) => {
            csvString += row.name.replace(",", "") + ", ";
            csvString += row.lastLogin.toString().replace(",", "") + ", ";
            csvString += row.viewCount + ", ";
            csvString += row.downloadCount + ", ";
            csvString += row.timelineCount + ", ";
            csvString += row.playlistCount + ", ";
            csvString += row.playlistShareCount + "\n";
        });

        downloadBlob(csvString, "export.csv", "text/csv;charset=utf-8;");
    }

    function handleClearFiltersClick() {
        setFilterStartDate(null);
        setFilterEndDate(null);
        setExcludedUserKeysFromUsersFilter([]);
        setExcludedUserKeysFromGroupsFilter([]);
        setFilterIsActive(false);
    }

    const handleClose = () => {
        props.onClose();
    };

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.toolbarTitle}
                        >
                            {"The data below is for the selected hub only: " +
                                props.hub.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container fluid className={classes.container}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUserFilterClick}
                            className={classes.filterButton}
                        >
                            User Filter
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGroupFilterClick}
                            className={classes.filterButton}
                        >
                            Group Filter
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDateFilterClick}
                            className={classes.filterButton}
                        >
                            Date Filter
                        </Button>
                        {filterIsActive && (
                            <Button
                                color="secondary"
                                onClick={handleClearFiltersClick}
                                className={classes.filterButton}
                            >
                                Clear
                            </Button>
                        )}
                    </div>
                    <Button
                        onClick={handleDownloadPress}
                        className={classes.downloadButton}
                    >
                        Download Data
                    </Button>{" "}
                    <TableContainer
                        component={Paper}
                        className={classes.tableContainer}
                    >
                        <Table aria-label="simple table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        User
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Last login
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Files accessed
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Downloads
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Timelines Created
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Playlists Created
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Playlists Shared
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.lastLogin}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.viewCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.downloadCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.timelineCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.playlistCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.playlistShareCount}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <HubUsageUserFilterDialog
                    open={userFilterDialogIsOpen}
                    onClose={handleCloseUserFilterDialog}
                    users={hubUsers}
                />
                <HubUsageGroupFilterDialog
                    open={groupFilterDialogIsOpen}
                    onClose={handleCloseGroupFilterDialog}
                    groups={hubGroups}
                    // hub={props.hub}
                />
                <HubUsageDateFilterDialog
                    open={dateFilterDialogIsOpen}
                    onClose={handleCloseDateFilterDialog}
                />
            </Dialog>
        </>
    );
}
