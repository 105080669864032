import { useState, useEffect, useContext } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";

// My Files
import { DatabaseContext, UserContext } from "../../../context";
import ViewEventDialog from "../../calendar/ViewEventDialog";

const useStyles = makeStyles((theme) => ({
    event: {
        ...theme.typography.bodyBold,
        display: "block",
        textAlign: "center",
        margin: 5,
        padding: 5,
        width: 250,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    noEventsText: { margin: 10, color: "rgb(100, 100, 100)" },
}));

export default function FileEventsDialog(props) {
    const classes = useStyles();
    const [linkedEvents, setLinkedEvents] = useState([]);
    const { events, calendars, folders } = useContext(DatabaseContext);

    const [viewEventDialogOpen, setViewEventDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        if (props.file) {
            let array = [];
            events.forEach((event) => {
                if (event.files) {
                    if (
                        event.files.filter((f) => f.key === props.file.key)
                            .length > 0
                    ) {
                        let calendar = calendars.filter(
                            (c) => c.key === event.calendar
                        )[0];

                        if (calendar) {
                            console.log("HERE:");
                            console.log(calendar);
                            if (calendar.user === currentUserData.key) {
                                array.push(event);
                            } else {
                                let hub = folders.filter(
                                    (f) => f.key === calendar.hub
                                )[0];
                                if (hub) {
                                    if (
                                        hub.managers.includes(
                                            currentUserData.key
                                        ) ||
                                        hub.users.includes(currentUserData.key)
                                    ) {
                                        array.push(event);
                                    }
                                }
                            }
                        }
                    }
                }
            });

            setLinkedEvents(array);
        }
    }, [events, calendars, folders, props, currentUserData]);

    const handleClose = () => {
        props.onClose();
    };

    function handleCloseViewEventDialog() {
        setViewEventDialogOpen(false);
    }

    const handleEventClick = (event) => () => {
        setSelectedEvent(event);
        setViewEventDialogOpen(true);
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                {linkedEvents.length === 0 && (
                    <span className={classes.noEventsText}>
                        The file is not linked to any events
                    </span>
                )}
                {linkedEvents.map((event) => (
                    <Chip
                        color="primary"
                        className={classes.event}
                        label={event.name}
                        onClick={handleEventClick(event)}
                        key={event.key}
                        style={{
                            backgroundColor: event.color,
                        }}
                    />
                ))}
            </Dialog>
            <ViewEventDialog
                open={viewEventDialogOpen}
                onClose={handleCloseViewEventDialog}
                event={selectedEvent}
                fromFile={props.file}
            />
        </>
    );
}
