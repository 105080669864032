import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Calendar from "react-calendar";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import firebase from "../../firebase";
import DialogWithTextInput from "../DialogWithTextInput";
import { makeid } from "../../utility";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 75,
    },
    newProjectButton: { marginBottom: 15 },

    addButton: {
        color: "rgb(0, 149, 61)",
    },
    searchResultsTableContainer: {
        marginTop: 15,
        marginBottom: 15,
        maxHeight: 264,
        overflow: "scroll",
    },
    myProjectsTableContainer: {
        marginTop: 15,
        marginBottom: 15,
        maxHeight: 264,
        overflow: "scroll",
    },
    sharedProjectsTableContainer: {
        marginTop: 15,
        marginBottom: 50,
        overflow: "scroll",
    },
    userNameSearchTextField: {
        marginTop: 10,
    },
    fromCalendar: {
        maxWidth: 300,
    },
    toCalendar: {
        maxWidth: 300,
    },
    searchButton: {
        marginTop: 15,
        // position: "absolute",
        // backgroundColor: "red",
        // right: 75,
        // top: 200,
        // left: "auto",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    paper: { padding: 15 },
}));

export default function ProjectSelectPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const history = useHistory();

    const { projectInfo, users, groups } = useContext(DatabaseContext);

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [availableProjects, setAvailableProjects] = useState([]); // myProjects + projectsSharedWithMe
    const [myProjects, setMyProjects] = useState([]); // Created by me
    const [projectsSharedWithMe, setProjectsSharedWithMe] = useState([]);

    const [searchedProjects, setSearchedProjects] = useState([]); // Search Results

    const [projectNameDialogOpen, setProjectNameDialogOpen] = useState(false);

    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [projectNameSearch, setProjectNameSearch] = useState("");
    const [userNameSearch, setUserNameSearch] = useState("");

    const [canCreateProjects, setCanCreateProjects] = useState(false);

    useEffect(() => {
        if (currentUserData) {
            let myProjectsArray = [];
            let sharedProjectsArray = [];
            let availableProjectsArray = [];

            if (currentUserData.permissions.createProjects) {
                setCanCreateProjects(true);
            }

            projectInfo.forEach((project) => {
                // Users Own Projects
                if (project.createdBy === currentUserData.key) {
                    project.canEdit = true;
                    project.canView = true;
                    myProjectsArray.push(project);
                    availableProjectsArray.push(project);
                }
                // Projects where user has been added individually
                let matchingUser = project.users.filter(
                    (u) => u.key === currentUserData.key
                )[0];
                if (matchingUser) {
                    // Check it hasn't already been added above
                    if (!project.canEdit) {
                        project.canEdit = matchingUser.canEdit;
                        project.canView = true;
                        sharedProjectsArray.push(project);
                        availableProjectsArray.push(project);
                    }
                }
                // Projects where user is part of a group that has access
                project.groups.forEach((projectGroup) => {
                    let matchingGroup = groups.filter(
                        (g) => g.key === projectGroup.key
                    )[0];
                    if (matchingGroup) {
                        if (
                            matchingGroup.users.filter(
                                (u) => u.key === currentUserData.key
                            ).length > 0
                        ) {
                            // Check it hasn't already been added above
                            if (!project.canEdit) {
                                project.canEdit = projectGroup.canEdit;
                                project.canView = true;
                                sharedProjectsArray.push(project);
                                availableProjectsArray.push(project);
                            }
                        }
                    }
                });
            });
            setMyProjects(myProjectsArray);
            setProjectsSharedWithMe(sharedProjectsArray);
            setAvailableProjects(availableProjectsArray);
        }

        // eslint-disable-next-line
    }, [currentUserData, projectInfo, history]);

    const handleEditProjectSelect = (project) => () => {
        history.push("/projects/e?key=" + project.key);
    };

    const handleViewProjectSelect = (project) => () => {
        history.push("/projects/v?key=" + project.key);
    };

    function handleNewProjectClicked() {
        setProjectNameDialogOpen(true);
    }

    function handleCloseProjectNameDialog(newName, okPressed) {
        if (okPressed) {
            if (newName !== "") {
                // Create the Project Info object & add to Firebase
                let infoRef = firebase.database().ref("projectinfo");

                let key = makeid(15);

                let infoObject = {
                    createdBy: currentUserData.key,
                    createdOn: dayjs().toString(),
                    lastUpdated: dayjs().toString(),
                    intro: "",
                    title: newName,
                };

                infoRef.child(key).set(infoObject);

                setProjectNameDialogOpen(false);

                history.push("/projects/e?key=" + key);
            }
        } else {
            setProjectNameDialogOpen(false);
        }
    }

    function onFromDateChange(nextDate) {
        setFromDate(nextDate);
    }

    function onToDateChange(nextDate) {
        setToDate(nextDate);
    }

    function handleSearchPressed() {
        var searchArray = [];
        console.clear();
        availableProjects.forEach((project) => {
            console.log(project);
            var shouldExclude = false;

            // Project Name Search
            if (projectNameSearch !== "") {
                if (
                    !project.title
                        .toLowerCase()
                        .includes(projectNameSearch.toLowerCase())
                ) {
                    shouldExclude = true;
                }
            }

            // Created By User Name Search
            if (userNameSearch !== "") {
                var matchingUser = users.filter(
                    (u) => u.key === project.createdBy
                )[0];
                if (matchingUser) {
                    if (
                        !matchingUser.fullName
                            .toLowerCase()
                            .includes(userNameSearch.toLowerCase())
                    ) {
                        shouldExclude = true;
                    }
                }
            }

            // From Date Search
            if (fromDate !== undefined && fromDate !== undefined) {
                if (dayjs(project.lastUpdated).isBefore(fromDate)) {
                    shouldExclude = true;
                }

                if (dayjs(project.lastUpdated).isAfter(toDate)) {
                    shouldExclude = true;
                }
            } else if (fromDate !== undefined) {
                if (dayjs(project.lastUpdated).isBefore(fromDate)) {
                    shouldExclude = true;
                }
            } else if (toDate !== undefined) {
                if (dayjs(project.lastUpdated).isAfter(toDate)) {
                    shouldExclude = true;
                }
            }

            // To Date Search

            if (!shouldExclude) {
                searchArray.push(project);
            }
        });

        setSearchedProjects(searchArray);
        setSearchResultsVisible(true);
    }

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "projectNameSearch") {
            setProjectNameSearch(newValue);
        } else if (event.target.id === "userNameSearch") {
            setUserNameSearch(newValue);
        }
    }

    return (
        <>
            <Container className={classes.pageContainer}>
                {canCreateProjects && (
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.newProjectButton}
                        onClick={handleNewProjectClicked}
                    >
                        New Project
                    </Button>
                )}
                <h5>Search</h5>
                <Paper className={classes.paper} variant="outlined">
                    <Row>
                        {/* <IconButton
                            onClick={HandleFromDatePressed}
                            component="span"
                        >
                            <EventNoteIcon />
                        </IconButton> */}
                        <Col>
                            <TextField
                                fullWidth
                                value={projectNameSearch}
                                id="projectNameSearch"
                                onChange={handleTextFieldChange}
                                label="Project Name"
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                className={classes.userNameSearchTextField}
                                value={userNameSearch}
                                id="userNameSearch"
                                onChange={handleTextFieldChange}
                                label="From User"
                                variant="outlined"
                            />
                        </Col>
                        <Col>
                            <h6>From</h6>
                            <Calendar
                                className={classes.fromCalendar}
                                onChange={onFromDateChange}
                                // onClickDay={onClickDay}
                                // showWeekNumbers
                                defaultView="month"
                                value={fromDate}
                                // tileContent={tileContent}
                            />
                        </Col>
                        <Col>
                            <h6>To</h6>
                            <Calendar
                                className={classes.toCalendar}
                                onChange={onToDateChange}
                                // onClickDay={onClickDay}
                                // showWeekNumbers
                                defaultView="month"
                                value={toDate}
                                // tileContent={tileContent}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col lg={11} md={11} sm={11}></Col> */}
                        <Col>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.searchButton}
                                onClick={handleSearchPressed}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Paper>

                {searchResultsVisible && (
                    <>
                        <div className={classes.dividerDiv} />
                        <h5>Search Results</h5>
                        <Paper className={classes.paper} variant="outlined">
                            <TableContainer
                                component={Paper}
                                // className={classes.mySurveysTableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {searchedProjects.map((p) => (
                                            <TableRow key={p.key}>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {p.title}
                                                </TableCell>
                                                {p.canEdit ? (
                                                    <TableCell
                                                        onClick={handleEditProjectSelect(
                                                            p
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Project
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Project
                                                    </TableCell>
                                                )}
                                                {p.canView ? (
                                                    <TableCell
                                                        onClick={handleViewProjectSelect(
                                                            p
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Project
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Project
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
                {myProjects.length > 0 && (
                    <>
                        <div className={classes.dividerDiv} />
                        <h5>My Projects</h5>
                        <Paper className={classes.paper} variant="outlined">
                            <TableContainer
                                component={Paper}
                                // className={classes.myProjectsTableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {myProjects.map((p) => (
                                            <TableRow key={p.key}>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {p.title}
                                                </TableCell>
                                                {p.canEdit ? (
                                                    <TableCell
                                                        onClick={handleEditProjectSelect(
                                                            p
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Project
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Project
                                                    </TableCell>
                                                )}
                                                {p.canView ? (
                                                    <TableCell
                                                        onClick={handleViewProjectSelect(
                                                            p
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Project
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Project
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
                {projectsSharedWithMe.length > 0 && (
                    <>
                        <div className={classes.dividerDiv} />
                        <h5>Projects Shared With Me</h5>
                        <Paper className={classes.paper} variant="outlined">
                            <TableContainer
                                component={Paper}
                                // className={classes.myProjectsTableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {projectsSharedWithMe.map((p) => (
                                            <TableRow key={p.key}>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {p.title}
                                                </TableCell>
                                                {p.canEdit ? (
                                                    <TableCell
                                                        onClick={handleEditProjectSelect(
                                                            p
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Project
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Project
                                                    </TableCell>
                                                )}
                                                {p.canView ? (
                                                    <TableCell
                                                        onClick={handleViewProjectSelect(
                                                            p
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Project
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Project
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
            </Container>
            <ToolbarWithLogo />
            <DialogWithTextInput
                open={projectNameDialogOpen}
                onClose={handleCloseProjectNameDialog}
                title={"New Project"}
                message={"Enter a name for the Project."}
                initialValue={""}
            />
        </>
    );
}
