import DraigRegular from "./fonts/Draig-Regular.otf";
import DraigBold from "./fonts/Draig-Bold.otf";
import QuatroRegular from "./fonts/Quatro-Slab-Regular.ttf";
import GoodHeadlineRegular from "./fonts/FFGoodHeadline-Regular.otf";

import { createMuiTheme } from "@material-ui/core/styles";

const draigRegular = {
    fontFamily: "DraigRegular",
    fontStyle: "regular",
    fontDisplay: "swap",
    fontWeight: "600",
    src: `
      local('Draig-Regular'),
      url(${DraigRegular})
    `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const draigBold = {
    fontFamily: "DraigBold",
    fontStyle: "bold",
    fontDisplay: "swap",
    fontWeight: "600",
    src: `
      local('Draig-Bold'),
      url(${DraigBold})
    `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const quatroRegular = {
    fontFamily: "QuatroRegular",
    fontStyle: "regular",
    fontDisplay: "swap",
    fontWeight: "600",
    src: `
      local('Quatro-Slab-Regular'),
      url(${QuatroRegular})
    `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const goodHeadlineRegular = {
    fontFamily: "GoodHeadlineRegular",
    fontStyle: "regular",
    fontDisplay: "swap",
    fontWeight: "600",
    src: `
      local('FFGoodHeadline-Regular'),
      url(${GoodHeadlineRegular})
    `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
    typography: {
        fontFamily: ['"Open Sans"', "QuatroRegular", "Roboto"].join(","),
        h1: {
            fontFamily: "DraigRegular",
        },
        h2: {
            fontFamily: "DraigRegular",
        },
        h3: {
            fontFamily: "DraigRegular",
        },
        h4: {
            fontFamily: "DraigRegular",
        },
        h5: {
            fontFamily: "DraigRegular",
            fontSize: "30px",
        },
        h6: {
            fontFamily: "DraigRegular",
            fontSize: "22px",
        },
        button: {
            fontFamily: "DraigRegular",
        },
        body1: {
            fontFamily: "QuatroRegular",
        },
        bodyBold: {
            fontFamily: "QuatroRegular",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": [
                    draigBold,
                    draigRegular,
                    quatroRegular,
                    goodHeadlineRegular,
                ],
            },
        },
    },
    palette: {
        type: "light",
        primary: {
            main: "rgb(0, 149, 61)",
        },
        secondary: {
            main: "rgb(244, 36, 29)",
        },
        // Rhondda Charcoal R 44 / G 44 / B 44
    },
});

export default theme;
