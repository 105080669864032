export const bunnyVideoLibraryID = "16404";

export function makeid(length) {
    var result = [];
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        );
    }
    return result.join("");
}

export function encodeImage(data) {
    var str = data.reduce(function (a, b) {
        return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
}

export function jsonFromArray(array) {
    let jsonObject = {};

    array.forEach((element) => {
        if (element !== "") {
            if (element.key) {
                jsonObject[element.key] = true;
            } else {
                jsonObject[element] = true;
            }
        }
    });

    return jsonObject;
}

export function range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
}

export function getManagedHubs(allFolders, currentUserData) {
    let hubs = allFolders.filter(
        (f) => f.hub && f.managers.includes(currentUserData.key)
    );

    return hubs;
}

export function getAncestorHubs(
    file,
    currentFolder,
    allFolders,
    currentUserData
) {
    // Returns all the ancestor hubs of a file, sorted in order of their place in the file system
    // (i.e. root hub is first in array, child hub is second)

    if (currentFolder === null) {
        currentFolder = allFolders.filter((f) => file.folder === f.key)[0];
    }

    if (currentFolder === undefined) {
        return [];
    }

    let ancestors = allFolders.filter(
        (f) => f.hub && currentFolder.ancestors.includes(f.key)
    );

    // Only include the ancestors that can be managed by the uploading user
    ancestors = ancestors.filter(
        (a) =>
            a.managers.includes(currentUserData.key) ||
            currentUserData.role === "System Digital Manager" ||
            a.users.includes(currentUserData.key)
    );

    // If the current folder is a hub, add that too
    if (currentFolder.hub) {
        ancestors.push(currentFolder);
    }

    let sorted = ancestors.toSorted(
        (a, b) => a.ancestors.length - b.ancestors.length
    );

    return sorted;
}

export function jsonFromUserEnteredStringArray(array) {
    let jsonObject = {};

    array.forEach((element) => {
        if (element !== "") {
            jsonObject[makeid(10)] = element;
        }
    });

    return jsonObject;
}

export function commaSeperatedStringFromArray(array) {
    var finalString = "";

    if (array === undefined || array.length === 0) {
        return finalString;
    }
    // return finalString;
    array.forEach((arrayString) => {
        finalString += arrayString;
        finalString += ", ";
    });

    if (finalString.length > 1) {
        finalString = finalString.slice(0, -2);
    }

    return finalString;
}

export function commaSeperatedStringFromLabels(labels) {
    var finalString = "";

    if (labels === undefined || labels.length === 0) {
        return finalString;
    }
    // return finalString;
    labels.forEach((label) => {
        finalString += label.text;
        finalString += ", ";
    });

    if (finalString.length > 1) {
        finalString = finalString.slice(0, -2);
    }

    return finalString;
}

export function shouldPlaylistBeVisible(playlist, allGroups, currentUserData) {
    if (playlist.owner === currentUserData.key) {
        return true;
    }

    if (playlist.users.includes(currentUserData.key)) {
        return true;
    }

    for (let i = 0; i < playlist.groups.length; i++) {
        let groupKey = playlist.groups[i];

        let matchingGroupObject = allGroups.filter(
            (group) => groupKey === group.key
        )[0];

        if (matchingGroupObject) {
            if (
                matchingGroupObject.users.filter(
                    (u) => u.key === currentUserData.key
                ).length > 0
            ) {
                return true;
            }
        }
    }

    return false;
}

export function shouldTimelineBeVisible(timeline, allGroups, currentUserData) {
    if (timeline.owner === currentUserData.key) {
        return true;
    }

    if (timeline.users.includes(currentUserData.key)) {
        return true;
    }

    for (let i = 0; i < timeline.groups.length; i++) {
        let groupKey = timeline.groups[i];

        let matchingGroupObject = allGroups.filter(
            (group) => groupKey === group.key
        )[0];

        if (matchingGroupObject) {
            if (
                matchingGroupObject.users.filter(
                    (u) => u.key === currentUserData.key
                ).length > 0
            ) {
                return true;
            }
        }
    }

    return false;
}

export function shouldFileBeVisible(file, groups, user) {
    if (file.uploadedBy && file.uploadedBy === user.key) {
        return true;
    }
    if (
        file.managers.includes(user.key) ||
        user.role === "System Digital Manager"
    ) {
        return true;
    } else if (user.files.includes(file.key)) {
        // If the user was added to the file individually, the file will be in their user.files array
        return true;
    } else {
        // Check the groups assigned to the file for the current user

        for (let i = 0; i < file.groups.length; i++) {
            let groupKey = file.groups[i];

            let matchingGroupObject = groups.filter(
                (group) => groupKey === group.key
            )[0];

            if (matchingGroupObject) {
                if (
                    matchingGroupObject.users.filter((u) => u.key === user.key)
                        .length > 0
                ) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function shouldFileBeVisibleToUser(file, groups, user) {
    if (file.uploadedBy && file.uploadedBy === user.key) {
        return true;
    }
    if (user.files.includes(file.key)) {
        // If the user was added to the file individually, the file will be in their user.files array
        return true;
    } else {
        // Check the groups assigned to the file for the current user

        for (let i = 0; i < file.groups.length; i++) {
            let groupKey = file.groups[i];

            let matchingGroupObject = groups.filter(
                (group) => groupKey === group.key
            )[0];

            if (matchingGroupObject) {
                if (
                    matchingGroupObject.users.filter((u) => u.key === user.key)
                        .length > 0
                ) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function doesFileHaveDuplicate(file, files) {
    for (let index = 0; index < files.length; index++) {
        const f = files[index];
        if (f.key !== file.key) {
            if (f.original) {
                if (f.original === file.key) {
                    console.log("Has Duplicate 1");
                    return true;
                }
            }

            if (file.original) {
                if (f.key === file.original) {
                    console.log("Has Duplicate 2");

                    return true;
                }
            }

            if (f.original && file.original) {
                if (f.original === file.original) {
                    console.log("Has Duplicate 3");

                    return true;
                }
            }
        }
    }

    console.log("No Duplicate");

    return false;
}

let filePathTrail = [];

export function filePath(file, folder, folders) {
    filePathTrail = [];

    filePathTrail.push({
        name: folder.name,
        key: folder.key,
        parent: folder.parent,
    });

    getParents(folder.key, folders);

    filePathTrail.reverse();

    let pathString = "";
    filePathTrail.forEach((folder) => {
        pathString += folder.name + "/";
    });

    pathString += file.displayName;

    return pathString;
}

function getParents(key, folders) {
    let folder = folders.filter((f) => f.key === key)[0];

    if (folder) {
        let parentFolder = folders.filter((f) => f.key === folder.parent)[0];

        if (parentFolder) {
            filePathTrail.push({
                name: parentFolder.name,
                key: parentFolder.key,
                parent: parentFolder.parent,
            });
            getParents(parentFolder.key, folders);
        }
    }
}

export function timeString(total) {
    let totalSeconds = Math.floor(total);

    if (totalSeconds < 60) {
        if (totalSeconds < 10) {
            return "0:0" + totalSeconds;
        } else {
            return "0:" + totalSeconds;
        }
    } else if (totalSeconds > 3600) {
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        let minutesString = "";
        let secondsString = "";

        if (minutes < 10) {
            minutesString = "0" + minutes;
        } else {
            minutesString = minutes;
        }

        if (seconds < 10) {
            secondsString = "0" + seconds;
        } else {
            secondsString = seconds;
        }

        return hours + ":" + minutesString + ":" + secondsString;
    } else {
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.floor(totalSeconds % 60);

        let secondsString = "";

        if (seconds < 10) {
            secondsString = "0" + seconds;
        } else {
            secondsString = seconds;
        }

        return minutes + ":" + secondsString;
    }
}

export function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}

export function uniq(a) {
    var prims = { boolean: {}, number: {}, string: {} },
        objs = [];

    return a.filter(function (item) {
        var type = typeof item;
        if (type in prims)
            return prims[type].hasOwnProperty(item)
                ? false
                : (prims[type][item] = true);
        else return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
}

export function downloadBlob(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
}

export function stringToBool(string) {
    if (string === undefined) {
        return undefined;
    }

    if (string.toLowerCase() === "true") {
        return true;
    } else if (string.toLowerCase() === "false") {
        return false;
    }

    return undefined;
}

export function boolToString(bool) {
    if (bool === undefined) {
        return undefined;
    }
    if (bool === true) {
        return "true";
    } else if (bool === false) {
        return "false";
    }

    return undefined;
}
