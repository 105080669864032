import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files
import { DatabaseContext } from "../../../../context";
import HubUsageDateFilterDialog from "./filters/HubUsageDateFilterDialog";
import HubUsageFileTypeFilterDialog from "./filters/HubUsageFileTypeFilterDialog";
import HubUsageFileFilterDialog from "./filters/HubUsageFileFilterDialog";
import { downloadBlob } from "../../../../utility";

// import { shouldFileBeVisibleToUser } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
    },
    toolbarTitle: {
        // flexGrow: 1,
    },
    title: {
        marginTop: 15,
    },
    filterButton: {
        marginTop: 15,
        marginLeft: 5,
        marginRight: 5,
    },
    downloadButton: {
        position: "absolute",
        left: "auto",
        right: 10,
        top: 80,
    },
    tableContainer: {
        marginTop: 15,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        width: window.innerWidth,
        overflow: "scroll",
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
}));

export default function HubFileUsageDialog(props) {
    const classes = useStyles();
    const { fileUsage, getFileUsage, files, groups } =
        useContext(DatabaseContext);

    // const { currentUserData } = useContext(UserContext);
    const [fileTypeFilterDialogIsOpen, setFileTypeFilterDialogIsOpen] =
        useState(false);
    const [fileFilterDialogIsOpen, setFileFilterDialogIsOpen] = useState(false);
    const [dateFilterDialogIsOpen, setDateFilterDialogIsOpen] = useState(false);

    const [rows, setRows] = useState([]);

    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);

    // const [excludedFileTypesFromFilter, setExcludedFileTypesFromFilter] =
    useState([]);

    const [excludedFileKeysFromFileFilter, setExcludedFileKeysFromFileFilter] =
        useState([]);

    const [hubFiles, setHubFiles] = useState([]);

    const [includeVideo, setIncludeVideo] = useState(true);
    const [includeAudio, setIncludeAudio] = useState(true);
    const [includeImages, setIncludeImages] = useState(true);
    const [includePDFs, setIncludePDFs] = useState(true);
    const [includePresentations, setIncludePresentations] = useState(true);
    const [includeDocuments, setIncludeDocuments] = useState(true);

    const [filterIsActive, setFilterIsActive] = useState(false);

    useEffect(() => {
        if (props.hub) {
            // Find all the files in the hub

            // // TEMP
            // console.clear();
            // var foundFileTypes = [
            //     "video/mp4",
            //     "video/quicktime",
            //     "application/pdf",
            //     "image/jpeg",
            //     "image/png",
            //     "audio/mpeg",
            //     "text/csv",
            //     "text/xml",
            //     "video/vnd.dlna.mpeg-tts",
            //     "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            //     "application/vnd.ms-powerpoint.presentation.macroenabled",
            // ];
            // files.forEach((file) => {
            //     if (!foundFileTypes.includes(file.type)) {
            //         console.log(file.type);
            //     }
            // });
            // // END TEMP

            if (fileUsage.length === 0) {
                getFileUsage();
            }

            let hubFilesArray = files.filter((f) =>
                f.ancestors.includes(props.hub.key)
            );

            // Set the File category
            hubFilesArray.forEach((file) => {
                if (file.type.includes("video")) {
                    file.category = "Video";
                } else if (file.type.includes("audio")) {
                    file.category = "Audio";
                } else if (file.type.includes("image")) {
                    file.category = "Image";
                } else if (file.type.includes("pdf")) {
                    file.category = "PDF";
                } else if (file.type.includes("presentation")) {
                    file.category = "Presentation";
                } else {
                    let extension =
                        file.filename.substring(
                            file.filename.lastIndexOf(".") + 1,
                            file.filename.length
                        ) || file.filename;

                    if (extension === "key") {
                        file.category = "Presentation";
                    } else {
                        file.category = "Document";
                    }
                }
            });

            setHubFiles(hubFilesArray);

            // Create row data for the files that are included after filtering

            let rowDataArray = [];

            hubFilesArray.forEach((file) => {
                if (excludedFileKeysFromFileFilter.includes(file.key)) {
                    file.isIncludedInFilters = false;
                } else if (file.category === "Video" && !includeVideo) {
                    file.isIncludedInFilters = false;
                } else if (file.category === "Audio" && !includeAudio) {
                    file.isIncludedInFilters = false;
                } else if (file.category === "Image" && !includeImages) {
                    file.isIncludedInFilters = false;
                } else if (file.category === "PDF" && !includePDFs) {
                    file.isIncludedInFilters = false;
                } else if (
                    file.category === "Presentation" &&
                    !includePresentations
                ) {
                    file.isIncludedInFilters = false;
                } else if (file.category === "Document" && !includeDocuments) {
                    file.isIncludedInFilters = false;
                } else {
                    file.isIncludedInFilters = true;
                    let matchingFileUsages = fileUsage.filter(
                        (usage) => file.key === usage.file
                    );

                    var viewCount = 0;
                    var downloadCount = 0;
                    var timelineCount = 0;
                    var playlistCount = 0;
                    var playlistShareCount = 0;
                    var mostRecentDate = null;

                    var userKeysViewed = [];
                    matchingFileUsages.forEach((usage) => {
                        if (
                            filterStartDate === null ||
                            dayjs(filterStartDate).isSame(
                                dayjs(usage.date),
                                "day"
                            ) ||
                            dayjs(filterStartDate).isBefore(dayjs(usage.date))
                        ) {
                            if (
                                filterEndDate === null ||
                                dayjs(filterEndDate).isSame(
                                    dayjs(usage.date),
                                    "day"
                                ) ||
                                dayjs(filterEndDate).isAfter(dayjs(usage.date))
                            ) {
                                if (usage.action === "Open") {
                                    viewCount += 1;

                                    // Num of users viewed
                                    if (!userKeysViewed.includes(usage.user)) {
                                        userKeysViewed.push(usage.user);
                                    }

                                    // Most recently viewed
                                    if (
                                        mostRecentDate === null ||
                                        dayjs(usage.date).isAfter(
                                            mostRecentDate
                                        )
                                    ) {
                                        mostRecentDate = dayjs(usage.date);
                                    }
                                } else if (usage.action === "Download") {
                                    downloadCount += 1;
                                } else if (usage.action === "TimelineCreate") {
                                    timelineCount += 1;
                                } else if (usage.action === "PlaylistCreate") {
                                    playlistCount += 1;
                                } else if (usage.action === "PlaylistShare") {
                                    var userKeysSharedTo = [];

                                    usage.usersSharedWith.forEach(
                                        (sharedUserKey) => {
                                            userKeysSharedTo.push(
                                                sharedUserKey
                                            );
                                        }
                                    );

                                    usage.groupsSharedWith.forEach((group) => {
                                        let groupObject = groups.filter(
                                            (g) => g.key === group.key
                                        )[0];

                                        if (groupObject) {
                                            groupObject.users.forEach(
                                                (groupUser) => {
                                                    userKeysSharedTo.push(
                                                        groupUser.key
                                                    );
                                                }
                                            );
                                        }
                                    });

                                    playlistShareCount +=
                                        userKeysSharedTo.length;
                                }
                            }
                        }
                    });

                    // Create the row
                    rowDataArray.push({
                        name: file.displayName,
                        lastViewed: dayjs(mostRecentDate),
                        viewCount: viewCount,
                        usersViewedCount: userKeysViewed.length,
                        downloadCount: downloadCount,
                        timelineCount: timelineCount,
                        playlistCount: playlistCount,
                        playlistShareCount: playlistShareCount,
                    });
                }
            });

            rowDataArray = rowDataArray.sort((a, b) =>
                a.viewCount < b.viewCount ? 1 : -1
            );

            setRows(rowDataArray);
        }
        // eslint-disable-next-line
    }, [
        props,
        fileUsage,
        filterStartDate,
        filterEndDate,
        excludedFileKeysFromFileFilter,
        includeVideo,
        includeAudio,
        includeImages,
        includePDFs,
        includePresentations,
        includeDocuments,
    ]);

    function handleFileTypeFilterClick() {
        setFileTypeFilterDialogIsOpen(true);
    }

    function handleDownloadPress() {
        var csvString =
            "File, Last Viewed, Num Views, Num Users Viewed, Downloads, Timelines Created, Playlists Created, Playlists Shared\n";

        rows.forEach((row) => {
            csvString += row.name.replace(",", "") + ", ";
            csvString += row.lastViewed.toString().replace(",", "") + ", ";
            csvString += row.viewCount + ", ";
            csvString += row.usersViewedCount + ", ";
            csvString += row.downloadCount + ", ";
            csvString += row.timelineCount + ", ";
            csvString += row.playlistCount + ", ";
            csvString += row.playlistShareCount + "\n";
        });

        downloadBlob(csvString, "export.csv", "text/csv;charset=utf-8;");
    }

    function handleCloseFileTypeFilterDialog(
        video,
        audio,
        images,
        pdfs,
        presentations,
        documents
    ) {
        setIncludeVideo(video);
        setIncludeAudio(audio);
        setIncludeImages(images);
        setIncludePDFs(pdfs);
        setIncludePresentations(presentations);
        setIncludeDocuments(documents);

        // setExcludedUserKeysFromUsersFilter(newExcludedUserKeys);
        // setUserFilterDialogIsOpen(false);
        setFileTypeFilterDialogIsOpen(false);
        setFilterIsActive(true);
    }

    function handleFileFilterClick() {
        setFileFilterDialogIsOpen(true);
    }

    function handleCloseFileFilterDialog(newExcludedFileKeys) {
        setExcludedFileKeysFromFileFilter(newExcludedFileKeys);
        setFileFilterDialogIsOpen(false);
        setFilterIsActive(true);
    }

    function handleDateFilterClick() {
        setDateFilterDialogIsOpen(true);
    }

    function handleCloseDateFilterDialog(startDate, endDate) {
        setFilterStartDate(startDate);
        setFilterEndDate(endDate);
        setDateFilterDialogIsOpen(false);
        setFilterIsActive(true);
    }

    function handleClearFiltersClick() {
        setFilterStartDate(null);
        setFilterEndDate(null);
        setExcludedFileKeysFromFileFilter([]);
        setIncludeVideo(true);
        setIncludeAudio(true);
        setIncludeImages(true);
        setIncludePDFs(true);
        setIncludePresentations(true);
        setIncludeDocuments(true);
        setFilterIsActive(false);
    }

    const handleClose = () => {
        props.onClose();
    };

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.toolbarTitle}
                        >
                            {"The data below is for the selected hub only: " +
                                props.hub.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container fluid className={classes.container}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFileTypeFilterClick}
                            className={classes.filterButton}
                        >
                            File Type Filter
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFileFilterClick}
                            className={classes.filterButton}
                        >
                            File Filter
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDateFilterClick}
                            className={classes.filterButton}
                        >
                            Date Filter
                        </Button>
                        {filterIsActive && (
                            <Button
                                color="secondary"
                                onClick={handleClearFiltersClick}
                                className={classes.filterButton}
                            >
                                Clear
                            </Button>
                        )}
                    </div>
                    <Button
                        onClick={handleDownloadPress}
                        className={classes.downloadButton}
                    >
                        Download Data
                    </Button>
                    <TableContainer
                        component={Paper}
                        className={classes.tableContainer}
                    >
                        <Table aria-label="simple table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        File
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Last Viewed
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Num Views
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Num Users Viewed
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Downloads
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Timelines Created
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Playlists Created
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeaderCell}
                                        align="center"
                                    >
                                        Playlists Shared
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {dayjs(
                                                row.lastViewed
                                            ).toString() === "Invalid Date"
                                                ? "-"
                                                : dayjs(
                                                      row.lastViewed
                                                  ).toString()}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.viewCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.usersViewedCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.downloadCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.timelineCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.playlistCount}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {row.playlistShareCount}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <HubUsageFileTypeFilterDialog
                    open={fileTypeFilterDialogIsOpen}
                    onClose={handleCloseFileTypeFilterDialog}
                    includeVideo={includeVideo}
                    includeAudio={includeAudio}
                    includeImages={includeImages}
                    includePDFs={includePDFs}
                    includePresentations={includePresentations}
                    includeDocuments={includeDocuments}
                />
                <HubUsageFileFilterDialog
                    open={fileFilterDialogIsOpen}
                    onClose={handleCloseFileFilterDialog}
                    files={hubFiles}
                />
                <HubUsageDateFilterDialog
                    open={dateFilterDialogIsOpen}
                    onClose={handleCloseDateFilterDialog}
                />
            </Dialog>
        </>
    );
}
