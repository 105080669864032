import { useState } from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import ManagerManagerEnrolementDialog from "./ManageManagerEnrolementDialog";
import ManagerUserEnrolementDialog from "./ManageUserEnrolementDialog";
import UserAvatar from "../UserAvatar";

const useStyles = makeStyles((theme) => ({
    topSpacing: {
        marginTop: theme.spacing(2),
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    noSelectionLabel: { color: "rgb(100, 100, 100)" },
}));

export default function AvatarGroupDialog(props) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    function handleOpenDialog() {
        setOpen(true);
    }

    function onClose(selected, other, selectionHasChanged) {
        setOpen(false);

        if (selectionHasChanged) {
            props.onChange(selected, other);
        }
    }

    return (
        <>
            <Typography
                className={classes.topSpacing}
                variant="subtitle1"
                gutterBottom
            >
                {props.title}
            </Typography>
            {props.selectedUsers.length === 0 && (
                <span className={classes.noSelectionLabel}>
                    {"This hub doesn't have any " + props.title}
                </span>
            )}
            <AvatarGroup max={5} className={classes.topSpacing}>
                {props.selectedUsers.map((user) => (
                    <UserAvatar
                        key={user.key}
                        userOrHub={user}
                        variant="circular"
                        path="users"
                    />
                ))}
            </AvatarGroup>
            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenDialog}
                    className={classes.topSpacing}
                >
                    {"Edit " + props.title}
                </Button>
                {props.title === "Managers" && (
                    <ManagerManagerEnrolementDialog
                        open={open}
                        onClose={onClose}
                        selectedUsers={props.selectedUsers}
                        otherUsers={props.otherUsers}
                    />
                )}
                {props.title === "Users" && (
                    <ManagerUserEnrolementDialog
                        open={open}
                        onClose={onClose}
                        selectedUsers={props.selectedUsers}
                        otherUsers={props.otherUsers}
                    />
                )}
            </div>
        </>
    );
}
