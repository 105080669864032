import React, { useState, useEffect, useContext } from "react";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import UserAvatar from "../../../management/UserAvatar";
import firebase from "../../../../firebase";
import { UserContext } from "../../../../context";
import { isNumeric, makeid } from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 10,
        width: "100%",
    },
    nameText: {
        ...theme.typography.bodyBold,
    },
    dateText: {
        color: "rgb(140, 140, 140)",
        paddingLeft: 5,
    },
    textColumn: {
        paddingLeft: (props) => (props.avatarSize === "small" ? 25 : 30),
    },
    commentText: {
        flexWrap: "wrap",
    },
    replyPadding: {
        paddingLeft: 35,
    },
    replyButton: {
        maxWidth: 25,
        minWidth: 25,
    },
    moreButton: {
        width: 30,
        height: 30,
    },
}));

export default function ProjectComment(props) {
    const classes = useStyles(props);
    const [time, setTime] = useState(Date.now()); // Updates the comments periodically so their dateString stays current (e.g. 5 minutes ago)

    const [dateString, setDateString] = useState("");

    const [isHovering, setIsHovering] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const [segments, setSegments] = useState([]); // Segments either containing text or time links

    const { currentUserData } = useContext(UserContext);

    const [isOwnComment, setIsOwnComment] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 5000);

        // Get the comment date and determine the largest non-zero time interval (e.g. if the comment was 1 month 6 days ago, we want '1 month' as the result)
        let commentDate = dayjs(props.comment.date);
        let now = dayjs();

        let diffs = [
            {
                interval: "years",
                value: now.diff(commentDate, "years"),
            },
            {
                interval: "months",
                value: now.diff(commentDate, "months"),
            },
            {
                interval: "weeks",
                value: now.diff(commentDate, "weeks"),
            },
            {
                interval: "days",
                value: now.diff(commentDate, "days"),
            },
            {
                interval: "hours",
                value: now.diff(commentDate, "hours"),
            },
            {
                interval: "minutes",
                value: now.diff(commentDate, "minutes"),
            },
        ].filter((diff) => diff.value > 0);

        if (diffs.length === 0) {
            props.comment.dateString = "a moment ago";
            setDateString(props.comment.dateString);
        } else {
            let diff = diffs[0];

            if (diff.value === 1) {
                props.comment.dateString =
                    diff.value + " " + diff.interval.replace("s", "") + " ago ";
                setDateString(props.comment.dateString);
            } else {
                props.comment.dateString =
                    diff.value + " " + diff.interval + " ago ";
                setDateString(props.comment.dateString);
            }
        }

        // Split message up into strings and link prefixed by @

        let segments = [];

        // const timestamps = findTimestamps(props.comment.text);
        //

        // if (timestamps.length > 0) {
        //     console.log(timestamps);
        //     timestamps.forEach((timestamp) => {
        //         let seperate = props.comment.text.split(timestamp);
        //         // console.log(seperate);
        //     });

        //     segments.push({ text: props.comment.text, isLink: false });
        // } else {
        //     segments.push({ text: props.comment.text, isLink: false });
        // }
        //
        props.comment.text.split("@").forEach((s) => {
            if (isTimestamp(s)) {
                segments.push({ text: s, isLink: true });
            } else {
                segments.push({ text: s, isLink: false });
            }
        });

        setSegments(segments);
        setIsOwnComment(props.isOwnComment);

        return () => {
            clearInterval(interval);
        };
    }, [props.comment, time, props.isOwnComment]);

    // function findTimestamps(string) {
    //     let stamps = [];

    //     // check for timestamps over an hour first
    //     const regex1 = RegExp(/([0-9]+):([0-9]+):([0-9]+)/, "g");
    //     let array1;

    //     var stringWithoutHourStamps = string;

    //     while ((array1 = regex1.exec(string)) !== null) {
    //         stamps.push(array1[0]);

    //         stringWithoutHourStamps = stringWithoutHourStamps.replace(
    //             array1[0],
    //             ""
    //         );
    //     }

    //     // Now check for timestamps under an hour
    //     const regex2 = RegExp(/([0-9]+):([0-9]+)/, "g");
    //     let array2;

    //     while ((array2 = regex2.exec(stringWithoutHourStamps)) !== null) {
    //         // console.log(
    //         //     `Found ${array2[0]}. Next starts at ${regex2.lastIndex}.`
    //         // );
    //         stamps.push(array2[0]);
    //     }

    //     return stamps;
    // }

    function isTimestamp(string) {
        // It's a timestamp if it contains a colon and no letters

        // const regExp1 = /([0-9]+):([0-9]+)/;
        // const match1 = string.match(regExp1);

        // if (match1 === string) {
        //     return true;
        // }

        // const regExp2 = /([0-9]+):([0-9]+):([0-9]+)/;
        // const match2 = string.match(regExp2);

        // if (match2 === string) {
        //     // return true;
        // }

        const regExp = new RegExp(/[a-zA-Z]/g);

        if (string.includes(":")) {
            if (!regExp.test(string)) {
                let segments = string.split(":");

                if (segments.length === 2) {
                    let minutes = segments[0];
                    let seconds = segments[1];

                    if (isNumeric(minutes) && isNumeric(seconds)) {
                        return true;
                    }
                } else if (segments.length === 3) {
                    let hours = segments[0];
                    let minutes = segments[1];
                    let seconds = segments[2];

                    if (
                        isNumeric(hours) &&
                        isNumeric(minutes) &&
                        isNumeric(seconds)
                    ) {
                        return true;
                    }
                }

                return true;
            }
        }

        return false;
    }

    function onMouseEnter() {
        if (props.comment.user === currentUserData.key) {
            setIsHovering(true);
        }
    }

    function onMouseLeave() {
        setIsHovering(false);
    }

    const handleMenuClick = (event) => {
        setMenuOpen(true);
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleDeleteButtonPress = () => {
        firebase
            .database()
            .ref("projects")
            .child(props.projectKey)
            .child("components")
            .child(props.component.key)
            .child("comments")
            .child(props.comment.key)
            .remove();

        handleMenuClose();
    };

    return (
        <div className={classes.root}>
            <Container
                fluid
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Col>
                    <Row>
                        <UserAvatar
                            userOrHub={props.comment.userObject}
                            size={props.avatarSize}
                            path="users"
                        />
                        <Col className={classes.textColumn}>
                            <Row>
                                <span className={classes.nameText}>
                                    {props.comment.userName}
                                </span>
                                <span className={classes.dateText}>
                                    {dateString}
                                </span>
                            </Row>
                            <Row>
                                {/* <span>{props.comment.text}</span> */}
                                {segments.map((s) => (
                                    <TextOrLink
                                        key={makeid(5)}
                                        isLink={s.isLink}
                                        text={s.text}
                                    />
                                ))}
                            </Row>
                        </Col>
                        {isOwnComment && (
                            <IconButton
                                className={classes.moreButton}
                                hidden={!isHovering && !menuOpen && !isMobile}
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )}
                    </Row>
                </Col>
            </Container>
            <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDeleteButtonPress}>
                    Delete Comment
                </MenuItem>
            </Menu>
        </div>
    );
}

function TextOrLink(props) {
    function handleClick(event) {
        event.preventDefault();

        props.handleSeekClick(props.text);
    }

    return (
        <>
            {props.isLink ? (
                <Link
                    onClick={handleClick}
                    style={{
                        cursor: "pointer",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {props.text}
                </Link>
            ) : (
                <span style={{ whiteSpace: "pre-wrap" }}>{props.text}</span>
            )}
        </>
    );
}
