import { makeStyles } from "@material-ui/core/styles";
// import { useFilePicker } from "use-file-picker";

// Material UI
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

// Bootstrap

// My Files
import { makeid } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    topSpacing: {
        marginTop: theme.spacing(2),
    },
    dragDropZone: {
        padding: "2rem",
        textAlign: "center",
        margin: 10,
    },
    insideDropArea: {
        padding: "2rem",
        textAlign: "center",
        background: "rgb(200, 200, 200)",
        margin: 10,
    },
    droppedFiles: {
        textAlign: "left",
        fontWeight: "bold",
        padding: "3px",
    },
}));

export default function DragDropFileBox(props) {
    const classes = useStyles();

    const { data, dispatch } = props;

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
        if (data.dropDepth > 0) return;
        dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "copy";
        dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let files = [...e.dataTransfer.files];

        files = files.filter((f) => f.type !== "");

        for (let i = 0; i < files.length; i++) {
            files[i].key = makeid(25);
        }

        if (files && files.length > 0) {
            const existingFiles = data.fileList.map((f) => f.key);
            files = files.filter((f) => !existingFiles.includes(f.key));

            dispatch({ type: "ADD_FILE_TO_LIST", files });
            e.dataTransfer.clearData();
            dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
            dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
        }

        dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    };

    const handleChoosePhotoClick = (e) => {
        const files = e.target.files;

        if (FileReader && files && files.length) {
            var fr = new FileReader();

            fr.onload = function () {
                let filesArray = [...files];

                for (let i = 0; i < files.length; i++) {
                    files[i].key = makeid(25);
                }

                if (filesArray && filesArray.length > 0) {
                    const existingFiles = data.fileList.map((f) => f.key);
                    filesArray = filesArray.filter(
                        (f) => !existingFiles.includes(f.key)
                    );

                    filesArray.forEach((file) => {
                        data.fileList.push(file);
                    });
                    dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
                    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
                }
            };
            fr.readAsDataURL(files[0]);
            // setIsEditing(true);
        }
    };

    return (
        <Paper
            className={
                data.inDropZone ? classes.insideDropArea : classes.dragDropZone
            }
            elevation={2}
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
        >
            <Button color="primary" component="label">
                Select
                <input
                    type="file"
                    multiple
                    hidden
                    onChange={handleChoosePhotoClick}
                />
            </Button>
            <span>or drag files to upload</span>
        </Paper>
    );
}
