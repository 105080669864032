import { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";

// My Files
import "../../calendar/Calendar.css";
import { DatabaseContext } from "../../../context";
import NewEventDialog from "../../calendar/NewEventDialog";
import firebase from "../../../firebase";
import { makeid, jsonFromArray } from "../../../utility";
import CalendarComponent from "../../calendar/CalendarComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        paddingLeft: 10,
    },
    backButton: {
        position: "fixed",
        marginLeft: 0,
        paddingLeft: 25,
        paddingRight: 25,
        width: 25,
        zIndex: 1,
    },
    title: {
        marginTop: 9,
        paddingLeft: 35,
        paddingRight: 25,
    },
    calendarName: {
        marginTop: 10,
    },
    textField: {
        margin: 20,
    },
    colorPicker: {
        // paddingLeft: 5,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        alignSelf: "center",
    },
    button: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
    event: {
        width: "100%",
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
    },
    noEventsText: { paddingTop: 10, color: "rgb(140, 140, 140)" },
}));

export default function LinkFileToEventDialog(props) {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState();

    const [selectedEvents, setSelectedEvents] = useState([]);

    const [newEventDialogOpen, setNewEventDialogOpen] = useState(false);

    const { events } = useContext(DatabaseContext);

    const [eventsOnDayByCalendar, setEventsOnDayByCalendar] = useState([]);

    const [visibleEvents, setVisibleEvents] = useState([]);

    useEffect(() => {
        let calendarsArray = [];
        var eventsToAdd = [];

        props.calendars.forEach((calendar) => {
            let calendarObject = { name: calendar.name, eventsOnDay: [] };

            calendar.events.forEach((event) => {
                let matchingEvent = events.filter(
                    (e) => e.key === event.key
                )[0];
                if (matchingEvent) {
                    if (
                        dayjs(matchingEvent.date).isSame(
                            dayjs(selectedDate),
                            "day"
                        )
                    ) {
                        calendarObject.eventsOnDay.push(matchingEvent);
                    }

                    eventsToAdd.push(matchingEvent);
                }
            });

            calendarsArray.push(calendarObject);
        });

        setVisibleEvents(eventsToAdd);

        setEventsOnDayByCalendar(calendarsArray);

        setSelectedEvents(props.selectedEvents);
    }, [events, selectedDate, props]);

    function handleClickOpenNewEventDialog() {
        setNewEventDialogOpen(true);
    }

    function handleCloseNewEventDialog() {
        setNewEventDialogOpen(false);
    }

    const handleClose = () => {
        props.onClose(selectedEvents);
    };

    const handleEventSelect = (event) => () => {
        if (selectedEvents.filter((e) => e.key === event.key)[0]) {
            // Remove the file from an event

            let newSelectedEvents = selectedEvents.filter(
                (e) => e.key !== event.key
            );
            setSelectedEvents(newSelectedEvents);
            props.selectionChanged(newSelectedEvents);

            if (props.file) {
                // props.file is only set if editing an existing file (not when uploading a new file).
                // In this case save to firebase on close

                // Remove the event from the file object
                let eventsObject = {};
                props.file.events.forEach((e) => {
                    if (e.key !== event.key) {
                        eventsObject[e.key] = true;
                    }
                });

                firebase
                    .database()
                    .ref("files")
                    .child(props.file.key)
                    .update({ events: eventsObject });

                // Remove the file from the event object

                let filesObject = {};

                event.files.forEach((f) => {
                    if (f.key !== props.file.key) {
                        filesObject[f.key] = true;
                    }
                });

                firebase
                    .database()
                    .ref("events")
                    .child(event.key)
                    .update({ files: filesObject });
            }
        } else {
            // Add the file to an event

            let newSelectedEvents = [...selectedEvents, event];
            setSelectedEvents(newSelectedEvents);
            props.selectionChanged(newSelectedEvents);

            if (props.file) {
                // props.file is only set if editing an existing file (not when uploading a new file).
                // In this case save to firebase on close

                let eventsObject = jsonFromArray(props.file.events);
                eventsObject[event.key] = true;

                // Add the event to the file object
                firebase
                    .database()
                    .ref("files")
                    .child(props.file.key)
                    .update({ events: eventsObject });

                // Add the file to the event object

                let filesObject = jsonFromArray(event.files);
                filesObject[props.file.key] = true;

                firebase
                    .database()
                    .ref("events")
                    .child(event.key)
                    .update({ files: filesObject });
            }
        }
    };

    function addEvent(name, selectedCalendar, color) {
        // // The user has created a new event, add to firebase
        var eventsRef = firebase.database().ref("events");

        let key = "-" + makeid(20);

        let newEvent = {
            date: dayjs(selectedDate).toString(),
            name: name,
            calendar: selectedCalendar.key,
            color: color.replace("#", ""),
        };

        eventsRef.child(key).set(newEvent);

        var calendarEventsRef = firebase
            .database()
            .ref("calendars/" + selectedCalendar.key + "/events/");

        calendarEventsRef.child(key).set(true);

        setSelectedEvents((prev) => [...prev, newEvent]);
        setNewEventDialogOpen(false);
    }

    function handleDateSelect(date) {
        setSelectedDate(date);
    }

    function handleBackClick() {
        console.log(selectedEvents);
        setSelectedDate();
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            className={classes.root}
        >
            {selectedDate ? (
                <>
                    <IconButton
                        onClick={handleBackClick}
                        component="span"
                        className={classes.backButton}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Container fluid className={classes.container}>
                        <Col>
                            <Row>
                                <Col>
                                    <h3 className={classes.title}>
                                        {dayjs(selectedDate).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </h3>
                                </Col>
                            </Row>
                            {eventsOnDayByCalendar.length === 0 && (
                                <span className={classes.noEventsText}>
                                    There are no Calendars available, create one
                                    from the Hub Management Page
                                </span>
                            )}
                            {eventsOnDayByCalendar.map((calendar) => (
                                <>
                                    <h5 className={classes.calendarName}>
                                        {calendar.name}
                                    </h5>

                                    {calendar.eventsOnDay.length === 0 && (
                                        <span className={classes.noEventsText}>
                                            There are no events on this date yet
                                        </span>
                                    )}

                                    {calendar.eventsOnDay.map(
                                        (calendarEvent) => (
                                            <Row key={calendarEvent.key}>
                                                <Chip
                                                    avatar={
                                                        selectedEvents.filter(
                                                            (e) =>
                                                                e.key ===
                                                                calendarEvent.key
                                                        ).length > 0 && (
                                                            <CheckCircleOutlineIcon
                                                                style={{
                                                                    backgroundColor:
                                                                        calendarEvent.color,
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    size="medium"
                                                    color="primary"
                                                    className={classes.event}
                                                    clickable
                                                    label={calendarEvent.name}
                                                    onClick={handleEventSelect(
                                                        calendarEvent
                                                    )}
                                                    key={calendarEvent.key}
                                                    style={{
                                                        backgroundColor:
                                                            calendarEvent.color,
                                                    }}
                                                />
                                            </Row>
                                        )
                                    )}
                                </>
                            ))}
                        </Col>
                        <IconButton
                            onClick={handleClickOpenNewEventDialog}
                            component="span"
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Container>
                </>
            ) : (
                <>
                    <h4 className={classes.title}>Select a Date</h4>
                    <div className={classes.calendarContainer}>
                        <CalendarComponent
                            handleDateSelect={handleDateSelect}
                            events={visibleEvents}
                            selectedEvents={selectedEvents}
                        />
                    </div>
                </>
            )}
            <NewEventDialog
                open={newEventDialogOpen}
                onClose={handleCloseNewEventDialog}
                date={selectedDate}
                addEvent={addEvent}
                calendars={props.calendars}
            />
        </Dialog>
    );
}
