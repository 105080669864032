import React, { useEffect, useContext, useState } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FileListAccordion from "./FileListAccordion";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import firebase from "firebase";
import { Tooltip } from "@material-ui/core";
import DialogWithTextInput from "../DialogWithTextInput";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginLeft: theme.spacing(2),
        // flex: 1,
    },
    dateText: {
        marginLeft: theme.spacing(2),
        ...theme.typography.body1,
        flexGrow: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewEventDialog(props) {
    const classes = useStyles();
    const { files, folders, calendars } = useContext(DatabaseContext);

    const { event } = props;

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const { currentUserData } = useContext(UserContext);

    const [userCanEdit, setUserCanEdit] = useState(false);

    const [newNameDialogOpen, setNewNameDialogOpen] = useState(false);

    useEffect(() => {
        if (event) {
            event.files.forEach((file) => {
                let fileFromFirebase = files.filter(
                    (f) => f.key === file.key
                )[0];

                if (fileFromFirebase) {
                    // Add the properties from firebase object to the file object
                    Object.assign(file, fileFromFirebase);
                }
            });

            // See if user is owner of the calendar or manager of the hub which owns the calender, and can therefore
            // Delete events

            let calendar = calendars.filter((c) => c.key === event.calendar)[0];

            if (calendar) {
                if (calendar.user === currentUserData.key) {
                    setUserCanEdit(true);
                } else {
                    let hub = folders.filter((f) => f.key === calendar.hub)[0];
                    if (hub) {
                        if (hub.managers.includes(currentUserData.key)) {
                            setUserCanEdit(true);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, currentUserData]);

    const handleClose = () => {
        props.onClose();
    };

    function handleDeletePressed() {
        setConfirmDeleteDialogOpen(true);
    }

    const handleConfirmDeleteDialogClose = (key) => () => {
        setConfirmDeleteDialogOpen(false);

        if (key === "OK") {
            // Delete the event from any linked files
            let filesWithEvent = [];

            files.forEach((file) => {
                let needsChange = false;
                let eventsToKeep = [];
                file.events.forEach((e) => {
                    if (e.key === event.key) {
                        filesWithEvent.push(file);
                        needsChange = true;
                    } else {
                        eventsToKeep.push(e.key);
                    }
                });

                if (needsChange) {
                    let ref = firebase.database().ref("files").child(file.key);

                    // Change remaining events to JSON
                    let eventsObject = {};

                    eventsToKeep.forEach((e) => {
                        eventsObject[e] = true;
                    });

                    ref.update({ events: eventsObject });
                }
            });

            // Delete the event from the calender
            let calendar = calendars.filter((c) => c.key === event.calendar)[0];

            if (calendar) {
                let eventsToKeep = [];
                calendar.events.forEach((e) => {
                    if (e.key !== event.key) {
                        eventsToKeep.push(e.key);
                    }
                });

                let ref = firebase
                    .database()
                    .ref("calendars")
                    .child(calendar.key);

                // Change remaining events to JSON
                let eventsObject = {};

                eventsToKeep.forEach((e) => {
                    eventsObject[e] = true;
                });

                ref.update({ events: eventsObject });
            }

            // Now delete the event
            let eventRef = firebase.database().ref("events").child(event.key);
            eventRef.remove();

            handleClose();
        }
    };

    function handleOpenNewNameDialog() {
        setNewNameDialogOpen(true);
    }

    function handleCloseNewNameDialog(newName, okPressed) {
        if (newName !== "" && okPressed) {
            let eventRef = firebase.database().ref("events").child(event.key);

            event.name = newName;

            eventRef.update({ name: newName });
        }

        setNewNameDialogOpen(false);
    }

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                TransitionComponent={Transition}
            >
                {event && (
                    <>
                        <AppBar>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>

                                <Typography
                                    variant="h6"
                                    className={classes.titleText}
                                >
                                    {event.name}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className={classes.dateText}
                                >
                                    {dayjs(event.date)
                                        .format("dddd, MMMM D, YYYY")
                                        .toString()}
                                </Typography>

                                {userCanEdit && (
                                    <>
                                        <Tooltip title="Edit Event">
                                            <IconButton
                                                edge="right"
                                                color="inherit"
                                                onClick={
                                                    handleOpenNewNameDialog
                                                }
                                                aria-label="close"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete Event">
                                            <IconButton
                                                edge="right"
                                                color="inherit"
                                                onClick={handleDeletePressed}
                                                aria-label="close"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                        <FileListAccordion
                            files={event.files}
                            fromFile={props.fromFile}
                        />
                    </>
                )}
            </Dialog>
            <Dialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Delete Event</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmDeleteDialogClose("Cancel")}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDeleteDialogClose("OK")}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogWithTextInput
                open={newNameDialogOpen}
                onClose={handleCloseNewNameDialog}
                title={"Edit Event"}
                message={"Enter a new name for the event."}
                initialValue={event && event.name}
            />
        </>
    );
}
