import { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    doneButton: {
        width: 60,
        margin: 10,
    },
}));

// DateRangePicker example:
// https://codesandbox.io/s/react-date-range-7wq0c?file=/src/components/DateRangeSelector.js:1646-1674

export default function HubUsageDateFilterDialog(props) {
    const classes = useStyles();

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    const handleClose = () => {
        props.onClose(
            dayjs(selectedDateRange.startDate),
            dayjs(selectedDateRange.endDate)
        );
    };

    const handleSelect = (ranges) => {
        setSelectedDateRange(ranges.selection);
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <Container fluid className={classes.container}>
                    <DateRangePicker
                        ranges={[selectedDateRange]}
                        onChange={handleSelect}
                    />
                    <Divider />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        className={classes.doneButton}
                    >
                        Done
                    </Button>
                </Container>
            </Dialog>
        </>
    );
}
