import React, { useEffect, useContext, useState, Fragment } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext } from "../../../context";
import WatchPlaylistPage from "../../playlists/WatchPlaylistPage";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 0,
    },
    row: {
        padding: 0,
        margin: 0,
    },
    arrowIcon: {
        padding: 0,
    },
    deleteIcon: { padding: 0 },
    addedByText: {
        color: "rgb(180, 180, 180)",
        fontSize: "12px",
        paddingTop: 0,
        marginTop: 0,
    },
    // buttonGroup: { position: "sticky", top: 0 },
}));

export default function ProjectPlaylistComponent(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const [isEditing, setIsEditing] = useState(false);
    const [playlistContainerWidth, setPlaylistContainerWidth] = useState();

    useEffect(() => {
        if (currentUserData) {
            if (props.component) {
                setIsEditing(props.isEditing);
            }

            let newWidth = window.innerWidth - 150.0;

            if (newWidth > 1150) {
                newWidth = 1150;
            }

            setPlaylistContainerWidth(newWidth);

            function handleResize() {
                let newWidth = window.innerWidth - 150.0;

                if (newWidth > 1150) {
                    newWidth = 1150;
                }

                setPlaylistContainerWidth(newWidth);
            }

            window.addEventListener("resize", handleResize);
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    function handleDeletePressed() {
        props.delete(props.component);
    }

    function handleMoveUpPressed() {
        props.moveUp(props.component);
    }

    function handleMoveDownPressed() {
        props.moveDown(props.component);
    }

    return (
        <>
            {isEditing ? (
                <>
                    <span
                        style={{
                            minHeight: 35,
                            width: playlistContainerWidth,
                            display: "inline-block",
                        }}
                    >
                        <WatchPlaylistPage
                            playlistKey={props.component.linkKey}
                        />
                    </span>
                    <span className={classes.buttonGroup}>
                        <ButtonGroup orientation="vertical">
                            <IconButton
                                className={classes.arrowIcon}
                                aria-label="moveUp"
                                onClick={handleMoveUpPressed}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton
                                className={classes.arrowIcon}
                                aria-label="moveDown"
                                onClick={handleMoveDownPressed}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </ButtonGroup>
                        <Tooltip title="Delete">
                            <IconButton
                                className={classes.deleteIcon}
                                aria-label="delete"
                                onClick={handleDeletePressed}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <h5 className={classes.addedByText}>
                            {props.component.addedByName +
                                " (" +
                                dayjs(props.component.addedOn).format(
                                    "DD/MM/YYYY"
                                ) +
                                ")"}
                        </h5>
                    </span>
                </>
            ) : (
                <WatchPlaylistPage playlistKey={props.component.linkKey} />
            )}
        </>
    );
}
