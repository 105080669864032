import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import {
    filePath,
    shouldPlaylistBeVisible,
    shouldTimelineBeVisible,
} from "../../utility";
import ToolbarWithLogo from "../ToolbarWithLogo";

const useStyles = makeStyles((theme) => ({
    recentTableContainer: {
        marginTop: 15,
        marginBottom: 15,
        maxHeight: 264,
        overflow: "scroll",
    },
    allTableContainer: {
        marginTop: 15,
        marginBottom: 50,
        overflow: "scroll",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function PlaylistsAndTimelinesPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const history = useHistory();

    const {
        files,
        folders,
        playlistInfo,
        playlistUsage,
        timelineInfo,
        groups,
    } = useContext(DatabaseContext);

    const [value, setValue] = useState(0);

    const [allPlaylists, setAllPlaylists] = useState([]);
    const [recentPlaylists, setRecentPlaylists] = useState([]);

    const [allTimelines, setAllTimelines] = useState([]);
    const [recentTimelines, setRecentTimelines] = useState([]);

    useEffect(() => {
        if (currentUserData) {
            // Get playlists
            let allPlaylistsArray = [];
            let recentPlaylistsArray = [];

            playlistInfo.forEach((playlist) => {
                if (
                    shouldPlaylistBeVisible(playlist, groups, currentUserData)
                ) {
                    // Shared and owned playlists have different text color

                    if (playlist.owner === currentUserData.key) {
                        playlist.isOwner = true;
                    }

                    // Find path of the video file
                    let video = files.filter(
                        (f) => f.key === playlist.video
                    )[0];
                    if (video) {
                        let folder = folders.filter(
                            (f) => f.key === video.folder
                        )[0];
                        playlist.filePath = filePath(video, folder, folders);
                    }

                    allPlaylistsArray.push(playlist);

                    let usageObject = playlistUsage.filter(
                        (u) =>
                            u.user === currentUserData.key &&
                            u.playlist === playlist.key
                    )[0];
                    if (usageObject) {
                        playlist.lastOpened = usageObject.lastOpened;
                        recentPlaylistsArray.push(playlist);
                    }
                }
            });

            recentPlaylistsArray.sort((a, b) =>
                dayjs(a.lastOpened) < dayjs(b.lastOpened)
                    ? 1
                    : dayjs(b.lastOpened) < dayjs(a.lastOpened)
                    ? -1
                    : 0
            );

            allPlaylistsArray.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );

            setRecentPlaylists(recentPlaylistsArray);
            setAllPlaylists(allPlaylistsArray);

            // Get timelines
            let allTimelinesArray = [];
            let recentTimelinesArray = [];

            timelineInfo.forEach((timeline) => {
                if (
                    shouldTimelineBeVisible(timeline, groups, currentUserData)
                ) {
                    if (timeline.owner === currentUserData.key) {
                        timeline.isOwner = true;
                    }
                    // Find path of the video file

                    let video = files.filter(
                        (f) => f.key === timeline.video
                    )[0];
                    if (video) {
                        let folder = folders.filter(
                            (f) => f.key === video.folder
                        )[0];

                        timeline.filePath = filePath(video, folder, folders);
                    }

                    allTimelinesArray.push(timeline);
                    if (timeline.lastOpened) {
                        recentTimelinesArray.push(timeline);
                    }
                }
            });

            recentTimelinesArray.sort((a, b) =>
                dayjs(a.lastOpened) < dayjs(b.lastOpened)
                    ? 1
                    : dayjs(b.lastOpened) < dayjs(a.lastOpened)
                    ? -1
                    : 0
            );

            allTimelinesArray.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );

            setRecentTimelines(recentTimelinesArray);
            setAllTimelines(allTimelinesArray);
        }

        // eslint-disable-next-line
    }, [currentUserData, playlistInfo, timelineInfo, playlistUsage]);

    const handlePlaylistSelect = (playlist) => () => {
        history.push("/playlist?key=" + playlist.key);
    };

    const handleTimelineSelect = (timeline) => () => {
        // history.push("/playlist?key=" + playlist.key);
        history.push(
            "/analysis?video=" + timeline.video + "&key=" + timeline.key
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    centered
                >
                    <Tab label="Playlists" />
                    <Tab label="Timelines" />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                <h5>Recent Playlists</h5>
                <TableContainer
                    component={Paper}
                    className={classes.recentTableContainer}
                >
                    <Table aria-label="simple table">
                        {recentPlaylists.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                        }}
                                    />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {recentPlaylists.map((playlist) => (
                                    <TableRow
                                        key={playlist.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="center"
                                            style={
                                                playlist.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {playlist.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="left"
                                            style={
                                                playlist.isOwner && {
                                                    color: "rgb(0, 149, 61)",
                                                }
                                            }
                                        >
                                            {playlist.filePath}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <h5>All Playlists</h5>
                <TableContainer
                    component={Paper}
                    className={classes.allTableContainer}
                >
                    <Table aria-label="simple table">
                        {allPlaylists.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                        }}
                                    />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {allPlaylists.map((playlist) => (
                                    <TableRow
                                        key={playlist.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="center"
                                            style={
                                                playlist.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {playlist.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="left"
                                            style={
                                                playlist.isOwner && {
                                                    color: "rgb(0, 149, 61)",
                                                }
                                            }
                                        >
                                            {playlist.filePath}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <h5>Recent Timelines</h5>
                <TableContainer
                    component={Paper}
                    className={classes.recentTableContainer}
                >
                    <Table aria-label="simple table">
                        {recentTimelines.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                            color: "rgb(0, 149, 61)",
                                        }}
                                    />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {recentTimelines.map((timeline) => (
                                    <TableRow
                                        key={timeline.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            onClick={handleTimelineSelect(
                                                timeline
                                            )}
                                            align="center"
                                            style={
                                                timeline.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {timeline.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={handleTimelineSelect(
                                                timeline
                                            )}
                                            align="left"
                                            style={
                                                timeline.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {timeline.filePath}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <h5>All Timelines</h5>
                <TableContainer
                    component={Paper}
                    className={classes.allTableContainer}
                >
                    <Table aria-label="simple table">
                        {allTimelines.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                        }}
                                    />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {allTimelines.map((timeline) => (
                                    <TableRow
                                        key={timeline.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            onClick={handleTimelineSelect(
                                                timeline
                                            )}
                                            align="center"
                                            style={
                                                timeline.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {timeline.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={handleTimelineSelect(
                                                timeline
                                            )}
                                            align="left"
                                            style={
                                                timeline.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {timeline.filePath}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </TabPanel>
            <ToolbarWithLogo />
        </>
    );
}
