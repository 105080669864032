import React, { useEffect, useContext, useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";

// My Files
import { UserContext } from "../../../context";
import firebase from "../../../firebase";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 0,
    },
    row: {
        padding: 0,
        margin: 0,
    },
    arrowIcon: {
        padding: 0,
    },
    titleButton: {
        paddingLeft: 0,
    },
    deleteIcon: { padding: 0 },
}));

export default function ProjectTitleComponent(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const [projectKey, setProjectKey] = useState("");
    const [text, setText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [textFieldWidth, setTextFieldWidth] = useState();

    useEffect(() => {
        if (currentUserData) {
            if (props.component) {
                setProjectKey(props.projectKey);
                setIsEditing(props.isEditing);
                setText(props.component.text);
            }

            let newWidth = window.innerWidth - 150.0;

            if (newWidth > 1150) {
                newWidth = 1150;
            }

            setTextFieldWidth(newWidth);

            function handleResize() {
                let newWidth = window.innerWidth - 150.0;

                if (newWidth > 1150) {
                    newWidth = 1150;
                }

                setTextFieldWidth(newWidth);
            }

            window.addEventListener("resize", handleResize);
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "text") {
            // console.log(question);
            setText(newValue);
            props.component.text = newValue;

            // Write changes to firebase

            if (projectKey !== "") {
                firebase
                    .database()
                    .ref("projects")
                    .child(projectKey)
                    .child("components")
                    .child(props.component.key)
                    .child("text")
                    .set(newValue);
            }
        }
    }

    function handleDeletePressed() {
        props.delete(props.component);
    }

    function handleMoveUpPressed() {
        props.moveUp(props.component);
    }

    function handleMoveDownPressed() {
        props.moveDown(props.component);
    }

    return (
        <>
            {isEditing ? (
                <>
                    <TextField
                        style={{ minHeight: 35, width: textFieldWidth }}
                        variant="outlined"
                        rows={1}
                        value={text}
                        id="text"
                        onChange={handleTextFieldChange}
                        placeholder="Your Title"
                    />
                    <ButtonGroup orientation="vertical">
                        <IconButton
                            className={classes.arrowIcon}
                            aria-label="moveUp"
                            onClick={handleMoveUpPressed}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                            className={classes.arrowIcon}
                            aria-label="moveDown"
                            onClick={handleMoveDownPressed}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </ButtonGroup>
                    <Tooltip title="Delete">
                        <IconButton
                            className={classes.deleteIcon}
                            aria-label="delete"
                            onClick={handleDeletePressed}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <Button className={classes.titleButton} color="primary">
                    {text}
                </Button>
            )}
        </>
    );
}
