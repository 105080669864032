import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

// My Files
import BackButton from "../../BackButton";
import AvatarGroupDialog from "./AvatarGroupDialog";
import AvatarUploadButton from "../AvatarUploadButton";
import ToolbarWithLogo from "../../ToolbarWithLogo";
import FloatingSaveButton from "../../FloatingSaveButton";
import { makeid } from "../../../utility";
import firebase from "../../../firebase";
import { DatabaseContext, UserContext } from "../../../context";
import Typography from "@material-ui/core/Typography";
import EditGroupDialog from "./EditGroupDialog";
import DialogWithTextInput from "../../DialogWithTextInput";
import { jsonFromArray } from "../../../utility";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import NoPermissionsMessage from "../../NoPermissionsMessage";
import HubFileUsageDialog from "./usage/HubFileUsageDialog";
import HubUserUsageDialog from "./usage/HubUserUsageDialog";
import ArchiveFileListDialog from "./archive/ArchiveFileSelectorDialog";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: 10,
        marginRight: 10,
        paddingBottom: 100,
    },
    userIcon: {
        width: "100%", // Fix IE 11 issue.
        height: "150px",
    },
    topSpacing: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(2),
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    groupChip: {
        marginTop: 7.5,
    },
    buttonProgress: {
        color: "rgb(244, 36, 29)",
        position: "fixed",
        zIndex: 1,
        top: "auto",
        bottom: 70,
        left: "auto",
        right: 10,
    },
    deleteButton: {
        position: "absolute",
        left: "auto",
        right: 10,
    },
    usageButton: { margin: 5, width: 90 },
    archiveButton: { margin: 5 },

    calendarsButton: {
        marginTop: theme.spacing(1),
    },
    noGroupsLabel: { color: "rgb(100, 100, 100)" },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NewHubPage() {
    const location = useLocation();
    const classes = useStyles();
    const history = useHistory();

    const [hub, setHub] = useState({}); // Use this to pass the avatarEditor between components, and also to null the avatar when it's been changed

    const { users, folders, calendars, groups, events, files } =
        useContext(DatabaseContext);

    const [selectedGroup, setSelectedGroup] = useState({
        key: "",
        name: "",
        hub: "",
        users: [],
    });

    const [selectedCalendar, setSelectedCalendar] = useState({
        key: "",
        name: "",
    });

    const [hubGroups, setHubGroups] = useState([]);

    const [hubCalendars, setHubCalendars] = useState([]);

    // const [deletedCalendars, setDeletedCalendars] = useState([]);

    const [key, setKey] = useState("-" + makeid(19));
    const [name, setName] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedManagers, setSelectedManagers] = useState([]);

    const [imageURL, setImageURL] = useState("");
    const [otherManagers, setOtherManagers] = useState([]); // Platform users that are not selected as managers for this hub
    const [otherUsers, setOtherUsers] = useState([]); // Platform sers that are not selected as users for this hub

    const [hasChangedAvatar, setHasChangedAvatar] = useState(false);

    const [isInProgress, setIsInProgress] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusIsVisible, setStatusIsVisible] = useState(false);

    const [editGroupDialogIsOpen, setEditGroupDialogIsOpen] = useState(false);
    const [newGroupDialogIsOpen, setNewGroupDialogIsOpen] = useState(false);
    const [userUsageDialogIsOpen, setUserUsageDialogIsOpen] = useState(false);
    const [fileUsageDialogIsOpen, setFileUsageDialogIsOpen] = useState(false);

    const [newCalendarDialogIsOpen, setNewCalendarDialogIsOpen] =
        useState(false);

    const [editCalendarDialogIsOpen, setEditCalendarDialogIsOpen] =
        useState(false);

    const [
        confirmDeleteCalendarDialogOpen,
        setConfirmDeleteCalendarDialogOpen,
    ] = useState(false);

    const [archiveFileListDialogIsOpen, setArchiveFileListDialogIsOpen] =
        useState(false);

    const [calendarToDelete, setCalendarToDelete] = useState();

    const { currentUserData } = useContext(UserContext);

    const [userCanView, setUserCanView] = useState(false);
    const [userCanArchive, setUserCanArchive] = useState(false);

    const [confirmDeleteHubDialogIsOpen, setConfirmDeleteHubDialogIsOpen] =
        useState(false);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData) {
            const urlParams = new URLSearchParams(location.search);
            const hubKey = urlParams.get("key");

            setKey(hubKey);

            let matchingHub = folders.filter((f) => f.key === hubKey)[0];
            let canView = false;

            if (
                matchingHub &&
                matchingHub.managers &&
                matchingHub.managers.includes(currentUserData.key)
            ) {
                canView = true;
            } else if (currentUserData.role === "System Digital Manager") {
                canView = true;
            }

            // console.log(matchingHub.key);

            let canArchive = false;
            if (
                currentUserData.role === "System Digital Manager" ||
                currentUserData.role === "FAW Analyst" ||
                currentUserData.role === "Club Digital Manager"
            ) {
                canArchive = true;
            }

            if (matchingHub && canView) {
                // Editing a hub
                setUserCanView(true);
                setUserCanArchive(canArchive);

                setHub(matchingHub);

                let selectedUsersArray = [];
                let otherUsersArray = [];

                let selectedManagersArray = [];
                let otherManagersArray = [];

                users.forEach((user) => {
                    user.cannotRemoveAsManager = undefined;
                    user.cannotRemoveAsUser = undefined;

                    // If the user is a manager of this hub
                    if (
                        matchingHub.managers &&
                        matchingHub.managers.includes(user.key)
                    ) {
                        selectedManagersArray.push(user);

                        // If the manager is also in a hub higher than this one in the hierarchy, they can't be removed here.
                        matchingHub.ancestors.forEach((ancestorKey) => {
                            let ancestorFolder = folders.filter(
                                (f) => f.key === ancestorKey
                            )[0];
                            if (
                                ancestorFolder &&
                                ancestorFolder.managers.filter(
                                    (u) => u === user.key
                                ).length > 0
                            ) {
                                user.cannotRemoveAsManager = true;
                            }
                        });
                    } else {
                        if (
                            user.role !== "Player" &&
                            user.role !== "FAW Player" &&
                            user.role !== "Club Player"
                        ) {
                            otherManagersArray.push(user);
                        }
                    }

                    // If the user is a user of this hub
                    if (
                        user.admins.includes(currentUserData.key) ||
                        user.createdBy === currentUserData.key ||
                        currentUserData.role === "System Digital Manager"
                    ) {
                        if (
                            matchingHub.users &&
                            matchingHub.users.includes(user.key)
                        ) {
                            selectedUsersArray.push(user);

                            // If the user is also in a hub higher than this one in the hierarchy, they can't be removed here.
                            matchingHub.ancestors.forEach((ancestorKey) => {
                                let ancestorFolder = folders.filter(
                                    (f) => f.key === ancestorKey
                                )[0];
                                if (
                                    ancestorFolder &&
                                    ancestorFolder.users.filter(
                                        (u) => u === user.key
                                    ).length > 0
                                ) {
                                    user.cannotRemoveAsUser = true;
                                }
                            });
                        } else {
                            otherUsersArray.push(user);
                        }
                    }
                });

                setHubGroups(groups.filter((g) => g.hub === matchingHub.key));

                if (name === "") {
                    setName(matchingHub.name);
                }
                setImageURL(matchingHub.imageURL);
                setHubCalendars(
                    calendars.filter((c) => c.hub === matchingHub.key)
                );
                setSelectedManagers(selectedManagersArray);
                setSelectedUsers(selectedUsersArray);
                setOtherManagers(otherManagersArray);
                setOtherUsers(otherUsersArray);
            }

            return;
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        history,
        currentUserData,
        key,
        folders,
        users,
        groups,
        location.search,
        calendars,
    ]);

    const managerSelectionDidChange = (selected, other) => {
        // First Work out the new managers, add these to the users by default

        let newManagers = [];
        selected.forEach((manager) => {
            if (
                selectedManagers.filter((m) => m.key === manager.key).length ===
                0
            ) {
                newManagers.push(manager);
            }
        });

        if (newManagers.length > 0) {
            let newSelectedUsers = selectedUsers;
            let newOtherUsers = otherUsers;
            newManagers.forEach((manager) => {
                newSelectedUsers.push(manager);

                newOtherUsers = newOtherUsers.filter(
                    (u) => u.key !== manager.key
                );
            });

            userSelectionDidChange(newSelectedUsers, newOtherUsers);
        }

        // Now update the managers

        setSelectedManagers(selected);
        setOtherManagers(other);

        // Update the descendants first

        updateManagersInDescendants(selected);
        updateUserAdmins(selected, selectedUsers);

        // Then update this hub
        let ref = firebase.database().ref("folders");

        ref.child(key).update({
            managers: jsonFromArray(selected),
        });
    };

    const userSelectionDidChange = (selected, other) => {
        setSelectedUsers(selected);
        setOtherUsers(other);

        // Update the descendants first
        updateUsersInDescendants(selected);
        updateUserAdmins(selectedManagers, selected);

        // Then update this hub
        let ref = firebase.database().ref("folders");

        ref.child(key).update({
            users: jsonFromArray(selected),
        });

        let deletedUserKeys = getDeletedUserKeys(selected);

        groups
            .filter((g) => g.hub === key)
            .forEach((group) => {
                if (group.name === "All Users") {
                    // Update the All Users Group for this descendant

                    firebase
                        .database()
                        .ref("groups")
                        .child(group.key)
                        .update({ users: jsonFromArray(selected) });
                } else {
                    // Update the other groups for this descendant, make sure they don't contain deleted users

                    let groupUsers = {};

                    group.users.forEach((user) => {
                        if (!deletedUserKeys.includes(user.key)) {
                            groupUsers[user.key] = true;
                        }
                    });
                    firebase
                        .database()
                        .ref("groups")
                        .child(group.key)
                        .update({ users: groupUsers });
                }
            });
    };

    function updateManagersInDescendants(newManagers) {
        let deletedManagerKeys = getDeletedManagerKeys(newManagers);

        let foldersJSON = {};

        folders.forEach((folder) => {
            if (folder.ancestors.includes(key)) {
                // Remember to get the managers that are already in this folder.managers object, then combine with the new users

                let combinedManagersObject = {};

                newManagers.forEach((user) => {
                    combinedManagersObject[user.key] = true;
                });

                folder.managers.forEach((managerKey) => {
                    if (!deletedManagerKeys.includes(managerKey)) {
                        combinedManagersObject[managerKey] = true;
                    }
                });

                // Update the folder
                // let folderRef = firebase
                //     .database()
                //     .ref("folders")
                //     .child(folder.key);

                // folderRef.update({
                //     managers: combinedManagersObject,
                // });

                foldersJSON[folder.key + "/managers"] = combinedManagersObject;
            }
        });

        let foldersRef = firebase.database().ref("folders");
        foldersRef.update(foldersJSON);

        let filesJSON = {};

        files.forEach((file) => {
            if (file.ancestors.includes(key)) {
                // Remember to get the managers that are already in this folder.managers object, then combine with the new users

                let combinedManagersObject = {};

                newManagers.forEach((user) => {
                    combinedManagersObject[user.key] = true;
                });

                file.managers.forEach((managerKey) => {
                    if (!deletedManagerKeys.includes(managerKey)) {
                        combinedManagersObject[managerKey] = true;
                    }
                });

                // Update the folder
                // let fileRef = firebase.database().ref("files").child(file.key);

                filesJSON[file.key + "/managers"] = combinedManagersObject;

                // fileRef.update({
                //     managers: combinedManagersObject,
                // });
            }
        });

        let filesRef = firebase.database().ref("files");
        filesRef.update(filesJSON);
    }

    function updateUsersInDescendants(newUsers) {
        let deletedUserKeys = getDeletedUserKeys(newUsers);

        let foldersJSON = {};

        folders.forEach((folder) => {
            if (folder.ancestors.includes(key) && folder.hub) {
                // Remember to get the users that are already in this folder.users object, then combine with the new users
                let combinedUsersObject = {};

                newUsers.forEach((user) => {
                    combinedUsersObject[user.key] = true;
                });

                folder.users.forEach((userKey) => {
                    if (!deletedUserKeys.includes(userKey)) {
                        combinedUsersObject[userKey] = true;
                    }
                });

                // Update the folder
                // let folderRef = firebase
                //     .database()
                //     .ref("folders")
                //     .child(folder.key);

                // folderRef.update({
                //     users: combinedUsersObject,
                // });

                foldersJSON[folder.key + "/users"] = combinedUsersObject;

                // Update the groups for this descendant

                groups
                    .filter((g) => g.hub === folder.key)
                    .forEach((group) => {
                        if (group.name === "All Users") {
                            // Update the All Users Group for this descendant

                            firebase
                                .database()
                                .ref("groups")
                                .child(group.key)
                                .update({ users: combinedUsersObject });
                        } else {
                            // Update the other groups for this descendant, make sure they don't contain deleted users

                            let groupUsers = {};

                            group.users.forEach((user) => {
                                if (!deletedUserKeys.includes(user.key)) {
                                    groupUsers[user.key] = true;
                                }
                            });
                            firebase
                                .database()
                                .ref("groups")
                                .child(group.key)
                                .update({ users: groupUsers });
                        }
                    });
            }
        });

        let foldersRef = firebase.database().ref("folders");
        foldersRef.update(foldersJSON);
    }

    function updateUserAdmins(newManagers, newUsers) {
        // All users of the hub need to have the managers of that hub in their user.admins array

        let usersJSON = {};

        newUsers.forEach((user) => {
            let userAdminsObject = {};

            // Preserve the existing contents of user.admins
            user.admins.forEach((adminKey) => {
                userAdminsObject[adminKey] = true;
            });

            // Then add the managers from the current hub
            newManagers.forEach((manager) => {
                userAdminsObject[manager.key] = true;
            });

            // let userRef = firebase.database().ref("users").child(user.key);

            usersJSON[user.key + "/admins"] = userAdminsObject;

            // userRef.update({ admins: userAdminsObject });
        });

        let usersRef = firebase.database().ref("users");
        usersRef.update(usersJSON);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function handleSaveClick() {
        editHub();
    }

    function handleChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "name") {
            setName(newValue);
        }
    }

    function getDeletedUserKeys(newUsers) {
        let deletedUserKeys = [];
        hub.users.forEach((userKey) => {
            if (newUsers.filter((u) => u.key === userKey).length === 0) {
                deletedUserKeys.push(userKey);
            }
        });
        return deletedUserKeys;
    }

    function getDeletedManagerKeys(newManagers) {
        let deletedManagerKeys = [];
        hub.managers.forEach((userKey) => {
            if (newManagers.filter((u) => u.key === userKey).length === 0) {
                deletedManagerKeys.push(userKey);
            }
        });
        return deletedManagerKeys;
    }

    async function editHub() {
        setIsInProgress(true);

        let ref = firebase.database().ref("folders");

        if (hasChangedAvatar) {
            await uploadAvatar()
                .then((downloadURL) => {
                    ref.child(key).update({
                        name: name,
                        imageURL: downloadURL,
                    });

                    setIsInProgress(false);
                    history.goBack();
                })
                .catch((error) => {
                    let errorCode = error.code;
                    let errorMessage = error.message;
                    console.log(errorCode + ":" + errorMessage);
                    setIsInProgress(false);
                    setStatusMessage(error.message);
                    setStatusType("error");
                    setStatusIsVisible(true);
                });
        } else {
            ref.child(key).update({
                name: name,
                imageURL: imageURL,
            });

            setIsInProgress(false);
            history.goBack();
        }
    }

    function uploadAvatar() {
        return new Promise((resolve, reject) => {
            if (hub.avatarEditor) {
                var storage = firebase.storage();

                hub.avatarEditor
                    .getImageScaledToCanvas()
                    .toBlob(function (blob) {
                        // Upload the new file
                        let avatarRef = storage
                            .ref()
                            .child("avatars/hubs/" + key + "/avatar.png");

                        avatarRef
                            .put(blob)
                            .then((snapshot) => {
                                // Upload was successful
                                console.log("Upload Successful");
                                hub.avatar = null;

                                avatarRef.getDownloadURL().then((url) => {
                                    resolve(url);
                                });
                            })
                            .catch((error) => {
                                let errorCode = error.code;
                                let errorMessage = error.message;
                                console.log(errorCode + ":" + errorMessage);
                                setIsInProgress(false);
                                setStatusMessage(error.message);
                                setStatusType("error");
                                setStatusIsVisible(true);
                                reject();
                            });
                    });
            } else {
                resolve("none");
            }
        });
    }

    function didChangeAvatar() {
        setHasChangedAvatar(true);
    }

    function handleCloseEditGroupDialog(newGroupUsers, groupToEdit) {
        if (newGroupUsers && groupToEdit) {
            let groupsRef = firebase.database().ref("groups");

            groupsRef.child(groupToEdit.key).update({
                name: groupToEdit.name,
                users: jsonFromArray(newGroupUsers),
            });

            setEditGroupDialogIsOpen(false);
            setSelectedGroup({
                name: "",
                hub: "",
                users: [],
            });
        }
    }

    function handleUserUsageClick() {
        setUserUsageDialogIsOpen(true);
    }

    function handleFileUsageClick() {
        setFileUsageDialogIsOpen(true);
    }

    const handleEditGroupClick = (group) => () => {
        setSelectedGroup(group);
        setEditGroupDialogIsOpen(true);
    };

    function handleAddGroupButtonPressed() {
        setNewGroupDialogIsOpen(true);
    }

    function handleCloseNewGroupDialog(groupName, okPressed) {
        if (groupName !== "" && groupName !== "All Users" && okPressed) {
            let groupsRef = firebase.database().ref("groups");

            groupsRef.push({
                name: groupName,
                hub: key,
            });
        }

        setNewGroupDialogIsOpen(false);
    }

    function handleCloseGroupDialogAfterDelete(groupToDelete) {
        let groupsRef = firebase.database().ref("groups");

        groupsRef.child(groupToDelete.key).remove();

        setSelectedGroup({
            key: "",
            name: "",
            hub: "",
            users: [],
        });

        setEditGroupDialogIsOpen(false);
    }

    function handleCloseNewCalendarDialog(calendarName, okPressed) {
        if (calendarName !== "" && okPressed) {
            let calRef = firebase.database().ref("calendars");

            calRef.child(makeid(19)).set({ name: calendarName, hub: key });
        }

        setNewCalendarDialogIsOpen(false);
    }

    function handleCloseEditCalendarDialog(calendarName, okPressed) {
        if (calendarName !== "" && okPressed) {
            let ref = firebase.database().ref("calendars");

            ref.child(selectedCalendar.key).update({ name: calendarName });

            // calRef.child(makeid(19)).set({ name: calendarName, hub: key });
        }

        setSelectedCalendar({ key: "", name: "" });
        setEditCalendarDialogIsOpen(false);
    }

    const handleCalendarPressed = (calendar) => () => {
        setSelectedCalendar(calendar);
        setEditCalendarDialogIsOpen(true);
    };

    function handleAddCalendarButtonPressed() {
        setNewCalendarDialogIsOpen(true);
    }

    const handleCalendarChipDeletePressed = (calendar) => () => {
        setCalendarToDelete(calendar);
        setConfirmDeleteCalendarDialogOpen(true);
    };

    function handleDeleteButtonPressed() {
        setConfirmDeleteHubDialogIsOpen(true);
    }

    function handleCloseOnDeleteHubDialog(result) {
        if (result === true && currentUserData) {
            if (
                hub.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                let childFolders = folders.filter((f) =>
                    f.ancestors.includes(hub.key)
                ).length;

                let childFiles = files.filter((f) =>
                    f.ancestors.includes(hub.key)
                ).length;

                if (childFolders + childFiles > 0) {
                    setStatusType("error");
                    setStatusMessage(
                        "This hub contains files and/or folders, you need to delete them before deleting the hub"
                    );
                    setStatusIsVisible(true);
                } else {
                    deleteHub();
                }
            }
        }

        setConfirmDeleteHubDialogIsOpen(false);
    }

    function handleCloseUserUsageDialog() {
        setUserUsageDialogIsOpen(false);
    }

    function handleCloseFileUsageDialog() {
        setFileUsageDialogIsOpen(false);
    }

    function deleteHub() {
        console.log("DELETE: " + hub.key);

        // Delete Avatar from storage
        var storage = firebase.storage();
        let avatarRef = storage
            .ref()
            .child("avatars/hubs/" + key + "/avatar.png");

        avatarRef.delete();

        // Delete Events

        // Delete Calendars and events from database
        calendars.forEach((calendar) => {
            if (calendar.hub === hub.key) {
                // Delete the events for this calendar
                calendar.events.forEach((event) => {
                    let eventRef = firebase
                        .database()
                        .ref("events")
                        .child(event.key);

                    eventRef.remove();
                });

                // Delete the calendar
                let calendarRef = firebase
                    .database()
                    .ref("calendars")
                    .child(calendar.key);
                calendarRef.remove();
            }
        });

        // Delete groups from database
        groups.forEach((group) => {
            if (group.hub === hub.key) {
                let groupRef = firebase
                    .database()
                    .ref("groups")
                    .child(group.key);
                groupRef.remove();
            }
        });

        // Delete hub from database
        let hubRef = firebase.database().ref("folders").child(hub.key);
        hubRef.remove();

        history.goBack();
    }

    function handleCloseOnDeleteCalendarDialog(result) {
        if (result === true && currentUserData) {
            deleteCalendar(calendarToDelete);
        }

        setCalendarToDelete(null);
        setConfirmDeleteCalendarDialogOpen(false);
    }

    function deleteCalendar(calendar) {
        // Delete calendar

        // Delete from file.events
        files.forEach((file) => {
            var didChange = false;
            var eventsObject = {};

            file.events.forEach((event) => {
                let eventObject = events.filter((e) => e.key === event.key)[0];
                if (eventObject) {
                    if (eventObject.calendar === calendar.key) {
                        didChange = true;
                    } else {
                        eventsObject[event.key] = true;
                    }
                } else {
                    eventsObject[event.key] = true;
                }
            });

            if (didChange) {
                var ref = firebase.database().ref("files").child(file.key);

                ref.update({ events: eventsObject });
            }
        });

        // Delete events

        events.forEach((event) => {
            if (event.calendar === calendar.key) {
                var ref = firebase.database().ref("events").child(event.key);

                ref.remove();
            }
        });

        // Delete calendar

        var calendarRef = firebase
            .database()
            .ref("calendars")
            .child(calendar.key);

        calendarRef.remove();
    }

    function shouldShowSaveButton() {
        if (hasChangedAvatar) {
            return true;
        }

        if (hub.name !== name) {
            return true;
        }

        return false;
    }

    function handleOpenArchiveDialogClick() {
        setArchiveFileListDialogIsOpen(true);
    }

    function handleCloseArchiveFileListDialog() {
        setArchiveFileListDialogIsOpen(false);
    }

    return (
        <>
            <BackButton />
            <IconButton
                className={classes.deleteButton}
                onClick={handleDeleteButtonPressed}
            >
                <DeleteIcon />
            </IconButton>
            {userCanView && (
                <>
                    <div className={classes.form}>
                        <AvatarUploadButton
                            recipient={hub}
                            didChangeAvatar={didChangeAvatar}
                            path="hubs"
                        />
                        <TextField
                            className={classes.topSpacing}
                            id="name"
                            label="Hub"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={handleChange}
                        />
                        <AvatarGroupDialog
                            title="Managers"
                            selectedUsers={selectedManagers}
                            otherUsers={otherManagers}
                            onChange={managerSelectionDidChange}
                        />
                        <AvatarGroupDialog
                            title="Users"
                            selectedUsers={selectedUsers}
                            otherUsers={otherUsers}
                            onChange={userSelectionDidChange}
                        />
                        <Typography
                            className={classes.topSpacing}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Groups
                        </Typography>
                        {hubGroups.length === 1 && (
                            <span className={classes.noGroupsLabel}>
                                This hub doesn't have any groups
                            </span>
                        )}
                        {hubGroups
                            .filter((g) => !g.toDelete)
                            .filter((g) => g.name !== "All Users")
                            .map((g) => (
                                <Chip
                                    className={classes.groupChip}
                                    key={g.key}
                                    label={
                                        g.users.length === 1
                                            ? g.name +
                                              " (" +
                                              g.users.length +
                                              " User)"
                                            : g.name +
                                              " (" +
                                              g.users.length +
                                              " Users)"
                                    }
                                    onClick={
                                        g.name !== "All Users" &&
                                        handleEditGroupClick(g)
                                    }
                                    // onDelete={handleDeleteGroupPress(g)}
                                />
                            ))}
                        <Tooltip title="New Group">
                            <IconButton onClick={handleAddGroupButtonPressed}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            className={classes.topSpacing}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Calendars
                        </Typography>
                        {hubCalendars.length === 0 && (
                            <span className={classes.noGroupsLabel}>
                                This hub doesn't have any Calendars
                            </span>
                        )}
                        {hubCalendars.map((c) => (
                            <Chip
                                className={classes.groupChip}
                                key={c.key}
                                label={c.name}
                                onDelete={handleCalendarChipDeletePressed(c)}
                                onClick={handleCalendarPressed(c)}
                            />
                        ))}
                        <Tooltip title="New Calendar">
                            <IconButton
                                onClick={handleAddCalendarButtonPressed}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            className={classes.topSpacing}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Usage Data
                        </Typography>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleUserUsageClick}
                                className={classes.usageButton}
                            >
                                User Data
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleFileUsageClick}
                                className={classes.usageButton}
                            >
                                File Data
                            </Button>
                        </div>
                        {userCanArchive && (
                            <Typography
                                className={classes.topSpacing}
                                variant="subtitle1"
                                gutterBottom
                            >
                                Archive
                            </Typography>
                        )}
                        {userCanArchive && (
                            <div>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenArchiveDialogClick}
                                    className={classes.archiveButton}
                                >
                                    Archive Files
                                </Button>
                            </div>
                        )}
                    </div>
                    {shouldShowSaveButton() && (
                        <FloatingSaveButton onClick={handleSaveClick} />
                    )}

                    <ToolbarWithLogo />
                    {isInProgress && (
                        <CircularProgress
                            size={56}
                            className={classes.buttonProgress}
                        />
                    )}
                    <div>
                        <Snackbar
                            open={statusIsVisible}
                            autoHideDuration={6000}
                            onClose={handleStatusClose}
                        >
                            <Alert
                                onClose={handleStatusClose}
                                severity={statusType}
                            >
                                {statusMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                    <EditGroupDialog
                        open={editGroupDialogIsOpen}
                        onClose={handleCloseEditGroupDialog}
                        onDelete={handleCloseGroupDialogAfterDelete}
                        hubUsers={selectedUsers}
                        group={selectedGroup}
                        hub={hub}
                    />
                    <DialogWithTextInput
                        open={newGroupDialogIsOpen}
                        onClose={handleCloseNewGroupDialog}
                        title={"New Group"}
                        message={"Enter a name for the new group."}
                    />
                    <DialogWithTextInput
                        open={newCalendarDialogIsOpen}
                        onClose={handleCloseNewCalendarDialog}
                        title={"New Calendar"}
                        message={"Enter a name for the new Calendar."}
                    />
                    <DialogWithTextInput
                        open={editCalendarDialogIsOpen}
                        onClose={handleCloseEditCalendarDialog}
                        title={"Edit Calendar"}
                        message={"Enter a name for the Calendar."}
                        initialValue={selectedCalendar.name}
                    />
                    {confirmDeleteCalendarDialogOpen && (
                        <ConfirmDeleteDialog
                            title="Delete Calendar"
                            message="Are you sure you want to delete this calendar and all it's events?"
                            open={confirmDeleteCalendarDialogOpen}
                            onClose={handleCloseOnDeleteCalendarDialog}
                        />
                    )}
                    {confirmDeleteCalendarDialogOpen && (
                        <ConfirmDeleteDialog
                            title="Delete Calendar"
                            message="Are you sure you want to delete this calendar and all it's events?"
                            open={confirmDeleteCalendarDialogOpen}
                            onClose={handleCloseOnDeleteCalendarDialog}
                        />
                    )}
                    {confirmDeleteHubDialogIsOpen && (
                        <ConfirmDeleteDialog
                            title="Delete Hub"
                            message="Are you sure you want to delete this hub?"
                            open={confirmDeleteHubDialogIsOpen}
                            onClose={handleCloseOnDeleteHubDialog}
                        />
                    )}
                    {userUsageDialogIsOpen && (
                        <HubUserUsageDialog
                            open={userUsageDialogIsOpen}
                            onClose={handleCloseUserUsageDialog}
                            hub={hub}
                        />
                    )}
                    {fileUsageDialogIsOpen && (
                        <HubFileUsageDialog
                            open={fileUsageDialogIsOpen}
                            onClose={handleCloseFileUsageDialog}
                            hub={hub}
                        />
                    )}
                    {archiveFileListDialogIsOpen && (
                        <ArchiveFileListDialog
                            open={archiveFileListDialogIsOpen}
                            onClose={handleCloseArchiveFileListDialog}
                            hub={hub}
                        />
                    )}
                </>
            )}
            {!userCanView && <NoPermissionsMessage />}
        </>
    );
}
