import { useState, useEffect, useReducer, useContext } from "react";
import JSZip from "jszip";
import FileDownload from "js-file-download";
import axios from "axios";

// Material UI
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

// My Files
import { DatabaseContext } from "../../../../context";
import {
    doesFileHaveDuplicate,
    bunnyVideoLibraryID,
} from "../../../../utility";
import firebase from "../../../../firebase";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: 15,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        overflow: "scroll",
    },
    // tableRow: { maxHeight: 10 },
    progress: { maxHeight: 35, maxWidth: 35 },
}));

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function ArchiveProgressDialog(props) {
    const classes = useStyles();

    const {
        files,
        comments,
        playlists,
        timelines,
        notifications,
        events,
        users,
        getTimelines,
        getPlaylists,
    } = useContext(DatabaseContext);

    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");

    const [willDelete, setWillDelete] = useState(false);
    const [filesToDownload, setFilesToDownload] = useState([]);

    const [hasStarted, setHasStarted] = useState(false);
    const [hasFinished, setHasFinished] = useState(false);

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        if (props.files) {
            if (timelines.length === 0) {
                getTimelines(true);
            }

            if (playlists.length === 0) {
                getPlaylists(true);
            }

            var operation = "download";
            if (props.delete) {
                operation = "download and delete";
                setWillDelete(true);
            } else {
                setWillDelete(false);
            }

            if (props.files) {
                setFilesToDownload(props.files);
            }

            setTitle(operation);
            if (props.files.length === 1) {
                setMessage(
                    "You are about to " +
                        operation +
                        " 1 file, do you want to continue?"
                );
            } else {
                setMessage(
                    "You are about to " +
                        operation +
                        " " +
                        props.files.length +
                        " files, do you want to continue?"
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    function onClose(event, reason) {
        if (reason === "backdropClick") {
            return;
        }
    }
    function handleCancel() {
        props.close("cancel");
        setHasStarted(false);
        setHasFinished(false);
    }

    function handleDismiss() {
        props.close("finished");
        setHasStarted(false);
        setHasFinished(false);
    }

    function handleConfirm() {
        filesToDownload.forEach((file) => {
            file.downloadProgress = 0;
            file.isDownloading = true;
        });
        setHasStarted(true);
        setHasFinished(false);
        startDownload();
    }

    async function startDownload() {
        console.clear();
        console.log("Downloading Files");
        const zip = new JSZip();

        let folderName = "Nodiant Archive";
        let zipFolder = zip.folder(folderName);

        for (let index = 0; index < filesToDownload.length; index++) {
            const file = filesToDownload[index];

            let filename = file.filename;
            // let folderName =
            //     filename.substring(0, filename.lastIndexOf(".")) || filename;

            let fileFolder = zipFolder.folder(filename);

            if (file.type.includes("video")) {
                await downloadVideo(file, fileFolder).then((r) => {
                    exportCommentsToXML(file, fileFolder);
                    exportPlaylistsToXML(file, fileFolder);
                    exportTimelinesToXML(file, fileFolder);

                    console.log("Download Finished: " + file.filename);
                    if (
                        filesToDownload.filter((f) => f.isDownloading)
                            .length === 0
                    ) {
                        zip.generateAsync({ type: "blob" }).then(function (
                            content
                        ) {
                            FileDownload(content, folderName + ".zip");

                            if (willDelete) {
                                if (doesFileHaveDuplicate(file, files)) {
                                    deleteVideoFromFirebase(file);
                                } else {
                                    deleteVideoFromFirebase(file);
                                    deleteVideoFromBunny(file);
                                }
                            }
                        });

                        setHasFinished(true);
                    }
                });
            } else {
                await downloadFile(file, fileFolder).then((r) => {
                    exportCommentsToXML(file, fileFolder);

                    console.log("Download Finished: " + file.filename);

                    if (
                        filesToDownload.filter((f) => f.isDownloading)
                            .length === 0
                    ) {
                        zip.generateAsync({ type: "blob" }).then(function (
                            content
                        ) {
                            FileDownload(content, folderName + ".zip");

                            if (willDelete) {
                                deleteFile(file);
                            }
                        });

                        setHasFinished(true);
                    }
                });
            }
        }
    }

    function downloadVideo(file, zip) {
        return new Promise((resolve, reject) => {
            file.downloadProgress = 0;

            let fileKey = file.key;

            if (file.original) {
                fileKey = file.original;
            }

            let url =
                "https://vz-db0e7359-d70.b-cdn.net/" + fileKey + "/original";

            axios({
                url: url,
                method: "GET",
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.floor(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );

                    file.downloadProgress = percentCompleted;

                    if (file.downloadProgress === 100) {
                        file.isDownloading = false;
                    }

                    forceUpdate();
                },
            }).then((response) => {
                zip.file(file.filename, response.data);
                file.isDownloading = false;
                resolve();
            });
        });
    }

    function downloadFile(file, zip) {
        return new Promise((resolve, reject) => {
            // All files other than videos
            file.downloadProgress = 0;

            axios({
                url: file.url,
                method: "GET",
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.floor(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );

                    file.downloadProgress = percentCompleted;

                    if (file.downloadProgress === 100) {
                        file.isDownloading = false;
                    }

                    forceUpdate();
                },
            }).then((response) => {
                zip.file(file.filename, response.data);
                file.isDownloading = false;
                resolve();
            });
        });
    }

    // All Files apart from videos
    function deleteFile(file) {
        console.log("Deleting File: " + file.filename);
        // Delete the comments
        comments.forEach((comment) => {
            if (comment.file === file.key) {
                let commentRef = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);
                commentRef.update({ deleted: true });
            }
        });

        // Delete any notifications for the file
        notifications.forEach((notification) => {
            if (notification.file === file.key) {
                let notificationRef = firebase
                    .database()
                    .ref("notifications")
                    .child(notification.key);
                notificationRef.remove();
            }
        });

        // // Delete the file in any events

        events.forEach((event) => {
            let filesToKeepArray = [];
            let needsChange = false;
            event.files.forEach((aFile) => {
                if (aFile.key === file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile.key);
                }
            });

            if (needsChange) {
                let eventRef = firebase
                    .database()
                    .ref("events")
                    .child(event.key);

                // Change remaining files to JSON
                let filesObject = {};

                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });

                eventRef.update({ files: filesObject });
            }
        });

        // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
        users.forEach((user) => {
            let filesToKeepArray = [];
            let needsChange = false;
            user.files.forEach((aFile) => {
                if (aFile === file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile);
                }
            });

            if (needsChange) {
                let userRef = firebase.database().ref("users").child(user.key);

                // Change remaining files to JSON
                let filesObject = {};

                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });

                userRef.update({ files: filesObject });
            }
        });

        if (!doesFileHaveDuplicate(file, files)) {
            // Delete the file from firebase storage
            console.log("Deleting From Storage");

            var fileKey = file.key;
            if (file.original) {
                // If the file has an original property, this is the key of the original file,
                // and therefore you need to use this key to remove the file from storage
                fileKey = file.original;
            }

            let storageRef = firebase
                .storage()
                .ref("files")
                .child(fileKey)
                .child(file.filename);
            storageRef.delete();

            // Delete the thumbnail from firebase storage
            let thumbnailStorageRef = firebase
                .storage()
                .ref("files")
                .child(fileKey)
                .child("thumbnail");
            thumbnailStorageRef.delete();
        }

        // Delete the file
        let fileRef = firebase.database().ref("files").child(file.key);

        fileRef.remove();
    }

    function deleteVideoFromFirebase(file) {
        // Delete the comments
        comments.forEach((comment) => {
            if (comment.file === file.key) {
                let commentRef = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);
                commentRef.update({ deleted: true });
            }
        });
        // Delete any notifications for the file
        notifications.forEach((notification) => {
            if (notification.file === file.key) {
                let notificationRef = firebase
                    .database()
                    .ref("notifications")
                    .child(notification.key);
                notificationRef.remove();
            }
        });
        // Delete the file in any events
        events.forEach((event) => {
            let filesToKeepArray = [];
            let needsChange = false;
            event.files.forEach((aFile) => {
                if (aFile.key === file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile.key);
                }
            });
            if (needsChange) {
                let eventRef = firebase
                    .database()
                    .ref("events")
                    .child(event.key);
                // Change remaining files to JSON
                let filesObject = {};
                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });
                eventRef.update({ files: filesObject });
            }
        });

        // Delete playlists that use the video
        playlists.forEach((playlist) => {
            if (playlist.video === file.key) {
                let playlistsRef = firebase
                    .database()
                    .ref("playlists")
                    .child(playlist.key);
                playlistsRef.remove();

                let playlistInfoRef = firebase
                    .database()
                    .ref("playlistinfo")
                    .child(playlist.key);

                playlistInfoRef.remove();
            }
        });

        // Delete timelines that use the video
        timelines.forEach((timeline) => {
            if (timeline.video === file.key) {
                let timelinesRef = firebase
                    .database()
                    .ref("timelines")
                    .child(timeline.key);
                timelinesRef.remove();

                let timelinesInfoRef = firebase
                    .database()
                    .ref("timelineinfo")
                    .child(timeline.key);

                timelinesInfoRef.remove();
            }
        });

        // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
        users.forEach((user) => {
            let filesToKeepArray = [];
            let needsChange = false;
            user.files.forEach((aFile) => {
                if (aFile === file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile);
                }
            });
            if (needsChange) {
                let userRef = firebase.database().ref("users").child(user.key);
                // Change remaining files to JSON
                let filesObject = {};
                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });
                userRef.update({ files: filesObject });
            }
        });

        // Delete the file
        let fileRef = firebase.database().ref("files").child(file.key);
        fileRef.remove();
    }

    function deleteVideoFromBunny(file) {
        return new Promise((resolve, reject) => {
            var getBunnyStreamAPIKey = firebase
                .functions()
                .httpsCallable("getBunnyStreamAPIKey");

            getBunnyStreamAPIKey()
                .then((result) => {
                    const apiKey = result.data.key;
                    let fileKey = file.key;

                    if (file.original) {
                        fileKey = file.original;
                    }

                    const url =
                        "https://video.bunnycdn.com/library/" +
                        bunnyVideoLibraryID +
                        "/videos/" +
                        fileKey;
                    const options = {
                        method: "DELETE",
                        headers: { AccessKey: apiKey },
                    };

                    fetch(url, options)
                        .then((response) => response.json())
                        .then((response) => {
                            console.log(response);
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            reject();
                        });
                })
                .catch((error) => {
                    // Getting the Error details.
                    console.log(error.code);
                    console.log(error.message);
                    console.log(error.details);
                    reject();
                });
        });
    }

    // function exportCommentsToCSV(file, zip) {
    //     const data = [];
    //     const filename = file.filename + "_comments";
    //     // const exportType = exportFromJSON.types.xml;
    //     const exportType = exportFromJSON.types.csv;

    //     let fileComments = comments.filter((c) => c.file === file.key);

    //     fileComments.forEach((comment) => {
    //         console.log(comment);
    //         data.push({
    //             user: comment.author,
    //             userID: comment.user,
    //             date: comment.date,
    //             file: comment.file,
    //             filename: file.filename,
    //             comment: comment.text,
    //             key: comment.key,
    //         });
    //     });

    //     zip.file(filename, data);

    //     // exportFromJSON({ data, fileName, exportType });
    // }

    function exportCommentsToXML(file, zip) {
        var XML = require("xml");

        let fileComments = comments.filter((c) => c.file === file.key);
        var commentsArray = [];

        fileComments.forEach((comment) => {
            var commentObject = [
                { user: comment.author },
                { userID: comment.user },
                { date: comment.date },
                { file: comment.file },
                { filename: file.filename },
                { comment: comment.text },
                { key: comment.key },
            ];

            commentsArray.push({ comment: commentObject });
        });

        var xmlFile = [
            {
                file: [{ comments: commentsArray }],
            },
        ];

        let output = XML(xmlFile, true);

        const blob = new Blob([output], { type: "text/xml" });

        zip.file("comments.xml", blob);
    }

    function exportTimelinesToXML(file, zip) {
        var XML = require("xml");

        let timelinesFolder = zip.folder("timelines");

        let fileTimelines = timelines.filter((c) => c.video === file.key);
        fileTimelines.forEach((timeline) => {
            // exportTimelineToXML(
            //     timeline.instances,
            //     file.filename + "_Timelines_" + timeline.name
            // );
            var uniqueCodes = [];

            var instancesArray = [];

            // Add the instances
            timeline.instances
                .sort((a, b) => {
                    if (a.index > b.index) return 1;
                    if (a.index < b.index) return -1;
                    return 0;
                })
                .forEach((row) => {
                    // console.log(row);

                    if (!uniqueCodes.includes(row.name)) {
                        uniqueCodes.push(row.name);
                    }

                    var instance = [
                        { ID: row.index + 1 },
                        { start: row.inTime },
                        { end: row.outTime },
                        { code: row.name },
                    ];

                    if (row.stars > 0) {
                        instance.push({ stars: row.stars });
                    }

                    row.labels.forEach((label) => {
                        let labelArray = [{ text: label.text }];

                        if (label.group) {
                            labelArray.push({ group: label.group });
                        }

                        if (label.start) {
                            labelArray.push({ start: label.start });
                        }
                        instance.push({
                            label: labelArray,
                        });
                    });

                    row.notes.forEach((note) => {
                        instance.push({ free_text: note });
                    });

                    instancesArray.push({
                        instance: instance,
                    });
                });

            // Add the Rows at the end of the file

            var rowsArray = [];
            uniqueCodes.forEach((code) => {
                var row = [
                    { code: code },
                    { R: 0 },
                    { G: 39321 },
                    { B: 13107 },
                    { red: 0.0 },
                    { green: 0.6 },
                    { blue: 0.2 },
                    { fontRed: 1.0 },
                    { fontGreen: 1.0 },
                    { fontBlue: 1.0 },
                ];

                rowsArray.push({ row: row });
            });

            var xmlFile = [
                {
                    file: [
                        { ALL_INSTANCES: instancesArray },
                        { ROWS: rowsArray },
                    ],
                },
            ];

            // console.log(XML(example3));
            //result: <toys><toy>Transformers</toy><toy>GI Joe</toy><toy>He-man</toy></toys>
            // console.log(XML(example3, true));
            let output = XML(xmlFile, true);

            // console.log(output);

            const blob = new Blob([output], { type: "text/xml" });

            let filename = timeline.name + ".xml";

            timelinesFolder.file(filename, blob);
        });
    }

    function exportPlaylistsToXML(file, zip) {
        var XML = require("xml");

        // console.clear();
        let playlistsFolder = zip.folder("playlists");

        let filePlaylists = playlists.filter((c) => c.video === file.key);

        filePlaylists.forEach((playlist) => {
            // console.log(playlist.name);
            var clipsArray = [];

            playlist.clips.forEach((clip) => {
                clipsArray.push({
                    clip: [
                        { name: clip.name },
                        { note: clip.note },
                        { index: clip.index },
                        { key: clip.key },
                        { inTime: clip.inTime },
                        { outTime: clip.outTime },
                        { stars: clip.stars },
                    ],
                });
            });

            var xmlFile = [
                {
                    file: [
                        { name: playlist.name },
                        { owner: playlist.owner },
                        { key: playlist.key },
                        { video: playlist.video },
                        { videoname: file.filename },
                        { clips: clipsArray },
                    ],
                },
            ];

            let output = XML(xmlFile, true);

            // console.log(output);
            // return;

            // Download the file
            const blob = new Blob([output], { type: "text/xml" });

            let filename = playlist.name + ".xml";

            playlistsFolder.file(filename, blob);
        });
    }

    return (
        <>
            <Dialog
                onClose={onClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>

                {!hasStarted && (
                    <DialogContent>
                        <DialogContentText>{message}</DialogContentText>
                    </DialogContent>
                )}

                {hasStarted ? (
                    <TableContainer className={classes.tableContainer}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {filesToDownload.map((file) => (
                                    <TableRow
                                        key={file.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"

                                            // style={{
                                            //     borderRight:
                                            //         "1px solid rgb(200,200,200)",
                                            // }}
                                        >
                                            {file.filename}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                borderRight:
                                                    "1px solid rgb(200,200,200)",
                                            }}
                                        >
                                            {file.downloadProgress === 100 && (
                                                <Typography variant="body2">
                                                    Finished
                                                </Typography>
                                            )}
                                            {file.isDownloading && (
                                                <CircularProgressWithLabel
                                                    edge="end"
                                                    variant="determinate"
                                                    value={
                                                        file.downloadProgress
                                                    }
                                                    className={classes.progress}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                )}
                {hasFinished && (
                    <DialogContent align="center">
                        <DialogContentText align="center">
                            Archive Finished
                        </DialogContentText>
                        <Button onClick={handleDismiss} color="primary">
                            Dismiss
                        </Button>
                        <DialogActions></DialogActions>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}
