import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Calendar from "react-calendar";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import firebase from "../../firebase";
import DialogWithTextInput from "../DialogWithTextInput";
import { makeid } from "../../utility";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 75,
    },
    newSurveyButton: { marginBottom: 15 },

    addButton: {
        color: "rgb(0, 149, 61)",
    },
    searchResultsTableContainer: {
        marginTop: 15,
        marginBottom: 15,
        maxHeight: 264,
        overflow: "scroll",
    },
    mySurveysTableContainer: {
        marginTop: 15,
        marginBottom: 15,
        maxHeight: 264,
        overflow: "scroll",
    },
    sharedSurveysTableContainer: {
        marginTop: 15,
        marginBottom: 50,
        overflow: "scroll",
    },
    userNameSearchTextField: {
        marginTop: 10,
    },
    fromCalendar: {
        maxWidth: 300,
    },
    toCalendar: {
        maxWidth: 300,
    },
    searchButton: {
        marginTop: 15,
        // position: "absolute",
        // backgroundColor: "red",
        // right: 75,
        // top: 200,
        // left: "auto",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    paper: { padding: 15 },
}));

export default function SurveySelectPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const history = useHistory();

    const { surveyInfo, users, groups } = useContext(DatabaseContext);

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [availableSurveys, setAvailableSurveys] = useState([]); // mySurveys + surveysSharedWithMe
    const [mySurveys, setMySurveys] = useState([]); // Created by me
    const [surveysSharedWithMe, setSurveysSharedWithMe] = useState([]);

    const [searchedSurveys, setSearchedSurveys] = useState([]); // Search Results

    const [surveyNameDialogOpen, setSurveyNameDialogOpen] = useState(false);

    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [surveyNameSearch, setSurveyNameSearch] = useState("");
    const [userNameSearch, setUserNameSearch] = useState("");

    const [canCreateSurveys, setCanCreateSurveys] = useState(false);

    useEffect(() => {
        if (currentUserData) {
            let mySurveysArray = [];
            let sharedSurveysArray = [];
            let availableSurveysArray = [];

            if (currentUserData.permissions.createSurveys) {
                setCanCreateSurveys(true);
            }

            surveyInfo.forEach((survey) => {
                // Users Own Surveys
                if (survey.createdBy === currentUserData.key) {
                    survey.canEdit = true;
                    survey.canViewResults = true;
                    survey.canTake = true;

                    mySurveysArray.push(survey);
                    availableSurveysArray.push(survey);
                }

                // Surveys where user has been added individually
                let matchingUser = survey.users.filter(
                    (u) => u.key === currentUserData.key
                )[0];

                if (matchingUser) {
                    // Check it hasn't already been added above
                    if (!survey.canTake) {
                        survey.canEdit = matchingUser.canEdit;
                        survey.canViewResults = matchingUser.canViewResults;
                        survey.canTake = true;

                        sharedSurveysArray.push(survey);
                        availableSurveysArray.push(survey);
                    }
                }

                // Surveys where user is part of a group that has access
                survey.groups.forEach((surveyGroup) => {
                    let matchingGroup = groups.filter(
                        (g) => g.key === surveyGroup.key
                    )[0];
                    if (matchingGroup) {
                        if (
                            matchingGroup.users.filter(
                                (u) => u.key === currentUserData.key
                            ).length > 0
                        ) {
                            // Check it hasn't already been added above
                            if (!survey.canTake) {
                                survey.canEdit = surveyGroup.canEdit;
                                survey.canViewResults =
                                    surveyGroup.canViewResults;
                                survey.canTake = true;

                                sharedSurveysArray.push(survey);
                                availableSurveysArray.push(survey);
                            }
                        }
                    }
                });
            });

            setMySurveys(mySurveysArray);
            setSurveysSharedWithMe(sharedSurveysArray);
            setAvailableSurveys(availableSurveysArray);
        }

        // eslint-disable-next-line
    }, [currentUserData, surveyInfo, history]);

    const handleSurveyQuestionsSelect = (survey) => () => {
        history.push("/surveys/e?key=" + survey.key);
    };

    const handleSurveyAnswersSelect = (survey) => () => {
        history.push("/surveys/a?key=" + survey.key);
    };

    const handleTakeSurveySelect = (survey) => () => {
        history.push("/surveys/r?key=" + survey.key);
    };

    function handleNewSurveyClicked() {
        setSurveyNameDialogOpen(true);
    }

    function handleCloseSurveyNameDialog(newName, okPressed) {
        if (okPressed) {
            if (newName !== "") {
                // Create the Survey Info object & add to Firebase
                let infoRef = firebase.database().ref("surveyinfo");

                let key = makeid(15);

                let infoObject = {
                    createdBy: currentUserData.key,
                    createdOn: dayjs().toString(),
                    lastUpdated: dayjs().toString(),
                    intro: "",
                    title: newName,
                    anonymous: true,
                };

                infoRef.child(key).set(infoObject);

                setSurveyNameDialogOpen(false);

                history.push("/surveys/e?key=" + key);
            }
        } else {
            setSurveyNameDialogOpen(false);
        }
    }

    function onFromDateChange(nextDate) {
        setFromDate(nextDate);
    }

    function onToDateChange(nextDate) {
        setToDate(nextDate);
    }

    function handleSearchPressed() {
        var searchArray = [];
        console.clear();
        availableSurveys.forEach((survey) => {
            console.log(survey);
            var shouldExclude = false;

            // Survey Name Search
            if (surveyNameSearch !== "") {
                if (
                    !survey.title
                        .toLowerCase()
                        .includes(surveyNameSearch.toLowerCase())
                ) {
                    shouldExclude = true;
                }
            }

            // Created By User Name Search
            if (userNameSearch !== "") {
                var matchingUser = users.filter(
                    (u) => u.key === survey.createdBy
                )[0];
                if (matchingUser) {
                    if (
                        !matchingUser.fullName
                            .toLowerCase()
                            .includes(userNameSearch.toLowerCase())
                    ) {
                        shouldExclude = true;
                    }
                }
            }

            // From Date Search
            if (fromDate !== undefined && fromDate !== undefined) {
                if (dayjs(survey.lastUpdated).isBefore(fromDate)) {
                    shouldExclude = true;
                }

                if (dayjs(survey.lastUpdated).isAfter(toDate)) {
                    shouldExclude = true;
                }
            } else if (fromDate !== undefined) {
                if (dayjs(survey.lastUpdated).isBefore(fromDate)) {
                    shouldExclude = true;
                }
            } else if (toDate !== undefined) {
                if (dayjs(survey.lastUpdated).isAfter(toDate)) {
                    shouldExclude = true;
                }
            }

            // To Date Search

            if (!shouldExclude) {
                searchArray.push(survey);
            }
        });

        setSearchedSurveys(searchArray);
        setSearchResultsVisible(true);
    }

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "surveyNameSearch") {
            setSurveyNameSearch(newValue);
        } else if (event.target.id === "userNameSearch") {
            setUserNameSearch(newValue);
        }
    }

    return (
        <>
            <Container className={classes.pageContainer}>
                {canCreateSurveys && (
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.newSurveyButton}
                        onClick={handleNewSurveyClicked}
                    >
                        New Survey
                    </Button>
                )}
                <h5>Search</h5>
                <Paper className={classes.paper} variant="outlined">
                    <Row>
                        {/* <IconButton
                            onClick={HandleFromDatePressed}
                            component="span"
                        >
                            <EventNoteIcon />
                        </IconButton> */}
                        <Col>
                            <TextField
                                fullWidth
                                value={surveyNameSearch}
                                id="surveyNameSearch"
                                onChange={handleTextFieldChange}
                                label="Survey Name"
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                className={classes.userNameSearchTextField}
                                value={userNameSearch}
                                id="userNameSearch"
                                onChange={handleTextFieldChange}
                                label="From User"
                                variant="outlined"
                            />
                        </Col>
                        <Col>
                            <h6>From</h6>
                            <Calendar
                                className={classes.fromCalendar}
                                onChange={onFromDateChange}
                                // onClickDay={onClickDay}
                                // showWeekNumbers
                                defaultView="month"
                                value={fromDate}
                                // tileContent={tileContent}
                            />
                        </Col>
                        <Col>
                            <h6>To</h6>
                            <Calendar
                                className={classes.toCalendar}
                                onChange={onToDateChange}
                                // onClickDay={onClickDay}
                                // showWeekNumbers
                                defaultView="month"
                                value={toDate}
                                // tileContent={tileContent}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col lg={11} md={11} sm={11}></Col> */}
                        <Col>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.searchButton}
                                onClick={handleSearchPressed}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Paper>

                {searchResultsVisible && (
                    <>
                        <div className={classes.dividerDiv} />
                        <h5>Search Results</h5>
                        <Paper className={classes.paper} variant="outlined">
                            <TableContainer
                                component={Paper}
                                // className={classes.mySurveysTableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {searchedSurveys.map((s) => (
                                            <TableRow key={s.key}>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {s.title}
                                                </TableCell>
                                                {s.canEdit ? (
                                                    <TableCell
                                                        onClick={handleSurveyQuestionsSelect(
                                                            s
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Questions
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Questions
                                                    </TableCell>
                                                )}
                                                {s.canViewResults ? (
                                                    <TableCell
                                                        onClick={handleSurveyAnswersSelect(
                                                            s
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Results
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Results
                                                    </TableCell>
                                                )}

                                                <TableCell
                                                    onClick={handleTakeSurveySelect(
                                                        s
                                                    )}
                                                    align="center"
                                                    style={{
                                                        cursor: "pointer",

                                                        color: "rgb(0, 149, 61)",
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    Take Survey
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
                {mySurveys.length > 0 && (
                    <>
                        <div className={classes.dividerDiv} />
                        <h5>My Surveys</h5>
                        <Paper className={classes.paper} variant="outlined">
                            <TableContainer
                                component={Paper}
                                // className={classes.mySurveysTableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {mySurveys.map((s) => (
                                            <TableRow key={s.key}>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {s.title}
                                                </TableCell>
                                                {s.canEdit ? (
                                                    <TableCell
                                                        onClick={handleSurveyQuestionsSelect(
                                                            s
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Questions
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Questions
                                                    </TableCell>
                                                )}
                                                {s.canViewResults ? (
                                                    <TableCell
                                                        onClick={handleSurveyAnswersSelect(
                                                            s
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Results
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Results
                                                    </TableCell>
                                                )}

                                                <TableCell
                                                    onClick={handleTakeSurveySelect(
                                                        s
                                                    )}
                                                    align="center"
                                                    style={{
                                                        cursor: "pointer",

                                                        color: "rgb(0, 149, 61)",
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    Take Survey
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
                {surveysSharedWithMe.length > 0 && (
                    <>
                        <div className={classes.dividerDiv} />
                        <h5>Surveys Shared With Me</h5>
                        <Paper className={classes.paper} variant="outlined">
                            <TableContainer
                                component={Paper}
                                // className={classes.mySurveysTableContainer}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {surveysSharedWithMe.map((s) => (
                                            <TableRow key={s.key}>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    {s.title}
                                                </TableCell>
                                                {s.canEdit ? (
                                                    <TableCell
                                                        onClick={handleSurveyQuestionsSelect(
                                                            s
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Questions
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        Edit Questions
                                                    </TableCell>
                                                )}
                                                {s.canViewResults ? (
                                                    <TableCell
                                                        onClick={handleSurveyAnswersSelect(
                                                            s
                                                        )}
                                                        align="center"
                                                        style={{
                                                            cursor: "pointer",

                                                            color: "rgb(0, 149, 61)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Results
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            cursor: "default",

                                                            color: "rgb(200,200,200)",
                                                            borderRight:
                                                                "1px solid rgb(200,200,200)",
                                                        }}
                                                    >
                                                        View Results
                                                    </TableCell>
                                                )}

                                                <TableCell
                                                    onClick={handleTakeSurveySelect(
                                                        s
                                                    )}
                                                    align="center"
                                                    style={{
                                                        cursor: "pointer",

                                                        color: "rgb(0, 149, 61)",
                                                        borderRight:
                                                            "1px solid rgb(200,200,200)",
                                                    }}
                                                >
                                                    Take Survey
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
            </Container>
            <ToolbarWithLogo />
            <DialogWithTextInput
                open={surveyNameDialogOpen}
                onClose={handleCloseSurveyNameDialog}
                title={"New Survey"}
                message={"Enter a name for the survey."}
                initialValue={""}
            />
        </>
    );
}
