// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    event: {
        ...theme.typography.bodyBold,
        display: "block",
        textAlign: "center",
        margin: 5,
        padding: 5,
        width: 250,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export default function MoreEventsDialog(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.open}
        >
            {props.events.map((calendarEvent) => (
                <Chip
                    color="primary"
                    className={classes.event}
                    label={calendarEvent.name}
                    onClick={props.handleEventClick(calendarEvent)}
                    key={calendarEvent.key}
                    style={{
                        backgroundColor: calendarEvent.color,
                    }}
                />
            ))}
        </Dialog>
    );
}
