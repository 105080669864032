// Material UI Components
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

// My Files
import UserMenu from "./UserMenu";
import HamburgerMenu from "./HamburgerMenu";
import { DatabaseContext, UserContext } from "../../context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    messagesIcon: {
        marginTop: 7.5,
        marginRight: 5,
        cursor: "pointer",
    },
}));

export default function NavigationBar(props) {
    const classes = useStyles();
    const history = useHistory();
    const { currentUserData } = useContext(UserContext);

    const { conversations } = useContext(DatabaseContext);

    const [unreadMessages, setUnreadMessages] = useState(0);

    const [userCanOpenMessages, setUserCanOpenMessages] = useState(false);

    useEffect(() => {
        setUnreadMessages(0);
        if (currentUserData) {
            if (currentUserData.permissions.openMessage === true) {
                setUserCanOpenMessages(true);
            } else {
                setUserCanOpenMessages(false);
            }
            let conversationsInvolvingCurrentUser = conversations.filter(
                (c) =>
                    c.participants.filter((p) => p.key === currentUserData.key)
                        .length > 0
            );

            let unreadCount = 0;
            conversationsInvolvingCurrentUser.forEach((conversation) => {
                if (!conversation.deleted) {
                    if (conversation.messages) {
                        conversation.messages.forEach((message) => {
                            if (message.user !== currentUserData.key) {
                                if (
                                    !message.readBy.includes(
                                        currentUserData.key
                                    )
                                ) {
                                    if (!message.deleted) {
                                        unreadCount++;
                                    }
                                }
                            }
                        });
                    }
                }
            });

            setUnreadMessages(unreadCount);
        }
    }, [conversations, currentUserData]);

    function handleMessagesClick() {
        history.replace("/messages");
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <HamburgerMenu />
                    <Typography variant="h6" className={classes.title}>
                        {"Nodiant " + props.navBarTitle}
                    </Typography>

                    {userCanOpenMessages && unreadMessages > 0 && (
                        <div
                            className={classes.messagesIcon}
                            onClick={handleMessagesClick}
                        >
                            <Tooltip
                                title={
                                    "You have " +
                                    unreadMessages +
                                    " unread messages"
                                }
                            >
                                <Badge
                                    badgeContent={unreadMessages}
                                    color="secondary"
                                >
                                    <MessageIcon color="disabled" />
                                </Badge>
                            </Tooltip>
                        </div>
                    )}
                    <UserMenu />
                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    );
}
