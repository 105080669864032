import { useState, useEffect } from "react";
import { render } from "@rakered/email";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { LinearProgress } from "@material-ui/core";

// My Files
import firebase from "../../firebase";
import SetPasswordEmail from "../management/emails/SetPasswordEmail";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    calendarControl: { margin: 20 },
    textField: {
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
    button: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
}));

export default function ResetPasswordDialog(props) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        setEmail(props.email);
    }, [props]);

    const handleClose = () => {
        props.onClose();
    };

    const handleTextChange = (event) => {
        setEmail(event.target.value);
    };

    function handleResetClick() {
        if (email !== "") {
            setInProgress(true);

            var generateEmailActionLink = firebase
                .functions()
                .httpsCallable("generateEmailActionLink");

            generateEmailActionLink({
                email: email,
            })
                .then((result) => {
                    let link = result.data.link;
                    sendPasswordEmail(link);
                })
                .catch((error) => {
                    // Getting the Error details.
                    console.log("Error");
                    console.log(error);
                    console.log(error.code);
                    console.log(error.message);
                    console.log(error.details);
                });
        }
    }

    function sendPasswordEmail(link) {
        var sendMail = firebase.functions().httpsCallable("sendMail");

        sendMail({
            dest: email,
            sender: "Nodiant Online <no.reply.nodiant@gmail.com>",
            subject: "Choose your Nodiant Online Password",
            html: render(
                <SetPasswordEmail
                    link={link}
                    message="Your password has been reset on the Nodiant Online Platform"
                />
            ),
        })
            .then(() => {
                console.log("Password Email Sent");
                setInProgress(false);
                props.onClose();
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <h5 className={classes.title}>Reset Password</h5>
                <TextField
                    className={classes.textField}
                    id="outlined-basic"
                    label="Enter your email address"
                    variant="outlined"
                    value={email}
                    onChange={handleTextChange}
                />
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleResetClick}
                >
                    Reset
                </Button>
                {inProgress && <LinearProgress />}
            </Dialog>
        </>
    );
}
