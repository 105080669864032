import { useState, useEffect, useContext, useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Scrollbars } from "react-custom-scrollbars-2";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files
import DialogWithMessage from "../../../DialogWithMessage";
import { DatabaseContext, UserContext } from "../../../../context";
import ArchiveFinderColumn from "./ArchiveFinderColumn";
import { makeid, shouldFileBeVisible, uniq } from "../../../../utility";
import ArchiveProgressDialog from "./ArchiveProgressDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        // paddingLeft: 10,
    },
    toolbarTitle: {
        // flexGrow: 1,
    },
    bottomToolbarTitle: {
        color: "rgb(0, 149, 61)",
        flexGrow: 1,
    },
    title: {
        marginTop: 15,
    },
    columnsContainer: {
        display: "flex",
        flexDirection: "row",
        // overflow: "auto", // Makes scroll bar appear beneath rows
    },
    column: {
        minWidth: "24%",
        maxWidth: "24%",
    },
    bottomToolbar: {
        top: "auto",
        bottom: 0,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ArchiveFileListDialog(props) {
    const classes = useStyles();

    const { files, groups, folders } = useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);
    // const [foldersFlat, setFoldersFlat] = useState([]);

    const [columns, setColumns] = useState([]);

    const [selectedColumnIndex, setSelectedColumnIndex] = useState([]); // Just used to show visually which is selected

    const scrollRef = useRef();

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState([]);

    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [archiveProgressDialogOpen, setArchiveProgressDialogOpen] =
        useState(false);

    const [dialogTitle, setDialogTile] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");

    const [willDelete, setWillDelete] = useState(false);

    useEffect(() => {
        if (props.hub) {
            console.log("Refreshing Folders");

            // Get the current folder key from the browser url
            // let currentFolderKey = location.pathname.replace("/files/", "");

            // let currentFolderKey = "-hnt6zgOpASI4uwMdfMc";
            let flatFolderArrayFiltered = getFlatFolderArray();

            // flatArrayFiltered now contains all the folders that the user should have access to. Now make a nested folder structure from this.

            let nestedFolderArray = createNestedFolderStructure(
                flatFolderArrayFiltered
            );

            // A folder is already selected (so user loaded this page with a folder key in the url)
            // Have to open the UI to this folder

            let currentFolderKey = props.hub.key;

            let currentFolder = flatFolderArrayFiltered.filter(
                (f) => f.id === currentFolderKey
            )[0];

            if (currentFolder) {
                // Add the root folder first as a column
                let newColumns = [
                    {
                        id: "Root",
                        name: "Everything",
                        folders: nestedFolderArray,
                        folder: { name: "Root", id: "root" },
                    },
                ];

                // Then get all the folder between the selected one and the root,
                // add them as columns
                let parents = [];
                getParents(currentFolder, parents);

                parents.reverse().forEach((parent) => {
                    let matchingFolder = flatFolderArrayFiltered.filter(
                        (f) => f.id === parent.id
                    )[0];

                    if (matchingFolder) {
                        newColumns.push({
                            id: matchingFolder.id,
                            name: matchingFolder.name,
                            folders: matchingFolder.folders,
                            folder: matchingFolder,
                        });
                    }
                });

                // Then add the selected folder as a column

                newColumns.push({
                    id: currentFolder.id,
                    name: currentFolder.name,
                    folders: currentFolder.folders,
                    folder: currentFolder,
                });

                // Then add the files for each column
                newColumns.forEach((column) => {
                    let filesForColumn = [];

                    files
                        .filter((file) => file.folder === column.id)
                        .forEach((file) => {
                            if (
                                shouldFileBeVisible(
                                    file,
                                    groups,
                                    currentUserData
                                )
                            ) {
                                filesForColumn.push(file);
                            }
                        });

                    column.files = filesForColumn;
                });

                // Now Set the selected folder index for each column, so the UI displays correctly
                newColumns.forEach((column) => {
                    column.folders.forEach((folder, folderIndex) => {
                        if (
                            currentFolder.ancestors.includes(folder.id) ||
                            currentFolder.id === folder.id ||
                            currentFolder.parent === folder.id
                        ) {
                            column.selectedFolderIndex = folderIndex;
                        }
                    });
                });

                setSelectedColumnIndex(newColumns.length - 2);
                setColumns(newColumns);
                // scrollRef.current.scrollToRight();
            }

            // setFoldersFlat(flatFolderArrayFiltered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData]);

    function getParents(folderNode, parentNodeTrail) {
        let parentNode = folderNode.parentNode;

        if (parentNode === undefined || parentNode.id === undefined) {
            return;
        } else {
            parentNodeTrail.push({
                id: parentNode.id,
            });
            getParents(parentNode, parentNodeTrail);
        }
    }

    function getFlatFolderArray() {
        const foldersClone = folders.map((folder) => ({
            key: folder.key,
            id: folder.key,
            name: folder.name,
            hub: folder.hub,
            parent: folder.parent,
            imageURL: folder.imageURL,
            parentNode: {},
            folders: [],
            groups: folder.groups,
            managers: folder.managers,
            users: folder.users,
            ancestors: folder.ancestors,
            createdBy: folder.createdBy,
        }));

        // Filter out folders the user should not have access to. To see a folder, the user should either be included in folder.users, or the folder should be an ancestor of one which does contain the user.

        let flatFolderArrayFiltered = [];

        // For managers, add the folder if they are in the managers array or a digital manager
        // For users only add the folder if it is a hub and they are in the hub.users object, or if they created the folder themselves.
        foldersClone.forEach((folder) => {
            let shouldAdd = false;
            if (
                folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                shouldAdd = true;
            } else if (
                folder.hub &&
                folder.users.includes(currentUserData.key)
            ) {
                shouldAdd = true;
            } else if (
                folder.createdBy &&
                currentUserData &&
                folder.createdBy === currentUserData.key
            ) {
                shouldAdd = true;
            }

            if (!folder.ancestors.includes(props.hub.key)) {
                // We don't want the user to be able to change to other hubs than the one they are editing,
                // So every folder needs to have the hub as an ancestor
                shouldAdd = false;
            }

            if (shouldAdd) {
                flatFolderArrayFiltered.push(folder);

                // Need to find the ancestors of the folder and add them so that the user can navigate to the folders they are members of
                folder.ancestors.forEach((ancestorKey) => {
                    let ancestor = foldersClone.filter(
                        (f) => f.key === ancestorKey
                    );

                    if (ancestor[0]) {
                        flatFolderArrayFiltered.push(ancestor[0]);
                    }
                });
            }
        });

        // Remove duplicates from the folders array

        flatFolderArrayFiltered = Array.from(new Set(flatFolderArrayFiltered));
        // // Sort the flat array alphabetically
        flatFolderArrayFiltered.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        return flatFolderArrayFiltered;
    }

    function createNestedFolderStructure(flatFolderArray) {
        let nestedFolderArray = [];

        for (let i = 0; i < flatFolderArray.length; i++) {
            let folder = flatFolderArray[i];

            if (folder.parent === "Root") {
                nestedFolderArray.push(folder);
            } else {
                let parentFolder = flatFolderArray.filter(
                    (anotherFolder) => anotherFolder.key === folder.parent
                )[0];

                if (parentFolder !== undefined) {
                    parentFolder.folders.push(folder);
                    folder.parentNode = parentFolder;
                }
            }
        }

        return nestedFolderArray;
    }

    function handleFolderSelect(columnIndex, folderIndex) {
        let selectedColumn = columns[columnIndex];
        let selectedFolder = selectedColumn.folders[folderIndex];

        setSelectedColumnIndex(columnIndex);

        // Set columns for UI
        let newColumns = [];
        for (let i = 0; i < columnIndex + 1; i++) {
            newColumns.push(columns[i]);
        }

        newColumns.push({
            id: selectedFolder.id,
            name: selectedFolder.name,
            folders: selectedFolder.folders,
            folder: selectedFolder,
            selectedFolderIndex: 0,
        });

        // Have to add files to each column
        newColumns.forEach((column) => {
            let filesForColumn = [];

            files
                .filter((file) => file.folder === column.id)
                .forEach((file) => {
                    if (shouldFileBeVisible(file, groups, currentUserData)) {
                        filesForColumn.push(file);
                    }
                });

            column.files = filesForColumn;
        });

        // history.push(`/files/${selectedFolder.id}`);

        // Add an empty column for scrolling purposes
        newColumns.push({
            id: makeid(15),
            name: "",
            folders: [],
            selectedFolderIndex: 0,
        });

        setColumns(newColumns);

        scrollRef.current.scrollToRight();
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    const handleClose = () => {
        props.onClose();
    };

    function handleFolderChecked(folder) {
        // Select Files
        var subFiles = files.filter((f) => f.ancestors.includes(folder.key));
        var newSelectedFiles = [...selectedFiles, ...subFiles];

        setSelectedFiles(uniq(newSelectedFiles));

        // Select Folders
        var subFolders = folders.filter((f) =>
            f.ancestors.includes(folder.key)
        );

        var newSelectedFolders = [...selectedFolders, ...subFolders, folder];

        setSelectedFolders(uniq(newSelectedFolders));
    }

    function handleFolderUnchecked(folder) {
        // Deselect Files
        var subFiles = files.filter((f) => f.ancestors.includes(folder.key));

        var newSelectedFiles = [...selectedFiles];

        newSelectedFiles.forEach((selectedFile) => {
            if (subFiles.includes(selectedFile)) {
                newSelectedFiles = newSelectedFiles.filter(
                    (f) => f.key !== selectedFile.key
                );
            }
        });

        setSelectedFiles(newSelectedFiles);

        // Deselect Folders
        var subFolders = folders.filter((f) =>
            f.ancestors.includes(folder.key)
        );

        var newSelectedFolders = [...selectedFolders];
        newSelectedFolders = newSelectedFolders.filter(
            (f) => f.key !== folder.key
        );

        newSelectedFolders.forEach((selectedFolder) => {
            if (subFolders.includes(selectedFolder)) {
                newSelectedFolders = newSelectedFolders.filter(
                    (f) => f.key !== selectedFolder.key
                );
            }
        });

        setSelectedFolders(newSelectedFolders);
    }

    function handleFileSelect(file) {
        var newSelected = [...selectedFiles, file];
        setSelectedFiles(newSelected);
    }

    function handleFileDeselect(file) {
        var newSelected = selectedFiles.filter((f) => f.key !== file.key);

        setSelectedFiles(newSelected);
    }

    function handleDownloadAndDeleteClick() {
        if (selectedFiles.length === 0) {
            setDialogTile("No Files Selected");
            setDialogMessage("Select some folders or files first");
            setMessageDialogOpen(true);
        } else {
            setWillDelete(true);
            setArchiveProgressDialogOpen(true);
        }
    }

    function handleDownloadClick() {
        if (selectedFiles.length === 0) {
            setDialogTile("No Files Selected");
            setDialogMessage("Select some folders or files first");
            setMessageDialogOpen(true);
        } else {
            setWillDelete(false);
            setArchiveProgressDialogOpen(true);
        }
    }

    function handleCloseMessageDialog() {
        setMessageDialogOpen(false);
    }

    function handleCloseArchiveProgressDialog(reason) {
        if (reason === "finished") {
            props.onClose();
        }
        setArchiveProgressDialogOpen(false);
    }

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.toolbarTitle}
                        >
                            Archive Files
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container fluid className={classes.container}>
                    <div
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            paddingTop: 15,
                            paddingBottom: 65,
                            "&::WebkitScrollbar": { display: "none" },
                        }}
                    >
                        <Scrollbars
                            className={classes.scrollbar}
                            style={{
                                width: window.innerWidth,
                                height: window.innerHeight - 145,
                            }}
                            ref={scrollRef}
                        >
                            <DragDropContext>
                                <div className={classes.columnsContainer}>
                                    {columns.map((column, index) => (
                                        <div
                                            className={classes.column}
                                            key={column.id}
                                        >
                                            <ArchiveFinderColumn
                                                key={column.id}
                                                column={column}
                                                index={index}
                                                archiveHub={props.hub}
                                                selectedFiles={selectedFiles}
                                                selectedFolders={
                                                    selectedFolders
                                                }
                                                handleFolderSelect={
                                                    handleFolderSelect
                                                }
                                                handleFolderChecked={
                                                    handleFolderChecked
                                                }
                                                handleFolderUnchecked={
                                                    handleFolderUnchecked
                                                }
                                                selectedColumnIndex={
                                                    selectedColumnIndex
                                                }
                                                showStatusMessage={
                                                    showStatusMessage
                                                }
                                                handleFileSelect={
                                                    handleFileSelect
                                                }
                                                handleFileDeselect={
                                                    handleFileDeselect
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </DragDropContext>
                        </Scrollbars>

                        <Snackbar
                            open={statusIsVisible}
                            autoHideDuration={6000}
                            onClose={handleStatusClose}
                        >
                            <Alert
                                onClose={handleStatusClose}
                                severity={statusType}
                            >
                                {statusMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                </Container>
                <AppBar
                    position="fixed"
                    color="default"
                    className={classes.bottomToolbar}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            className={classes.bottomToolbarTitle}
                        >
                            {selectedFiles.length === 1
                                ? selectedFiles.length + " File Selected"
                                : selectedFiles.length + " Files Selected"}
                        </Typography>
                        <Button color="primary" onClick={handleDownloadClick}>
                            Download
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleDownloadAndDeleteClick}
                        >
                            Download and Delete
                        </Button>
                    </Toolbar>
                </AppBar>
            </Dialog>
            <DialogWithMessage
                open={messageDialogOpen}
                onClose={handleCloseMessageDialog}
                title={dialogTitle}
                message={dialogMessage}
            />
            <ArchiveProgressDialog
                open={archiveProgressDialogOpen}
                close={handleCloseArchiveProgressDialog}
                files={selectedFiles}
                delete={willDelete}
            />
        </>
    );
}
