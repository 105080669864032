import { useState } from "react";
import { render } from "@rakered/email";

// Material UI
import Dialog from "@material-ui/core/Dialog";

import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { LinearProgress } from "@material-ui/core";

// My Files
import firebase from "../../../firebase";
import SetPasswordEmail from "../emails/SetPasswordEmail";

export default function AccountRecoveryDialog(props) {
    const [email, setEmail] = useState("");
    const [inProgress, setInProgress] = useState(false);

    function handleCancel() {
        props.dismiss();
        setEmail("");
    }

    function handleOK() {
        if (email !== "") {
            setInProgress(true);
            // First create a new user account in firebase auth. Then change the user.uid property in the database to match this new account.

            // By default, when you create a new user, firebase automatically logs in with that user. To avoid this we create a duplicate instance of firebase here

            var updateUser = firebase.functions().httpsCallable("updateUser");

            updateUser({
                uid: props.user.uid,
                user: { email: email },
            })
                .then(() => {
                    // email address has been updated, now send password reset email
                    var generateEmailActionLink = firebase
                        .functions()
                        .httpsCallable("generateEmailActionLink");

                    generateEmailActionLink({
                        email: email,
                    }).then((result) => {
                        let link = result.data.link;
                        sendPasswordEmail(link);

                        let userRef = firebase
                            .database()
                            .ref("users")
                            .child(props.user.key);

                        userRef.update({ email: email });

                        props.onClose(true, "", email);
                        setEmail("");
                        setInProgress(false);
                    });
                })
                .catch((error) => {
                    // Getting the Error details.
                    let errorCode = error.code;
                    let errorMessage = error.message;
                    console.log(errorCode + ":" + errorMessage);
                    props.onClose(false, errorMessage, email);
                    setInProgress(false);
                    setEmail("");
                });
        }
    }

    function sendPasswordEmail(link) {
        var sendMail = firebase.functions().httpsCallable("sendMail");

        sendMail({
            dest: email,
            sender: "Nodiant Online <no.reply.nodiant@gmail.com>",
            subject: "Choose your Nodiant Online Password",
            html: render(
                <SetPasswordEmail
                    link={link}
                    message="Your account on the Nodiant Online platform has been recovered."
                />
            ),
        })
            .then(() => {
                console.log("Email Sent");
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });
    }

    const handleKeyboardInput = (e) => {
        setEmail(e.target.value);
    };

    return (
        <>
            <Dialog
                onClose={handleCancel}
                aria-labelledby="simple-dialog-title"
                open={props.open}
            >
                <DialogTitle id="form-dialog-title">
                    {"Account Recovery"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If the user has forgotten their password AND no longer
                        has access to their email address. You can recover the
                        account by entering a new email address here:
                    </DialogContentText>
                    <TextField
                        value={email}
                        onChange={handleKeyboardInput}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="New email address"
                        type=""
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOK} color="primary">
                        OK
                    </Button>
                </DialogActions>
                {inProgress && <LinearProgress />}
            </Dialog>
        </>
    );
}
