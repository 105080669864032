import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

// My Files
import UserAvatar from "../management/UserAvatar";
import firebase from "../../firebase";
import { UserContext, DatabaseContext } from "../../context";
import UserFeed from "../home/UserFeed";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        position: "sticky",
        top: 0,
        minWidth: 210,
        width: "100%",
        height: 55,
        background: "white",
        zIndex: 1,
        padding: 0,
    },
    myAccountButton: {
        top: 0,
    },
    signOutButton: {
        position: "absolute",
        top: 0,
        left: "auto",
        right: 0,
    },
    avatarDiv: {
        cursor: "pointer",
    },
}));

export default function UserMenu() {
    const classes = useStyles();

    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { currentUserData } = useContext(UserContext);
    const { notifications } = useContext(DatabaseContext);
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        if (currentUserData) {
            let count = 0;
            notifications.forEach((n) => {
                if (
                    n.users.includes(currentUserData.key) ||
                    n.managers.includes(currentUserData.key)
                ) {
                    if (!n.seenBy.includes(currentUserData.key)) {
                        count++;
                    }
                }
            });

            setNotificationCount(count);
        }
    }, [currentUserData, notifications]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleSignOutClick() {
        firebase.auth().signOut();
        setAnchorEl(null);
        history.replace("/");
    }

    function handleMyAccountClick() {
        history.push({
            pathname: "/myaccount",
        });
    }

    return (
        <div>
            <Badge badgeContent={notificationCount} color="secondary">
                <div onClick={handleMenu} className={classes.avatarDiv}>
                    <UserAvatar userOrHub={currentUserData} path="users" />
                </div>
            </Badge>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    disablePadding: true,
                }}
            >
                <div className={classes.toolbar}>
                    <Tooltip title="My Account">
                        <IconButton
                            aria-label="My Account"
                            className={classes.myAccountButton}
                            onClick={handleMyAccountClick}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Sign Out">
                        <IconButton
                            aria-label="Sign Out"
                            className={classes.signOutButton}
                            onClick={handleSignOutClick}
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip>
                    <Divider />
                </div>
                <UserFeed popupFeed />
            </Menu>
        </div>
    );
}
