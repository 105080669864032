// Material UI
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
// import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

// My Files
import UserAvatar from "../management/UserAvatar";
import ConversationGroupAvatar from "./ConversationGroupAvatar";
// import { DatabaseContext, UserContext } from "../../context";

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",
        paddingBottom: 70,
        paddingTop: 0,
    },
    addButton: {
        position: "fixed",
        zIndex: 1,
        top: "auto",
        bottom: 70,
        left: 10,
        // left: 340,
        // right: 10,
        // left: "auto",
    },
}));

export default function ConversationList(props) {
    const classes = useStyles();

    const handleConversationClick = (conv) => () => {
        props.handleSelect(conv);
    };

    return (
        <>
            <List className={classes.root}>
                {props.conversations
                    .filter((c) => c.deleted === false)

                    .map((c, index) => (
                        <div
                            key={c.key}
                            onClick={handleConversationClick(c, index)}
                        >
                            <ListItem
                                button
                                alignItems="flex-start"
                                selected={
                                    props.selected &&
                                    props.selected.key === c.key
                                }
                            >
                                <ListItemAvatar>
                                    {c.otherUsers.length === 1 ? (
                                        <Badge
                                            badgeContent={c.unreadMessagesCount}
                                            color="secondary"
                                        >
                                            <UserAvatar
                                                userOrHub={c.otherUsers[0]}
                                                path="users"
                                                variant="circular"
                                            />
                                        </Badge>
                                    ) : (
                                        <Badge
                                            badgeContent={c.unreadMessagesCount}
                                            color="secondary"
                                        >
                                            <ConversationGroupAvatar
                                                conversation={c}
                                            />
                                        </Badge>
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={c.title}
                                    secondary={
                                        <>
                                            {c.messages.length > 0 &&
                                                (c.messages[
                                                    c.messages.length - 1
                                                ].message.length < 22
                                                    ? c.messages[
                                                          c.messages.length - 1
                                                      ].message
                                                    : c.messages[
                                                          c.messages.length - 1
                                                      ].message.substring(
                                                          0,
                                                          22
                                                      ) + "...")}
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    ))}
            </List>
            {props.canAdd && (
                <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.addButton}
                    onClick={props.handleAddClick}
                >
                    <AddIcon />
                </Fab>
            )}
        </>
    );
}
