import { useState, useEffect, useContext, useReducer } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

// My Files
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { UserContext } from "../../context";
import { isNumeric } from "../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    calendarControl: { margin: 20 },
    timeTextField: {
        marginTop: 50,
        margin: 20,
    },
    nameTextField: {
        margin: 20,
    },
    notesTextField: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
    colorPicker: {
        // paddingLeft: 5,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        alignSelf: "center",
    },
    button: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
    deleteButton: {
        position: "absolute",
        right: 5,
    },
    starIcon: {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    starsRow: {
        marginBottom: 15,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MarkOutDialog(props) {
    const classes = useStyles();

    const [rowToEdit, setRowToEdit] = useState();

    const [inTime, setInTime] = useState(0);
    const [outTime, setOutTime] = useState(0);

    const [name, setName] = useState("");
    const [notes, setNotes] = useState([""]);
    const [stars, setStars] = useState(0);
    const { currentUserData } = useContext(UserContext);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        if (props.rowToEdit) {
            let row = props.rowToEdit;

            setRowToEdit(row);

            setInTime(row.inTime);
            setOutTime(row.outTime);

            setName(row.name);

            if (row.notes.length === 0) {
                setNotes([""]);
            } else {
                setNotes(row.notes);
            }
            setStars(row.stars);
        } else {
            setRowToEdit(null);

            setName("");
            setNotes([""]);
            setStars(0);
        }
    }, [props.rowToEdit]);

    const handleTextChange = (event) => {
        if (event.target.id === "Mark Name") {
            setName(event.target.value);
        } else if (event.target.id === "In Time") {
            setInTime(event.target.value);
        } else if (event.target.id === "Out Time") {
            setOutTime(event.target.value);
        } else if (isNumeric(event.target.id)) {
            // Note text fields have a numeric id, which corresponds to the index in the notes array
            notes[event.target.id] = event.target.value;
            forceUpdate();
        }
    };

    const handleDeleteClick = () => {
        setConfirmDeleteDialogOpen(true);
    };

    function handleCloseDeleteDialog(result) {
        if (result === true && currentUserData) {
            props.onDelete(rowToEdit);
        }

        setConfirmDeleteDialogOpen(false);
    }

    const handleDoneClick = () => {
        if (name === "") {
            showStatusMessage("Name can't be blank", "error");
            return;
        }

        if (!isNumeric(inTime.toString())) {
            showStatusMessage("In Time must be a number", "error");
            return;
        }

        if (!isNumeric(outTime.toString())) {
            showStatusMessage("Out Time must be a number", "error");
            return;
        }

        if (inTime < 0) {
            showStatusMessage("In time cannot be below zero", "error");
            return;
        }

        if (outTime < 0) {
            showStatusMessage("Out time cannot be below zero", "error");
            return;
        }

        if (inTime > outTime) {
            showStatusMessage("In Time must be smaller than Out Time", "error");
            return;
        }

        if (rowToEdit) {
            rowToEdit.inTime = inTime;
            rowToEdit.outTime = outTime;
            rowToEdit.name = name;
            rowToEdit.notes = notes.filter((n) => n !== "");
            rowToEdit.stars = stars;
            props.onEdit(rowToEdit);
        } else {
            props.onAdd(
                name,
                notes.filter((n) => n !== ""),
                stars
            );
        }

        setName("");
        setNotes([""]);
        setStars(0);
        setInTime(0);
        setOutTime(0);
    };

    function handleOneStarPress() {
        setStars(1);
    }

    function handleTwoStarPress() {
        setStars(2);
    }

    function handleThreeStarPress() {
        setStars(3);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    function handleClose() {
        props.onClose();
        setName("");
        setStars(0);
        setInTime(0);
        setOutTime(0);
        setNotes([""]);
    }

    function handleStarAddPress() {
        if (stars < 3) {
            setStars(stars + 1);
        }
    }

    function handleStarRemovePress() {
        if (stars > 0) {
            setStars(stars - 1);
        }
    }

    return (
        <Dialog
            // onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            className={classes.root}
            onClose={handleClose}
        >
            {/* <h4 className={classes.title}>"title"</h4> */}
            {rowToEdit && (
                <>
                    <IconButton
                        onClick={handleDeleteClick}
                        className={classes.deleteButton}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <div>
                        <TextField
                            className={classes.timeTextField}
                            id="In Time"
                            label="In Time (Sec)"
                            variant="outlined"
                            value={inTime}
                            onChange={handleTextChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    Seconds
                                </InputAdornment>
                            }
                        />
                        <TextField
                            className={classes.timeTextField}
                            id="Out Time"
                            label="Out Time (Sec)"
                            variant="outlined"
                            value={outTime}
                            onChange={handleTextChange}
                        />
                    </div>
                </>
            )}

            <TextField
                className={classes.nameTextField}
                id="Mark Name"
                label="Mark Name"
                variant="outlined"
                value={name}
                onChange={handleTextChange}
            />
            {notes.map((note, index) => (
                <TextField
                    key={index}
                    className={classes.notesTextField}
                    id={index.toString()}
                    label="Note"
                    variant="outlined"
                    value={notes[index]}
                    onChange={handleTextChange}
                />
            ))}
            <div className={classes.starsRow}>
                <IconButton onClick={handleStarRemovePress}>
                    <RemoveIcon />
                </IconButton>
                {stars === 0 && (
                    <>
                        <StarBorderIcon
                            onClick={handleOneStarPress}
                            className={classes.starIcon}
                        />
                        <StarBorderIcon
                            onClick={handleTwoStarPress}
                            className={classes.starIcon}
                        />
                        <StarBorderIcon
                            onClick={handleThreeStarPress}
                            className={classes.starIcon}
                        />
                    </>
                )}
                {stars === 1 && (
                    <>
                        <StarIcon
                            onClick={handleOneStarPress}
                            className={classes.starIcon}
                        />
                        <StarBorderIcon
                            onClick={handleTwoStarPress}
                            className={classes.starIcon}
                        />
                        <StarBorderIcon
                            onClick={handleThreeStarPress}
                            className={classes.starIcon}
                        />
                    </>
                )}
                {stars === 2 && (
                    <>
                        <StarIcon
                            onClick={handleOneStarPress}
                            className={classes.starIcon}
                        />
                        <StarIcon
                            onClick={handleTwoStarPress}
                            className={classes.starIcon}
                        />
                        <StarBorderIcon
                            onClick={handleThreeStarPress}
                            className={classes.starIcon}
                        />
                    </>
                )}
                {stars === 3 && (
                    <>
                        <StarIcon
                            onClick={handleOneStarPress}
                            className={classes.starIcon}
                        />
                        <StarIcon
                            onClick={handleTwoStarPress}
                            className={classes.starIcon}
                        />
                        <StarIcon
                            onClick={handleThreeStarPress}
                            className={classes.starIcon}
                        />
                    </>
                )}
                <IconButton onClick={handleStarAddPress}>
                    <AddIcon />
                </IconButton>
            </div>
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleDoneClick}
            >
                Done
            </Button>
            {confirmDeleteDialogOpen && (
                <ConfirmDeleteDialog
                    title="Delete Row"
                    message="Are you sure you want to delete this row?"
                    open={confirmDeleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                />
            )}
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}
