import React, { useEffect, useContext, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import { Scrollbars } from "react-custom-scrollbars-2";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import { shouldFileBeVisible, makeid } from "../../utility";
import FinderColumn from "../files/viewing/finderPage/FinderColumn";

const useStyles = makeStyles((theme) => ({
    columnsContainer: {
        display: "flex",
        flexDirection: "row",
        // overflow: "auto", // Makes scroll bar appear beneath rows
    },
    column: {
        minWidth: "24%",
        maxWidth: "24%",
    },
    contentContainer: {
        paddingLeft: 15,
        paddingTop: 15,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectSelectFileDialog(props) {
    const location = useLocation();
    const classes = useStyles();

    // Files and folders from firebase
    const { files, folders, groups } = useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [foldersFlat, setFoldersFlat] = useState([]);

    const [columns, setColumns] = useState([]);

    const [selectedColumnIndex, setSelectedColumnIndex] = useState([]); // Just used to show visually which is selected

    const scrollRef = useRef();

    useEffect(() => {
        if (currentUserData) {
            console.log("ProjectSelectFileDialog:useEffect");

            // console.log("Refreshing Folders");
            let flatFolderArrayFiltered = getFlatFolderArray();

            // flatArrayFiltered now contains all the folders that the user should have access to. Now make a nested folder structure from this.

            let nestedFolderArray = createNestedFolderStructure(
                flatFolderArrayFiltered
            );

            // Get the current folder key from the browser url
            let currentFolderKey = location.pathname.replace("/files/", "");

            // if (currentFolderKey === "/files") {
            if (true) {
                let newColumns = [
                    {
                        id: "Root",
                        name: "Everything",
                        folders: nestedFolderArray,
                        folder: { name: "Root", id: "root" },
                    },
                ];

                console.log(newColumns);
                setColumns(newColumns);
            } else {
                let flatFolderArrayFiltered = getFlatFolderArray();

                // flatArrayFiltered now contains all the folders that the user should have access to. Now make a nested folder structure from this.

                let nestedFolderArray = createNestedFolderStructure(
                    flatFolderArrayFiltered
                );

                // A folder is already selected (so user loaded this page with a folder key in the url)
                // Have to open the UI to this folder

                let currentFolder = flatFolderArrayFiltered.filter(
                    (f) => f.id === currentFolderKey
                )[0];

                if (currentFolder) {
                    // Add the root folder first as a column
                    let newColumns = [
                        {
                            id: "Root",
                            name: "Everything",
                            folders: nestedFolderArray,
                            folder: { name: "Root", id: "root" },
                        },
                    ];

                    // Then get all the folder between the selected one and the root,
                    // add them as columns
                    let parents = [];
                    getParents(currentFolder, parents);

                    parents.reverse().forEach((parent) => {
                        let matchingFolder = flatFolderArrayFiltered.filter(
                            (f) => f.id === parent.id
                        )[0];

                        if (matchingFolder) {
                            newColumns.push({
                                id: matchingFolder.id,
                                name: matchingFolder.name,
                                folders: matchingFolder.folders,
                                folder: matchingFolder,
                            });
                        }
                    });

                    // Then add the selected folder as a column

                    newColumns.push({
                        id: currentFolder.id,
                        name: currentFolder.name,
                        folders: currentFolder.folders,
                        folder: currentFolder,
                    });

                    // Then add the files for each column
                    newColumns.forEach((column) => {
                        let filesForColumn = [];

                        files
                            .filter((file) => file.folder === column.id)
                            .forEach((file) => {
                                if (
                                    shouldFileBeVisible(
                                        file,
                                        groups,
                                        currentUserData
                                    )
                                ) {
                                    filesForColumn.push(file);
                                }
                            });

                        column.files = filesForColumn;
                    });

                    // Now Set the selected folder index for each column, so the UI displays correctly
                    newColumns.forEach((column) => {
                        column.folders.forEach((folder, folderIndex) => {
                            if (
                                currentFolder.ancestors.includes(folder.id) ||
                                currentFolder.id === folder.id ||
                                currentFolder.parent === folder.id
                            ) {
                                column.selectedFolderIndex = folderIndex;
                            }
                        });
                    });

                    // console.log("Current Folder");
                    // console.log(currentFolder);

                    setSelectedColumnIndex(newColumns.length - 2);
                    setColumns(newColumns);
                    scrollRef.current.scrollToRight();
                }
            }

            setFoldersFlat(flatFolderArrayFiltered);
        }

        // eslint-disable-next-line
    }, [folders, files, currentUserData]);

    function getParents(folderNode, parentNodeTrail) {
        let parentNode = folderNode.parentNode;

        if (parentNode === undefined || parentNode.id === undefined) {
            return;
        } else {
            parentNodeTrail.push({
                id: parentNode.id,
            });
            getParents(parentNode, parentNodeTrail);
        }
    }

    function getFlatFolderArray() {
        const foldersClone = folders.map((folder) => ({
            key: folder.key,
            id: folder.key,
            name: folder.name,
            hub: folder.hub,
            parent: folder.parent,
            imageURL: folder.imageURL,
            parentNode: {},
            folders: [],
            groups: folder.groups,
            managers: folder.managers,
            users: folder.users,
            ancestors: folder.ancestors,
            createdBy: folder.createdBy,
        }));

        // Filter out folders the user should not have access to. To see a folder, the user should either be included in folder.users, or the folder should be an ancestor of one which does contain the user.

        let flatFolderArrayFiltered = [];

        // For managers, add the folder if they are in the managers array or a digital manager
        // For users only add the folder if it is a hub and they are in the hub.users object, or if they created the folder themselves.
        foldersClone.forEach((folder) => {
            let shouldAdd = false;
            if (
                folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                shouldAdd = true;
            } else if (
                folder.hub &&
                folder.users.includes(currentUserData.key)
            ) {
                shouldAdd = true;
            } else if (
                folder.createdBy &&
                currentUserData &&
                folder.createdBy === currentUserData.key
            ) {
                shouldAdd = true;
            }
            if (shouldAdd) {
                flatFolderArrayFiltered.push(folder);

                // Need to find the ancestors of the folder and add them so that the user can navigate to the folders they are members of
                folder.ancestors.forEach((ancestorKey) => {
                    let ancestor = foldersClone.filter(
                        (f) => f.key === ancestorKey
                    );

                    if (ancestor[0]) {
                        flatFolderArrayFiltered.push(ancestor[0]);
                    }
                });
            }
        });

        // For users, add a folder if some descendant contains a file that should be visible
        files.forEach((file) => {
            if (shouldFileBeVisible(file, groups, currentUserData)) {
                // Need to find the ancestors of the file and add them so that the user can navigate to the file
                file.ancestors.forEach((ancestorKey) => {
                    let ancestor = foldersClone.filter(
                        (f) => f.key === ancestorKey
                    );

                    if (ancestor[0]) {
                        flatFolderArrayFiltered.push(ancestor[0]);
                    }
                });
            }
        });

        // Remove duplicates from the folders array

        flatFolderArrayFiltered = Array.from(new Set(flatFolderArrayFiltered));

        // // Sort the flat array alphabetically
        flatFolderArrayFiltered.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        // Add a folder for the users favourite files at index 0
        let favesFolder = {
            id: "faves_fggh2973jgbvbiu",
            key: "faves_fggh2973jgbvbiu",
            name: "Favourites",
            parent: "Root",
            ancestors: [],
            folders: [],
            files: [],
            hub: false,
            parentNode: {},
            users: [],
            managers: [],
        };

        flatFolderArrayFiltered.unshift(favesFolder);

        return flatFolderArrayFiltered;
    }

    function createNestedFolderStructure(flatFolderArray) {
        let nestedFolderArray = [];

        for (let i = 0; i < flatFolderArray.length; i++) {
            let folder = flatFolderArray[i];

            if (folder.parent === "Root") {
                nestedFolderArray.push(folder);
            } else {
                let parentFolder = flatFolderArray.filter(
                    (anotherFolder) => anotherFolder.key === folder.parent
                )[0];

                if (parentFolder !== undefined) {
                    parentFolder.folders.push(folder);
                    folder.parentNode = parentFolder;
                }
            }
        }

        return nestedFolderArray;
    }

    function handleCancelPress() {
        props.onClose();
    }

    function handleFolderSelect(columnIndex, folderIndex) {
        let selectedColumn = columns[columnIndex];
        let selectedFolder = selectedColumn.folders[folderIndex];

        setSelectedColumnIndex(columnIndex);

        // Set columns for UI
        let newColumns = [];
        for (let i = 0; i < columnIndex + 1; i++) {
            newColumns.push(columns[i]);
        }

        newColumns.push({
            id: selectedFolder.id,
            name: selectedFolder.name,
            folders: selectedFolder.folders,
            folder: selectedFolder,
            selectedFolderIndex: 0,
        });

        // Have to add files to each column
        newColumns.forEach((column) => {
            let filesForColumn = [];

            files
                .filter((file) => file.folder === column.id)
                .forEach((file) => {
                    if (shouldFileBeVisible(file, groups, currentUserData)) {
                        filesForColumn.push(file);
                    }
                });

            column.files = filesForColumn;
        });

        // history.push(`/files/${selectedFolder.id}`);

        // Add an empty column for scrolling purposes
        newColumns.push({
            id: makeid(15),
            name: "",
            folders: [],
            selectedFolderIndex: 0,
        });

        setColumns(newColumns);

        scrollRef.current.scrollToRight();
    }

    function onDragEnd(result) {}

    function didSelectFile(file) {
        props.onClose(file);
    }

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <Tooltip title="Cancel">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCancelPress}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h6" className={classes.title}>
                        Select File
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <div
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    paddingTop: 15,
                    paddingBottom: 65,
                    "&::WebkitScrollbar": { display: "none" },
                }}
            >
                <Scrollbars
                    className={classes.scrollbar}
                    style={{
                        width: window.innerWidth,
                        height: window.innerHeight - 145,
                    }}
                    ref={scrollRef}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className={classes.columnsContainer}>
                            {columns.map((column, index) => (
                                <div className={classes.column} key={column.id}>
                                    <FinderColumn
                                        embedded={true}
                                        key={column.id}
                                        column={column}
                                        index={index}
                                        handleFileSelect={didSelectFile}
                                        handleFolderSelect={handleFolderSelect}
                                        selectedColumnIndex={
                                            selectedColumnIndex
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </DragDropContext>
                </Scrollbars>
            </div>
        </Dialog>
    );
}
