import * as React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import Button from "@material-ui/core/Button";

const styles = {
    root: {
        width: "100%",
    },
    canvas: {
        border: "0.0625rem solid #9c9c9c",
        borderRadius: "0.25rem",
        // marginLeft: 10,
        width: "90%",
    },
    clearButton: { margin: 10 },
    confirmButton: { marginTop: 10, marginBottom: 10 },
};

const Canvas = class extends React.Component {
    constructor(props) {
        super(props);

        this.canvas = React.createRef();
    }

    render() {
        return (
            <Container fluid className={styles.root}>
                <Col>
                    <Row>
                        <ReactSketchCanvas
                            ref={this.canvas}
                            strokeWidth={5}
                            strokeColor="black"
                            style={styles.canvas}
                            withTimestamp
                        />
                        <Button
                            variant="contained"
                            color="default"
                            style={styles.clearButton}
                            onClick={() => {
                                this.canvas.current.clearCanvas();
                            }}
                        >
                            Clear
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.confirmButton}
                            onClick={() => {
                                this.canvas.current
                                    .getSketchingTime()
                                    .then((sketchingTime) => {
                                        this.canvas.current
                                            .exportImage("png")
                                            .then((data) => {
                                                this.props.imageData(
                                                    data,
                                                    sketchingTime
                                                );
                                            })
                                            .catch((e) => {
                                                console.log(e);
                                            });
                                    });
                            }}
                        >
                            Confirm
                        </Button>
                    </Row>
                </Col>
            </Container>
        );
    }
};

export default Canvas;
