import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

// Material UI
import FolderIcon from "@material-ui/icons/Folder";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import GroupIcon from "@material-ui/icons/Group";

// My Files
import { DatabaseContext, UserContext } from "../../../../context";
import firebase from "../../../../firebase";
import { encodeImage } from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 150,
        fontSize: 120,
        cursor: "pointer",
    },
    label: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: 3,
        marginLeft: 5,
        width: 140,
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tile: {
        marginTop: 10,
        marginLeft: 10,
        width: 150,
        cursor: "pointer",
    },
    avatar: {
        width: 115,
        height: 120,
        marginLeft: 15,
        cursor: "pointer",
    },
}));

export default function FolderIconWithLabel(props) {
    const [avatar, setAvatar] = useState();
    const history = useHistory();
    const { files, folders } = useContext(DatabaseContext);

    const classes = useStyles();

    const [clickLocation, setClickLocation] = useState({
        mouseX: null,
        mouseY: null,
    });

    const { currentUserData } = useContext(UserContext);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);
    const [folderNameDialogOpen, setFolderNameDialogOpen] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [isHub, setIsHub] = useState(false);

    useEffect(() => {
        const getImage = () => {
            console.log("Downloading Hub Avatar from Firebase");
            axios
                .get(props.folder.imageURL, {
                    responseType: "arraybuffer",
                })
                .then((response) => {
                    const buffer = Buffer.from(response.data, "base64");
                    const photo =
                        "data:image/png;base64," + encodeImage(buffer);

                    setAvatar(photo);
                })
                .catch((ex) => {
                    console.error(ex);
                });
        };

        if (props.folder.hub) {
            getImage();
            setIsHub(true);
        } else {
            setIsHub(false);
        }

        setFolderName(props.folder.name);
    }, [avatar, props.folder.key, props.folder]);

    const handleClick = (event) => {
        event.preventDefault();

        // Checks that the current user is a manager for the folder or hub
        if (
            props.folder.managers.includes(currentUserData.key) ||
            currentUserData.role === "System Digital Manager"
        ) {
            setClickLocation({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
        }
    };

    const handleClose = (event) => {
        if (event.target.id === "renamefolder") {
            handleFolderRenameDialogOpen();
        } else if (event.target.id === "deletefolder") {
            handleConfirmDeleteDialogOpen();
        } else if (event.target.id === "edithub") {
            history.replace({ pathname: "/managehub", hub: props.folder });
        }

        setClickLocation({
            mouseX: null,
            mouseY: null,
        });
    };

    const handleFolderRenameDialogOpen = () => {
        setFolderNameDialogOpen(true);
    };

    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true);
    };

    const handleFolderRenameDialogClose = (key) => () => {
        setFolderNameDialogOpen(false);

        if (key === "OK") {
            if (
                props.folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                let ref = firebase
                    .database()
                    .ref("folders")
                    .child(props.folder.key);

                ref.update({ name: folderName });
            }
        }
    };

    const handleConfirmDeleteDialogClose = (key) => () => {
        setConfirmDeleteDialogOpen(false);

        if (key === "OK") {
            if (
                props.folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                let shouldDelete = true;

                folders.forEach((f) => {
                    if (f.parent === props.folder.key) {
                        shouldDelete = false;
                    }
                });

                files.forEach((f) => {
                    if (f.folder === props.folder.key) {
                        shouldDelete = false;
                    }
                });

                if (shouldDelete) {
                    let folderRef = firebase
                        .database()
                        .ref("folders")
                        .child(props.folder.key);

                    folderRef.remove();
                }
            }
        }
    };

    const handleKeyboardInput = (e) => {
        setFolderName(e.target.value);
    };

    return (
        <div
            className={classes.tile}
            onContextMenu={handleClick}
            style={{ cursor: "context-menu" }}
        >
            <>
                {props.folder.hub === undefined ? (
                    <div onClick={props.handleClick}>
                        <FolderIcon className={classes.icon} />
                        <p className={classes.label}>{props.folder.name}</p>
                    </div>
                ) : (
                    <div onClick={props.handleClick}>
                        <Avatar
                            variant="rounded"
                            src={avatar}
                            className={classes.avatar}
                        >
                            <GroupIcon />
                        </Avatar>
                        <p className={classes.label}>{props.folder.name}</p>
                    </div>
                )}
                <Menu
                    keepMounted
                    open={clickLocation.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        clickLocation.mouseY !== null &&
                        clickLocation.mouseX !== null
                            ? {
                                  top: clickLocation.mouseY,
                                  left: clickLocation.mouseX,
                              }
                            : undefined
                    }
                >
                    {isHub ? (
                        <div>
                            <MenuItem id="edithub" onClick={handleClose}>
                                Edit Hub
                            </MenuItem>
                        </div>
                    ) : (
                        <div>
                            <MenuItem id="renamefolder" onClick={handleClose}>
                                Rename Folder
                            </MenuItem>
                            <MenuItem id="deletefolder" onClick={handleClose}>
                                Delete Folder
                            </MenuItem>
                        </div>
                    )}
                </Menu>
                <Dialog
                    open={folderNameDialogOpen}
                    onClose={handleFolderRenameDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Rename Folder
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter a new name for the folder.
                        </DialogContentText>
                        <TextField
                            value={folderName}
                            onChange={handleKeyboardInput}
                            autoFocus
                            margin="dense"
                            id="name"
                            label=""
                            type=""
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleFolderRenameDialogClose("Cancel")}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleFolderRenameDialogClose("OK")}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={confirmDeleteDialogOpen}
                    onClose={handleConfirmDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Delete Folder
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this folder?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleConfirmDeleteDialogClose("Cancel")}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeleteDialogClose("OK")}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    );
}
