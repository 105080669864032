import React, { useState, useEffect, useContext } from "react";

import { Draggable } from "react-beautiful-dnd";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import FolderIcon from "@material-ui/icons/Folder";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Checkbox from "@material-ui/core/Checkbox";

// My Files
import UserAvatar from "../../../management/UserAvatar";
import { UserContext } from "../../../../context";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 5,
    },
    white: {
        backgroundColor: "rgb(245, 245, 245)",
        height: 60,
        padding: 5,
        borderRadius: 5,
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: 5,
        height: 60,
        borderRadius: 5,
    },
    grey: {
        backgroundColor: "rgb(210, 210, 210 )",
        padding: 5,
        height: 60,
        borderRadius: 5,
    },
    label: {
        textAlign: "left",
        cursor: "pointer",
        // marginTop: 3,
        // marginLeft: 5,
        marginTop: 17,
        marginLeft: 15,
        fontSize: 16,
        width: "90%",
        overflowWrap: "break-word",
        maxWidth: "90%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    folderIcon: {
        fontSize: 30,
        padding: 0,
        marginLeft: 5,
    },
    arrowIcon: {
        fontSize: 10,
    },
}));

export default function ArchiveFolderListItem(props) {
    const classes = useStyles();

    const { currentUserData } = useContext(UserContext);

    // const [isHub, setIsHub] = useState(false);

    const [selected, setSelected] = useState(false);

    const [canSelect, setCanSelect] = useState(false); // Can only select a folder if it is the main hub selected when archiving, or a sub folder of it

    useEffect(() => {
        // if (props.folder.hub) {
        //     setIsHub(true);
        // } else {
        //     setIsHub(false);
        // }

        if (currentUserData) {
            // console.clear();
            // console.log("Hub:");
            // console.log(props.archiveHub);
            // console.log("Folder:");
            // console.log(props.folder);
            // console.log("--------");

            if (props.archiveHub && props.folder) {
                if (
                    props.folder.ancestors.includes(props.archiveHub.key) ||
                    props.folder.key === props.archiveHub.key
                ) {
                    setCanSelect(true);
                }
            }

            setSelected(props.selected);
        }
    }, [props, currentUserData]);

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            props.handleFolderChecked(props.folder);
        } else {
            props.handleFolderUnchecked(props.folder);
        }
    };

    function handleClick() {
        props.handleClick(props.index);
    }

    return (
        <Draggable
            draggableId={props.folder.id}
            index={props.index}
            isDragDisabled={true}
        >
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.container}
                    ref={provided.innerRef}
                >
                    {props.green && (
                        <ListItem
                            key={props.folder.id}
                            className={classes.green}
                            onClick={handleClick}
                        >
                            {canSelect && (
                                <Checkbox
                                    checked={selected}
                                    onChange={handleCheckboxChange}
                                    color="default"
                                    inputProps={{
                                        "aria-label": "selected checkbox",
                                    }}
                                />
                            )}
                            {props.folder.hub ? (
                                <UserAvatar
                                    userOrHub={props.folder}
                                    path="hubs"
                                    variant="rounded"
                                />
                            ) : (
                                <FolderIcon className={classes.folderIcon} />
                            )}
                            <p className={classes.label}>{props.folder.name}</p>
                            {/* <p className={classes.label}>{props.folder.id}</p> */}
                            <ArrowForwardIosIcon
                                className={classes.arrowIcon}
                            />
                        </ListItem>
                    )}

                    {props.grey && (
                        <ListItem
                            key={props.folder.id}
                            className={classes.grey}
                            onClick={handleClick}
                        >
                            {canSelect && (
                                <Checkbox
                                    checked={selected}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "selected checkbox",
                                    }}
                                />
                            )}
                            {props.folder.hub ? (
                                <UserAvatar
                                    userOrHub={props.folder}
                                    path="hubs"
                                    variant="rounded"
                                />
                            ) : (
                                <FolderIcon className={classes.folderIcon} />
                            )}
                            <p className={classes.label}>{props.folder.name}</p>
                            {/* <p className={classes.label}>{props.folder.id}</p> */}
                            <ArrowForwardIosIcon
                                className={classes.arrowIcon}
                            />
                        </ListItem>
                    )}

                    {props.white && (
                        <ListItem
                            key={props.folder.id}
                            className={classes.white}
                            onClick={handleClick}
                        >
                            {canSelect && (
                                <Checkbox
                                    checked={selected}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                    inputProps={{
                                        "aria-label": "selected checkbox",
                                    }}
                                />
                            )}
                            {props.folder.hub ? (
                                <UserAvatar
                                    userOrHub={props.folder}
                                    path="hubs"
                                    variant="rounded"
                                />
                            ) : (
                                <FolderIcon className={classes.folderIcon} />
                            )}
                            <p className={classes.label}>{props.folder.name}</p>
                            {/* <p className={classes.label}>{props.folder.id}</p> */}
                            <ArrowForwardIosIcon
                                className={classes.arrowIcon}
                            />
                        </ListItem>
                    )}
                </div>
            )}
        </Draggable>
    );
}
