import {
    CallToAction,
    Content,
    Email,
    Header,
    Paragraph,
    Title,
} from "@rakered/email";

export default function ConsentFormAndPasswordEmail(props) {
    return (
        <Email>
            <Content>
                <Header logo="https://firebasestorage.googleapis.com/v0/b/nodiant-e20a8.appspot.com/o/180.png?alt=media&token=9821b2a9-1a70-4f63-a488-96377bb3a234" />
                <Title>Complete Registration</Title>

                <Paragraph>
                    You have been registered on the Nodiant Online Platform.
                </Paragraph>
                <Paragraph>
                    To finalise the process, we need your consent.
                </Paragraph>

                <CallToAction
                    href={`https://nodiant.faw.cymru/${props.path}?key=${props.userKey}&email=${props.email}`}
                >
                    Complete Registration
                </CallToAction>
            </Content>
        </Email>
    );
}
