import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Breadcrumbs } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SettingsIcon from "@material-ui/icons/Settings";
import Link from "@material-ui/core/Link";

// My Files
import { DatabaseContext, UserContext } from "../../../context";
import ToolbarWithLogo from "../../ToolbarWithLogo";
import FloatingAddButton from "../../FloatingAddButton";
import UserAvatar from "../UserAvatar";
import firebase from "../../../firebase";
import NoPermissionsMessage from "../../NoPermissionsMessage";
import { jsonFromArray, makeid } from "../../../utility";
import DialogWithTextInput from "../../DialogWithTextInput";

const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    container: {
        paddingBottom: 120,
    },
    avatar: {
        width: "50px",
        height: "50px",
    },
    listText: {
        paddingLeft: theme.spacing(1),
    },
    noHubsText: {
        marginTop: 15,
        marginLeft: 15,
    },
}));

export default function HubListPage() {
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();

    const [current, setCurrent] = useState({ name: "Hubs", key: "Root" }); // The currently selected parent hub

    const { folders, users } = useContext(DatabaseContext);

    const [userCanAccess, setUserCanAccess] = useState();
    const [userCanAdd, setUserCanAdd] = useState();

    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: "Hubs", key: "Root" },
    ]); // Used to keep track of the parent groups as user navigates down the tree, then use this to implement the breadcrumb

    const { currentUserData } = useContext(UserContext);

    const [visibleHubs, setVisibleHubs] = useState([]); // Just the hubs that are visible at the moment

    const [noHubsAtThisLevel, setNoHubsAtThisLevel] = useState(false);

    const [newHubDialogOpen, setNewHubDialogOpen] = useState(false);

    // When the user selects a hub, this is used to store the trail back up the tree to root. So the breadcrumb can be updated.
    let parentNodeTrail = [];

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData) {
            if (
                currentUserData.role === "Player" ||
                currentUserData.role === "FAW Player" ||
                currentUserData.role === "Club Player" ||
                currentUserData.role === "Coach Ed Student" ||
                currentUserData.role === "FAW Coach Ed Student" ||
                currentUserData.role === "Club Coach Ed Student" ||
                currentUserData.role === "FAW Parent" ||
                currentUserData.role === "Club Parent" ||
                currentUserData.role === "Referee"
            ) {
                setUserCanAccess(false);
            } else {
                setUserCanAccess(true);
            }
        }
        let key = location.pathname.replace("/hubs/", "");

        let currentHub = folders.filter((h) => h.key === key)[0];
        if (currentHub) {
            setCurrent(currentHub);
            if (currentUserData.role === "System Digital Manager") {
                setUserCanAdd(true);
            } else if (
                currentHub.managers &&
                currentHub.managers.includes(currentUserData.key)
            ) {
                setUserCanAdd(true);
            } else {
                setUserCanAdd(false);
            }

            // Get the route back to the root node for the breadcrumb
            if (currentHub.key === "root") {
                // Don't think it ever goes in here
                parentNodeTrail = [];
            } else {
                parentNodeTrail = [
                    {
                        name: currentHub.name,
                        key: currentHub.key,
                        parent: currentHub.parent,
                    },
                ];
            }
            //

            let potentiallyVisibleHubs = folders.filter(
                (f) => f.hub && f.parent === currentHub.key
            );

            potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
                a.name > b.name ? 1 : -1
            );

            if (potentiallyVisibleHubs.length === 0) {
                setNoHubsAtThisLevel(true);
            } else {
                setNoHubsAtThisLevel(false);
            }

            if (
                currentUserData &&
                currentUserData.role === "System Digital Manager"
            ) {
                setVisibleHubs(potentiallyVisibleHubs);
            } else {
                let visibleHubsArray = [];
                // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
                potentiallyVisibleHubs.forEach((hub) => {
                    folders
                        .filter(
                            (f) =>
                                f.hub &&
                                (f.ancestors.includes(hub.key) ||
                                    f.key === hub.key)
                        )
                        .forEach((descendantHub) => {
                            if (
                                descendantHub.managers.includes(
                                    currentUserData.key
                                )
                            ) {
                                if (!visibleHubsArray.includes(hub)) {
                                    visibleHubsArray.push(hub);
                                }
                            }
                        });
                });

                setVisibleHubs(visibleHubsArray);
            }

            getParents(currentHub.key);

            parentNodeTrail.push({
                name: "Hubs",
                key: "Root",
            });

            parentNodeTrail.reverse();

            setBreadcrumbs(parentNodeTrail);
        } else {
            let potentiallyVisibleHubs = folders.filter(
                (f) => f.hub && f.parent === "Root"
            );

            potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
                a.name > b.name ? 1 : -1
            );

            if (potentiallyVisibleHubs.length === 0) {
                setNoHubsAtThisLevel(true);
            } else {
                setNoHubsAtThisLevel(false);
            }

            if (
                currentUserData &&
                currentUserData.role === "System Digital Manager"
            ) {
                setUserCanAdd(true);

                setVisibleHubs(potentiallyVisibleHubs);
            } else {
                setUserCanAdd(false);

                let visibleHubsArray = [];
                // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
                potentiallyVisibleHubs.forEach((hub) => {
                    folders
                        .filter(
                            (f) =>
                                f.hub &&
                                (f.ancestors.includes(hub.key) ||
                                    f.key === hub.key)
                        )
                        .forEach((descendantHub) => {
                            if (
                                descendantHub.managers.includes(
                                    currentUserData.key
                                )
                            ) {
                                if (!visibleHubsArray.includes(hub)) {
                                    visibleHubsArray.push(hub);
                                }
                            }
                        });
                });

                setVisibleHubs(visibleHubsArray);
            }
        }

        return () => unsubscribe();
    }, [location.pathname, folders, history, currentUserData]);

    // Recursively gets the parentNodes for a folderNode. Puts them in parentNodeTrail array
    function getParents(key) {
        let folder = folders.filter((f) => f.key === key)[0];

        if (folder) {
            let parentFolder = folders.filter(
                (f) => f.key === folder.parent
            )[0];

            if (parentFolder) {
                parentNodeTrail.push({
                    name: parentFolder.name,
                    key: parentFolder.key,
                    parent: parentFolder.parent,
                });
                getParents(parentFolder.key);
            }
        }
    }

    function handleAddClick() {
        setNewHubDialogOpen(true);
    }

    const handleHubClick = (hub) => () => {
        setCurrent(hub);

        setBreadcrumbs((prevValue) => {
            return [...prevValue, { name: hub.name, key: hub.key }];
        });

        if (hub.name === "Root") {
            history.push("/hubs");
        } else {
            history.push(`/hubs/${hub.key}`);
        }
    };

    const handleManageHubClick = (hub) => () => {
        history.push("/managehub?key=" + hub.key);
    };

    const handleBreadcrumbClick = (breadcrumb) => () => {
        // Remove breadcrumbs after (and including) the one that has been pressed
        let index = 0;

        for (index = 0; index < breadcrumbs.length; index++) {
            let hub = breadcrumbs[index];

            if (hub.key === breadcrumb.key) {
                break;
            }
        }

        var newBreadCrumbs = [...breadcrumbs];

        newBreadCrumbs.length = index + 1;

        setBreadcrumbs(newBreadCrumbs);

        // Set the current parent to the breadcrumb that has been selected
        setCurrent(breadcrumb);

        if (breadcrumb.name === "Root") {
            history.replace("/hubs");
        } else {
            history.replace(`/hubs/${breadcrumb.key}`);
        }
    };

    function handleCloseNewHubDialog(hubName, okPressed) {
        if (hubName !== "" && okPressed) {
            // Create Hub in firebase
            var ref = firebase.database().ref("folders");

            // Have to convert arrays to Objects for storage in firebase
            var ancestorsObject = [];
            var usersObject = [];
            var managersObject = [];

            if (current.ancestors && current.key !== "Root") {
                ancestorsObject = jsonFromArray(current.ancestors);
                ancestorsObject[current.key] = true;

                if (current.users) {
                    usersObject = jsonFromArray(current.users);
                }

                if (current.managers) {
                }
                managersObject = jsonFromArray(current.managers);
            }

            let newHubKey = makeid(19);

            ref.child(newHubKey).set({
                hub: true,
                name: hubName,
                parent: current.key,
                ancestors: ancestorsObject,
                users: usersObject,
                managers: managersObject,
                imageURL: "",
            });

            // Hub has been added

            // Create All Users group for the hub
            let groupsRef = firebase.database().ref("groups");

            groupsRef.push({
                name: "All Users",
                hub: newHubKey,
                users: usersObject,
            });

            // When a user is added to a hub, need to set their user.admins array to contain all the managers of this hub
            if (current.users) {
                current.users.forEach((userKey) => {
                    let user = users.filter((u) => u.key === userKey)[0];

                    if (user) {
                        let userRef = firebase
                            .database()
                            .ref("users")
                            .child(user.key);
                        let userAdminsObject = {};

                        // Preserve the existing contents of user.admins
                        user.admins.forEach((adminKey) => {
                            userAdminsObject[adminKey] = true;
                        });

                        // Then add the managers from the current hub
                        current.managers.forEach((managerKey) => {
                            userAdminsObject[managerKey] = true;
                        });

                        userRef.update({ admins: userAdminsObject });
                    }
                });
            }
        }

        setNewHubDialogOpen(false);
    }
    return (
        <React.Fragment>
            {userCanAccess && (
                <>
                    <div className={classes.container}>
                        <Paper square>
                            <Typography
                                className={classes.text}
                                variant="h5"
                                gutterBottom
                            >
                                Hubs
                            </Typography>
                            <Breadcrumbs
                                separator="›"
                                aria-label="breadcrumb"
                                className={classes.text}
                                //maxItems="2"
                            >
                                {breadcrumbs.map((breadcrumb) => (
                                    <Link
                                        href="#"
                                        onClick={handleBreadcrumbClick(
                                            breadcrumb
                                        )}
                                        key={breadcrumb.key}
                                    >
                                        {breadcrumb.name}
                                    </Link>
                                ))}
                            </Breadcrumbs>
                            {noHubsAtThisLevel && (
                                <h5 className={classes.noHubsText}>
                                    There are no hubs at this level yet
                                </h5>
                            )}
                            <List className={classes.list}>
                                {visibleHubs.map((hub, index, array) => (
                                    <React.Fragment key={hub.key}>
                                        <ListItem
                                            button
                                            onClick={handleHubClick(hub)}
                                        >
                                            <ListItemAvatar>
                                                <UserAvatar
                                                    userOrHub={hub}
                                                    path="hubs"
                                                    variant="rounded"
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={hub.name}
                                                className={classes.listText}
                                            />
                                            {(currentUserData.role ===
                                                "System Digital Manager" ||
                                                hub.managers.includes(
                                                    currentUserData.key
                                                )) && (
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={handleManageHubClick(
                                                            hub
                                                        )}
                                                    >
                                                        <SettingsIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                        {index < array.length - 1 && (
                                            <Divider
                                                variant="inset"
                                                component="li"
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </List>
                        </Paper>
                    </div>
                    {userCanAdd && (
                        <FloatingAddButton onClick={handleAddClick} />
                    )}
                </>
            )}
            {userCanAccess === false && <NoPermissionsMessage />}
            <DialogWithTextInput
                open={newHubDialogOpen}
                onClose={handleCloseNewHubDialog}
                title={"New Hub"}
                message={"Enter a name for the new Hub."}
            />
            <ToolbarWithLogo />
        </React.Fragment>
    );
}
