import React, { useEffect, useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import SurveyQuestion from "./SurveyQuestion";
import firebase from "../../firebase";
import { makeid, stringToBool } from "../../utility";
import BackButton from "../BackButton";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 75,
    },
    paper: {
        padding: 15,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
    },
    submitButton: {
        marginLeft: 0,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SurveyResponsePage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();

    const { surveyInfo } = useContext(DatabaseContext);

    const [title, setTitle] = useState("");
    const [introduction, setIntroduction] = useState("");

    // const [key, setKey] = useState("");

    const [survey, setSurvey] = useState();

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [showRequiredText, setShowRequiredText] = useState(false);

    const [anonymous, setAnonymous] = useState(true);

    const getSurvey = useCallback(
        async (key) => {
            let surveyRef = firebase.database().ref("surveys").child(key);

            if (!survey) {
                console.log("Reading Survey from Firebase");

                surveyRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    let questionsArray = [];

                    if (data && data.questions) {
                        let questionKeys = Object.keys(data.questions);
                        let questionValues = Object.values(data.questions);

                        for (let i = 0; i < questionKeys.length; i++) {
                            var optionsArray = [];

                            let questionObject = {
                                key: questionKeys[i],
                                row: questionValues[i].row,
                                questionText: questionValues[i].questionText,
                                answerFormat: questionValues[i].answerFormat,
                                answerRequired: stringToBool(
                                    questionValues[i].answerRequired
                                ),
                            };

                            // Get the multiple choice options if present
                            if (questionValues[i].multipleChoiceOptions) {
                                let optionKeys = Object.keys(
                                    questionValues[i].multipleChoiceOptions
                                );
                                let optionValues = Object.values(
                                    questionValues[i].multipleChoiceOptions
                                );

                                for (let j = 0; j < optionKeys.length; j++) {
                                    let optionObject = {
                                        key: optionKeys[j],
                                        index: optionValues[j].index,
                                        text: optionValues[j].text,
                                    };

                                    optionsArray.push(optionObject);
                                }
                            }

                            questionObject.multipleChoiceOptions = optionsArray;
                            questionsArray.push(questionObject);
                        }
                    }

                    let survey = {
                        key: key,
                        questions: questionsArray.sort((a, b) =>
                            a.row > b.row ? 1 : -1
                        ),
                    };

                    // console.log(survey);
                    setSurvey(survey);
                });
            } else {
                console.log("Already Read Survey");
            }
        },
        [survey]
    );
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const key = urlParams.get("key");

        let matchingInfo = surveyInfo.filter((s) => s.key === key)[0];

        if (matchingInfo && currentUserData) {
            // setKey(matchingInfo.key);
            setTitle(matchingInfo.title);
            setIntroduction(matchingInfo.intro);
            setAnonymous(matchingInfo.anonymous);

            getSurvey(key);

            if (survey) {
                console.log("Have read survey succesfully");
            }
        }

        // eslint-disable-next-line
    }, [currentUserData, surveyInfo, survey]);

    function shouldSubmit() {
        if (survey.questions.length === 0) {
            return false;
        }

        var hasAnsweredAllRequired = true;
        survey.questions.forEach((question) => {
            if (question.answerRequired) {
                if (!questionHasBeenAnswered(question)) {
                    hasAnsweredAllRequired = false;
                }
            }
        });

        return hasAnsweredAllRequired;
    }

    function questionHasBeenAnswered(question) {
        if (
            question.answerFormat === "Short Text" ||
            question.answerFormat === "Long Text" ||
            question.answerFormat === "True or False" ||
            question.answerFormat === "Multiple Choice"
        ) {
            // These types all have a text answer
            if (
                question.responseTextAnswer === undefined ||
                question.responseTextAnswer === ""
            ) {
                return false;
            } else {
                return true;
            }

            // answerObject.answer = question.responseTextAnswer;
        } else if (question.answerFormat === "Number") {
            // Number answer
            if (question.responseNumberAnswer === undefined) {
                return false;
            } else {
                return true;
            }
        } else if (question.answerFormat === "Checkboxes") {
            // Multiple text answers
            if (question.responseCheckboxAnswers) {
                if (
                    question.responseCheckboxAnswers === undefined ||
                    question.responseCheckboxAnswers.length === 0
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        } else if (question.answerFormat === "Date") {
            // Date Answer
            if (question.responseDateAnswer === undefined) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    function handleSubmitPressed() {
        if (shouldSubmit() === false) {
            showStatusMessage(
                "Please give answers for all the required questions",
                "error"
            );
            setShowRequiredText(true);
            return;
        }

        // console.clear();
        // Add the answers to Firebase
        let responseKey = makeid(19);

        let ref = firebase
            .database()
            .ref("surveyresponses/")
            .child(responseKey);

        let userValue = "Anonymous";
        if (anonymous === false) {
            userValue = currentUserData.key;
        }

        var responseObject = {
            survey: survey.key,
            surveyTitle: title,
            submissionDate: dayjs().toString(),
            user: userValue,
        };

        ref.set(responseObject);

        survey.questions.forEach((question) => {
            var answerObject = {
                question: question.key,
                questionText: question.questionText,
                answerFormat: question.answerFormat,
            };
            // console.log(question);

            if (
                question.answerFormat === "Short Text" ||
                question.answerFormat === "Long Text" ||
                question.answerFormat === "True or False" ||
                question.answerFormat === "Multiple Choice"
            ) {
                // These types all have a text answer

                answerObject.answer = question.responseTextAnswer;

                // answerObject.answer = question.responseTextAnswer;
            } else if (question.answerFormat === "Number") {
                // Number answer
                answerObject.answer = question.responseNumberAnswer.toString();
            } else if (question.answerFormat === "Checkboxes") {
                // Multiple text answers
                if (question.responseCheckboxAnswers) {
                    var answersString = "";
                    question.responseCheckboxAnswers.forEach((answer) => {
                        answersString += answer + "£$%";
                    });

                    if (answersString !== "") {
                        answersString = answersString.substring(
                            0,
                            answersString.length - 3
                        );
                    }

                    answerObject.answer = answersString;
                }
            } else if (question.answerFormat === "Date") {
                // Date Answer
                answerObject.answer = question.responseDateAnswer.toString();
            }

            if (answerObject.answer !== undefined) {
                ref.child("answers").push(answerObject);
            }
        });

        history.goBack();
    }

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    return (
        <>
            <BackButton />
            <Container className={classes.pageContainer}>
                <Paper className={classes.paper} variant="outlined">
                    <h2>{title}</h2>
                    <Divider className={classes.divider} />
                    <h6>{introduction}</h6>
                </Paper>
                <div className={classes.dividerDiv} />
                {survey && survey.questions.length === 0 && (
                    <h6>The survey doesn't have any questions</h6>
                )}
                {survey &&
                    survey.questions.map((q) => (
                        <React.Fragment key={q.key}>
                            <SurveyQuestion
                                key={q.key}
                                surveyKey={survey.key}
                                question={q}
                                showRequiredText={showRequiredText}
                                questionHasBeenAnswered={
                                    questionHasBeenAnswered
                                }
                            />
                            <div className={classes.dividerDiv} />
                        </React.Fragment>
                    ))}
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitButton}
                    onClick={handleSubmitPressed}
                >
                    Submit
                </Button>
            </Container>
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={5000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            <ToolbarWithLogo />
        </>
    );
}
