import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Avatar, Toolbar } from "@material-ui/core";
// import IconButton from "@material-ui/core/IconButton";
// import AddIcon from "@material-ui/icons/Add";
// import SearchIcon from "@material-ui/icons/Search";
// import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: "auto",
        bottom: 0,
    },
    logo: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
    },
    grow: {
        flexGrow: 1,
    },
}));

export default function ToolbarWithLogo() {
    const classes = useStyles();

    return (
        <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar>
                <Avatar
                    className={classes.logo}
                    variant="square"
                    alt="FAW Logo"
                    src="../images/FAW_CLEAR.png"
                />
                {/* <div className={classes.grow} />
                <IconButton color="inherit" className={classes.search}>
                    <SearchIcon />
                </IconButton>
                <IconButton edge="end" color="inherit">
                    <FilterListIcon />
                </IconButton> */}
            </Toolbar>
        </AppBar>
    );
}
