import React, { useEffect, useState, useContext } from "react";
import LazyLoad from "react-lazyload";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import SaveIcon from "@material-ui/icons/Save";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// My Files
import UserAvatar from "../UserAvatar";
import { UserContext } from "../../../context";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: "100%",
    },
    container: {
        height: "100vh",
        background: "rgb(230, 230, 230)",
    },
    chip: {
        marginLeft: 15,
        marginTop: 15,
    },
    nameTextField: {
        marginLeft: 60,
        marginRight: 15,
        marginTop: 25,
    },
    filterTextField: {
        marginLeft: 70,
        marginRight: 15,
        marginTop: 0,
    },
    usersTitle: {
        marginTop: 15,
        marginLeft: 60,
        marginBottom: 0,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditGroupDialog(props) {
    const classes = useStyles();

    const { currentUserData } = useContext(UserContext);
    const [usernameFilter, setUsernameFilter] = useState("");

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [otherUsers, setOtherUsers] = useState([]);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const [groupName, setGroupName] = useState("");

    useEffect(() => {
        let selectedArray = [];
        let otherArray = [];

        props.hubUsers.forEach((user) => {
            if (
                props.group.users.filter((u) => u.key === user.key).length > 0
            ) {
                selectedArray.push(user);
            } else {
                otherArray.push(user);
            }
        });

        selectedArray.sort((a, b) =>
            a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
        );

        otherArray.sort((a, b) =>
            a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
        );

        setSelectedUsers(selectedArray);
        setOtherUsers(otherArray);
        setGroupName(props.group.name);

        // eslint-disable-next-line
    }, [props.open]);

    const handleUserSelect = (user) => () => {
        let selectedArray = [...selectedUsers];
        selectedArray.push(user);
        setSelectedUsers(selectedArray);
        setUsernameFilter("");
    };

    const handleClose = () => {
        if (groupName !== "") {
            setUsernameFilter("");
            props.onClose(selectedUsers, props.group);
        }
    };

    const handleDeleteChip = (user) => () => {
        let selectedArray = selectedUsers.filter((u) => u.key !== user.key);

        setSelectedUsers(selectedArray);

        let otherArray = [...otherUsers, user];

        setOtherUsers(otherArray);
    };

    const handleChangeText = (event) => {
        if (event.target.id === "groupName") {
            setGroupName(event.target.value);
            props.group.name = event.target.value;
        } else if (event.target.id === "usernameFilter") {
            setUsernameFilter(event.target.value);
        }
    };

    function handleDeleteClick() {
        setConfirmDeleteDialogOpen(true);
    }

    function handleCloseOnDeleteDialog(result) {
        if (result === true && currentUserData) {
            // Delete Group
            props.onDelete(props.group);
        }

        setConfirmDeleteDialogOpen(false);
    }

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <SaveIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.titleText}>
                            Edit Group
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleDeleteClick}
                            aria-label="close"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container fluid className={classes.container}>
                    <Row>
                        <TextField
                            className={classes.nameTextField}
                            id="groupName"
                            label="Name"
                            variant="outlined"
                            value={groupName}
                            onChange={handleChangeText}
                        />
                    </Row>
                    <Row>
                        <h2 className={classes.usersTitle}>Users</h2>
                    </Row>
                    <Row>
                        {selectedUsers
                            .sort((a, b) =>
                                a.lastName.toLowerCase() >
                                b.lastName.toLowerCase()
                                    ? 1
                                    : -1
                            )
                            .map((user) => (
                                <Chip
                                    avatar={
                                        <Avatar
                                            alt={user.fullName}
                                            src={user.avatar}
                                        />
                                    }
                                    key={user.key}
                                    label={user.fullName}
                                    className={classes.chip}
                                    onDelete={handleDeleteChip(user)}
                                    variant="outlined"
                                />
                            ))}
                    </Row>
                    <Row>
                        <TextField
                            id="usernameFilter"
                            label="Type User Name"
                            className={classes.filterTextField}
                            fullWidth
                            value={usernameFilter}
                            onChange={handleChangeText}
                        ></TextField>
                    </Row>
                    <Row>
                        <List className={classes.list}>
                            {otherUsers
                                .filter(
                                    (user) =>
                                        selectedUsers.filter(
                                            (u) => u.key === user.key
                                        ).length === 0
                                )
                                .filter((user) =>
                                    user.fullName.includes(usernameFilter)
                                )
                                .sort((a, b) =>
                                    a.lastName.toLowerCase() >
                                    b.lastName.toLowerCase()
                                        ? 1
                                        : -1
                                )
                                .map((user) => (
                                    <div
                                        key={user.key}
                                        onClick={handleUserSelect(user)}
                                    >
                                        <ListItem
                                            button
                                            alignItems="flex-start"
                                            // selected={selectedIndex === index}
                                        >
                                            <ListItemAvatar>
                                                <LazyLoad overflow>
                                                    <UserAvatar
                                                        userOrHub={user}
                                                        path="users"
                                                        variant="circular"
                                                    />
                                                </LazyLoad>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={user.fullName}
                                                secondary={user.customRoleName}
                                            />
                                        </ListItem>
                                        <Divider
                                            variant="inset"
                                            component="li"
                                        />
                                    </div>
                                ))}
                        </List>
                    </Row>
                </Container>
            </Dialog>
            {confirmDeleteDialogOpen && (
                <ConfirmDeleteDialog
                    title="Delete Group"
                    message="Are you sure you want to delete this group?"
                    open={confirmDeleteDialogOpen}
                    onClose={handleCloseOnDeleteDialog}
                />
            )}
        </>
    );
}
