import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { CirclePicker } from "react-color";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    calendarControl: { margin: 20 },
    textField: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
    colorPicker: {
        // paddingLeft: 5,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        alignSelf: "center",
    },
    button: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
    },
}));

export default function NewEventDialog(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [color, setColor] = useState("#f44336");
    const [selectedCalendar, setSelectedCalendar] = useState();

    useEffect(() => {
        if (!selectedCalendar && props.calendars[0]) {
            setSelectedCalendar(props.calendars[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const handleClose = () => {
        props.onClose();
    };

    const handleTextChange = (event) => {
        setName(event.target.value);
    };

    const handleAddClick = () => {
        if (name !== "") {
            props.addEvent(name, selectedCalendar, color);
            setName("");
            setColor("#f44336");
        }
    };

    const handleColorChange = (color) => {
        setColor(color.hex);
    };

    const handleCalendarChange = (event) => {
        let selected = props.calendars.filter(
            (c) => c.key === event.target.value
        )[0];
        if (selected) {
            setSelectedCalendar(selected);
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            className={classes.root}
        >
            <h4 className={classes.title}>
                {dayjs(props.date).format("DD/MM/YYYY")}
            </h4>
            <FormControl className={classes.calendarControl}>
                <InputLabel htmlFor="age-native-simple">Calendar</InputLabel>
                <Select
                    native
                    value={selectedCalendar && selectedCalendar.key}
                    onChange={handleCalendarChange}
                >
                    {props.calendars.map((calendar) => (
                        <option key={calendar.key} value={calendar.key}>
                            {calendar.name}
                        </option>
                    ))}
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                id="outlined-basic"
                label="Event Name"
                variant="outlined"
                value={name}
                onChange={handleTextChange}
            />
            <CirclePicker
                onChange={handleColorChange}
                color={color}
                className={classes.colorPicker}
            />
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleAddClick}
            >
                Add Event
            </Button>
        </Dialog>
    );
}
