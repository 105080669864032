import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext, DatabaseContext } from "../../../../context";
import { Chip } from "@material-ui/core";
import { shouldPlaylistBeVisible } from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
    title: {
        marginTop: 15,
    },
    cancelButton: {
        position: "absolute",
        top: 5,
        left: 5,
        bottom: "auto",
        right: "auto",
        padding: 2,
    },
    chip: {
        marginLeft: "2%",
        width: "96%",
        marginBottom: 5,
        minWidth: 250,
    },
}));

export default function PlaylistSelectDialog(props) {
    const classes = useStyles();
    const history = useHistory();
    const { currentUserData } = useContext(UserContext);
    const { playlistInfo, groups } = useContext(DatabaseContext);

    const [visiblePlaylists, setVisiblePlaylists] = useState([]);

    useEffect(() => {
        if (currentUserData) {
            if (props.file) {
                let playlistsArray = [];

                playlistInfo.forEach((playlist) => {
                    if (
                        shouldPlaylistBeVisible(
                            playlist,
                            groups,
                            currentUserData
                        )
                    ) {
                        if (playlist.video === props.file.key) {
                            playlistsArray.push(playlist);

                            if (playlist.owner === currentUserData.key) {
                                playlist.isOwner = true;
                            }
                        }
                    }
                });

                setVisiblePlaylists(playlistsArray);
            }
        }
        // eslint-disable-next-line
    }, [props, playlistInfo, currentUserData]);

    const handleClose = () => {
        props.onClose();
    };

    const handlePlaylistClick = (playlist) => () => {
        if (props.file && props.file.key) {
            history.push("/playlist?key=" + playlist.key);
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            maxWidth="lg"
            open={props.open}
            className={classes.root}
        >
            <Container fluid>
                <Col>
                    <h4 className={classes.title}>Playlists</h4>
                    {visiblePlaylists.length === 0 ? (
                        <span>
                            You haven't created any playlists for this video
                        </span>
                    ) : (
                        <List>
                            {visiblePlaylists.map((p) => (
                                <Row key={p.key}>
                                    <Chip
                                        className={classes.chip}
                                        label={p.name}
                                        onClick={handlePlaylistClick(p)}
                                        style={
                                            p.isOwner
                                                ? {
                                                      backgroundColor:
                                                          "rgb(0, 149, 61)",
                                                      color: "white",
                                                  }
                                                : {
                                                      backgroundColor:
                                                          "rgb(220, 220, 220)",
                                                  }
                                        }
                                    />
                                </Row>
                            ))}
                        </List>
                    )}
                </Col>
            </Container>
        </Dialog>
    );
}
