import React, { useEffect, useContext, useState } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import { filePath, shouldPlaylistBeVisible } from "../../utility";

const useStyles = makeStyles((theme) => ({
    recentTableContainer: {
        marginTop: 15,
        marginBottom: 15,
        maxHeight: 264,
        overflow: "scroll",
    },
    allTableContainer: {
        marginTop: 15,
        marginBottom: 50,
        overflow: "scroll",
    },
    contentContainer: {
        paddingLeft: 15,
        paddingTop: 15,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectSelectPlaylistDialog(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const { files, folders, playlistInfo, playlistUsage, groups } =
        useContext(DatabaseContext);

    const [allPlaylists, setAllPlaylists] = useState([]);
    const [recentPlaylists, setRecentPlaylists] = useState([]);

    useEffect(() => {
        if (currentUserData) {
            // Get playlists
            let allPlaylistsArray = [];
            let recentPlaylistsArray = [];

            playlistInfo.forEach((playlist) => {
                if (
                    shouldPlaylistBeVisible(playlist, groups, currentUserData)
                ) {
                    // Shared and owned playlists have different text color

                    if (playlist.owner === currentUserData.key) {
                        playlist.isOwner = true;
                    }

                    // Find path of the video file
                    let video = files.filter(
                        (f) => f.key === playlist.video
                    )[0];
                    if (video) {
                        let folder = folders.filter(
                            (f) => f.key === video.folder
                        )[0];
                        playlist.filePath = filePath(video, folder, folders);
                    }

                    allPlaylistsArray.push(playlist);

                    let usageObject = playlistUsage.filter(
                        (u) =>
                            u.user === currentUserData.key &&
                            u.playlist === playlist.key
                    )[0];
                    if (usageObject) {
                        playlist.lastOpened = usageObject.lastOpened;
                        recentPlaylistsArray.push(playlist);
                    }
                }
            });

            recentPlaylistsArray.sort((a, b) =>
                dayjs(a.lastOpened) < dayjs(b.lastOpened)
                    ? 1
                    : dayjs(b.lastOpened) < dayjs(a.lastOpened)
                    ? -1
                    : 0
            );

            allPlaylistsArray.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );

            setRecentPlaylists(recentPlaylistsArray);
            setAllPlaylists(allPlaylistsArray);
        }

        // eslint-disable-next-line
    }, [currentUserData, playlistInfo, playlistUsage]);

    const handlePlaylistSelect = (playlist) => () => {
        props.onClose(playlist);
    };

    function handleCancelPress() {
        props.onClose();
    }

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <Tooltip title="Cancel">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCancelPress}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h6" className={classes.title}>
                        Select Playlist
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <div className={classes.contentContainer}>
                <h5>Recent Playlists</h5>
                <TableContainer
                    component={Paper}
                    className={classes.recentTableContainer}
                >
                    <Table aria-label="simple table">
                        {recentPlaylists.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                        }}
                                    />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {recentPlaylists.map((playlist) => (
                                    <TableRow
                                        key={playlist.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="center"
                                            style={
                                                playlist.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {playlist.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="left"
                                            style={
                                                playlist.isOwner && {
                                                    color: "rgb(0, 149, 61)",
                                                }
                                            }
                                        >
                                            {playlist.filePath}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <h5>All Playlists</h5>
                <TableContainer
                    component={Paper}
                    className={classes.allTableContainer}
                >
                    <Table aria-label="simple table">
                        {allPlaylists.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRight:
                                                "1px solid rgb(200,200,200)",
                                        }}
                                    />
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {allPlaylists.map((playlist) => (
                                    <TableRow
                                        key={playlist.key}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="center"
                                            style={
                                                playlist.isOwner
                                                    ? {
                                                          color: "rgb(0, 149, 61)",
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                                    : {
                                                          borderRight:
                                                              "1px solid rgb(200,200,200)",
                                                      }
                                            }
                                        >
                                            {playlist.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={handlePlaylistSelect(
                                                playlist
                                            )}
                                            align="left"
                                            style={
                                                playlist.isOwner && {
                                                    color: "rgb(0, 149, 61)",
                                                }
                                            }
                                        >
                                            {playlist.filePath}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    );
}
