import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import UserAvatar from "../management/UserAvatar";
import firebase from "../../firebase";
import NewGroupDialog from "./NewGroupDialog";
import { uniq } from "../../utility";

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
    },
    appBar: {
        position: "relative",
    },
    container: {
        height: "100%",
    },
    chip: {
        marginLeft: 15,
        marginTop: 15,
    },
    nameTextField: {
        marginLeft: 70,
        marginRight: 15,
        marginTop: 5,
    },
    title: {
        flexGrow: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewConversationDialog(props) {
    const classes = useStyles();

    const { users, folders } = useContext(DatabaseContext);

    const [availableUsers, setAvailableUsers] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [usernameFilter, setUsernameFilter] = useState("");

    const [groupDialogOpen, setGroupDialogOpen] = useState(false);

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        if (currentUserData) {
            var userKeys = [];
            // Find all the users that are in the same hubs as current user
            folders
                .filter((f) => f.hub)
                .forEach((hub) => {
                    if (hub.users) {
                        if (hub.users.includes(currentUserData.key)) {
                            hub.managers
                                .filter((u) => u !== currentUserData.key)
                                .forEach((user) => {
                                    userKeys.push(user);
                                });

                            hub.users
                                .filter((u) => u !== currentUserData.key)
                                .forEach((user) => {
                                    userKeys.push(user);
                                });
                        }
                    }

                    if (hub.managers) {
                        if (hub.managers.includes(currentUserData.key)) {
                            hub.managers
                                .filter((u) => u !== currentUserData.key)
                                .forEach((user) => {
                                    userKeys.push(user);
                                });

                            hub.users
                                .filter((u) => u !== currentUserData.key)
                                .forEach((user) => {
                                    userKeys.push(user);
                                });
                        }
                    }
                });

            // usersToAdd contains all the user keys that the current user can see, now find the matching user objects

            var usersToAdd = [];
            uniq(userKeys).forEach((userKey) => {
                let matchingUser = users.filter((u) => u.key === userKey)[0];

                if (matchingUser) {
                    usersToAdd.push(matchingUser);
                }
            });

            setAvailableUsers(usersToAdd);
        }
        /// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData, users, folders]);

    const handleUserSelect = (user) => () => {
        let selectedArray = [...selectedUsers];
        selectedArray.push(user);
        setSelectedUsers(selectedArray);
        setUsernameFilter("");
    };

    const handleDeleteChip = (user) => () => {
        let selectedArray = selectedUsers.filter((u) => u.key !== user.key);

        setSelectedUsers(selectedArray);
    };

    const handleClose = (didAdd) => {
        setUsernameFilter("");
        setSelectedUsers([]);
        props.onClose(didAdd);
    };

    const handleChangeFilterText = (event) => {
        setUsernameFilter(event.target.value);
    };

    function handleCloseGroupDialog() {
        setGroupDialogOpen(false);
    }

    function handleAddClick() {
        // If just one user, can add the conversation straight away
        if (selectedUsers.length === 1) {
            const ref = firebase.database().ref().child("conversations");
            let selectedUserId = selectedUsers[0].key;

            let conversation = {
                participants: {
                    [currentUserData.key]: true,
                    [selectedUserId]: true,
                },
                lastMessage: dayjs().toString(),
                deleted: false,
            };
            ref.push(conversation);
            handleClose(true);
        } else if (selectedUsers.length > 1) {
            // Adding multiple users, so need to create a group. Launch a dialog to set group name, avatar & options
            setGroupDialogOpen(true);
        }
    }

    function createGroup(group, downloadURL) {
        setGroupDialogOpen(false);
        const ref = firebase.database().ref().child("conversations");

        let participants = { [currentUserData.key]: true };

        selectedUsers.forEach((user) => {
            let key = user.key;
            participants[key] = true;
        });

        ref.child(group.key).set({
            title: group.title,
            participants: participants,
            imageURL: downloadURL,
            lastMessage: dayjs().toString(),
            deleted: false,
        });

        handleClose(true);
    }

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={props.open}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        New Conversation
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleAddClick}
                        className={classes.menuButton}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container fluid className={classes.container}>
                <Row>
                    {selectedUsers.map((user) => (
                        <Chip
                            avatar={
                                <Avatar alt={user.fullName} src={user.avatar} />
                            }
                            key={user.key}
                            label={user.fullName}
                            className={classes.chip}
                            onDelete={handleDeleteChip(user)}
                            // clickable
                            // color="primary"
                            variant="outlined"
                        />
                    ))}
                </Row>
                <Row>
                    <TextField
                        id="standard-basic"
                        label="Type User Name"
                        className={classes.nameTextField}
                        fullWidth
                        value={usernameFilter}
                        onChange={handleChangeFilterText}
                    ></TextField>
                </Row>
                <Row>
                    <List className={classes.list}>
                        {currentUserData &&
                            availableUsers
                                .filter(
                                    (user) =>
                                        user.key !== currentUserData.key &&
                                        selectedUsers.filter(
                                            (u) => u.key === user.key
                                        ).length === 0
                                )
                                .filter((user) =>
                                    user.fullName.includes(usernameFilter)
                                )
                                .map((user) => (
                                    <div
                                        key={user.key}
                                        onClick={handleUserSelect(user)}
                                    >
                                        <ListItem
                                            button
                                            alignItems="flex-start"
                                            // selected={selectedIndex === index}
                                        >
                                            <ListItemAvatar>
                                                <LazyLoad overflow>
                                                    <UserAvatar
                                                        userOrHub={user}
                                                        path="users"
                                                        variant="circular"
                                                    />
                                                </LazyLoad>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={user.fullName}
                                            />
                                        </ListItem>
                                        <Divider
                                            variant="inset"
                                            component="li"
                                        />
                                    </div>
                                ))}
                    </List>
                </Row>
            </Container>
            <NewGroupDialog
                open={groupDialogOpen}
                onClose={handleCloseGroupDialog}
                createGroup={createGroup}
            />
        </Dialog>
    );
}
