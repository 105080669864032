import React, { useState, useContext, useEffect } from "react";
// import dayjs from "dayjs";

import { Draggable } from "react-beautiful-dnd";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Checkbox from "@material-ui/core/Checkbox";

// My Files
import { UserContext } from "../../../../context";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingRight: 10,
    },
    label: {
        textAlign: "left",
        cursor: "pointer",
        marginTop: 15,
        marginLeft: 10,
        width: "100%",
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    listItem: {
        backgroundColor: "rgb(245, 245, 245)",
        padding: 5,
        height: 60,
        borderRadius: 5,
        margin: 5,
    },
}));

export default function ArchiveImageListItem(props) {
    const classes = useStyles();

    const { currentUserData } = useContext(UserContext);

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (currentUserData) {
            setSelected(props.selected);
        }
    }, [props, currentUserData]);

    function handleClick() {
        if (selected) {
            setSelected(false);
            props.handleFileDeselect(props.file);
        } else {
            setSelected(true);
            props.handleFileSelect(props.file);
        }
    }

    return (
        <Draggable
            draggableId={props.file.key}
            index={props.index}
            isDragDisabled={true}
        >
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.container}
                    ref={provided.innerRef}
                >
                    <ListItem
                        onClick={handleClick}
                        className={classes.listItem}
                    >
                        <Checkbox
                            checked={selected}
                            onChange={handleClick}
                            color="primary"
                            inputProps={{ "aria-label": "selected checkbox" }}
                        />
                        <Avatar
                            variant="rounded"
                            src={props.file.url}
                            className={classes.thumbnail}
                        >
                            <InsertDriveFileIcon />
                        </Avatar>
                        <p className={classes.label}>
                            {props.file.displayName}
                        </p>
                    </ListItem>
                </div>
            )}
        </Draggable>
    );
}
