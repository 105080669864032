import { useReducer, useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Material UI
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Box } from "@material-ui/core";
// import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";

// My Files
import FloatingUploadButton from "../../FloatingUploadButton";
import DragDropFileBox from "./DragDropFileBox";
import FileForUpload from "./FileForUpload";
import ToolbarWithLogo from "../../ToolbarWithLogo";
import BackButton from "../../BackButton";
import { DatabaseContext, UserContext } from "../../../context";
import firebase from "../../../firebase";
import NoPermissionsMessage from "../../NoPermissionsMessage";
import DialogWithMessage from "../../DialogWithMessage";
import UploadsMenu from "../../navbar/UploadsMenu";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: { paddingBottom: 65 },
}));

export default function UploadFilesPage() {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();
    const { users, calendars, folders, groups } = useContext(DatabaseContext);
    const [managers, setManagers] = useState([]);
    const [alertIsVisible, setAlertIsVisible] = useState(false);

    const [fileSizeDialogIsVisible, setFileSizeDialogIsVisible] =
        useState(false);

    const [userCanUpload, setUserCanUpload] = useState();
    const { currentUserData } = useContext(UserContext);

    const [availableCalendars, setAvailableCalendars] = useState([]);
    const [ancestorHubs, setAncestorHubs] = useState([]);

    const [uploadCount, setUploadCount] = useState(-1);
    const [uploads, setUploads] = useState([]);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = (key) => {
        return (
            <Box position="relative" display="inline-flex">
                <>
                    <UploadsMenu uploadCount={uploadCount} uploads={uploads} />
                    <Tooltip title="Dismiss">
                        <IconButton onClick={handleDismissSnackbarPressed}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </>
            </Box>
        );
    };

    // Use this so we can call the upload functions in all the FileForUpload elements
    var biRef = { filesForUpload: [] };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        // let userArray = [];
        let managersArray = [];

        // If Location.folder is set, the user came here from the File Navigator Page
        if (location.folder) {
            // Get managers of the current folder
            location.folder.managers.forEach((managerId) => {
                let manager = users.filter((u) => u.key === managerId)[0];

                if (manager) {
                    managersArray.push(manager);
                }
            });

            // Get default users of the current folder
            // Only use this for the option to add all users of the current hub
            // location.folder.users.forEach((userId) => {
            //     let user = users.filter((u) => u.key === userId)[0];

            //     if (user) {
            //         userArray.push(user);
            //     }
            // });

            // Get ancestor hubs, these are used for the groups and users
            let ancestors = folders.filter(
                (f) => f.hub && location.folder.ancestors.includes(f.key)
            );

            // Only include the ancestors that can be managed by the uploading user
            ancestors = ancestors.filter(
                (a) =>
                    a.managers.includes(currentUserData.key) ||
                    currentUserData.role === "System Digital Manager" ||
                    a.users.includes(currentUserData.key)
            );

            // If the current folder is a hub, add that too
            if (location.folder.hub) {
                ancestors.push(location.folder);
            }

            // Get the groups for these ancestors
            ancestors.forEach((ancestorHub) => {
                let matchingGroups = groups.filter(
                    (g) => g.hub === ancestorHub.key
                );

                // Reset the didSelect from the group, in case you are going in and out of uploadFilesPage
                // matchingGroups.forEach((group) => {
                //     group.didSelect = false;
                // });

                ancestorHub.groups = matchingGroups;
            });

            setAncestorHubs(ancestors);

            // Get calendars
            if (
                location.folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager" ||
                currentUserData.permissions.uploadEditOwnVideo
            ) {
                setUserCanUpload(true);

                // Find calendars that user can see
                let calendarsArray = [];

                calendars.forEach((calendar) => {
                    let shouldAdd = false;

                    // User should be able to see calendar if it's their personal one, or if they are uploading a file
                    // to the hub for that calendar
                    if (calendar.user === currentUserData.key) {
                        shouldAdd = true;
                    } else if (calendar.hub === location.folder.key) {
                        shouldAdd = true;
                    } else if (
                        location.folder.ancestors.includes(calendar.hub)
                    ) {
                        shouldAdd = true;
                    }

                    if (shouldAdd) {
                        calendarsArray.push(calendar);
                    }
                });

                setAvailableCalendars(calendarsArray);
            } else {
                setUserCanUpload(false);
            }
        } else {
            history.replace("/files");
        }

        // setDefaultUsers(userArray);
        setManagers(managersArray);

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [
        location.folder,
        users,
        history,
        currentUserData,
        calendars,
        folders,
        groups,
    ]);

    const reducer = (state, action) => {
        switch (action.type) {
            case "SET_DROP_DEPTH":
                return { ...state, dropDepth: action.dropDepth };
            case "SET_IN_DROP_ZONE":
                return { ...state, inDropZone: action.inDropZone };
            case "ADD_FILE_TO_LIST":
                return {
                    ...state,
                    fileList: state.fileList.concat(action.files),
                };
            case "REMOVE_FILE_FROM_LIST":
                return {
                    ...state,
                    fileList: state.fileList.filter(
                        (f) => f.key !== action.file.key
                    ),
                };
            default:
                return state;
        }
    };

    const [data, dispatch] = useReducer(reducer, {
        dropDepth: 0,
        inDropZone: false,
        fileList: [],
    });

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    function copyFileSettingsToAll(settings) {
        for (let i = 0; i < biRef.filesForUpload.length; i++) {
            biRef.filesForUpload[i].changeSettings(settings);
        }

        setAlertIsVisible(true);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertIsVisible(false);
    };

    function handleUploadButtonClick() {
        setUploadCount(biRef.filesForUpload.length);
        setUploads([]);

        enqueueSnackbar("Uploading files", {
            variant: "default",
            action,
            persist: true,
            key: "fileUpload",
            preventDuplicate: true,
        });

        for (let i = 0; i < biRef.filesForUpload.length; i++) {
            biRef.filesForUpload[i].startUpload();
        }

        history.goBack();
    }

    function showFileSizeMessage() {
        setFileSizeDialogIsVisible(true);
    }

    function handleCloseFileSizeDialog() {
        setFileSizeDialogIsVisible(false);
    }

    function removeUpload(file) {
        dispatch({ type: "REMOVE_FILE_FROM_LIST", file });

        // data.fileList.filter((f) => f.key !== file.key);
        // state.fileList.filter((f) => f.key !== file.key);
    }

    function handleUploadStart(file) {
        var newUploads = uploads;

        newUploads.push({ file: file, progress: 0, show: true });
        setUploads(newUploads);
    }

    function handleUploadProgress(file, progress, tusUpload) {
        let matchingUpload = uploads.filter((u) => u.file.key === file.key)[0];

        if (matchingUpload) {
            matchingUpload.tusUpload = tusUpload;
            matchingUpload.progress = progress;
        }

        setUploads(uploads);
        forceUpdate();

        enqueueSnackbar("Uploading files", {
            variant: "default",
            action,
            persist: true,
            key: "fileUpload",
            preventDuplicate: true,
        });
    }

    function handleDismissSnackbarPressed() {
        closeSnackbar("fileUpload");
    }

    return (
        <>
            {userCanUpload && (
                <div
                    className={classes.root}
                    onDrop={(e) => handleDrop(e)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDragEnter={(e) => handleDragEnter(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                >
                    <BackButton />
                    <DragDropFileBox data={data} dispatch={dispatch} />
                    {data.fileList.map((f) => {
                        return (
                            <FileForUpload
                                key={f.name}
                                file={f}
                                // defaultUsers={defaultUsers}
                                managers={managers}
                                folder={location.folder}
                                biRef={biRef}
                                handleCopyClick={copyFileSettingsToAll}
                                handleRemoveClick={removeUpload}
                                calendars={availableCalendars}
                                ancestorHubs={ancestorHubs}
                                showFileSizeMessage={showFileSizeMessage}
                                handleUploadStart={handleUploadStart}
                                handleUploadProgress={handleUploadProgress}
                            />
                        );
                    })}
                    <FloatingUploadButton onClick={handleUploadButtonClick} />
                    <div>
                        <Snackbar
                            open={alertIsVisible}
                            autoHideDuration={6000}
                            onClose={handleAlertClose}
                        >
                            <Alert
                                onClose={handleAlertClose}
                                severity="success"
                            >
                                Settings copied to all files
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            )}
            {userCanUpload === false && <NoPermissionsMessage />}
            <ToolbarWithLogo />
            <DialogWithMessage
                open={fileSizeDialogIsVisible}
                onClose={handleCloseFileSizeDialog}
                title={"File Size Warning"}
                message={"One or more of the selected files is larger than 5GB"}
            />
        </>
    );
}
