// Material UI
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function ConfirmDeleteDialog(props) {
    function handleCancel() {
        props.onClose(false);
    }

    function handleDelete() {
        props.onClose(true);
    }

    return (
        <>
            <Dialog
                onClose={handleCancel}
                aria-labelledby="simple-dialog-title"
                open={props.open}
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.message}</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Delete
                    </Button>
                </DialogActions>
                {props.inProgress && <LinearProgress />}
            </Dialog>
        </>
    );
}
