import React, { useState, useEffect } from "react";

import axios from "axios";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import GroupIcon from "@material-ui/icons/Group";

// My Files
import { encodeImage } from "../../utility";

const useStyles = makeStyles((theme) => ({}));

export default function ConversationGroupAvatar(props) {
    const classes = useStyles(props);
    const [avatar, setAvatar] = useState();
    const [foundImage, setFoundImage] = useState(false);

    useEffect(() => {
        const getImage = () => {
            if (!props.conversation) {
                return;
            }

            if (props.conversation.avatar) {
                setAvatar(props.conversation.avatar);
                setFoundImage(true);
                return;
            }

            console.log("Downloading Group Avatar from Firebase");

            axios
                .get(props.conversation.imageURL, {
                    responseType: "arraybuffer",
                })
                .then((response) => {
                    const buffer = Buffer.from(response.data, "base64");
                    const photo =
                        "data:image/png;base64," + encodeImage(buffer);

                    setAvatar(photo);
                    props.conversation.avatar = photo;
                    setFoundImage(true);
                })
                .catch((ex) => {
                    console.error(ex);
                });
        };

        getImage();
    }, [props.conversation, avatar]);

    // if (!avatar) {
    //     return <Skeleton variant="circle" width={40} height={40} />;
    // }

    return (
        <div>
            {foundImage ? (
                <Avatar
                    className={classes.avatar}
                    alt="Profile Picture"
                    src={avatar}
                    variant={props.variant}
                />
            ) : (
                <Avatar
                    className={classes.avatar}
                    alt="Profile Picture"
                    variant={props.variant}
                >
                    <GroupIcon />
                </Avatar>
            )}
        </div>
    );
}
