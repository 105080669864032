// Material UI
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import ProjectCommentSection from "./ProjectCommentSection";

export default function ProjectCommentsDialog(props) {
    function handleOK() {
        props.onClose(false);
    }

    return (
        <>
            <Dialog
                onClose={handleOK}
                aria-labelledby="simple-dialog-title"
                open={props.open}
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.message}</DialogContentText>
                </DialogContent>
                <ProjectCommentSection
                    component={props.component}
                    projectKey={props.projectKey}
                    canEdit={props.canEdit}
                />
                <DialogActions>
                    <Button onClick={handleOK} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
