import React, { useState, useContext, useEffect, useReducer } from "react";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CloseIcon from "@material-ui/icons/Close";
import { ListSubheader } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";

// My Files
// import "../calendar/Calendar.css";
import { DatabaseContext, UserContext } from "../../context";
import UserAvatar from "../management/UserAvatar";
import firebase from "../../firebase";
import { jsonFromArray } from "../../utility";

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
        padding: 0,
    },
    container: {
        height: "100%",
        margin: 0,
        paddingLeft: 15,
    },
    chip: {
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
    },
    nameTextField: {
        marginLeft: 15,
        marginRight: 15,
    },
    title: {
        flexGrow: 1,
    },
    sectionTitle: {
        marginLeft: 15,
        marginTop: 10,
    },
    notificationsSwitch: {
        marginLeft: 15,
        marginTop: 20,
    },
    notificationsMessage: {
        marginLeft: 25,
        marginRight: 15,
        marginTop: 10,
    },
    noGroupsText: {
        marginLeft: 30,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareSurveyDialog(props) {
    const classes = useStyles();
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const { users, groups, folders } = useContext(DatabaseContext);
    const { currentUserData } = useContext(UserContext);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allAvailableUsers, setAllAvaibleUsers] = useState([]);

    const [usernameFilter, setUsernameFilter] = useState("");

    const [selectedGroups, setSelectedGroups] = useState([]);

    const [allHubs, setAllHubs] = useState([]);

    const [sendNotifications, setSendNotifications] = useState(false);
    const [notificationsMessage, setNotificationsMessage] = useState("");

    useEffect(() => {
        if (props.surveyInfo) {
            // Populate selected users and groups
            let selectedUsersArray = [];
            let allAvailableUsersArray = [];
            if (props.surveyInfo.users) {
                users.forEach((user) => {
                    user.canEdit = false;
                    user.canViewResults = false;

                    if (userShouldBeVisible(user)) {
                        allAvailableUsersArray.push(user);

                        let matchingUserInSurvey =
                            props.surveyInfo.users.filter(
                                (u) => u.key === user.key
                            )[0];

                        if (matchingUserInSurvey) {
                            user.canEdit = matchingUserInSurvey.canEdit;
                            user.canViewResults =
                                matchingUserInSurvey.canViewResults;
                            selectedUsersArray.push(user);
                        }
                    }
                });
            }

            setAllAvaibleUsers(allAvailableUsersArray);
            setSelectedUsers(selectedUsersArray);

            // Get all the Hub's that the current user is manager or user of
            let hubs = folders.filter(
                (f) =>
                    f.hub &&
                    (f.managers.includes(currentUserData.key) ||
                        f.users.includes(currentUserData.key))
            );

            let selectedGroupsArray = [];

            // Get the Groups for these hubs
            hubs.forEach((hub) => {
                let matchingGroups = groups.filter((g) => g.hub === hub.key);
                // Set the selected groups
                matchingGroups.forEach((group) => {
                    group.hubName = hub.name;
                    group.canEdit = false;
                    group.canViewResults = false;

                    let matchingGroupInSurvey = props.surveyInfo.groups.filter(
                        (g) => g.key === group.key
                    )[0];

                    if (matchingGroupInSurvey) {
                        group.canEdit = matchingGroupInSurvey.canEdit;
                        group.canViewResults =
                            matchingGroupInSurvey.canViewResults;
                        selectedGroupsArray.push(group);
                    }
                    // if (
                    //     props.surveyInfo.groups.filter((g) => g === group.key)
                    //         .length > 0
                    // ) {
                    //     // group.didSelect = true;
                    //     selectedGroupsArray.push(group);
                    // } else {
                    //     // group.didSelect = false;
                    // }
                });

                hub.groups = matchingGroups;
            });

            setAllHubs(hubs);

            // if (props.surveyInfo.groups) {
            //     props.surveyInfo.groups.forEach((groupKey) => {
            //         let matchingGroup = groups.filter(
            //             (g) => g.key === groupKey
            //         )[0];
            //         if (matchingGroup) {
            //             selectedGroupsArray.push(matchingGroup);
            //         }
            //     });
            // }

            setSelectedGroups(selectedGroupsArray);

            // Get the groups and users that should be visible (all those that can see the video file)
            // let hubsArray = [];
            // if (props.file.groups && allHubs.length === 0) {
            //     props.file.groups.forEach((groupKey) => {
            //         let group = groups.filter((g) => g.key === groupKey)[0];
            //         if (group) {
            //             let hub = hubsArray.filter(
            //                 (h) => h.key === group.hub
            //             )[0];

            //             if (hub) {
            //                 if (group.name === "All Users") {
            //                     group.displayName = "All in '" + hub.name + "'";
            //                 } else {
            //                     group.displayName = group.name;
            //                 }

            //                 hub.groups.push(group);
            //             } else {
            //                 let folder = folders.filter(
            //                     (f) => f.key === group.hub
            //                 )[0];
            //                 if (folder) {
            //                     if (group.name === "All Users") {
            //                         group.displayName =
            //                             "All in '" + folder.name + "'";
            //                     } else {
            //                         group.displayName = group.name;
            //                     }

            //                     hubsArray.push({
            //                         key: group.hub,
            //                         name: folder.name,
            //                         groups: [group],
            //                     });
            //                 }
            //             }

            //             // groupsArray.push(group);
            //         }
            //     });
            //     setAllHubs(hubsArray);
            // }
        }

        // eslint-disable-next-line
    }, [props.open]);

    const handleUserSelect = (user) => () => {
        user.canEdit = false;
        user.canViewResults = false;

        let selectedArray = [...selectedUsers];
        selectedArray.push(user);
        setSelectedUsers(selectedArray);
        setUsernameFilter("");
    };

    const handleGroupSelect = (group) => () => {
        // // Get the users from this group and remove them from the users which can be selected
        // let selectedUsersArray = [...selectedUsers];

        // group.users.forEach((user) => {
        //     let matchingUsers = users.filter((u) => u.key === user.key);

        //     if (matchingUsers.length === 1) {
        //         let userToSelect = matchingUsers[0];

        //         // Only add the user if they hadn't already been selected as an individual
        //         if (
        //             selectedUsersArray.filter((u) => u.key === userToSelect.key)
        //                 .length === 0
        //         ) {
        //             selectedUsersArray.push(userToSelect);
        //         }

        //         setSelectedUsers(selectedUsersArray);
        //     }
        // });

        group.canEdit = false;
        group.canViewResults = false;

        // Now select the group
        let selectedArray = [...selectedGroups];
        selectedArray.push(group);
        setSelectedGroups(selectedArray);
    };

    const handleDeleteUser = (user) => () => {
        let selectedArray = selectedUsers.filter((u) => u.key !== user.key);

        setSelectedUsers(selectedArray);
    };

    const handleToggleCheckbox = (id, userOrGroup) => () => {
        if (id === "edit") {
            userOrGroup.canEdit = !userOrGroup.canEdit;
        } else if (id === "viewResults") {
            userOrGroup.canViewResults = !userOrGroup.canViewResults;
        }

        forceUpdate();
    };

    const handleDeleteGroup = (group) => () => {
        // Remove the users that were added from this group, unless they are also in a still selected group

        let selectedUsersArray = [...selectedUsers];

        group.users.forEach((user) => {
            let shouldRemove = true;

            selectedGroups.forEach((selectedGroup) => {
                if (selectedGroup.key !== group.key) {
                    if (
                        selectedGroup.users.filter((u) => u.key === user.key)
                            .length > 0
                    ) {
                        shouldRemove = false;
                    }
                }
            });

            if (shouldRemove) {
                selectedUsersArray = selectedUsersArray.filter(
                    (u) => u.key !== user.key
                );
            }
        });

        setSelectedUsers(selectedUsersArray);

        //
        let selectedArray = selectedGroups.filter((u) => u.key !== group.key);
        setSelectedGroups(selectedArray);
    };

    function handleCancelPress() {
        setUsernameFilter("");
        setNotificationsMessage("");
        setSendNotifications(false);
        setSelectedGroups([]);
        setSelectedUsers([]);
        props.onClose();
    }

    function handleConfirmPress() {
        // Updates the users and groups array in the surveyinfo record in the database

        // Users
        let usersObject = {};

        selectedUsers.forEach((user) => {
            let userObject = {};
            userObject.canEdit = user.canEdit;
            userObject.canViewResults = user.canViewResults;

            usersObject[user.key] = userObject;
        });

        // Groups

        let groupsObject = {};

        selectedGroups.forEach((group) => {
            let groupObject = {};
            groupObject.canEdit = group.canEdit;
            groupObject.canViewResults = group.canViewResults;

            groupsObject[group.key] = groupObject;
        });

        // selectedUsers.forEach((user) => {
        //     if (!isUserInSelectedGroups(user)) {
        //         usersObject[user.key] = true;

        //         if (user.canEdit) {
        //             usersCanEditObject[user.key] = true;
        //         }

        //         if (user.canViewResults) {
        //             usersCanViewResultsObject[user.key] = true;
        //         }
        //     }
        // });

        // Groups
        // let groupsObject = jsonFromArray(selectedGroups);

        // Update users and groups in database
        let surveyInfoRef = firebase.database().ref("surveyinfo");

        surveyInfoRef
            .child(props.surveyInfo.key)

            .update({
                users: usersObject,
                groups: groupsObject,
            });

        if (sendNotifications) {
            var notificationsRef = firebase.database().ref("notifications");
            notificationsRef.child(props.surveyInfo.key).set({
                // file: props.surveyInfo.file,
                title: props.surveyInfo.title,
                type: "survey",
                users: jsonFromArray(selectedUsers),
                message: notificationsMessage,
                date: dayjs().toString(),
            });
        }

        let userCount = selectedUsers.length;
        let groupCount = selectedGroups.length;

        setUsernameFilter("");
        setNotificationsMessage("");
        setSendNotifications(false);
        setSelectedGroups([]);
        setSelectedUsers([]);
        props.onClose(userCount, groupCount);
        setSelectedUsers([]);
    }

    const handleChangeFilterText = (event) => {
        setUsernameFilter(event.target.value);
    };

    function isUserInSelectedGroups(user) {
        for (let i = 0; i < selectedGroups.length; i++) {
            let group = selectedGroups[i];
            for (let j = 0; j < group.users.length; j++) {
                let u = group.users[j];

                if (user.key === u.key) {
                    return true;
                }
            }
        }
        return false;
    }

    function groupLabel(group) {
        if (group.displayName && group.displayName !== "") {
            if (group.users === 1) {
                return group.displayName + " (1 User)";
            } else {
                return (
                    group.displayName + " (" + group.users.length + " Users)"
                );
            }
        } else {
            if (group.users === 1) {
                return group.name + " (1 User)";
            } else {
                return group.name + " (" + group.users.length + " Users)";
            }
        }
    }

    function userPassesSearch(user) {
        if (
            user.fullName.toLowerCase().includes(usernameFilter.toLowerCase())
        ) {
            return true;
        }

        return false;
    }

    function userIsSelected(user) {
        if (selectedUsers.filter((u) => u.key === user.key).length === 1) {
            return true;
        }

        return false;
    }

    function userShouldBeVisible(user) {
        if (currentUserData) {
            if (user.key === currentUserData.key) {
                return false;
            }

            var hasPermissionToSee = false;

            if (
                user.admins.includes(currentUserData.key) ||
                user.createdBy === currentUserData.key ||
                currentUserData.role === "System Digital Manager"
            ) {
                hasPermissionToSee = true;
            }

            // if (props.file.managers && props.file.managers.includes(user.key)) {
            //     hasPermissionToSee = true;
            // }

            // if (user.files.includes(props.file.key)) {
            //     hasPermissionToSee = true;
            // }

            // allHubs.forEach((hub) => {
            //     hub.groups.forEach((group) => {
            //         if (
            //             user.firstName === "Matthew" &&
            //             user.lastName === "Parry"
            //         ) {
            //         }

            //         if (
            //             group.users.filter((u) => u.key === user.key).length > 0
            //         ) {
            //             hasPermissionToSee = true;
            //         }
            //     });
            // });

            if (hasPermissionToSee) {
                return true;
            }
        }

        return false;
    }

    function handleSwitchChange(event) {
        if (event.target.name === "send") {
            setSendNotifications(event.target.checked);
        }
    }

    const handleChangeMessageText = (event) => {
        setNotificationsMessage(event.target.value);
    };

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar>
                <Toolbar>
                    <Tooltip title="Cancel">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCancelPress}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h6" className={classes.title}>
                        Share Survey
                    </Typography>
                    <Tooltip title="Confirm">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleConfirmPress}
                            aria-label="close"
                        >
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container fluid className={classes.container}>
                <Row>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendNotifications}
                                    onChange={handleSwitchChange}
                                    name="send"
                                    color="primary"
                                />
                            }
                            label={"Send Notifications"}
                            className={classes.notificationsSwitch}
                        />
                    </FormGroup>
                    {sendNotifications && (
                        <TextField
                            id="standard-multiline-flexible"
                            label="Notification Message"
                            variant="outlined"
                            value={notificationsMessage}
                            onChange={handleChangeMessageText}
                            className={classes.notificationsMessage}
                        />
                    )}
                </Row>
                <Divider style={{ marginTop: 10 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Selected</h3>
                </Row>
                <Row>
                    {selectedGroups.map((group) => (
                        <ListItem>
                            <IconButton
                                aria-label="delete"
                                onClick={handleDeleteGroup(group)}
                            >
                                <CloseIcon />
                            </IconButton>
                            <ListItemAvatar>
                                <UserAvatar
                                    userOrHub={group}
                                    path="users"
                                    variant="circular"
                                />
                            </ListItemAvatar>
                            <ListItemText
                                id={group.key}
                                primary={group.name}
                                secondary={group.hubName}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={group.canEdit}
                                        onChange={handleToggleCheckbox(
                                            "edit",
                                            group
                                        )}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Can Edit Questions"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={group.canViewResults}
                                        onChange={handleToggleCheckbox(
                                            "viewResults",
                                            group
                                        )}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Can View Answers"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={true}
                                        // onChange={handleToggle}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Can Take Survey"
                            />
                        </ListItem>
                        // <Chip
                        //     key={group.key}
                        //     label={groupLabel(group)}
                        //     className={classes.chip}
                        //     onDelete={handleDeleteGroup(group)}
                        // />
                    ))}
                </Row>
                <Row>
                    <List dense className={classes.root}></List>
                    {selectedUsers
                        .sort((a, b) =>
                            a.lastName.toLowerCase() > b.lastName.toLowerCase()
                                ? 1
                                : -1
                        )
                        .map(
                            (user) =>
                                !isUserInSelectedGroups(user) && (
                                    <>
                                        <ListItem>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={handleDeleteUser(user)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <ListItemAvatar>
                                                <UserAvatar
                                                    userOrHub={user}
                                                    path="users"
                                                    variant="circular"
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                id={user.key}
                                                primary={user.fullName}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={user.canEdit}
                                                        onChange={handleToggleCheckbox(
                                                            "edit",
                                                            user
                                                        )}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Can Edit Questions"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            user.canViewResults
                                                        }
                                                        onChange={handleToggleCheckbox(
                                                            "viewResults",
                                                            user
                                                        )}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Can View Answers"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={true}
                                                        // onChange={handleToggle}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Can Take Survey"
                                            />
                                        </ListItem>
                                    </>
                                    // <Chip
                                    //     avatar={
                                    // <Avatar
                                    //     alt={user.fullName}
                                    //     src={user.avatar}
                                    // />
                                    //     }
                                    //     key={user.key}
                                    //     label={user.fullName}
                                    //     className={classes.chip}
                                    //     onDelete={
                                    //         user.key !== currentUserData.key &&
                                    //         handleDeleteUser(user)
                                    //     }
                                    //     // clickable
                                    //     // color="primary"
                                    //     variant="outlined"
                                    // />
                                )
                        )}
                </Row>
                <Divider style={{ marginTop: 25 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Groups</h3>
                </Row>
                <Row style={{ margin: 0, padding: 0 }}>
                    <Row>
                        {allHubs.map((hub) =>
                            hub.groups && hub.groups.length > 0 ? (
                                <React.Fragment key={hub.key}>
                                    <ListSubheader>{hub.name}</ListSubheader>

                                    <ListItem
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                        }}
                                    >
                                        {hub.groups
                                            .filter(
                                                (group) =>
                                                    !selectedGroups.includes(
                                                        group
                                                    )
                                            )
                                            .sort((a, b) => {
                                                if (a.name > b.name) return 1;
                                                if (a.name < b.name) return -1;
                                                return 0;
                                            })
                                            .map((group) => (
                                                <Chip
                                                    key={group.key}
                                                    label={groupLabel(group)}
                                                    className={classes.chip}
                                                    clickable
                                                    onClick={handleGroupSelect(
                                                        group
                                                    )}
                                                />
                                            ))}
                                    </ListItem>
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={hub.key}>
                                    <ListSubheader>{hub.name}</ListSubheader>

                                    <ListItem
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                        }}
                                    >
                                        <span className={classes.noGroupsText}>
                                            No Groups
                                        </span>
                                    </ListItem>
                                </React.Fragment>
                            )
                        )}
                    </Row>
                </Row>
                <Divider style={{ marginTop: 25 }} />
                <Row>
                    <h3 className={classes.sectionTitle}>Users</h3>
                </Row>
                <Row>
                    <TextField
                        id="standard-basic"
                        label="Type User Name"
                        className={classes.nameTextField}
                        fullWidth
                        value={usernameFilter}
                        onChange={handleChangeFilterText}
                    ></TextField>
                </Row>

                <Row>
                    <List className={classes.list}>
                        {allAvailableUsers
                            .filter(
                                (user) =>
                                    userPassesSearch(user) &&
                                    !userIsSelected(user)
                            )
                            .map((user) => (
                                <div
                                    key={user.key}
                                    onClick={handleUserSelect(user)}
                                >
                                    <ListItem
                                        button
                                        alignItems="flex-start"
                                        // selected={selectedIndex === index}
                                    >
                                        <ListItemAvatar>
                                            <UserAvatar
                                                userOrHub={user}
                                                path="users"
                                                variant="circular"
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.fullName}
                                            secondary={user.customRoleName}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            ))}
                    </List>
                </Row>
            </Container>
        </Dialog>
    );
}
