import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

// My Files

import { DatabaseContext, UserContext } from "../../../context";

import ToolbarWithLogo from "../../ToolbarWithLogo";
import FloatingAddButton from "../../FloatingAddButton";
import UserAvatar from "../UserAvatar";
import NoPermissionsMessage from "../../NoPermissionsMessage";
import firebase from "../../../firebase";

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0),
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    grow: {
        flexGrow: 1,
    },
    container: {
        paddingBottom: 45,
    },
    avatar: {
        width: "50px",
        height: "50px",
    },
    listText: {
        paddingLeft: theme.spacing(1),
    },
    nameTextField: {
        marginLeft: 75,
        width: window.innerWidth - 75,
    },
}));

export default function UserListPage() {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    const { users } = useContext(DatabaseContext);

    const [usernameFilter, setUsernameFilter] = useState("");

    const [userCanEdit, setUserCanEdit] = useState();

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData && currentUserData.permissions) {
            if (currentUserData.permissions.addEditUsers) {
                setUserCanEdit(true);
            } else {
                setUserCanEdit(false);
            }
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [currentUserData, history]);

    function handleAddClick() {
        history.push("/newuser");
    }

    const handleManageUserClick = (user) => () => {
        history.push({
            pathname: `/manageuser/${user.key}`,
            user: user,
        });
    };

    const handleChangeFilterText = (event) => {
        setUsernameFilter(event.target.value);
    };

    return (
        <>
            {userCanEdit && (
                <>
                    <div className={classes.container}>
                        <Paper square>
                            <Typography
                                className={classes.title}
                                variant="h5"
                                gutterBottom
                            >
                                Users
                            </Typography>
                            <TextField
                                id="standard-basic"
                                label="Type User Name"
                                className={classes.nameTextField}
                                value={usernameFilter}
                                onChange={handleChangeFilterText}
                            ></TextField>
                            <List className={classes.list}>
                                {users
                                    .filter(
                                        (u) =>
                                            u.admins.includes(
                                                currentUserData.key
                                            ) ||
                                            u.createdBy ===
                                                currentUserData.key ||
                                            currentUserData.role ===
                                                "System Digital Manager"
                                    )
                                    .filter((u) =>
                                        u.fullName
                                            .toLowerCase()
                                            .includes(
                                                usernameFilter.toLowerCase()
                                            )
                                    )
                                    .sort((a, b) =>
                                        a.lastName.toLowerCase() >
                                        b.lastName.toLowerCase()
                                            ? 1
                                            : -1
                                    )
                                    .map((user, index, array) => (
                                        <React.Fragment key={user.key}>
                                            <ListItem
                                                button
                                                onClick={handleManageUserClick(
                                                    user
                                                )}
                                            >
                                                <ListItemAvatar>
                                                    <LazyLoad>
                                                        <UserAvatar
                                                            userOrHub={user}
                                                            path="users"
                                                            variant="circular"
                                                        />
                                                    </LazyLoad>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    className={classes.listText}
                                                    primary={
                                                        user.firstName +
                                                        " " +
                                                        user.lastName
                                                    }
                                                    secondary={
                                                        user.customRoleName
                                                    }
                                                />
                                            </ListItem>{" "}
                                            {index < array.length - 1 && (
                                                <Divider
                                                    variant="inset"
                                                    component="li"
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                            </List>
                        </Paper>
                    </div>
                    <FloatingAddButton onClick={handleAddClick} />
                </>
            )}
            {userCanEdit === false && <NoPermissionsMessage />}
            <ToolbarWithLogo />
        </>
    );
}
