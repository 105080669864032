import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";

// Material UI
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext, DatabaseContext } from "../../../../context";
import { Chip, Tooltip } from "@material-ui/core";
import DialogWithTextInput from "../../../DialogWithTextInput";
import firebase from "../../../../firebase";
import { shouldTimelineBeVisible, makeid } from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
    title: {
        marginTop: 15,
    },
    addButton: { flexGrow: 1, margin: 10, cursor: "pointer" },

    cancelButton: {
        position: "absolute",
        top: 5,
        left: 5,
        bottom: "auto",
        right: "auto",
        padding: 2,
    },
    chip: {
        marginLeft: "2%",
        width: "96%",
        marginBottom: 5,
        minWidth: 250,
    },
}));

export default function AnalysisSelectDialog(props) {
    const classes = useStyles();
    const history = useHistory();
    const { currentUserData } = useContext(UserContext);
    const { timelineInfo, groups } = useContext(DatabaseContext);

    const [visibleTimelines, setVisibleTimelines] = useState([]);

    const [newTimelineNameDialogIsOpen, setNewTimelineNameDialogIsOpen] =
        useState(false);

    useEffect(() => {
        if (currentUserData) {
            if (props.file) {
                let timelineArray = [];

                timelineInfo.forEach((timeline) => {
                    if (
                        shouldTimelineBeVisible(
                            timeline,
                            groups,
                            currentUserData
                        )
                    ) {
                        if (timeline.video === props.file.key) {
                            timelineArray.push(timeline);

                            if (timeline.owner === currentUserData.key) {
                                timeline.isOwner = true;
                            }
                        }
                    }
                });

                setVisibleTimelines(timelineArray);
            }
        }
        // eslint-disable-next-line
    }, [props, currentUserData, timelineInfo]);

    const handleClose = () => {
        props.onClose();
    };

    function handleAddButtonPress() {
        setNewTimelineNameDialogIsOpen(true);
    }

    function handleCloseNewTimelineNameDialog(name, okPressed) {
        if (name !== "" && okPressed) {
            // Add a record to file Usage
            let ref = firebase.database().ref("fileusage");

            ref.push({
                file: props.file.key,
                fileName: props.file.displayName,
                fileType: props.file.type,
                user: currentUserData.key,
                action: "TimelineCreate",
                date: dayjs().toString(),
            });

            // Create the timeline
            let timelinesRef = firebase.database().ref("timelines");

            let key = makeid(19);

            timelinesRef.child(key).set({
                name: name,
                owner: currentUserData.key,
                video: props.file.key,
                created: dayjs().toString(),
            });

            let timelineInfoRef = firebase.database().ref("timelineinfo");

            timelineInfoRef.child(key).set({
                name: name,
                owner: currentUserData.key,
                video: props.file.key,
                created: dayjs().toString(),
            });
        }

        setNewTimelineNameDialogIsOpen(false);
    }

    const handleTimelineClick = (timeline) => () => {
        if (props.file && props.file.key) {
            history.push(
                "/analysis?video=" + props.file.key + "&key=" + timeline.key
            );
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            maxWidth="lg"
            open={props.open}
            className={classes.root}
        >
            <Container fluid>
                <Col>
                    <h4 className={classes.title}>Timelines</h4>
                    {visibleTimelines.length === 0 ? (
                        <span>
                            You haven't created any timelines for this video
                        </span>
                    ) : (
                        <List>
                            {visibleTimelines.map((t) => (
                                <Row key={t.key}>
                                    <Chip
                                        className={classes.chip}
                                        label={t.name}
                                        onClick={handleTimelineClick(t)}
                                        style={
                                            t.isOwner
                                                ? {
                                                      backgroundColor:
                                                          "rgb(0, 149, 61)",
                                                      color: "white",
                                                  }
                                                : {
                                                      backgroundColor:
                                                          "rgb(220, 220, 220)",
                                                  }
                                        }
                                    />
                                </Row>
                            ))}
                        </List>
                    )}

                    <Row>
                        <Tooltip title="New Timeline">
                            <AddIcon
                                className={classes.addButton}
                                onClick={handleAddButtonPress}
                            />
                        </Tooltip>
                    </Row>
                </Col>
            </Container>
            <DialogWithTextInput
                open={newTimelineNameDialogIsOpen}
                onClose={handleCloseNewTimelineNameDialog}
                title={"New Timeline"}
                message={"Enter a name for the new timeline."}
            />
        </Dialog>
    );
}
