import React, { useEffect, useContext, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// My Files
import { UserContext } from "../../../../context";

const useStyles = makeStyles((theme) => ({
    document: {
        // maxHeight: 200,
    },
    page: {
        [theme.breakpoints.down("sm")]: { paddingLeft: 0 },
        paddingLeft: 0,
    },
}));

export default function ProjectPDFFileComponent(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (currentUserData) {
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleNextPagePress() {
        setPageNumber(pageNumber + 1);
    }

    function handlePreviousPagePress() {
        setPageNumber(pageNumber - 1);
    }

    return (
        <>
            {props.file && (
                <Document
                    file={props.file.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.document}
                >
                    <MobileStepper
                        style={{ maxWidth: props.contentWidth }}
                        steps={numPages}
                        position="static"
                        variant="text"
                        activeStep={pageNumber - 1}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNextPagePress}
                                disabled={pageNumber === numPages}
                            >
                                Next
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handlePreviousPagePress}
                                disabled={pageNumber === 1}
                            >
                                <KeyboardArrowLeft />
                                Prev
                            </Button>
                        }
                    />
                    <Page
                        pageNumber={pageNumber}
                        className={classes.page}
                        width={props.contentWidth}
                    />
                </Document>
            )}
        </>
    );
}
