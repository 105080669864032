import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FileDownload from "js-file-download";
import { useSnackbar } from "notistack";

import ReactPlayer from "react-player";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useHistory } from "react-router-dom";

// Bootstrap
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
// My Files
import { DatabaseContext, UserContext } from "../../context";
import firebase from "../../firebase";
import { shouldFileBeVisible, bunnyVideoLibraryID } from "../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    accordionSummary: {
        fontSize: theme.typography.pxToRem(20),
        // ...theme.typography.body1,
    },
    pageContainer: {
        paddingTop: 15,
    },
}));

let downloadProgress = 0;

const action = (key) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={downloadProgress} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="inherit"
                >{`${Math.round(downloadProgress)}%`}</Typography>
            </Box>
        </Box>
    );
};

export default function FileListAccordion(props) {
    const classes = useStyles();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const history = useHistory();

    const { currentUserData } = useContext(UserContext);
    const [linkedFiles, setLinkedFiles] = useState([]);
    const { comments, notifications, events, users, groups } =
        useContext(DatabaseContext);

    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
        useState(false);

    const [fileToDelete, setFileToDelete] = useState();

    useEffect(() => {
        const getURLs = () => {
            let filesArray = [];

            props.files.forEach((file) => {
                if (shouldFileBeVisible(file, groups, currentUserData)) {
                    filesArray.push(file);
                }
            });

            setLinkedFiles(filesArray);
        };

        getURLs();
    }, [props, currentUserData, groups]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleNextPagePress() {
        setPageNumber(pageNumber + 1);
    }

    function handlePreviousPagePress() {
        setPageNumber(pageNumber - 1);
    }

    const handleDownloadClick = (file) => () => {
        if (file.type.includes("video")) {
            downloadVideoFile(file);
        } else {
            downloadFile(file);
        }
    };

    function downloadVideoFile(file) {
        // This downloads the original file
        let url = "https://vz-db0e7359-d70.b-cdn.net/" + file.key + "/original";

        // This can download a 540p or 720p version
        // Only works on files that were uploaded after MP4 fallback was enabled on Bunny.net
        // let url =
        //     "https://vz-db0e7359-d70.b-cdn.net/" +
        //     location.file.key +
        //     "/play_720p.mp4"; // Can only download 540p or 720p using this method, needs vid

        enqueueSnackbar("Downloading: " + file.filename, {
            variant: "default",
            action,
            persist: true,
            key: file.key,
        });

        axios({
            url: url,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                );

                downloadProgress = percentCompleted;

                enqueueSnackbar("Downloading: " + file.filename, {
                    variant: "default",
                    action,
                    persist: true,
                    key: file.key,
                    preventDuplicate: true,
                });
            },
        }).then((response) => {
            FileDownload(response.data, file.filename);
            downloadProgress = 0;
            closeSnackbar(file.key);
        });
    }

    function downloadFile(file) {
        enqueueSnackbar("Downloading: " + file.filename, {
            variant: "default",
            action,
            persist: true,
            key: file.key,
        });

        axios({
            url: file.url,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                );

                downloadProgress = percentCompleted;

                enqueueSnackbar("Downloading: " + file.filename, {
                    variant: "default",
                    action,
                    persist: true,
                    key: file.key,
                    preventDuplicate: true,
                });
            },
        }).then((response) => {
            FileDownload(response.data, file.filename);
            downloadProgress = 0;
            closeSnackbar(file.key);
        });
    }

    const handleGoToFileClick = (file) => () => {
        if (file.type.includes("video")) {
            history.push({
                pathname: `/video/${file.key}`,
                file: file,
            });
        } else if (file.type.includes("image")) {
            history.push({
                pathname: `/image/${file.key}`,
                file: file,
            });
        } else if (file.type === "application/pdf") {
            history.push({
                pathname: `/pdf/${file.key}`,
                file: file,
            });
        } else if (file.type === "link") {
            history.push({
                pathname: `/link/${file.key}`,
                file: file,
            });
        } else {
            history.push({
                pathname: `/file/${file.key}`,
                file: file,
            });
        }
    };

    // const handleDeleteClick = (file) => () => {
    //     setFileToDelete(file);
    //     setConfirmDeleteDialogIsOpen(true);
    // };

    function handleConfirmDeleteDialogClose() {
        setConfirmDeleteDialogIsOpen(false);
    }

    function handleConfirmDeletePressed() {
        if (fileToDelete) {
            if (fileToDelete.type.includes("video")) {
                deleteFromBunnyStorage();
                deleteFromDatabase();
            } else {
                deleteFromFirebaseStorage();
                deleteFromDatabase();
            }
        }
    }

    function deleteFromDatabase() {
        // Delete the comments
        comments.forEach((comment) => {
            if (comment.file === fileToDelete.key) {
                let commentRef = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);
                commentRef.remove();
            }
        });
        // Delete any notifications for the file
        notifications.forEach((notification) => {
            if (notification.file === fileToDelete.key) {
                let notificationRef = firebase
                    .database()
                    .ref("notifications")
                    .child(notification.key);
                notificationRef.remove();
            }
        });
        // Delete the file in any events
        events.forEach((event) => {
            let filesToKeepArray = [];
            let needsChange = false;
            event.files.forEach((aFile) => {
                if (aFile.key === fileToDelete.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile.key);
                }
            });
            if (needsChange) {
                let eventRef = firebase
                    .database()
                    .ref("events")
                    .child(event.key);
                // Change remaining files to JSON
                let filesObject = {};
                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });
                eventRef.update({ files: filesObject });
            }
        });
        // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
        users.forEach((user) => {
            let filesToKeepArray = [];
            let needsChange = false;
            user.files.forEach((aFile) => {
                if (aFile === fileToDelete.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile);
                }
            });
            if (needsChange) {
                let userRef = firebase.database().ref("users").child(user.key);
                // Change remaining files to JSON
                let filesObject = {};
                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });
                userRef.update({ files: filesObject });
            }
        });

        // Delete the file
        let fileRef = firebase.database().ref("files").child(fileToDelete.key);
        fileRef.remove();

        setLinkedFiles(linkedFiles.filter((f) => f.key !== fileToDelete.key));
        setFileToDelete();
        setConfirmDeleteDialogIsOpen(false);
    }

    function deleteFromBunnyStorage() {
        return new Promise((resolve, reject) => {
            var getBunnyStreamAPIKey = firebase
                .functions()
                .httpsCallable("getBunnyStreamAPIKey");

            getBunnyStreamAPIKey()
                .then((result) => {
                    const apiKey = result.data.key;

                    const url =
                        "https://video.bunnycdn.com/library/" +
                        bunnyVideoLibraryID +
                        "/videos/" +
                        fileToDelete.key;
                    const options = {
                        method: "DELETE",
                        headers: { AccessKey: apiKey },
                    };

                    fetch(url, options)
                        .then((response) => response.json())
                        .then((response) => {
                            console.log(response);
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            reject();
                        });
                })
                .catch((error) => {
                    // Getting the Error details.
                    console.log(error.code);
                    console.log(error.message);
                    console.log(error.details);
                    reject();
                });
        });
    }

    function deleteFromFirebaseStorage() {
        let storageRef = firebase
            .storage()
            .ref("files")
            .child(fileToDelete.key)
            .child(fileToDelete.filename);
        storageRef.delete();

        // Delete the file
        let fileRef = firebase.database().ref("files").child(fileToDelete.key);

        fileRef.remove();
    }

    return (
        <Container fluid className={classes.pageContainer}>
            {/* <Typography
                
                variant="h5"
                gutterBottom
            >
                Files
            </Typography> */}
            {linkedFiles.map((file, index) => (
                <Accordion
                    key={file.key}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h1 className={classes.accordionSummary}>
                            {file.displayName}
                        </h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Col sm={12} md={6}>
                            <>
                                {file && file.type.includes("video") && (
                                    <ReactPlayer
                                        className={classes.videoPlayer}
                                        url={
                                            "https://vz-db0e7359-d70.b-cdn.net/" +
                                            file.key +
                                            "/playlist.m3u8"
                                        }
                                        autoPlay={false}
                                        controls={true}
                                        width="100%"
                                        // hlsConfig={{
                                        //     startPosition: -1,
                                        // }}
                                        height="auto"
                                    />
                                )}
                                {file && file.type.includes("image") && (
                                    <img src={file.url} alt="animage" />
                                )}
                                {file && file.type === "application/pdf" && (
                                    <Document
                                        file={file.url}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className={classes.document}
                                    >
                                        <MobileStepper
                                            steps={numPages}
                                            position="static"
                                            variant="text"
                                            activeStep={pageNumber - 1}
                                            nextButton={
                                                <Button
                                                    size="small"
                                                    onClick={
                                                        handleNextPagePress
                                                    }
                                                    disabled={
                                                        pageNumber === numPages
                                                    }
                                                >
                                                    Next
                                                    <KeyboardArrowRight />
                                                </Button>
                                            }
                                            backButton={
                                                <Button
                                                    size="small"
                                                    onClick={
                                                        handlePreviousPagePress
                                                    }
                                                    disabled={pageNumber === 1}
                                                >
                                                    <KeyboardArrowLeft />
                                                    Prev
                                                </Button>
                                            }
                                        />
                                        <Page
                                            pageNumber={pageNumber}
                                            className={classes.page}
                                        />
                                    </Document>
                                )}
                                {props.fromFile &&
                                props.fromFile.key === file.key ? (
                                    <></>
                                ) : (
                                    <Tooltip title="Go to File">
                                        <IconButton
                                            onClick={handleGoToFileClick(file)}
                                        >
                                            <OpenInBrowserIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title="Download File">
                                    <IconButton
                                        disabled={!file.allowDownloads}
                                        onClick={handleDownloadClick(file)}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* {(file.managers.includes(currentUserData.key) ||
                                    currentUserData.role ===
                                        "System Digital Manager") && (
                                    <Tooltip title="Delete File">
                                        <IconButton
                                            onClick={handleDeleteClick(file)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )} */}
                            </>
                        </Col>
                    </AccordionDetails>
                </Accordion>
            ))}
            {confirmDeleteDialogIsOpen && (
                <Dialog
                    open={confirmDeleteDialogIsOpen}
                    onClose={handleConfirmDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Delete File
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this file?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleConfirmDeleteDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeletePressed}
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
}
