import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// My Files
import { UserContext } from "../../../../context";
import ProjectComment from "./ProjectComment";
import ProjectCommentInput from "./ProjectCommentInput";

const useStyles = makeStyles((theme) => ({
    root: { paddingBottom: 15 },
    commentCountText: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingBottom: 5,
        [theme.breakpoints.down("sm")]: { paddingLeft: 15 },
    },
}));

export default function ProjectCommentSection(props) {
    const classes = useStyles();

    const [comments, setComments] = useState([]);

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        var commentsArray = [];

        props.component.comments.forEach((comment) => {
            if (props.canEdit || comment.user === currentUserData.key) {
                commentsArray.push(comment);
            }
        });

        commentsArray.sort((a, b) =>
            dayjs(a.date) < dayjs(b.date)
                ? 1
                : dayjs(b.date) < dayjs(a.date)
                ? -1
                : 0
        );

        setComments(commentsArray);
    }, [props, currentUserData]);

    return (
        <>
            <Container fluid className={classes.root}>
                <Row>
                    <ProjectCommentInput
                        component={props.component}
                        projectKey={props.projectKey}
                        avatarSize={"medium"}
                    />
                </Row>
                <Row>
                    <h6 className={classes.commentCountText}>
                        {comments.length === 1
                            ? comments.length + " Comment"
                            : comments.length + " Comments"}
                    </h6>
                </Row>

                {comments.map((comment) => (
                    <Row key={comment.key}>
                        <ProjectComment
                            comment={comment}
                            component={props.component}
                            projectKey={props.projectKey}
                            avatarSize="medium"
                            isOwnComment={comment.user === currentUserData.key}
                        />
                    </Row>
                ))}
            </Container>
        </>
    );
}
