import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import VideoNotificationCard from "./VideoNotificationCard";
import PDFNotificationCard from "./PDFNotificationCard";
import AudioNotificationCard from "./AudioNotificationCard";
import ImageNotificationCard from "./ImageNotificationCard";
import PlaylistNotificationCard from "./PlaylistNotificationCard";
import SurveyNotificationCard from "./SurveyNotificationCard";
import TimelineNotificationCard from "./TimelineNotificationCard";
import LinkNotificationCard from "./LinkNotificationCard";
import { jsonFromArray } from "../../utility";
import firebase from "../../firebase";
import NoNotificationsCard from "./NoNotificationsCard";
import GenericFileNotificationCard from "./GenericFileNotificationCard";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    noNotificationsMessage: {
        paddingTop: 15,
        padding: 10,
        alignSelf: "center",
        color: "rgb(100, 100, 100)",
    },
    clearAllButton: {
        width: "100%",
    },
}));

export default function UserFeed(props) {
    const classes = useStyles();
    const history = useHistory();

    const { notifications, comments } = useContext(DatabaseContext);
    const { currentUserData } = useContext(UserContext);

    const [userNotifications, setUserNotifications] = useState([]);
    const [notificationsHaveLoaded, setNotificationsHaveLoaded] =
        useState(false);

    useEffect(() => {
        if (currentUserData) {
            let array = [];
            notifications.forEach((n) => {
                if (
                    n.users.includes(currentUserData.key) ||
                    n.managers.includes(currentUserData.key)
                ) {
                    n.comments = comments.filter(
                        (c) => c.file === n.file && c.deleted === false
                    );
                    if (!n.comments) {
                        n.comments = [];
                    }
                    if (n.fromDate === undefined) {
                        array.push(n);
                    } else {
                        if (dayjs().isSame(n.fromDate)) {
                            array.push(n);
                        } else if (dayjs().isAfter(n.fromDate)) {
                            array.push(n);
                        }
                    }
                }
            });

            array = array.filter(
                (n) =>
                    !n.seenBy.includes(currentUserData.key) || !props.popupFeed
            );

            array.sort((a, b) =>
                dayjs(b.date).isBefore() > dayjs(a.date)
                    ? 1
                    : dayjs(a.date) > dayjs(b.date)
                    ? -1
                    : 0
            );

            setNotificationsHaveLoaded(true);
            setUserNotifications(array);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData, notifications, comments, history]);

    function updateSeenBy(notification) {
        if (currentUserData) {
            let ref = firebase.database().ref("notifications");

            let seenByObject = jsonFromArray(notification.seenBy);
            seenByObject[currentUserData.key] = true;

            ref.child(notification.key).update({ seenBy: seenByObject });
        }
    }

    function handleClearAllNotificationsPress() {
        userNotifications.forEach((notification) => {
            updateSeenBy(notification);
        });
    }

    return (
        <>
            {props.popupFeed &&
                notificationsHaveLoaded &&
                userNotifications.length === 0 && (
                    <p className={classes.noNotificationsMessage}>
                        You don't have any Notifications
                    </p>
                )}
            {!props.popupFeed &&
                notificationsHaveLoaded &&
                userNotifications.length === 0 && <NoNotificationsCard />}
            <>
                {props.popupFeed && userNotifications.length > 0 && (
                    <Button
                        className={classes.clearAllButton}
                        color="primary"
                        onClick={handleClearAllNotificationsPress}
                    >
                        Clear All
                    </Button>
                )}

                {userNotifications.map((n) => (
                    <LazyLoad overflow key={n.key}>
                        <div
                            key={n.key}
                            style={props.popupFeed ? { maxWidth: 600 } : {}}
                        >
                            {n.type === "announcement" && (
                                <Card
                                    className={classes.card}
                                    variant="outlined"
                                >
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Announcement
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {n.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            {n.message}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                            {n.type === "file" && (
                                <GenericFileNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "video" && (
                                <VideoNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "playlist" && (
                                <PlaylistNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "survey" && (
                                <SurveyNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "timeline" && (
                                <TimelineNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "pdf" && (
                                <PDFNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "audio" && (
                                <AudioNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "image" && (
                                <ImageNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                            {n.type === "link" && (
                                <LinkNotificationCard
                                    updateSeenBy={updateSeenBy}
                                    popupFeed={props.popupFeed}
                                    notification={n}
                                />
                            )}
                        </div>
                    </LazyLoad>
                ))}
            </>
        </>
    );
}
