import firebase from "firebase";

// The Firebase Admin SDK to access Firestore.

// nodiant@fawtrust.cymru

// Live databaseURL
// https://nodiant-e20a8-default-rtdb.europe-west1.firebasedatabase.app/
// Development databaseURL
// https://nodiant-e20a8-46dbf-dev.europe-west1.firebasedatabase.app

// Live
export const config = {
    apiKey: "AIzaSyA_GjxeB7UCwclsEiOx9OfN-K3T7gdc_0Y",
    authDomain: "nodiant-e20a8.firebaseapp.com",
    databaseURL:
        "https://nodiant-e20a8-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nodiant-e20a8",
    storageBucket: "nodiant-e20a8.appspot.com",
    messagingSenderId: "412204354266",
    appId: "1:412204354266:web:1173dcab02e3dbf9c073e3",
    measurementId: "G-99JJLCDVV9",
};

// Development
// export const config = {
//     apiKey: "AIzaSyA_GjxeB7UCwclsEiOx9OfN-K3T7gdc_0Y",
//     authDomain: "nodiant-e20a8.firebaseapp.com",
//     databaseURL:
//         "https://nodiant-e20a8-46dbf-dev.europe-west1.firebasedatabase.app",
//     projectId: "nodiant-e20a8",
//     storageBucket: "nodiant-e20a8.appspot.com",
//     messagingSenderId: "412204354266",
//     appId: "1:412204354266:web:1173dcab02e3dbf9c073e3",
//     measurementId: "G-99JJLCDVV9",
// };

// Initialize Firebase
firebase.initializeApp(config);
firebase.analytics();

export default firebase;
