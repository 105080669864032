import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Breadcrumbs, Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import MovieIcon from "@material-ui/icons/Movie";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../../context";
import ToolbarWithLogo from "../../ToolbarWithLogo";
import FolderIconWithLabel from "./fileIcons/FolderIconWithLabel";
import FileIconWithLabel from "./fileIcons/FileIconWithLabel";
import PDFIconWithLabel from "./fileIcons/PDFIconWithLabel";
import ImageIconWithLabel from "./fileIcons/ImageIconWithLabel";
import VideoIconWithLabel from "./fileIcons/VideoIconWithLabel";
import AudioIconWithLabel from "./fileIcons/AudioIconWithLabel";
import FileSystemSpeedDial from "./FileSystemSpeedDial";
import firebase from "../../../firebase";
import { makeid, shouldFileBeVisible } from "../../../utility";
import DynamicTree from "./DynamicTree";
import UserAvatar from "../../management/UserAvatar";

const useStyles = makeStyles((theme) => ({
    foldersColumn: {
        background: "rgb(230, 230, 230)",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    filesColumn: {
        background: "rgb(240, 240, 240)",
    },
    container: {
        height: "100vh",
    },
    fullHeightRow: {
        height: "100%",
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    contentRow: {
        paddingBottom: 110,
    },
    list: {
        width: "100%",
        paddingBottom: 63,
    },
}));

export default function FileNavigatorPage() {
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();

    const [displayMode, setDisplayMode] = useState("Folder"); // List or Folder

    // Folders are stored in firebase as a flat structure. Need to put them into a tree structure for this page. These arrays have been filtered to those the current user is allowed to access
    const [foldersFlat, setFoldersFlat] = useState([]);
    const [foldersNested, setFoldersNested] = useState([]);

    // currentFiles and currentFolders are displayed on the right hand pane
    const [currentFiles, setCurrentFiles] = useState([]);
    const [currentFolders, setCurrentFolders] = useState([]);

    const [currentFolder, setCurrentFolder] = useState();

    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: "Everything", id: "Root", childNodes: [] },
    ]);

    // Files and folders from firebase
    const { files, folders, groups } = useContext(DatabaseContext);

    const { currentUserData } = useContext(UserContext);

    const [userIsManager, setUserIsManager] = useState(false);

    // When the user selects a folder from the tree, this is used to store the trail back up the tree to root. So the breadcrumb can be updated.
    let parentNodeTrail = [];

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData) {
            if (isMobile) {
                setDisplayMode("List");
            }

            // console.log("Refreshing Folders");

            const foldersClone = folders.map((folder) => ({
                key: folder.key,
                id: folder.key,
                name: folder.name,
                hub: folder.hub,
                parent: folder.parent,
                imageURL: folder.imageURL,
                parentNode: {},
                childNodes: [],
                groups: folder.groups,
                managers: folder.managers,
                users: folder.users,
                ancestors: folder.ancestors,
            }));

            // Filter out folders the user should not have access to. To see a folder, the user should either be included in folder.users, or the folder should be an ancestor of one which does contain the user.

            let flatFolderArrayFiltered = [];

            // For managers, add the folder if they are in the managers array or a digital manager
            // For users only add the folder if it is a hub and they are in the hub.users object
            foldersClone.forEach((folder) => {
                let shouldAdd = false;
                if (
                    folder.managers.includes(currentUserData.key) ||
                    currentUserData.role === "System Digital Manager"
                ) {
                    shouldAdd = true;
                } else if (
                    folder.hub &&
                    folder.users.includes(currentUserData.key)
                ) {
                    shouldAdd = true;
                }
                if (shouldAdd) {
                    flatFolderArrayFiltered.push(folder);

                    // Need to find the ancestors of the folder and add them so that the user can navigate to the folders they are members of
                    folder.ancestors.forEach((ancestorKey) => {
                        let ancestor = foldersClone.filter(
                            (f) => f.key === ancestorKey
                        );

                        if (ancestor[0]) {
                            flatFolderArrayFiltered.push(ancestor[0]);
                        }
                    });
                }
            });

            // For users, add a folder if some descendant contains a file that should be visible
            files.forEach((file) => {
                if (shouldFileBeVisible(file, groups, currentUserData)) {
                    // Need to find the ancestors of the file and add them so that the user can navigate to the file
                    file.ancestors.forEach((ancestorKey) => {
                        let ancestor = foldersClone.filter(
                            (f) => f.key === ancestorKey
                        );

                        if (ancestor[0]) {
                            flatFolderArrayFiltered.push(ancestor[0]);
                        }
                    });
                }
            });

            // Remove duplicates from the folders array

            flatFolderArrayFiltered = Array.from(
                new Set(flatFolderArrayFiltered)
            );

            // flatArrayFiltered now contains all the folders that the user should have access to. Now make a nested folder structure from this.

            let nestedFolderArray = [];

            for (let i = 0; i < flatFolderArrayFiltered.length; i++) {
                let folder = flatFolderArrayFiltered[i];

                // flatArray.push(folder);

                if (folder.parent === "Root") {
                    nestedFolderArray.push(folder);
                } else {
                    let parentFolder = flatFolderArrayFiltered.filter(
                        (anotherFolder) => anotherFolder.key === folder.parent
                    )[0];

                    if (parentFolder !== undefined) {
                        parentFolder.childNodes.push(folder);
                        folder.parentNode = parentFolder;
                    }
                }
            }

            // Get the current folder key from the browser url
            let currentFolderKey = location.pathname.replace("/files/", "");

            if (currentFolderKey === "/files") {
                // Current folder is root
                setCurrentFolder();
                setCurrentFolders(nestedFolderArray);
                setCurrentFiles([]);
            } else {
                // A folder is already selected (so user loaded this page with a folder key in the url)
                // Find the matching folder and load it, and set the breadcrumb etc..

                let currentFolderNode = flatFolderArrayFiltered.filter(
                    (f) => f.id === currentFolderKey
                )[0];

                // console.log("SelectedFolderNode:");
                // console.log(currentFolderNode);

                if (currentFolderNode) {
                    let current = flatFolderArrayFiltered.filter(
                        (f) => f.key === currentFolderNode.id
                    )[0];

                    setCurrentFolder(current);

                    // console.log("Current:");
                    // console.log(current);
                    if (
                        current &&
                        current.managers &&
                        current.managers.includes(currentUserData.key)
                    ) {
                        setUserIsManager(true);
                    } else if (
                        currentUserData.role === "System Digital Manager"
                    ) {
                        setUserIsManager(true);
                    } else {
                        setUserIsManager(false);
                    }

                    setCurrentFolders(currentFolderNode.childNodes);
                    let filesForFolder = [];

                    files
                        .filter((file) => file.folder === currentFolderNode.id)
                        .forEach((file) => {
                            if (
                                shouldFileBeVisible(
                                    file,
                                    groups,
                                    currentUserData
                                )
                            ) {
                                filesForFolder.push(file);
                            }
                        });

                    setCurrentFiles(filesForFolder);

                    // Get the parent nodes recursively back to root, use this to set the breadcrumb

                    if (currentFolderNode.id === "root") {
                        // eslint-disable-next-line
                        parentNodeTrail = [];
                    } else {
                        parentNodeTrail = [
                            {
                                name: currentFolderNode.name,
                                id: currentFolderNode.id,
                                childNodes: currentFolderNode.childNodes,
                            },
                        ];
                    }

                    getParentNodes(currentFolderNode);

                    parentNodeTrail.push({
                        name: "Everything",
                        id: "Root",
                        childNodes: [foldersNested],
                    });

                    parentNodeTrail.reverse();

                    setBreadcrumbs(parentNodeTrail);
                }
            }

            setFoldersFlat(flatFolderArrayFiltered);
            setFoldersNested(nestedFolderArray);
        }

        return () => unsubscribe();
    }, [folders, files, location.pathname]);

    function handleAddFileClick() {
        history.push({
            pathname: "/upload/",
            folder: currentFolder,
        });
    }

    function handleAddFolderClick(name) {
        if (name === "") {
            return;
        }

        // Have to convert the arrays to JSON objects for firebase storage
        // let usersObject = {};

        // currentFolder.users.forEach((user) => {
        //     usersObject[user] = true;
        // });

        let managersObject = {};

        currentFolder.managers.forEach((manager) => {
            managersObject[manager] = true;
        });

        let ancestorsObject = {};

        currentFolder.ancestors.forEach((ancestor) => {
            ancestorsObject[ancestor] = true;
        });

        ancestorsObject[currentFolder.id] = true;

        // Add the new folder to firebase

        var ref = firebase.database().ref("folders");

        ref.child(makeid(18)).set({
            name: name,
            parent: currentFolder.id,
            // users: usersObject,
            managers: managersObject,
            ancestors: ancestorsObject,
        });
    }

    // Recursively gets the parentNodes for a folderNode. Puts them in parentNodeTrail array
    function getParentNodes(folderNode) {
        let parentNode = folderNode.parentNode;

        if (parentNode === undefined || parentNode.id === undefined) {
            return;
        } else {
            parentNodeTrail.push({
                name: parentNode.name,
                id: parentNode.id,
                childNodes: parentNode.childNodes,
            });
            getParentNodes(parentNode);
        }
    }

    // Clicked on a folder in the tree
    function handleFolderTreeClick(folderNode) {
        let current = foldersFlat.filter((f) => f.key === folderNode.id)[0];

        if (
            current &&
            current.managers &&
            current.managers.includes(currentUserData.key)
        ) {
            setUserIsManager(true);
        } else if (
            current &&
            currentUserData.role === "System Digital Manager"
        ) {
            setUserIsManager(true);
        } else {
            setUserIsManager(false);
        }

        setCurrentFolder(current);
        setCurrentFolders(folderNode.childNodes);

        let filesForFolder = [];

        files
            .filter((file) => file.folder === folderNode.id)
            .forEach((file) => {
                if (shouldFileBeVisible(file, groups, currentUserData)) {
                    filesForFolder.push(file);
                }
            });

        setCurrentFiles(filesForFolder);

        // Get the parent nodes recursively back to root, use this to set the breadcrumb

        if (folderNode.id === "root") {
            parentNodeTrail = [];
        } else {
            parentNodeTrail = [
                {
                    name: folderNode.name,
                    id: folderNode.id,
                    childNodes: folderNode.childNodes,
                },
            ];
        }

        getParentNodes(folderNode);

        parentNodeTrail.push({
            name: "Everything",
            id: "Root",
            childNodes: [foldersNested],
        });

        parentNodeTrail.reverse();

        setBreadcrumbs(parentNodeTrail);

        if (folderNode.id === "root") {
            history.push("/files");
        } else {
            history.push(`/files/${folderNode.id}`);
        }
    }

    // Clicked on a folder from the right pane
    const handleFolderClick = (folderNode, event) => () => {
        // Use the id to get the folder object

        let current = foldersFlat.filter((f) => f.key === folderNode.id)[0];

        if (
            current &&
            current.managers &&
            current.managers.includes(currentUserData.key)
        ) {
            setUserIsManager(true);
        } else if (currentUserData.role === "System Digital Manager") {
            setUserIsManager(true);
        } else {
            setUserIsManager(false);
        }

        setCurrentFolder(current);
        setCurrentFolders(folderNode.childNodes);

        let filesForFolder = [];

        files
            .filter((file) => file.folder === folderNode.id)
            .forEach((file) => {
                if (shouldFileBeVisible(file, groups, currentUserData)) {
                    filesForFolder.push(file);
                }
            });

        setCurrentFiles(filesForFolder);

        setBreadcrumbs((prevValue) => {
            return [
                ...prevValue,
                {
                    name: folderNode.name,
                    id: folderNode.id,
                    childNodes: folderNode.childNodes,
                },
            ];
        });

        history.push(`/files/${folderNode.id}`);
    };

    const handleFileClick = (file) => () => {
        // Record that the user opened the file

        let ref = firebase.database().ref("fileusage");

        ref.push({
            file: file.key,
            fileName: file.displayName,
            fileType: file.type,
            user: currentUserData.key,
            action: "Open",
            date: dayjs().toString(),
        });

        // Go to the file page

        if (file.type.includes("video")) {
            history.push({
                pathname: `/video/${file.key}`,
                file: file,
                folder: currentFolder,
            });
        } else if (file.type.includes("audio")) {
            history.push({
                pathname: `/audio/${file.key}`,
                file: file,
                folder: currentFolder,
            });
        } else if (file.type.includes("image")) {
            history.push({
                pathname: `/image/${file.key}`,
                file: file,
                folder: currentFolder,
            });
        } else if (file.type === "application/pdf") {
            history.push({
                pathname: `/pdf/${file.key}`,
                file: file,
                folder: currentFolder,
            });
        } else {
            history.push({
                pathname: `/file/${file.key}`,
                file: file,
                folder: currentFolder,
            });
        }
    };

    const handleBreadcrumbClick = (breadcrumb) => () => {
        // Remove breadcrumbs after (and including) the one that has been pressed
        let index = 0;

        for (index = 0; index < breadcrumbs.length; index++) {
            let folder = breadcrumbs[index];

            if (folder.id === breadcrumb.id) {
                break;
            }
        }

        let newBreadCrumbs = [...breadcrumbs];

        newBreadCrumbs.length = index + 1;

        setBreadcrumbs(newBreadCrumbs);

        // Change the files and folders

        if (breadcrumb.id === "Root") {
            setUserIsManager(false);
            setCurrentFolder();
            setCurrentFolders(foldersNested);
            setCurrentFiles([]);

            history.push("/files");
        } else {
            let folderNode = breadcrumbs.filter(
                (folder) => folder.id === breadcrumb.id
            )[0];

            if (folderNode !== undefined) {
                let current = foldersFlat.filter(
                    (f) => f.key === folderNode.id
                )[0];

                if (
                    current &&
                    current.managers &&
                    current.managers.includes(currentUserData.key)
                ) {
                    setUserIsManager(true);
                } else if (
                    current &&
                    currentUserData.role === "System Digital Manager"
                ) {
                    setUserIsManager(true);
                } else {
                    setUserIsManager(false);
                }

                setCurrentFolder(current);

                setCurrentFolders(folderNode.childNodes);

                let filesForFolder = [];

                files
                    .filter((file) => file.folder === folderNode.id)
                    .forEach((file) => {
                        if (
                            shouldFileBeVisible(file, groups, currentUserData)
                        ) {
                            filesForFolder.push(file);
                        }
                    });

                setCurrentFiles(filesForFolder);

                history.push(`/files/${folderNode.id}`);
            }
        }
    };

    return (
        <>
            <Container fluid className={classes.container}>
                <Row className={classes.fullHeightRow}>
                    <Col className={classes.foldersColumn} lg={3} md={3} sm={0}>
                        <DynamicTree
                            key="root"
                            id="root"
                            data={[...foldersNested]}
                            name="Everything"
                            onClick={handleFolderTreeClick}
                            isActive={breadcrumbs[breadcrumbs.length - 1]}
                            breadcrumbIds={breadcrumbs.map((b) => b.id)}
                        />
                    </Col>
                    <Col className={classes.filesColumn} lg={9} md={12} sm={12}>
                        <Row>
                            <Breadcrumbs
                                separator="›"
                                aria-label="breadcrumb"
                                className={classes.text}
                                maxItems={4}
                                itemsBeforeCollapse={0}
                                itemsAfterCollapse={4}
                            >
                                {breadcrumbs.map((breadcrumb) => (
                                    <Link
                                        href="#"
                                        onClick={handleBreadcrumbClick(
                                            breadcrumb
                                        )}
                                        key={breadcrumb.id}
                                    >
                                        {breadcrumb.name}
                                    </Link>
                                ))}
                            </Breadcrumbs>
                        </Row>
                        <Divider />
                        {displayMode === "List" && (
                            <Row>
                                <List className={classes.list}>
                                    {currentFolders
                                        .sort((a, b) => {
                                            if (a.name > b.name) return 1;
                                            if (a.name < b.name) return -1;
                                            return 0;
                                        })
                                        .map((folderNode) => (
                                            <>
                                                <ListItem
                                                    key={folderNode.key}
                                                    button
                                                    onClick={handleFolderClick(
                                                        folderNode
                                                    )}
                                                >
                                                    <ListItemAvatar>
                                                        {folderNode.hub ? (
                                                            <UserAvatar
                                                                userOrHub={
                                                                    folderNode
                                                                }
                                                                path="hubs"
                                                                variant="rounded"
                                                            />
                                                        ) : (
                                                            <FolderIcon />
                                                        )}
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={
                                                            classes.listText
                                                        }
                                                        primary={
                                                            folderNode.name
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="fullWidth"
                                                    component="li"
                                                />
                                            </>
                                        ))}
                                    {currentFiles
                                        .filter((file) =>
                                            file.type.includes("video")
                                        )
                                        .sort((a, b) => {
                                            if (a.displayName > b.displayName)
                                                return 1;
                                            if (a.displayName < b.displayName)
                                                return -1;
                                            return 0;
                                        })
                                        .map((file) => (
                                            <>
                                                <ListItem
                                                    key={file.key}
                                                    button
                                                    onClick={handleFileClick(
                                                        file
                                                    )}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="rounded"
                                                            src={
                                                                "https://vz-db0e7359-d70.b-cdn.net/" +
                                                                file.key +
                                                                "/thumbnail.jpg"
                                                            }
                                                        >
                                                            <MovieIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={
                                                            classes.listText
                                                        }
                                                        primary={
                                                            file.displayName
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="fullWidth"
                                                    component="li"
                                                />
                                            </>
                                        ))}
                                    {currentFiles
                                        .filter((file) =>
                                            file.type.includes("audio")
                                        )
                                        .sort((a, b) => {
                                            if (a.displayName > b.displayName)
                                                return 1;
                                            if (a.displayName < b.displayName)
                                                return -1;
                                            return 0;
                                        })
                                        .map((file) => (
                                            <>
                                                <ListItem
                                                    key={file.key}
                                                    button
                                                    onClick={handleFileClick(
                                                        file
                                                    )}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="rounded"
                                                            src={
                                                                "https://vz-db0e7359-d70.b-cdn.net/" +
                                                                file.key +
                                                                "/thumbnail.jpg"
                                                            }
                                                        >
                                                            <VolumeUpIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={
                                                            classes.listText
                                                        }
                                                        primary={
                                                            file.displayName
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="fullWidth"
                                                    component="li"
                                                />
                                            </>
                                        ))}
                                    {currentFiles
                                        .filter((file) =>
                                            file.type.includes("image")
                                        )
                                        .sort((a, b) => {
                                            if (a.displayName > b.displayName)
                                                return 1;
                                            if (a.displayName < b.displayName)
                                                return -1;
                                            return 0;
                                        })
                                        .map((file) => (
                                            <>
                                                <ListItem
                                                    key={file.key}
                                                    button
                                                    onClick={handleFileClick(
                                                        file
                                                    )}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="rounded"
                                                            src={file.url}
                                                        >
                                                            <InsertDriveFileIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={
                                                            classes.listText
                                                        }
                                                        primary={
                                                            file.displayName
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="fullWidth"
                                                    component="li"
                                                />
                                            </>
                                        ))}
                                    {currentFiles
                                        .filter(
                                            (file) =>
                                                file.type === "application/pdf"
                                        )
                                        .sort((a, b) => {
                                            if (a.displayName > b.displayName)
                                                return 1;
                                            if (a.displayName < b.displayName)
                                                return -1;
                                            return 0;
                                        })
                                        .map((file) => (
                                            <>
                                                <ListItem
                                                    key={file.key}
                                                    button
                                                    onClick={handleFileClick(
                                                        file
                                                    )}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="rounded"
                                                            src={file.thumbnail}
                                                        >
                                                            <PictureAsPdfIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={
                                                            classes.listText
                                                        }
                                                        primary={
                                                            file.displayName
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="fullWidth"
                                                    component="li"
                                                />
                                            </>
                                        ))}
                                    {currentFiles
                                        .filter(
                                            (file) =>
                                                !file.type.includes("video") &&
                                                !file.type.includes("audio") &&
                                                !file.type.includes("image") &&
                                                file.type !== "application/pdf"
                                        )
                                        .sort((a, b) => {
                                            if (a.displayName > b.displayName)
                                                return 1;
                                            if (a.displayName < b.displayName)
                                                return -1;
                                            return 0;
                                        })
                                        .map((file) => (
                                            <>
                                                <ListItem
                                                    key={file.key}
                                                    button
                                                    onClick={handleFileClick(
                                                        file
                                                    )}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar variant="rounded">
                                                            <InsertDriveFileIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={
                                                            classes.listText
                                                        }
                                                        primary={
                                                            file.displayName
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="fullWidth"
                                                    component="li"
                                                />
                                            </>
                                        ))}
                                </List>
                            </Row>
                        )}
                        {displayMode === "Folder" && (
                            <Row className={classes.contentRow}>
                                {currentFolders
                                    .sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((folderNode) => (
                                        <React.Fragment key={folderNode.key}>
                                            <FolderIconWithLabel
                                                folder={folderNode}
                                                handleClick={handleFolderClick(
                                                    folderNode
                                                )}
                                            />
                                        </React.Fragment>
                                    ))}
                                {currentFiles
                                    .filter((file) =>
                                        file.type.includes("video")
                                    )
                                    .sort((a, b) => {
                                        if (a.displayName > b.displayName)
                                            return 1;
                                        if (a.displayName < b.displayName)
                                            return -1;
                                        return 0;
                                    })
                                    .map((file) => (
                                        <React.Fragment key={file.key}>
                                            <VideoIconWithLabel
                                                file={file}
                                                handleClick={handleFileClick(
                                                    file
                                                )}
                                            />
                                        </React.Fragment>
                                    ))}
                                {currentFiles
                                    .filter((file) =>
                                        file.type.includes("audio")
                                    )
                                    .sort((a, b) => {
                                        if (a.displayName > b.displayName)
                                            return 1;
                                        if (a.displayName < b.displayName)
                                            return -1;
                                        return 0;
                                    })
                                    .map((file) => (
                                        <React.Fragment key={file.key}>
                                            <AudioIconWithLabel
                                                file={file}
                                                handleClick={handleFileClick(
                                                    file
                                                )}
                                            />
                                        </React.Fragment>
                                    ))}
                                {currentFiles
                                    .filter((file) =>
                                        file.type.includes("image")
                                    )
                                    .sort((a, b) => {
                                        if (a.displayName > b.displayName)
                                            return 1;
                                        if (a.displayName < b.displayName)
                                            return -1;
                                        return 0;
                                    })
                                    .map((file) => (
                                        <React.Fragment key={file.key}>
                                            <ImageIconWithLabel
                                                file={file}
                                                handleClick={handleFileClick(
                                                    file
                                                )}
                                            />
                                        </React.Fragment>
                                    ))}
                                {currentFiles
                                    .filter(
                                        (file) =>
                                            file.type === "application/pdf"
                                    )
                                    .sort((a, b) => {
                                        if (a.displayName > b.displayName)
                                            return 1;
                                        if (a.displayName < b.displayName)
                                            return -1;
                                        return 0;
                                    })
                                    .map((file) => (
                                        <React.Fragment key={file.key}>
                                            <PDFIconWithLabel
                                                file={file}
                                                handleClick={handleFileClick(
                                                    file
                                                )}
                                            />
                                        </React.Fragment>
                                    ))}
                                {currentFiles
                                    .filter(
                                        (file) =>
                                            !file.type.includes("video") &&
                                            !file.type.includes("audio") &&
                                            !file.type.includes("image") &&
                                            file.type !== "application/pdf"
                                    )
                                    .sort((a, b) => {
                                        if (a.displayName > b.displayName)
                                            return 1;
                                        if (a.displayName < b.displayName)
                                            return -1;
                                        return 0;
                                    })
                                    .map((file) => (
                                        <React.Fragment key={file.key}>
                                            <FileIconWithLabel
                                                file={file}
                                                handleClick={handleFileClick(
                                                    file
                                                )}
                                            />
                                        </React.Fragment>
                                    ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
            {userIsManager && (
                <FileSystemSpeedDial
                    onFileClick={handleAddFileClick}
                    onFolderClick={handleAddFolderClick}
                />
            )}
            <ToolbarWithLogo />
        </>
    );
}
