import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Droppable } from "react-beautiful-dnd";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import FolderListItem from "./FolderListItem";
import PDFListItem from "./PDFListItem";
import VideoListItem from "./VideoListItem";
import ImageListItem from "./ImageListItem";
import AudioListItem from "./AudioListItem";
import MiscFileListItem from "./MiscFileListItem";
import LinkListItem from "./LinkListItem";
import { Divider } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { UserContext, DatabaseContext } from "../../../../context";
import firebase from "../../../../firebase";
import {
    jsonFromArray,
    makeid,
    shouldFileBeVisible,
} from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    column: {
        overflowY: "scroll",
        maxHeight: "80vh",
        position: "relative",
    },
    columnTitle: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 5,
        marginLeft: 10,
        padding: 0,
        textAlign: "left",
        cursor: "pointer",
        fontSize: 18,
        overflowWrap: "anywhere",
        width: "99%",
        minWidth: "99%",
        height: 25,
        maxHeight: 25,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        overflow: "hidden",
    },
    addButton: {
        margin: "0 auto",
        cursor: "pointer",
        marginTop: 11,
        display: "flex",
    },
    titleRowPadding: { margin: 0, padding: 0 },
}));

export default function FinderColumn(props) {
    const classes = useStyles();
    const history = useHistory();
    // Just used to show visually which is selected
    // const [selectedFolderIndex, setSelectedFolderIndex] = useState();
    // const { currentUserData } = useContext(UserContext);
    // const { files, folders } = useContext(DatabaseContext);

    const [clickLocation, setClickLocation] = useState({
        mouseX: null,
        mouseY: null,
    });

    const [columnHeight, setColumnHeight] = useState(0);

    const [folderCount, setFolderCount] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [pdfCount, setPDFCount] = useState(0);
    const [imageCount, setImageCount] = useState(0);
    const [audioCount, setAudioCount] = useState(0);

    const { currentUserData } = useContext(UserContext);
    const { files, folders, groups } = useContext(DatabaseContext);

    const [userCanUpload, setUserCanUpload] = useState(false);

    const [newFolderNameDialogOpen, setNewFolderNameDialogOpen] =
        useState(false);

    const [newFolderName, setNewFolderName] = useState("");

    const [editingEnabled, setEditingEnabled] = useState(true); // Only false when we are embedding this is a part of the app just used to select files (e.g. in the projects section)

    useEffect(() => {
        setColumnHeight(window.innerHeight - 145);

        // Set the counts of each type so they get the correct index
        setFolderCount(props.column.folders.length);

        let videoCount_ = 0;
        let pdfCount_ = 0;
        let imageCount_ = 0;
        let audioCount_ = 0;

        if (props.column.files) {
            props.column.files.forEach((file) => {
                if (file.type.includes("video")) {
                    videoCount_++;
                } else if (file.type.includes("audio")) {
                    audioCount_++;
                } else if (file.type.includes("image")) {
                    imageCount_++;
                } else if (file.type === "application/pdf") {
                    pdfCount_++;
                }
            });

            setVideoCount(videoCount_);
            setAudioCount(audioCount_);
            setImageCount(imageCount_);
            setPDFCount(pdfCount_);
        }

        if (props.column.folder && props.column.folder.id !== "root") {
            // If User is a manager of the folder, or a System Digital Manager, they can upload files here
            if (props.column.folder && props.column.folder.managers) {
                if (
                    props.column.folder.managers.includes(
                        currentUserData.key
                    ) ||
                    currentUserData.role === "System Digital Manager"
                ) {
                    setUserCanUpload(true);
                }
            }

            if (
                currentUserData &&
                currentUserData.permissions &&
                props.column.folder.users
            ) {
                // If user has the uploadEditOwnVideo permissions and they are a user of this folder or one of it's ancestors, they can upload files here
                if (currentUserData.permissions.uploadEditOwnVideo) {
                    if (
                        props.column.folder.users.includes(currentUserData.key)
                    ) {
                        setUserCanUpload(true);
                    } else {
                        let parentFolder = folders.filter(
                            (f) => f.key === props.column.folder.parent
                        )[0];
                        if (
                            parentFolder &&
                            parentFolder.users.includes(currentUserData.key)
                        ) {
                            setUserCanUpload(true);
                        }

                        props.column.folder.ancestors.forEach((ancestorKey) => {
                            let ancestorFolder = folders.filter(
                                (f) => f.key === ancestorKey
                            )[0];
                            if (
                                ancestorFolder &&
                                ancestorFolder.users.includes(
                                    currentUserData.key
                                )
                            ) {
                                setUserCanUpload(true);
                            }
                        });
                    }
                }
            }
        }

        // Special case for favourites folder, load favourite files
        if (props.column.id === "faves_fggh2973jgbvbiu") {
            setUserCanUpload(false);
            currentUserData.faveFiles.forEach((faveFile) => {
                let matchingFile = files.filter((f) => f.key === faveFile)[0];
                if (matchingFile) {
                    if (
                        shouldFileBeVisible(
                            matchingFile,
                            groups,
                            currentUserData
                        )
                    ) {
                        if (
                            props.column.files.filter((f) => f.key === faveFile)
                                .length === 0
                        ) {
                            props.column.files.push(matchingFile);
                        }
                    }
                }
            });
        }

        if (props.embedded === true) {
            setEditingEnabled(false);
            setUserCanUpload(false);
        }

        function handleResize() {
            setColumnHeight(window.innerHeight - 145);
        }

        window.addEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, currentUserData]);

    function handleFolderClick(folderIndex) {
        props.column.selectedFolderIndex = folderIndex;
        props.handleFolderSelect(props.index, folderIndex);

        // console.clear();
        // console.log(props.column.folders[folderIndex].createdBy);
        // console.log(props.column.folders[folderIndex].users);
        // console.log("Folder: " + props.column.folders[folderIndex].name);
        // console.log("Parent: " + props.column.folders[folderIndex].parent);
        // console.log("Ancestors:");
        // console.log(props.column.folders[folderIndex].ancestors);
    }

    function deleteFolder(folder) {
        let shouldDelete = true;

        folders.forEach((f) => {
            if (f.parent === folder.key) {
                shouldDelete = false;
            }
        });

        files.forEach((f) => {
            if (f.folder === folder.key) {
                shouldDelete = false;
            }
        });

        if (shouldDelete) {
            let folderRef = firebase
                .database()
                .ref("folders")
                .child(folder.key);

            let selectedFolder =
                props.column.folders[props.column.selectedFolderIndex];

            if (selectedFolder && selectedFolder.key === folder.key) {
                // If deleting the selected folders, select the parent in the UI
                history.push(`/files/${selectedFolder.parent}`);
            }

            folderRef.remove();

            props.showStatusMessage("Folder deleted successfully", "success");
        } else {
            props.showStatusMessage(
                "Can't delete folders that contain subfolders or files",
                "error"
            );
        }
    }

    function handleAddButtonPress(event) {
        setClickLocation({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 2,
        });
    }

    const handleMenuClose = (event) => {
        if (event.target.id === "newfolder") {
            setNewFolderName("");
            setNewFolderNameDialogOpen(true);
        } else if (event.target.id === "newfile") {
            // handleConfirmDeleteDialogOpen();
            let currentFolder = props.column.folder;
            if (currentFolder) {
                history.push({
                    pathname: "/upload/",
                    folder: currentFolder,
                });
            }
        } else if (event.target.id === "newlink") {
            let currentFolder = props.column.folder;

            if (currentFolder) {
                history.push({
                    pathname: "/addlink/",
                    folder: currentFolder,
                });
            }
        }

        setClickLocation({
            mouseX: null,
            mouseY: null,
        });
    };

    const handleNewFolderNameDialogClose = (key) => () => {
        if (key === "OK") {
            if (newFolderName !== "") {
                let currentFolder = props.column.folder;
                if (currentFolder) {
                    // let usersObject = jsonFromArray(currentFolder.users);
                    let managersObject = jsonFromArray(currentFolder.managers);
                    let ancestorsObject = jsonFromArray(
                        currentFolder.ancestors
                    );
                    ancestorsObject[currentFolder.id] = true;

                    // Add the new folder to firebase

                    var createdBy = "";
                    if (currentUserData) {
                        createdBy = currentUserData.key;
                    }

                    var ref = firebase.database().ref("folders");

                    ref.child(makeid(18)).set({
                        name: newFolderName,
                        parent: currentFolder.id,
                        createdBy: createdBy,
                        // users: usersObject,
                        managers: managersObject,
                        ancestors: ancestorsObject,
                    });
                }

                setNewFolderNameDialogOpen(false);
            }
        } else {
            setNewFolderNameDialogOpen(false);
        }
    };

    const handleKeyboardInput = (e) => {
        setNewFolderName(e.target.value);
    };

    return (
        <Droppable droppableId={props.column.id}>
            {(provided, snapshot) => (
                <div
                    className={classes.column}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={
                        snapshot.isDraggingOver
                            ? {
                                  minHeight: columnHeight,
                                  border: "2px solid rgb(0, 149, 61)",
                                  color: "rgba(0,0,0,0.5)",
                              }
                            : {
                                  minHeight: columnHeight,
                                  borderRight: "1px solid lightgrey",
                              }
                    }
                >
                    <Row className={classes.titleRowPadding}>
                        <Col
                            xs={9}
                            sm={10}
                            md={10}
                            lg={11}
                            xl={11}
                            xxl={11}
                            className={classes.titleRowPadding}
                        >
                            <span className={classes.columnTitle}>
                                {props.column.name}
                            </span>
                        </Col>
                        <Col
                            xs={3}
                            sm={2}
                            md={2}
                            lg={1}
                            xl={1}
                            xxl={1}
                            className={classes.titleRowPadding}
                        >
                            <AddCircleOutlineIcon
                                color="primary"
                                aria-label="add"
                                className={classes.addButton}
                                onClick={handleAddButtonPress}
                                disabled={!userCanUpload}
                                style={
                                    !userCanUpload
                                        ? { visibility: "hidden" }
                                        : {}
                                }
                            />
                        </Col>
                    </Row>

                    <Divider />
                    {props.column.folders.map((folder, index) => (
                        <FolderListItem
                            key={folder.key}
                            embedded={props.embedded}
                            folder={folder}
                            index={index}
                            handleClick={handleFolderClick}
                            deleteFolder={deleteFolder}
                            showStatusMessage={props.showStatusMessage}
                            selectedColumn={
                                props.selectedColumnIndex === props.index
                            }
                            unselected={
                                props.column.selectedFolderIndex === index
                            }
                            green={
                                props.column.selectedFolderIndex === index &&
                                props.selectedColumnIndex === props.index
                            }
                            grey={
                                props.column.selectedFolderIndex === index &&
                                props.selectedColumnIndex !== props.index &&
                                props.selectedColumnIndex >= props.index
                            }
                            white={
                                props.column.selectedFolderIndex !== index ||
                                props.selectedColumnIndex < props.index
                            }
                        />
                    ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("video"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <VideoListItem
                                    key={file.key}
                                    embedded={props.embedded}
                                    handleFileSelect={props.handleFileSelect}
                                    file={file}
                                    folder={props.column.folder}
                                    index={index + folderCount}
                                    showStatusMessage={props.showStatusMessage}
                                />
                            ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type === "application/pdf")
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <PDFListItem
                                    key={file.key}
                                    embedded={props.embedded}
                                    handleFileSelect={props.handleFileSelect}
                                    file={file}
                                    folder={props.column.folder}
                                    index={index + folderCount + videoCount}
                                    showStatusMessage={props.showStatusMessage}
                                />
                            ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("image"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <ImageListItem
                                    key={file.key}
                                    embedded={props.embedded}
                                    handleFileSelect={props.handleFileSelect}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount
                                    }
                                    showStatusMessage={props.showStatusMessage}
                                />
                            ))}
                    {props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("audio"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <AudioListItem
                                    key={file.key}
                                    embedded={props.embedded}
                                    handleFileSelect={props.handleFileSelect}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount +
                                        imageCount
                                    }
                                    showStatusMessage={props.showStatusMessage}
                                />
                            ))}
                    {editingEnabled &&
                        props.column.files &&
                        props.column.files
                            .filter((file) => file.type.includes("link"))
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <LinkListItem
                                    key={file.key}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount +
                                        imageCount
                                    }
                                    showStatusMessage={props.showStatusMessage}
                                />
                            ))}

                    {props.column.files &&
                        props.column.files
                            .filter(
                                (file) =>
                                    !file.type.includes("video") &&
                                    !file.type.includes("audio") &&
                                    !file.type.includes("image") &&
                                    !file.type.includes("link") &&
                                    file.type !== "application/pdf"
                            )
                            .sort((a, b) => {
                                if (a.displayName > b.displayName) return 1;
                                if (a.displayName < b.displayName) return -1;
                                return 0;
                            })
                            .map((file, index) => (
                                <MiscFileListItem
                                    key={file.key}
                                    embedded={props.embedded}
                                    handleFileSelect={props.handleFileSelect}
                                    file={file}
                                    folder={props.column.folder}
                                    index={
                                        index +
                                        folderCount +
                                        videoCount +
                                        pdfCount +
                                        imageCount +
                                        audioCount
                                    }
                                    showStatusMessage={props.showStatusMessage}
                                />
                            ))}

                    {provided.placeholder}
                    {editingEnabled && (
                        <Menu
                            keepMounted
                            open={clickLocation.mouseY !== null}
                            onClose={handleMenuClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                clickLocation.mouseY !== null &&
                                clickLocation.mouseX !== null
                                    ? {
                                          top: clickLocation.mouseY,
                                          left: clickLocation.mouseX,
                                      }
                                    : undefined
                            }
                        >
                            <div>
                                <MenuItem
                                    id="newfolder"
                                    onClick={handleMenuClose}
                                >
                                    Add Folder
                                </MenuItem>
                                <MenuItem
                                    id="newfile"
                                    onClick={handleMenuClose}
                                >
                                    Add Files
                                </MenuItem>
                                <MenuItem
                                    id="newlink"
                                    onClick={handleMenuClose}
                                >
                                    Add Link
                                </MenuItem>
                            </div>
                        </Menu>
                    )}
                    {editingEnabled && (
                        <Dialog
                            open={newFolderNameDialogOpen}
                            onClose={handleNewFolderNameDialogClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                New Folder
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Enter a name for the new folder.
                                </DialogContentText>
                                <TextField
                                    value={newFolderName}
                                    onChange={handleKeyboardInput}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label=""
                                    type=""
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleNewFolderNameDialogClose(
                                        "Cancel"
                                    )}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleNewFolderNameDialogClose(
                                        "OK"
                                    )}
                                    color="primary"
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </div>
            )}
        </Droppable>
    );
}
