import { useState, useEffect } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// My Files
import "./Calendar.css";

const useStyles = makeStyles((theme) => ({
    listContainer: { paddingBottom: 125 },
    eventChip: {
        ...theme.typography.bodyBold,
        // display: "block",
        // marginTop: 5,
        // margin: 2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export default function CalendarListView(props) {
    const classes = useStyles();

    const [dates, setDates] = useState([]);

    useEffect(() => {
        let datesArray = [];
        props.events
            .filter((e) => e.visible)
            .forEach((event) => {
                let matchingDate = datesArray.filter((d) =>
                    dayjs(d.date).isSame(dayjs(event.date))
                )[0];

                if (matchingDate != null) {
                    matchingDate.events.push(event);
                } else {
                    // Add a new date object
                    datesArray.push({ date: event.date, events: [event] });
                }
            });

        datesArray.sort((a, b) =>
            dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
        );

        setDates(datesArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div className={classes.listContainer}>
            {dates.map((date) => (
                <>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {dayjs(date.date).format("dddd, MMMM D, YYYY")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {date.events.map((event) => (
                                    <ListItem>
                                        <Chip
                                            color="primary"
                                            className={classes.eventChip}
                                            label={event.name}
                                            onClick={
                                                props.handleEventClick &&
                                                props.handleEventClick(event)
                                            }
                                            key={event.key}
                                            style={{
                                                backgroundColor: event.color,
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </>
            ))}
        </div>
    );
}
