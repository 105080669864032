import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { isMobile } from "react-device-detect";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
    document: {
        paddingTop: 65,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenPDFDialog(props) {
    const classes = useStyles();

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const [pdfWidth, setPdfWidth] = useState();

    useEffect(() => {
        if (isMobile) {
            setPdfWidth(window.innerWidth);
        } else {
            setPdfWidth(window.innerWidth * 0.85);
        }

        function handleResize() {
            if (isMobile) {
                setPdfWidth(window.innerWidth);
            } else {
                setPdfWidth(window.innerWidth * 0.85);
            }
        }

        window.addEventListener("resize", handleResize);
    }, []);

    const handleClose = () => {
        props.onClose();
    };

    // function handleCloseViewEventDialog() {
    //     setViewEventDialogOpen(false);
    // }

    // const handleEventClick = (event) => () => {
    //     setSelectedEvent(event);
    //     setViewEventDialogOpen(true);
    // };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleNextPagePress() {
        setPageNumber(pageNumber + 1);
    }

    function handlePreviousPagePress() {
        setPageNumber(pageNumber - 1);
    }

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.titleText}>
                            {props.file.displayName}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Document
                    file={props.file.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.document}
                >
                    <MobileStepper
                        steps={numPages}
                        position="static"
                        variant="text"
                        activeStep={pageNumber - 1}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNextPagePress}
                                disabled={pageNumber === numPages}
                            >
                                Next
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handlePreviousPagePress}
                                disabled={pageNumber === 1}
                            >
                                <KeyboardArrowLeft />
                                Prev
                            </Button>
                        }
                    />
                    <Page
                        pageNumber={pageNumber}
                        className={classes.page}
                        width={pdfWidth}
                    />
                </Document>
            </Dialog>
        </>
    );
}
