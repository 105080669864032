import Calendar from "react-calendar";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// My Files
import "./Calendar.css";
import { makeid } from "../../utility";

const useStyles = makeStyles((theme) => ({
    event: {
        ...theme.typography.bodyBold,
        display: "block",
        marginTop: 5,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export default function CalendarComponent(props) {
    const classes = useStyles();

    function onChange(nextValue) {
        props.handleDateSelect(nextValue);
    }

    // This is the content that is displayed in a date
    function tileContent({ date, view }) {
        if (view === "month") {
            // Return all the events on that date

            let matchingEvents = props.events.filter((calendarEvent) =>
                dayjs(calendarEvent.date).isSame(dayjs(date), "day")
            );

            let numEventsToShow = 3;

            // Only show the first x events in a date
            if (matchingEvents.length <= numEventsToShow) {
                return matchingEvents.map((calendarEvent) => (
                    <Chip
                        color="primary"
                        className={classes.event}
                        label={calendarEvent.name}
                        onClick={
                            props.handleEventClick &&
                            props.handleEventClick(calendarEvent)
                        }
                        key={calendarEvent.key}
                        style={{
                            backgroundColor: calendarEvent.color,
                        }}
                    />
                ));
            } else {
                // Show the first x events, than a "x more chip"
                let eventsToShow = [];
                let moreEvents = [];

                for (let i = 0; i < matchingEvents.length; i++) {
                    let event = matchingEvents[i];
                    if (i < numEventsToShow) {
                        eventsToShow.push(
                            <Chip
                                color="primary"
                                className={classes.event}
                                label={event.name}
                                onClick={
                                    props.handleEventClick &&
                                    props.handleEventClick(event)
                                }
                                key={event.key}
                                style={{
                                    backgroundColor: event.color,
                                }}
                            />
                        );
                    } else {
                        moreEvents.push(event);
                    }
                }

                eventsToShow.push(
                    <Chip
                        color="default"
                        className={classes.event}
                        label={
                            matchingEvents.length - numEventsToShow + " more..."
                        }
                        onClick={props.handleMoreEventsClick(moreEvents)}
                        variant="outlined"
                        key={makeid(15)}
                    />
                );

                return eventsToShow;
            }
        }
    }

    return (
        <Calendar
            onChange={onChange}
            // onClickDay={onClickDay}
            // showWeekNumbers
            defaultView="month"
            // value={value}
            tileContent={tileContent}
        />
    );
}
