import React, { useContext, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SendIcon from "@material-ui/icons/Send";

// My Files
import UserAvatar from "../management/UserAvatar";
import firebase from "../../firebase";
import ConversationMessage from "./ConversationMessage";
import ConversationGroupAvatar from "./ConversationGroupAvatar";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { UserContext } from "../../context";
import { jsonFromArray } from "../../utility";

const useStyles = makeStyles((theme) => ({
    topLine: {
        background: "rgb(240, 240, 240)",
        bottom: "auto",
        top: 64,
        position: "fixed",
        zIndex: 1,
        width: "83%",
        height: 60,
    },
    topLineContainer: { marginLeft: 15, paddingTop: 10 },
    headingText: {
        paddingTop: 4,
    },
    messagesContainer: {
        paddingBottom: 110,
    },
    messageInputContainer: {
        position: "fixed",
        display: "flex",
        top: "auto",
        bottom: 64,
        width: "83%",
        background: "rgb(240, 240, 240)",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
    },
    messageInputRow: {
        marginLeft: 0,
        paddingTop: 2.5,
        paddingBottom: 5,
    },
    inputTextField: {
        marginLeft: 10,
        width: "100%",
    },
    deleteIcon: {
        marginRight: 18,
    },
}));

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};

export default function ConversationDetail(props) {
    const classes = useStyles();

    const [text, setText] = useState("");
    const [messages, setMessages] = useState([]);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);
    const { currentUserData } = useContext(UserContext);

    const [conversation, setConversation] = useState();

    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
        // Sort messages with most recent at the bottom

        if (props.conversation) {
            if (props.conversation.deleted === false) {
                setConversation(props.conversation);
                setMessages(
                    props.conversation.messages.sort((a, b) =>
                        dayjs(a.date) > dayjs(b.date)
                            ? 1
                            : dayjs(b.date) > dayjs(a.date)
                            ? -1
                            : 0
                    )
                );

                // If any messages are set to unread, change them to read now they are being loaded
                props.conversation.messages.forEach((message) => {
                    if (!message.read) {
                        let messageRef = firebase
                            .database()
                            .ref("conversations")
                            .child(props.conversation.key)
                            .child("messages")
                            .child(message.key);

                        let readByObject = jsonFromArray(message.readBy);
                        readByObject[currentUserData.key] = true;

                        messageRef.update({ readBy: readByObject });
                        message.readBy.push(currentUserData.key);
                    }
                });

                // Conversation can only be deleted by the person who started it
                if (props.conversation.user && currentUserData) {
                    if (props.conversation.user.key === currentUserData.key) {
                        setCanDelete(true);
                    } else {
                        setCanDelete(false);
                    }
                }
            }
        }
    }, [props, currentUserData]);

    function handleTextInput(event) {
        setText(event.target.value);
    }

    function handleReplyClick() {
        if (conversation && text !== "") {
            let messageRef = firebase
                .database()
                .ref("conversations/" + conversation.key + "/messages")
                .push();

            let message = {
                id: messageRef.key,
                message: text,
                date: dayjs().toString(),
                user: conversation.user.key,
                fullName: conversation.user.fullName,
                deleted: false,
            };

            messageRef.set(message);

            message.readBy = [];
            message.key = message.id;

            let conversationRef = firebase
                .database()
                .ref("conversations")
                .child(conversation.key);
            conversationRef.update({ lastMessage: dayjs().toString() });

            setText("");

            conversation.messages.push(message);
            setConversation(conversation);
        }
    }

    function handleCloseOnDeleteDialog(result) {
        if (result === true && currentUserData && conversation && canDelete) {
            // Delete Conversation

            let conversationRef = firebase
                .database()
                .ref("conversations")
                .child(conversation.key);

            conversationRef.update({ deleted: true });

            conversation.deleted = true;
            setConversation(undefined);
        }

        setConfirmDeleteDialogOpen(false);
    }

    function handleOpenOnDeleteDialog() {
        setConfirmDeleteDialogOpen(true);
    }

    return (
        <div style={{ height: "100%" }}>
            {conversation && (
                <div style={{ height: "100%" }}>
                    <div className={classes.topLine}>
                        <Container fluid className={classes.topLineContainer}>
                            <Row>
                                {conversation.otherUsers.length === 1 ? (
                                    <UserAvatar
                                        userOrHub={conversation.otherUsers[0]}
                                        path="users"
                                        variant="circular"
                                    />
                                ) : (
                                    <ConversationGroupAvatar
                                        conversation={conversation}
                                    />
                                )}

                                <Col>
                                    <h3 className={classes.headingText}>
                                        {conversation.title}
                                    </h3>
                                </Col>
                                {canDelete && (
                                    <IconButton
                                        className={classes.deleteIcon}
                                        aria-label="delete"
                                        onClick={handleOpenOnDeleteDialog}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Row>
                        </Container>
                        <Divider />
                    </div>
                    <div
                        // fluid
                        className={classes.messagesContainer}
                        style={{
                            paddingTop: 45,
                        }}
                    >
                        <List>
                            {messages
                                .filter((message) => message.deleted === false)
                                .map((message) => (
                                    <ConversationMessage
                                        conversation={conversation}
                                        message={message}
                                        key={message.key}
                                        hideMenu
                                    />
                                ))}
                        </List>
                    </div>
                    <Container fluid className={classes.messageInputContainer}>
                        <UserAvatar
                            userOrHub={conversation.user}
                            size={props.avatarSize}
                            path="users"
                        />
                        <TextField
                            id="standard-multiline-flexible"
                            label="Add a message..."
                            multiline
                            value={text}
                            fullWidth
                            onChange={handleTextInput}
                            className={classes.inputTextField}
                        />
                        <IconButton onClick={handleReplyClick}>
                            <SendIcon />
                        </IconButton>
                    </Container>
                    <AlwaysScrollToBottom />

                    {confirmDeleteDialogOpen && (
                        <ConfirmDeleteDialog
                            title="Delete Conversation"
                            message="Are you sure you want to delete this conversation?"
                            open={confirmDeleteDialogOpen}
                            onClose={handleCloseOnDeleteDialog}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
