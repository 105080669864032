import React, { useState, useContext } from "react";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// My Files
import firebase from "../../firebase";
import { UserContext } from "../../context";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 10,
        marginLeft: 15,
        marginRight: 15,
    },
    textField: {
        marginTop: 10,
    },
    button: {
        marginTop: 15,
        marginRight: 50,
        marginLeft: 20,
        marginBottom: 15,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangeEmailDialog(props) {
    const classes = useStyles();

    const [currentPassword, setCurrentPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const [inProgress, setInProgress] = useState(false);

    const [statusMessage, setStatusMessage] = useState("");
    const [statusMessageIsVisible, setStatusMessageIsVisible] = useState(false);

    const { currentUserData } = useContext(UserContext);

    const handleClose = () => {
        setCurrentPassword("");
        setNewEmail("");
        setStatusMessageIsVisible(false);
        setStatusMessage("");

        props.onClose();
    };

    const handleChange = (event) => {
        if (event.target.id === "currentpassword") {
            setCurrentPassword(event.target.value);
        } else if (event.target.id === "email") {
            setNewEmail(event.target.value);
        }
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusMessageIsVisible(false);
    };

    function handleOKPress() {
        const auth = firebase.auth();
        const user = auth.currentUser;

        setInProgress(true);

        if (user) {
            const credential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                currentPassword
            );

            user.reauthenticateWithCredential(credential)
                .then((u) => {
                    user.updateEmail(newEmail)
                        .then((u) => {
                            setInProgress(false);

                            // Has been updated in auth, now change in database
                            let ref = firebase.database().ref("users");

                            ref.child(currentUserData.key)
                                .update({
                                    email: newEmail,
                                })
                                .then(() => {
                                    props.onSuccess();

                                    setCurrentPassword("");
                                    setNewEmail("");
                                    setStatusMessageIsVisible(false);
                                    setStatusMessage("");
                                });
                        })
                        .catch((error) => {
                            setInProgress(false);
                            setStatusMessage(error.message);
                            setStatusMessageIsVisible(true);
                        });
                })
                .catch((error) => {
                    setCurrentPassword("");
                    setNewEmail("");

                    setStatusMessage("Password is incorrect");
                    setStatusMessageIsVisible(true);
                    setInProgress(false);
                });
        }
    }

    return (
        <Dialog onClose={handleClose} open={props.open}>
            <Container fluid className={classes.container}>
                <Row>
                    <TextField
                        className={classes.textField}
                        id="currentpassword"
                        label="Enter Your Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={currentPassword}
                        onChange={handleChange}
                    />
                </Row>
                <Row>
                    <TextField
                        className={classes.textField}
                        id="email"
                        label="New Email"
                        type="email"
                        autoComplete="new-email"
                        variant="outlined"
                        value={newEmail}
                        onChange={handleChange}
                    />
                </Row>
                <Row>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOKPress}
                        className={classes.button}
                        fullWidth
                    >
                        OK
                    </Button>
                </Row>
            </Container>
            <Snackbar
                open={statusMessageIsVisible}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity="error">
                    {statusMessage}
                </Alert>
            </Snackbar>
            {inProgress && <LinearProgress />}
        </Dialog>
    );
}
