import {
    CallToAction,
    Content,
    Email,
    Header,
    Paragraph,
    Title,
} from "@rakered/email";

export default function SetPasswordEmail(props) {
    return (
        <Email>
            <Content>
                <Header logo="https://firebasestorage.googleapis.com/v0/b/nodiant-e20a8.appspot.com/o/180.png?alt=media&token=9821b2a9-1a70-4f63-a488-96377bb3a234" />
                <Title>Choose your Password</Title>

                <Paragraph>{props.message}</Paragraph>
                <Paragraph>Press the button to choose your password.</Paragraph>
                <CallToAction href={props.link}>
                    Choose your password
                </CallToAction>
            </Content>
        </Email>
    );
}
