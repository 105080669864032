import React, { useState } from "react";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// My Files
import firebase from "../../firebase";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 10,
        marginLeft: 15,
        marginRight: 15,
    },
    textField: {
        marginTop: 10,
    },
    button: {
        marginTop: 15,
        marginRight: 50,
        marginLeft: 20,
        marginBottom: 15,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangePasswordDialog(props) {
    const classes = useStyles();

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmedNewPassword, setConfirmedNewPassword] = useState("");

    const [inProgress, setInProgress] = useState(false);

    const [statusMessage, setStatusMessage] = useState("");
    const [statusMessageIsVisible, setStatusMessageIsVisible] = useState(false);

    const handleClose = () => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmedNewPassword("");
        setStatusMessageIsVisible(false);
        setStatusMessage("");

        props.onClose();
    };

    const handleChange = (event) => {
        if (event.target.id === "currentpassword") {
            setCurrentPassword(event.target.value);
        } else if (event.target.id === "newpassword") {
            setNewPassword(event.target.value);
        } else if (event.target.id === "confirmednewpassword") {
            setConfirmedNewPassword(event.target.value);
        }
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusMessageIsVisible(false);
    };

    function handleOKPress() {
        const auth = firebase.auth();
        const user = auth.currentUser;

        setInProgress(true);

        if (user) {
            const credential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                currentPassword
            );

            user.reauthenticateWithCredential(credential)
                .then((u) => {
                    if (newPassword === confirmedNewPassword) {
                        user.updatePassword(newPassword)
                            .then((u) => {
                                setInProgress(false);

                                props.onSuccess();

                                setCurrentPassword("");
                                setNewPassword("");
                                setConfirmedNewPassword("");
                                setStatusMessageIsVisible(false);
                                setStatusMessage("");
                            })
                            .catch((error) => {
                                setInProgress(false);
                                setStatusMessage(error.message);
                                setStatusMessageIsVisible(true);
                            });
                    } else {
                        setInProgress(false);
                        setStatusMessage("New Passwords do not match");
                        setStatusMessageIsVisible(true);
                    }
                })
                .catch((error) => {
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmedNewPassword("");

                    setStatusMessage("Current Password is incorrect");
                    setStatusMessageIsVisible(true);
                    setInProgress(false);
                });
        }
    }

    return (
        <Dialog onClose={handleClose} open={props.open}>
            <Container fluid className={classes.container}>
                <Row>
                    <TextField
                        className={classes.textField}
                        id="currentpassword"
                        label="Current Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={currentPassword}
                        onChange={handleChange}
                    />
                </Row>
                <Row>
                    <TextField
                        className={classes.textField}
                        id="newpassword"
                        label="New Password"
                        type="password"
                        autoComplete="new-password"
                        variant="outlined"
                        value={newPassword}
                        onChange={handleChange}
                    />
                </Row>
                <Row>
                    <TextField
                        className={classes.textField}
                        id="confirmednewpassword"
                        label="Confirm New Password"
                        type="password"
                        autoComplete="new-password"
                        variant="outlined"
                        value={confirmedNewPassword}
                        onChange={handleChange}
                    />
                </Row>
                <Row>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOKPress}
                        className={classes.button}
                        fullWidth
                    >
                        OK
                    </Button>
                </Row>
            </Container>
            <Snackbar
                open={statusMessageIsVisible}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity="error">
                    {statusMessage}
                </Alert>
            </Snackbar>
            {inProgress && <LinearProgress />}
        </Dialog>
    );
}
