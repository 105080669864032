import { useState, useEffect, useContext } from "react";
import { isMobile } from "react-device-detect";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import UserAvatar from "../management/UserAvatar";
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
    dateText: {
        color: "rgb(140, 140, 140)",
        paddingTop: 15,
    },
    moreButton: {
        width: 30,
        height: 30,
    },
}));

export default function ConversationMessage(props) {
    const classes = useStyles();
    const [time, setTime] = useState(Date.now()); // Updates the comments periodically so their dateString stays current (e.g. 5 minutes ago)

    const [dateString, setDateString] = useState("");

    const { users } = useContext(DatabaseContext);

    const [user, setUser] = useState();

    const [isHovering, setIsHovering] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const { currentUserData } = useContext(UserContext);

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 5000);

        // Get the comment date and determine the largest non-zero time interval (e.g. if the comment was 1 month 6 days ago, we want '1 month' as the result)
        let commentDate = dayjs(props.message.date);
        let now = dayjs();

        if (!props.message.user.fullName) {
            let userId = props.message.user;

            setUser(users.filter((u) => u.key === userId)[0]);
        }

        let diffs = [
            {
                interval: "years",
                value: now.diff(commentDate, "years"),
            },
            {
                interval: "months",
                value: now.diff(commentDate, "months"),
            },
            {
                interval: "weeks",
                value: now.diff(commentDate, "weeks"),
            },
            {
                interval: "days",
                value: now.diff(commentDate, "days"),
            },
            {
                interval: "hours",
                value: now.diff(commentDate, "hours"),
            },
            {
                interval: "minutes",
                value: now.diff(commentDate, "minutes"),
            },
        ].filter((diff) => diff.value > 0);

        if (diffs.length === 0) {
            props.message.dateString = "a moment ago";
            setDateString(props.message.dateString);
        } else {
            let diff = diffs[0];

            if (diff.value === 1) {
                props.message.dateString =
                    diff.value + " " + diff.interval.replace("s", "") + " ago ";
                setDateString(props.message.dateString);
            } else {
                props.message.dateString =
                    diff.value + " " + diff.interval + " ago ";
                setDateString(props.message.dateString);
            }
        }

        return () => {
            clearInterval(interval);
        };
    }, [props.message, time, users]);

    function onMouseEnter() {
        if (currentUserData && props.message.user === currentUserData.key) {
            setIsHovering(true);
        }
    }

    function onMouseLeave() {
        setIsHovering(false);
    }

    const handleMenuClick = (event) => {
        setMenuOpen(true);
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleDeleteButtonPress = () => {
        let messageRef = firebase
            .database()
            .ref("conversations")
            .child(props.conversation.key)
            .child("messages")
            .child(props.message.key);

        messageRef.update({ deleted: true });

        handleMenuClose();
    };

    return (
        <>
            <Container
                fluid
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                key={props.message.key}
            >
                <Row>
                    <Col>
                        <Row>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <UserAvatar
                                        userOrHub={user}
                                        path="users"
                                        size="small"
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {props.message.fullName}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textSecondary"
                                            >
                                                {" " + dateString}
                                            </Typography>
                                        </>
                                    }
                                    secondary={props.message.message}
                                />
                            </ListItem>
                        </Row>
                    </Col>
                    {!props.hideMenu && (
                        <IconButton
                            className={classes.moreButton}
                            hidden={!isHovering && !menuOpen && !isMobile}
                            onClick={handleMenuClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </Row>
                <Divider variant="inset" component="li" />
            </Container>
            <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDeleteButtonPress}>
                    Delete Message
                </MenuItem>
            </Menu>
        </>
    );
}
