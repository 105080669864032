import { useState, useEffect, useContext } from "react";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Slide from "@material-ui/core/Slide";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import { Breadcrumbs } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

// My Files
import UserAvatar from "../../management/UserAvatar";
import { UserContext, DatabaseContext } from "../../../context";
import firebase from "../../../firebase";
import { jsonFromArray } from "../../../utility";
import ConfirmDialog from "../../ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    descendantRow: {
        marginLeft: 50,
    },
    infoText: {
        marginLeft: 15,
        marginTop: 10,
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    container: {
        paddingBottom: 45,
    },
    avatar: {
        width: "50px",
        height: "50px",
    },
    listText: {
        paddingLeft: theme.spacing(1),
    },
    noHubsText: {
        marginTop: 15,
        marginLeft: 15,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Shows a list of hubs which can be added to a user
export default function ShareFileDialog(props) {
    const classes = useStyles();

    const { currentUserData } = useContext(UserContext);

    const { folders } = useContext(DatabaseContext);

    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: "Hubs", key: "Root" },
    ]); // Used to keep track of the parent groups as user navigates down the tree, then use this to implement the breadcrumb

    const [visibleHubs, setVisibleHubs] = useState([]); // Just the hubs that are visible at the moment

    const [noHubsAtThisLevel, setNoHubsAtThisLevel] = useState(false);

    const [selectedHub, setSelectedHub] = useState();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    useEffect(() => {
        let potentiallyVisibleHubs = folders.filter(
            (f) => f.hub && f.parent === "Root"
        );

        potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        if (potentiallyVisibleHubs.length === 0) {
            setNoHubsAtThisLevel(true);
        } else {
            setNoHubsAtThisLevel(false);
        }

        if (
            currentUserData &&
            currentUserData.role === "System Digital Manager"
        ) {
            setVisibleHubs(potentiallyVisibleHubs);
        } else {
            let visibleHubsArray = [];
            // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
            potentiallyVisibleHubs.forEach((hub) => {
                folders
                    .filter(
                        (f) =>
                            f.hub &&
                            (f.ancestors.includes(hub.key) || f.key === hub.key)
                    )
                    .forEach((descendantHub) => {
                        if (
                            descendantHub.managers.includes(currentUserData.key)
                        ) {
                            if (!visibleHubsArray.includes(hub)) {
                                visibleHubsArray.push(hub);
                            }
                        }
                    });
            });

            setVisibleHubs(visibleHubsArray);
        }

        // Don't put props.SelectedHubs in the dependancies! causes them to be reset when breadcrumb is changed
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData, folders, props.open]);

    const handleHubClick = (hub) => () => {
        setBreadcrumbs((prevValue) => {
            return [...prevValue, { name: hub.name, key: hub.key }];
        });

        let potentiallyVisibleHubs = folders.filter(
            (f) => f.hub && f.parent === hub.key
        );

        potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        if (potentiallyVisibleHubs.length === 0) {
            setNoHubsAtThisLevel(true);
        } else {
            setNoHubsAtThisLevel(false);
        }

        if (
            currentUserData &&
            currentUserData.role === "System Digital Manager"
        ) {
            setVisibleHubs(potentiallyVisibleHubs);
        } else {
            let visibleHubsArray = [];
            // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
            potentiallyVisibleHubs.forEach((hub) => {
                folders
                    .filter(
                        (f) =>
                            f.hub &&
                            (f.ancestors.includes(hub.key) || f.key === hub.key)
                    )
                    .forEach((descendantHub) => {
                        if (
                            descendantHub.managers.includes(currentUserData.key)
                        ) {
                            if (!visibleHubsArray.includes(hub)) {
                                visibleHubsArray.push(hub);
                            }
                        }
                    });
            });

            setVisibleHubs(visibleHubsArray);
        }
    };

    const handleBreadcrumbClick = (breadcrumb) => () => {
        // Remove breadcrumbs after (and including) the one that has been pressed
        let index = 0;

        for (index = 0; index < breadcrumbs.length; index++) {
            let hub = breadcrumbs[index];

            if (hub.key === breadcrumb.key) {
                break;
            }
        }

        var newBreadCrumbs = [...breadcrumbs];

        newBreadCrumbs.length = index + 1;

        setBreadcrumbs(newBreadCrumbs);

        // Set the current parent to the breadcrumb that has been selected

        //
        let potentiallyVisibleHubs = folders.filter(
            (f) => f.hub && f.parent === breadcrumb.key
        );

        potentiallyVisibleHubs = potentiallyVisibleHubs.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        if (potentiallyVisibleHubs.length === 0) {
            setNoHubsAtThisLevel(true);
        } else {
            setNoHubsAtThisLevel(false);
        }

        if (
            currentUserData &&
            currentUserData.role === "System Digital Manager"
        ) {
            setVisibleHubs(potentiallyVisibleHubs);
        } else {
            let visibleHubsArray = [];
            // Out of the hubs that could be shown at this point, check if the currentUser is a manager of any hub down the hierarchy, if so, should show that hub.
            potentiallyVisibleHubs.forEach((hub) => {
                folders
                    .filter(
                        (f) =>
                            f.hub &&
                            (f.ancestors.includes(hub.key) || f.key === hub.key)
                    )
                    .forEach((descendantHub) => {
                        if (
                            descendantHub.managers.includes(currentUserData.key)
                        ) {
                            if (!visibleHubsArray.includes(hub)) {
                                visibleHubsArray.push(hub);
                            }
                        }
                    });
            });

            setVisibleHubs(visibleHubsArray);
        }
    };

    function handleCancel() {
        props.onClose(false);
    }

    function handleSuccess() {
        props.onClose(true);
    }

    const handleAddToHubClick = (addingHub) => () => {
        setSelectedHub(addingHub);
        setConfirmDialogOpen(true);
    };

    function shouldSelectHubButtonBeEnabled(hub) {
        // If the user is not in the managers of the folder (and they are not a System Digital Manager)
        // The button should be disabled
        if (currentUserData.role === "System Digital Manager") {
            return true;
        } else if (hub.managers.includes(currentUserData.key)) {
            return true;
        }

        return false;
    }

    function handleCloseConfirmDialog(result) {
        if (result === true) {
            // Confirmed that we want to share the file with the selectedHub

            if (selectedHub) {
                // We are creating a duplicate of the file, and setting it's folder to the selected hub

                // creates a new entry for the file in the database, but it references the same file in storage
                var filesRef = firebase.database().ref("files");

                var original = props.file.key;

                if (props.file.original) {
                    // Creating a duplicate of a duplicate, so the original should be the key of the original file
                    original = props.file.original;
                }

                var uploadedBy = "";
                if (currentUserData) {
                    uploadedBy = currentUserData.key;
                }

                filesRef.push({
                    filename: props.file.filename,
                    displayName: props.file.displayName,
                    folder: selectedHub.key,
                    type: props.file.type,
                    allowDownloads: props.file.allowDownloads,
                    allowComments: props.file.allowComments,
                    uploadedBy: uploadedBy,
                    managers: jsonFromArray(selectedHub.managers),
                    ancestors: jsonFromArray(selectedHub.ancestors),
                    url: props.file.url,
                    thumbnail: props.file.thumbnail,
                    original: original,
                });
                handleSuccess();
            }
        } else {
            setSelectedHub(null);
        }

        setConfirmDialogOpen(false);
    }

    return (
        <Dialog
            fullScreen
            // onClose={handle}
            aria-labelledby="simple-dialog-title"
            maxWidth="lg"
            open={props.open}
            fullWidth={true}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCancel}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.titleText}>
                        Select Hub
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <div className={classes.container}>
                <Paper square>
                    <Breadcrumbs
                        separator="›"
                        aria-label="breadcrumb"
                        className={classes.text}
                        //maxItems="2"
                    >
                        {breadcrumbs.map((breadcrumb) => (
                            <Link
                                href="#"
                                onClick={handleBreadcrumbClick(breadcrumb)}
                                key={breadcrumb.key}
                            >
                                {breadcrumb.name}
                            </Link>
                        ))}
                    </Breadcrumbs>
                    {noHubsAtThisLevel && (
                        <h5 className={classes.noHubsText}>
                            There are no hubs at this level yet
                        </h5>
                    )}
                    <List className={classes.list}>
                        {visibleHubs.map((hub, index, array) => (
                            <React.Fragment key={hub.key}>
                                <ListItem button onClick={handleHubClick(hub)}>
                                    <ListItemAvatar>
                                        <UserAvatar
                                            userOrHub={hub}
                                            path="hubs"
                                            variant="rounded"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={hub.name}
                                        className={classes.listText}
                                        secondary={
                                            !shouldSelectHubButtonBeEnabled(
                                                hub
                                            ) &&
                                            "You are not a manager of this hub"
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Share with Hub">
                                            <Button
                                                color="primary"
                                                disabled={
                                                    !shouldSelectHubButtonBeEnabled(
                                                        hub
                                                    )
                                                }
                                                onClick={handleAddToHubClick(
                                                    hub
                                                )}
                                            >
                                                Select
                                            </Button>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {index < array.length - 1 && (
                                    <Divider variant="inset" component="li" />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
                {selectedHub && (
                    <ConfirmDialog
                        open={confirmDialogOpen}
                        onClose={handleCloseConfirmDialog}
                        title={"Confirm Share"}
                        message={
                            "Are you sure you want to share the file with " +
                            selectedHub.name +
                            "?"
                        }
                    />
                )}
            </div>
        </Dialog>
    );
}
