import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1%",
        marginLeft: "2%",
        marginRight: "2%",
        marginBottom: 5,
    },
    closeButton: {
        cursor: "pointer",
        position: "absolute",
        left: "auto",
        right: 0,
        paddingTop: 5,
    },
    chip: {
        marginBottom: 10,
    },
}));

export default function LinkNotificationCard(props) {
    const classes = useStyles();
    const history = useHistory();

    const handleFileClick = (n) => () => {
        props.updateSeenBy(props.notification);
        history.push("/link/" + n.file);
    };

    function handleDismissPressed() {
        props.updateSeenBy(props.notification);
    }

    return (
        <Card className={classes.card} variant="outlined">
            <CardActionArea>
                {props.popupFeed && (
                    <Tooltip title="Dismiss">
                        <CloseIcon
                            className={classes.closeButton}
                            onClick={handleDismissPressed}
                        />
                    </Tooltip>
                )}
                <CardContent onClick={handleFileClick(props.notification)}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.notification.title}
                    </Typography>
                    {props.notification.hubName && (
                        <Chip
                            className={classes.chip}
                            size="small"
                            label={props.notification.hubName}
                        />
                    )}
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {props.notification.message}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    onClick={handleFileClick(props.notification)}
                >
                    Comments ({props.notification.comments.length})
                </Button>
            </CardActions>
        </Card>
    );
}
