import React, { useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { LinearProgress } from "@material-ui/core";

// My Files
import AvatarUploadButton from "../management/AvatarUploadButton";
import firebase from "../../firebase";
import { makeid } from "../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 10,
    },
    textField: {
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
    },
    button: {
        margin: 20,
    },
}));

export default function NewGroupDialog(props) {
    const classes = useStyles();

    const [group, setGroup] = useState({ key: makeid(19) });
    const [inProgress, setInProgress] = useState(false);

    const handleClose = () => {
        props.onClose();
    };

    const handleTextChange = (event) => {
        setGroup((prev) => ({ ...prev, title: event.target.value }));
    };

    async function handleAddClick() {
        if (group.title !== "") {
            setInProgress(true);
            await uploadAvatar().then((downloadURL) => {
                props.createGroup(group, downloadURL);
                setInProgress(false);
            });
        }
    }

    function uploadAvatar() {
        return new Promise((resolve, reject) => {
            let storage = firebase.storage();

            if (group.avatarEditor) {
                group.avatarEditor
                    .getImageScaledToCanvas()
                    .toBlob(function (blob) {
                        // Upload the new file
                        let avatarRef = storage
                            .ref()
                            .child(
                                "avatars/conversations/" +
                                    group.key +
                                    "/avatar.png"
                            );

                        avatarRef
                            .put(blob)
                            .then((snapshot) => {
                                // Upload was successful
                                console.log("Upload Successful");
                                group.avatar = null;

                                avatarRef.getDownloadURL().then((url) => {
                                    resolve(url);
                                });
                            })
                            .catch((error) => {
                                let errorCode = error.code;
                                let errorMessage = error.message;
                                console.log(errorCode + ":" + errorMessage);
                                setInProgress(false);
                                reject();
                            });
                    });
            } else {
                // Didn't select an image for the group
                resolve();
            }
        });
    }

    return (
        <Dialog
            onClose={handleClose}
            open={props.open}
            className={classes.root}
        >
            <h4 className={classes.title}>New Group</h4>
            <AvatarUploadButton recipient={group} path="conversations" />
            <TextField
                className={classes.textField}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={group.title}
                onChange={handleTextChange}
            />
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleAddClick}
            >
                Create Group
            </Button>
            {inProgress && <LinearProgress />}
        </Dialog>
    );
}
