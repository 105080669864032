import React, { useEffect, useContext, useState } from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// My Files
import { UserContext } from "../../../context";
import firebase from "../../../firebase";
import MessageIcon from "@material-ui/icons/Message";
import ProjectCommentsDialog from "./Project Comments/ProjectCommentsDialog";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 0,
    },
    row: {
        padding: 0,
        margin: 0,
    },
    arrowIcon: {
        padding: 0,
    },
    addedByText: {
        color: "rgb(180, 180, 180)",
        fontSize: "12px",
    },
    commentsIcon: { padding: 15 },
}));

export default function ProjectTextBoxComponent(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const [projectKey, setProjectKey] = useState("");
    const [text, setText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [textFieldWidth, setTextFieldWidth] = useState();
    const [allowComments, setAllowComments] = useState(false);
    const [commentsDialogOpen, setCommentsDialogOpen] = useState(false);

    useEffect(() => {
        if (currentUserData) {
            if (props.component) {
                setProjectKey(props.projectKey);
                setIsEditing(props.isEditing);
                setText(props.component.text);
                setAllowComments(props.component.allowComments);
            }

            let newWidth = window.innerWidth - 150.0;

            if (newWidth > 1150) {
                newWidth = 1150;
            }

            setTextFieldWidth(newWidth);

            function handleResize() {
                let newWidth = window.innerWidth - 150.0;

                if (newWidth > 1150) {
                    newWidth = 1150;
                }

                setTextFieldWidth(newWidth);
            }

            window.addEventListener("resize", handleResize);
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    const handleAllowCommentsChange = (event) => {
        setAllowComments(event.target.checked);

        // Write change to firebase

        if (projectKey !== "") {
            firebase
                .database()
                .ref("projects")
                .child(projectKey)
                .child("components")
                .child(props.component.key)
                .child("allowComments")
                .set(event.target.checked);
        }
    };

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "text") {
            // console.log(question);
            setText(newValue);
            props.component.text = newValue;

            // Write changes to firebase

            if (projectKey !== "") {
                firebase
                    .database()
                    .ref("projects")
                    .child(projectKey)
                    .child("components")
                    .child(props.component.key)
                    .child("text")
                    .set(newValue);
            }
        }
    }

    function handleDeletePressed() {
        props.delete(props.component);
    }

    function handleMoveUpPressed() {
        props.moveUp(props.component);
    }

    function handleMoveDownPressed() {
        props.moveDown(props.component);
    }

    function handleCommentsPressed() {
        setCommentsDialogOpen(true);
    }

    function handleCloseCommentsDialog() {
        setCommentsDialogOpen(false);
    }

    return (
        <>
            {isEditing ? (
                <>
                    <TextField
                        style={{ minHeight: 35, width: textFieldWidth }}
                        multiline
                        width={textFieldWidth}
                        variant="outlined"
                        rows={5}
                        value={text}
                        id="text"
                        onChange={handleTextFieldChange}
                        placeholder="Your Text"
                    />
                    <ButtonGroup orientation="vertical">
                        <IconButton
                            className={classes.arrowIcon}
                            aria-label="moveUp"
                            onClick={handleMoveUpPressed}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                            className={classes.arrowIcon}
                            aria-label="moveDown"
                            onClick={handleMoveDownPressed}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </ButtonGroup>
                    <Tooltip title="Delete">
                        <IconButton
                            className={classes.deleteIcon}
                            aria-label="delete"
                            onClick={handleDeletePressed}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="Primary"
                                checked={allowComments}
                                onChange={handleAllowCommentsChange}
                            />
                        }
                        label="Allow Comments"
                    />
                    <h5 className={classes.addedByText}>
                        {props.component.addedByName +
                            " (" +
                            dayjs(props.component.addedOn).format(
                                "DD/MM/YYYY"
                            ) +
                            ")"}
                    </h5>
                </>
            ) : (
                <>
                    <TextField
                        style={
                            allowComments
                                ? { minHeight: 35, width: textFieldWidth }
                                : { minHeight: 35 }
                        }
                        multiline
                        fullWidth={!allowComments}
                        width={textFieldWidth}
                        variant="outlined"
                        value={text}
                        id="text"
                    />

                    {allowComments === true && (
                        <Tooltip title="Comments">
                            <IconButton
                                className={classes.commentsIcon}
                                aria-label="Comments"
                                onClick={handleCommentsPressed}
                            >
                                <MessageIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <h5 className={classes.addedByText}>
                        {props.component.addedByName +
                            " (" +
                            dayjs(props.component.addedOn).format(
                                "DD/MM/YYYY"
                            ) +
                            ")"}
                    </h5>
                </>
            )}
            <ProjectCommentsDialog
                open={commentsDialogOpen}
                onClose={handleCloseCommentsDialog}
                component={props.component}
                canEdit={props.canEdit}
                projectKey={projectKey}
            />
        </>
    );
}
