// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
    noNotificationsCard: {
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
    },
}));
export default function NoNotificationsCard() {
    const classes = useStyles();

    return (
        <Card className={classes.noNotificationsCard} variant="outlined">
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt="nodiantlogo"
                    height={"300"}
                    image={"/images/logo512alt.png"}
                    title={"nodiant"}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Welcome to Nodiant
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        Announcements and Notifications will appear here
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
