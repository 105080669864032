import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

// My Files
import "../../calendar/Calendar.css";

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
    },
    container: {
        margin: 0,
    },
    chip: {
        marginLeft: 15,
        marginTop: 15,
    },
    messageTextField: {
        margin: 15,
    },
    title: {
        flexGrow: 1,
    },
    switch: { margin: 10 },
    embargoTitle: { marginLeft: 15 },
    embargoCalendar: { marginLeft: 15, marginRight: 15, maxWidth: 500 },
    event: {
        ...theme.typography.bodyBold,
        display: "block",
        marginTop: 5,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileNotificationsDialog(props) {
    const classes = useStyles();

    const [sendNotifications, setSendNotifications] = useState(false);

    const [message, setMessage] = useState("");

    const [fromDate, setFromDate] = useState(dayjs());

    useEffect(() => {
        if (props.send) {
            setSendNotifications(props.send);
        }
        if (props.message) {
            setMessage(props.message);
        }
    }, [props]);

    const handleClose = () => {
        props.onClose({
            sendNotifications: sendNotifications,
            message: message,
            fromDate: fromDate,
        });
    };

    const handleChangeMessageText = (event) => {
        setMessage(event.target.value);
    };

    function handleSwitchChange(event) {
        if (event.target.name === "send") {
            setSendNotifications(event.target.checked);
        }
    }

    function onFromDateChange(nextValue) {
        setFromDate(nextValue);
    }

    function tileContent({ date, view }) {
        // if (fromDate !== null) {
        //     if (dayjs(date).isSame(fromDate, "day")) {
        //         return (
        //             <Chip
        //                 color="primary"
        //                 className={classes.event}
        //                 label=""
        //                 // onClick={
        //                 //     props.handleEventClick &&
        //                 //     props.handleEventClick(calendarEvent)
        //                 // }
        //                 // key={calendarEvent.key}
        //                 // style={{
        //                 //     backgroundColor: calendarEvent.color,
        //                 // }}
        //             />
        //         );
        //     }
        // }
    }

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={props.open}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Notifications
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container fluid className={classes.container}>
                <Row>
                    <FormGroup row>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    checked={sendNotifications}
                                    onChange={handleSwitchChange}
                                    name="send"
                                    color="primary"
                                />
                            }
                            label={"Send Notifications"}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <TextField
                        id="standard-multiline-flexible"
                        label="Notification Message"
                        variant="outlined"
                        multiline
                        maxRows={15}
                        value={message}
                        onChange={handleChangeMessageText}
                        className={classes.messageTextField}
                    />
                </Row>
                <Row>
                    <h4 className={classes.embargoTitle}>Embargo Date</h4>
                </Row>
                <Row>
                    <div>
                        <Calendar
                            className={classes.embargoCalendar}
                            onChange={onFromDateChange}
                            // onClickDay={onClickDay}
                            // showWeekNumbers
                            defaultView="month"
                            // value={value}
                            tileContent={tileContent}
                        />
                    </div>
                </Row>
            </Container>
        </Dialog>
    );
}
