import { useState, useEffect } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    doneButton: {
        width: 60,
        margin: 10,
    },
    groupChip: {
        marginTop: 7.5,
    },
}));

export default function HubUsageFileFilterDialog(props) {
    const classes = useStyles();

    // const [hubGroups, setHubGroups] = useState([]);
    const [hubFiles, setHubFiles] = useState([]);

    // const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        if (props.files) {
            setHubFiles(props.files);
            setSelectedFiles(props.files.filter((u) => u.isIncludedInFilters));
        }
        // // eslint-disable-next-line
    }, [props.files]);

    const handleClose = () => {
        var excludedFileKeys = [];

        hubFiles.forEach((user) => {
            if (!user.isIncludedInFilters) {
                excludedFileKeys.push(user.key);
            }
        });
        props.onClose(excludedFileKeys);
    };

    const handleToggle = (user) => () => {
        if (selectedFiles.includes(user)) {
            user.isIncludedInFilters = false;
            setSelectedFiles(selectedFiles.filter((u) => u !== user));
        } else {
            user.isIncludedInFilters = true;
            setSelectedFiles([...selectedFiles, user]);
        }
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <Container fluid className={classes.container}>
                    <h4 className={classes.title}>File Filter</h4>
                    <List className={classes.list}>
                        {hubFiles.map((f) => (
                            <ListItem
                                key={f.key}
                                role={undefined}
                                dense
                                button
                                onClick={handleToggle(f)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedFiles.includes(f)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": f.key,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={f.key}
                                    primary={f.displayName}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        className={classes.doneButton}
                    >
                        Done
                    </Button>
                </Container>
            </Dialog>
        </>
    );
}
