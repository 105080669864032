// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { ListItemSecondaryAction } from "@material-ui/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { IconButton } from "@material-ui/core";

// My Files
import { commaSeperatedStringFromLabels, timeString } from "../../utility";

const useStyles = makeStyles((theme) => ({
    listItem: {
        // minHeight: 500,
        // paddingLeft: 50,
    },
    reorderUpButton: {
        position: "absolute",
        top: 0,
        padding: 5,
    },
    reorderDownButton: {
        position: "absolute",
        bottom: 0,
        padding: 5,
        margin: 0,
    },
    padding: {
        minWidth: 35,
        width: 35,
    },
    normalPadding: {
        minWidth: 15,
        width: 15,
    },
    reorderPadding: {
        minWidth: 35,
        width: 35,
    },
}));

export default function PlaylistListItem(props) {
    const classes = useStyles();

    function notesAndLabelsText(clip) {
        var text = "";

        if (clip.labels) {
            // text += commaSeperatedStringFromLa
            text += commaSeperatedStringFromLabels(clip.labels);
        }

        if (clip.note && clip.note !== "") {
            text += " (" + clip.note + ")";
        }

        return text;
    }

    function handleUpwardButtonPressed() {
        props.handleMoveClipUpward(props.clip);
    }

    function handleDownwardButtonPressed() {
        props.handleMoveClipDownward(props.clip);
    }

    function handleClipSelect(e) {
        if (!props.isReorderingClips) {
            props.handleClipSelect(props.index, props.clip, e);
        } else {
        }
    }

    return (
        <div>
            <ListItem
                className={classes.listItem}
                button
                onClick={handleClipSelect}
                style={
                    props.currentClip === props.clip
                        ? {
                              background: "rgb(0, 149, 61)",
                              color: "white",
                              minHeight: 75,
                              padding: 0,
                          }
                        : {
                              background: "rgb(230,230,230)",
                              minHeight: 75,
                              padding: 0,
                              margin: 0,
                              //   overflow: "hidden",
                              //   WebkitLineClamp: 1,
                              //   WebkitBoxOrient: "vertical",
                              //   display: "-webkit-box",
                          }
                }
            >
                {props.isReorderingClips ? (
                    <div>
                        <div className={classes.reorderPadding}></div>
                        <IconButton
                            className={classes.reorderUpButton}
                            component="span"
                            onClick={handleUpwardButtonPressed}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                            className={classes.reorderDownButton}
                            component="span"
                            onClick={handleDownwardButtonPressed}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </div>
                ) : (
                    <div className={classes.normalPadding}></div>
                )}
                <ListItemText
                    primary={props.clip.name}
                    primaryTypographyProps={{
                        style: {
                            paddingRight: 75,
                            overflow: "hidden",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            display: "-webkit-box",
                            // textAlign: "justify",
                            // textJustify: "inter-word",
                        },
                    }}
                    secondary={notesAndLabelsText(props.clip)}
                    secondaryTypographyProps={{
                        style: {
                            paddingRight: 100,
                            overflow: "hidden",
                            // WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            display: "-webkit-box",
                            textAlign: "justify",
                            textJustify: "inter-word",
                        },
                    }}
                />
                <ListItemSecondaryAction
                    style={
                        props.currentClip === props.clip
                            ? {
                                  color: "white",
                              }
                            : {}
                    }
                >
                    {timeString(props.clip.inTime) +
                        " - " +
                        timeString(props.clip.outTime)}
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}
