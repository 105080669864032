import React, { useContext, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";

// Material UI
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";

// My Files

import UserAvatar from "../../management/UserAvatar";
import firebase from "../../../firebase";
import ConversationMessage from "../ConversationMessage";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import { UserContext } from "../../../context";
import ConversationGroupAvatar from "../ConversationGroupAvatar";
import { jsonFromArray } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: 15,
    },
    topLine: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
    },
    headingText: {
        paddingTop: 4,
    },
    messagesContainer: {
        marginTop: 45,
        paddingBottom: 110,
    },
    messageInputContainer: {
        position: "fixed",
        display: "flex",
        top: "auto",
        bottom: 0,
        width: "100%",
        background: "rgb(240, 240, 240)",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
    },
    inputTextField: {
        marginLeft: 10,
    },
    inputAvatar: {
        marginTop: 15,
        // marginLeft: 15,
        // paddingLeft: 15,
    },
    messageInput: {
        marginTop: 5,
        marginLeft: 5,
        // paddingBottom: 5,
    },
    cancelButton: {
        flexGrow: 1,
        minWidth: 60,
        maxWidth: 60,
        minHeight: 25,
        maxHeight: 25,
        fontSize: 13,
        marginTop: 7,
        marginLeft: 0,
        marginBottom: 5,
    },
    commentButton: {
        minWidth: 60,
        maxWidth: 60,
        minHeight: 25,
        maxHeight: 25,
        fontSize: 13,
        marginTop: 7,
        marginLeft: 5,
        marginRight: 14,
        marginBottom: 5,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};

export default function ConversationDetailMobile(props) {
    const classes = useStyles();

    const [text, setText] = useState("");
    // const [messages, setMessages] = useState([]);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);
    const { currentUserData } = useContext(UserContext);

    const [conversation, setConversation] = useState();

    useEffect(() => {
        // Sort messages with most recent at the bottom

        if (props.conversation) {
            if (props.conversation.deleted === false) {
                setConversation(props.conversation);
            }

            // If any messages are set to unread, change them to read now they are being loaded
            props.conversation.messages.forEach((message) => {
                if (!message.read) {
                    let messageRef = firebase
                        .database()
                        .ref("conversations")
                        .child(props.conversation.key)
                        .child("messages")
                        .child(message.key);
                    let readByObject = jsonFromArray(message.readBy);
                    readByObject[currentUserData.key] = true;
                    messageRef.update({ readBy: readByObject });
                    message.readBy.push(currentUserData.key);
                }
            });
        }
    }, [props, currentUserData]);

    function handleTextInput(event) {
        setText(event.target.value);
    }

    function handleReplyClick() {
        if (conversation) {
            let messageRef = firebase
                .database()
                .ref("conversations/" + conversation.key + "/messages")
                .push();

            let message = {
                id: messageRef.key,
                message: text,
                date: dayjs().toString(),
                user: conversation.user.key,
                fullName: conversation.user.fullName,
                deleted: false,
            };

            messageRef.set(message);

            message.readBy = [];
            message.key = message.id;

            let conversationRef = firebase
                .database()
                .ref("conversations")
                .child(conversation.key);
            conversationRef.update({ lastMessage: dayjs().toString() });

            setText("");

            conversation.messages.push(message);
            setConversation(conversation);
        }
    }

    function handleCloseOnDeleteDialog(result) {
        if (result === true && currentUserData && conversation) {
            // Delete Conversation

            let conversationRef = firebase
                .database()
                .ref("conversations")
                .child(conversation.key);

            conversationRef.update({ deleted: true });

            conversation.deleted = true;
            setConversation(undefined);
        }

        setConfirmDeleteDialogOpen(false);
    }

    // function handleOpenOnDeleteDialog() {
    //     setConfirmDeleteDialogOpen(true);
    // }

    return (
        <Dialog
            fullScreen
            // onClose={handleClose}
            open={props.open}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    {conversation && conversation.otherUsers.length === 1 ? (
                        <UserAvatar
                            userOrHub={conversation.otherUsers[0]}
                            path="users"
                            variant="circular"
                        />
                    ) : (
                        <ConversationGroupAvatar conversation={conversation} />
                    )}
                    <Typography variant="h6" className={classes.title}>
                        {conversation && conversation.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            {conversation && (
                <>
                    <Container fluid className={classes.messagesContainer}>
                        <List>
                            {conversation.messages
                                .filter((message) => message.deleted === false)
                                .sort((a, b) =>
                                    dayjs(a.date) > dayjs(b.date)
                                        ? 1
                                        : dayjs(b.date) > dayjs(a.date)
                                        ? -1
                                        : 0
                                )
                                .map((message) => (
                                    <ConversationMessage
                                        conversation={conversation}
                                        message={message}
                                        key={message.key}
                                        hideMenu
                                    />
                                ))}
                        </List>
                    </Container>
                    <Container fluid className={classes.messageInputContainer}>
                        <UserAvatar
                            userOrHub={conversation.user}
                            size={props.avatarSize}
                            path="users"
                        />
                        <TextField
                            id="standard-multiline-flexible"
                            label="Add a message..."
                            multiline
                            value={text}
                            fullWidth
                            onChange={handleTextInput}
                            className={classes.inputTextField}
                        />
                        <IconButton onClick={handleReplyClick}>
                            <SendIcon />
                        </IconButton>
                    </Container>
                    {confirmDeleteDialogOpen && (
                        <ConfirmDeleteDialog
                            title="Delete Conversation"
                            message="Are you sure you want to delete this conversation?"
                            open={confirmDeleteDialogOpen}
                            onClose={handleCloseOnDeleteDialog}
                        />
                    )}
                </>
            )}
            <AlwaysScrollToBottom />
        </Dialog>
    );
}
