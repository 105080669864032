import React from "react";
import ReactPlayer from "react-player";

export default function ProjectVideoFileComponent(props) {
    const playerRef = React.useRef();

    return (
        <>
            {props.file && (
                <ReactPlayer
                    style={{ maxWidth: props.contentWidth }}
                    ref={playerRef}
                    url={
                        props.file.original
                            ? "https://vz-db0e7359-d70.b-cdn.net/" +
                              props.file.original +
                              "/playlist.m3u8"
                            : "https://vz-db0e7359-d70.b-cdn.net/" +
                              props.file.key +
                              "/playlist.m3u8"
                    }
                    autoPlay={false}
                    controls={true}
                    // onProgress={onProgress}
                    // playing={playing}
                    // onPlay={handlePlay}
                    // playbackRate={playbackRate}
                    width="100%"
                    // hlsConfig={{
                    //     startPosition: -1,
                    // }}
                    height="auto"
                />
            )}
        </>
    );
}
