import { useState, useEffect, useContext } from "react";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

// My Files
import { UserContext } from "../../../context";
import firebase from "../../../firebase";
import { jsonFromArray, makeid } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
    title: {
        marginTop: 15,
        marginLeft: 15,
        marginRight: 15,
    },
    subtitle: {
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 5,
    },
    doneButton: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "33.3%",
        width: "33.3%",
    },
}));

// Shows a list of hubs which can be added to a user
export default function ManageGroupEnrolementDialog(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [canMakeChanges, setCanMakeChanges] = useState(false);

    useEffect(() => {
        if (currentUserData) {
            if (
                props.hub.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager"
            ) {
                setCanMakeChanges(true);
            }
        }

        setGroups([...props.hub.groups]);

        setSelectedGroups(
            props.hub.groups.filter((g) => doesGroupContainUser(g))
        );

        function doesGroupContainUser(group) {
            if (group.users.filter((u) => u.key === props.userKey).length > 0) {
                return true;
            }
            return false;
        }
    }, [props, currentUserData]);

    const handleClose = () => {
        props.onClose();
    };

    function handleDonePress() {
        props.onClose();
    }

    const handleToggle = (group) => () => {
        // !! MIRROR CHANGES HERE IN NewUserPage !!

        // Save to firebase as toggle is pressed
        if (selectedGroups.includes(group)) {
            // Remove the group from selected group
            props.didRemove(group);
            setSelectedGroups(selectedGroups.filter((g) => g !== group));
            if (props.userKey !== "newuser") {
                let ref = firebase.database().ref("groups").child(group.key);

                let usersObject = {};
                group.users.forEach((user) => {
                    if (user.key !== props.userKey) {
                        usersObject[user.key] = true;
                    }
                });

                ref.update({ users: usersObject });
            }
        } else {
            // Add the group to selected group
            setSelectedGroups([...selectedGroups, group]);

            props.didAdd(group);

            if (props.userKey !== "newuser") {
                let ref = firebase.database().ref("groups").child(group.key);

                let usersObject = jsonFromArray(group.users);
                usersObject[props.userKey] = true;

                ref.update({ users: usersObject });
            }
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            maxWidth="lg"
            open={props.open}
            className={classes.root}
        >
            <h4 className={classes.title}>{props.hub.name + " - Groups"}</h4>

            {groups.length === 1 ? (
                <span className={classes.subtitle}>
                    This hub doesn't have any groups
                </span>
            ) : (
                <>
                    <span className={classes.subtitle}>
                        Choose groups for this user:
                    </span>
                    <Divider />
                </>
            )}
            {/* <Divider style={{ marginTop: 5 }} /> */}

            <List style={{ padding: 0 }}>
                {groups
                    .filter((g) => g.name !== "All Users")
                    .sort((a, b) => {
                        if (a.name > b.name) return 1;
                        if (a.name < b.name) return -1;
                        return 0;
                    })
                    .map((g) => (
                        <>
                            <ListItem key={makeid(10)} alignItems="flex-start">
                                <ListItemText
                                    primary={g.name}
                                    secondary={
                                        !canMakeChanges &&
                                        "You are not a manager of this hub"
                                    }
                                ></ListItemText>
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(g)}
                                        checked={selectedGroups.includes(g)}
                                        inputProps={{
                                            "aria-labelledby": g.key,
                                        }}
                                        disabled={!canMakeChanges}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </>
                    ))}
            </List>
            <Button
                onClick={handleDonePress}
                variant="contained"
                color="primary"
                className={classes.doneButton}
            >
                Done
            </Button>
        </Dialog>
    );
}
