import { useState, useEffect } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    doneButton: {
        width: 60,
        margin: 10,
    },
    groupChip: {
        marginTop: 7.5,
    },
}));

export default function HubUsageUserFilterDialog(props) {
    const classes = useStyles();

    // const [hubGroups, setHubGroups] = useState([]);
    const [hubUsers, setHubUsers] = useState([]);

    // const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        if (props.users) {
            setHubUsers(props.users);
            setSelectedUsers(props.users.filter((u) => u.isIncludedInFilters));
        }
        // eslint-disable-next-line
    }, [props.users]);

    const handleClose = () => {
        var excludedUserKeys = [];

        hubUsers.forEach((user) => {
            if (!user.isIncludedInFilters) {
                excludedUserKeys.push(user.key);
            }
        });
        props.onClose(excludedUserKeys);
    };

    const handleToggle = (user) => () => {
        if (selectedUsers.includes(user)) {
            user.isIncludedInFilters = false;
            setSelectedUsers(selectedUsers.filter((u) => u !== user));
        } else {
            user.isIncludedInFilters = true;
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <Container fluid className={classes.container}>
                    <h4 className={classes.title}>User Filter</h4>
                    <List className={classes.list}>
                        {hubUsers.map((u) => (
                            <ListItem
                                key={u.key}
                                role={undefined}
                                dense
                                button
                                onClick={handleToggle(u)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedUsers.includes(u)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": u.key,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={u.key} primary={u.fullName} />
                            </ListItem>
                        ))}
                    </List>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        className={classes.doneButton}
                    >
                        Done
                    </Button>
                </Container>
            </Dialog>
        </>
    );
}
