import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { render } from "@rakered/email";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { CircularProgress } from "@material-ui/core";
import BackButton from "../../BackButton";
import Typography from "@material-ui/core/Typography";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SettingsIcon from "@material-ui/icons/Settings";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../../context";
import firebase, { config } from "../../../firebase";
import UserPermissionsDialog from "./UserPermissionsDialog";
import AvatarUploadButton from "../AvatarUploadButton";
import ToolbarWithLogo from "../../ToolbarWithLogo";
import FloatingSaveButton from "../../FloatingSaveButton";
import NoPermissionsMessage from "../../NoPermissionsMessage";
import ConsentFormEmail from "../emails/ConsentFormEmail";
import SetPasswordEmail from "../emails/SetPasswordEmail";
import ConsentFormAndPasswordEmail from "../emails/ConsentFormAndPasswordEmail";
import UserAvatar from "../UserAvatar";
import ManageHubEnrolementDialog from "./ManageHubEnrolementDialog";
import AccountRecoveryDialog from "./AccountRecoveryDialog";
import DuplicateUserDialog from "./DuplicateUserDialog";
import { makeid, jsonFromArray } from "../../../utility";
import { AvatarGroup } from "@material-ui/lab";
import ManageGroupEnrolementDialog from "./ManageGroupEnrolementDialog";
import ConfirmDeleteDialog from "../../ConfirmDeleteDialog";
import DialogWithMessage from "../../DialogWithMessage";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 100,
    },
    buttonProgress: {
        color: "rgb(244, 36, 29)",
        position: "fixed",
        zIndex: 1,
        top: "auto",
        bottom: 70,
        left: "auto",
        right: 10,
    },
    inputMargin: {
        margin: theme.spacing(1),
        flex: 1,
    },
    title: {
        paddingTop: 10,
    },
    sectionHeading: {
        paddingTop: 5,
        paddingLeft: 10,
    },
    under18Checkbox: { paddingLeft: 20 },
    hubTile: {
        maxWidth: 140,
        cursor: "pointer",
    },
    hubAvatar: { paddingLeft: 47 },
    hubLabel: {
        textAlign: "center",
        width: 140,
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    deleteButton: {
        position: "absolute",
        left: "auto",
        right: 10,
    },
    editHubsButton: {
        marginTop: theme.spacing(2),
        marginLeft: 10,
    },
    consentText: { color: "rgb(100, 100, 100)", paddingLeft: 10 },
    recoveryButton: { margin: 10 },
    noHubsLabel: { color: "rgb(100, 100, 100)", marginLeft: 10 },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NewUserPage() {
    const location = useLocation();

    const classes = useStyles();

    const history = useHistory();

    const [user, setUser] = useState({}); // Use this to pass the avatarEditor between components, and also to null the avatar when it's been changed

    const [key, setKey] = useState("newuser");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [under18, setUnder18] = useState(false);
    const [role, setRole] = useState("");
    const [customRoleName, setCustomRoleName] = useState("");

    const [parentFirstName, setParentFirstName] = useState("");
    const [parentLastName, setParentLastName] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [parentPhone, setParentPhone] = useState("");
    const [imageURL, setImageURL] = useState("");

    const [isEditing, setIsEditing] = useState(false);

    const [permissions, setPermissions] = useState({});

    const [deleteIsInProgress, setDeleteIsInProgress] = useState(false);

    const [userCanDelete, setUserCanDelete] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [hasChangedAvatar, setHasChangedAvatar] = useState(false);

    const [permissionsDialogIsOpen, setPermissionsDialogIsOpen] =
        useState(false);

    const [duplicateUserDialogIsOpen, setDuplicateUserDialogIsOpen] =
        useState(false);

    const [accountRecoveryDialogIsOpen, setAccountRecoveryDialogIsOpen] =
        useState(false);
    const [hubDialogIsOpen, setHubDialogIsOpen] = useState(false);
    const [groupDialogIsOpen, setGroupDialogIsOpen] = useState(false);

    const {
        users,
        userLogins,
        files,
        folders,
        comments,
        conversations,
        groups,
        userConsent,
    } = useContext(DatabaseContext);

    const [userCanView, setUserCanView] = useState();

    const { currentUserData } = useContext(UserContext);

    const [hubs, setHubs] = useState([]);
    const [selectedHubs, setSelectedHubs] = useState([]);

    const [duplicateUser, setDuplicateUser] = useState(); // Used if the email address is already in use, we find the matching user and display their detils to see if it's the person they were trying to add

    const [editingHub, setEditingHub] = useState();

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const [hasGivenConsent, setHasGivenConsent] = useState();
    const [lastLogin, setLastLogin] = useState("");

    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [messageDialogString, setMessageDialogString] = useState("");
    const [messageDialogTitle, setMessageDialogTitle] = useState("");

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData && currentUserData.permissions) {
            setUserCanView(true);

            if (location.pathname.includes("newuser")) {
                // Creating a user, set default permissions
                if (currentUserData.permissions.addEditUsers) {
                    // For coach educators, default user should be Coach Ed Student
                    if (currentUserData.role === "FAW Coach Educator") {
                        let newUserRole = "";
                        if (role === "") {
                            newUserRole = "FAW Coach Ed Student";
                            setRole(newUserRole);
                            loadPermissionTemplate(newUserRole);
                        } else {
                            newUserRole = role;
                        }

                        setUser((prev) => ({
                            ...prev,
                            role: newUserRole,
                            key: "newuser",
                        }));
                    }
                    if (currentUserData.role === "Club Coach Educator") {
                        let newUserRole = "";
                        if (role === "") {
                            newUserRole = "Club Coach Ed Student";
                            setRole(newUserRole);
                            loadPermissionTemplate(newUserRole);
                        } else {
                            newUserRole = role;
                        }

                        setUser((prev) => ({
                            ...prev,
                            role: newUserRole,
                            key: "newuser",
                        }));
                    } else if (isClubUser()) {
                        let newUserRole = "";
                        if (role === "") {
                            newUserRole = "Club Player";
                            setRole(newUserRole);
                            loadPermissionTemplate(newUserRole);
                        } else {
                            newUserRole = role;
                        }

                        setUser((prev) => ({
                            ...prev,
                            role: newUserRole,
                            key: "newuser",
                        }));
                    } else if (
                        isFAWUser() ||
                        currentUserData.role === "System Digital Manager"
                    ) {
                        let newUserRole = "";
                        if (role === "") {
                            newUserRole = "FAW Player";
                            setRole(newUserRole);
                            loadPermissionTemplate(newUserRole);
                        } else {
                            newUserRole = role;
                        }

                        setUser((prev) => ({
                            ...prev,
                            role: newUserRole,
                            key: "newuser",
                        }));
                    } else {
                        let newUserRole = "";
                        if (role === "") {
                            newUserRole = "FAW Player";
                            setRole(newUserRole);
                            loadPermissionTemplate(newUserRole);
                        } else {
                            newUserRole = role;
                        }

                        setUser((prev) => ({
                            ...prev,
                            role: newUserRole,
                            key: "newuser",
                        }));
                    }

                    setHubs(folders.filter((f) => f.hub));

                    // By Default the user should be added to the Public Tutorial Videos Folder
                    setSelectedHubs(
                        folders.filter(
                            (f) =>
                                f.key === "RhDWyGntB2ZjyoI1HEj" ||
                                f.ancestors.includes("RhDWyGntB2ZjyoI1HEj")
                        )
                    );
                } else {
                    history.goBack();
                    return;
                }
            } else {
                // Editing a user, load their data here

                let key = location.pathname.replace("/manageuser/", "");

                let matchingUser = users.filter((u) => u.key === key)[0];

                if (matchingUser) {
                    setIsEditing(true);

                    var canView = false;
                    var canDelete = false;

                    if (currentUserData.permissions.addEditUsers) {
                        if (currentUserData.role === "System Digital Manager") {
                            canView = true;
                            canDelete = true;
                        } else if (
                            matchingUser.createdBy === currentUserData.key
                        ) {
                            canView = true;
                            canDelete = true;
                        } else if (
                            matchingUser.admins &&
                            matchingUser.admins.includes(currentUserData.key)
                        ) {
                            canView = true;
                            canDelete = true;
                        }
                    }

                    setUserCanDelete(canDelete);
                    setUserCanView(canView);

                    if (!canView) {
                        history.goBack();
                        return;
                    }

                    if (email === "") {
                        setKey(matchingUser.key);
                        setFirstName(matchingUser.firstName);
                        setLastName(matchingUser.lastName);
                        setEmail(matchingUser.email);
                        setPhone(matchingUser.phone);
                        setImageURL(matchingUser.imageURL);
                        setRole(matchingUser.role);
                        setCustomRoleName(matchingUser.customRoleName);

                        setParentFirstName(matchingUser.parentFirstName);
                        setParentLastName(matchingUser.parentLastName);
                        setParentEmail(matchingUser.parentEmail);
                        setParentPhone(matchingUser.parentPhone);

                        setUser(matchingUser);

                        if (matchingUser.under18) {
                            setUnder18(true);
                        } else {
                            setUnder18(false);
                        }

                        let matchingLogin = userLogins.filter(
                            (l) => l.key === matchingUser.key
                        )[0];

                        if (matchingLogin) {
                            setLastLogin(matchingLogin.lastLogin);
                        }

                        let matchingConsent = userConsent.filter(
                            (c) => c.key === matchingUser.key
                        )[0];
                        if (matchingConsent) {
                            if (matchingConsent.hasConsent) {
                                setHasGivenConsent(true);
                            } else {
                                setHasGivenConsent(false);
                            }
                        }
                    }

                    // From folders, get those which are hubs, and get those which the user is a member
                    let hubsArray = [];
                    let selectedHubsArray = [];
                    folders.forEach((folder) => {
                        if (folder.hub) {
                            hubsArray.push(folder);

                            if (folder.users.includes(matchingUser.key)) {
                                folder.groups = groups.filter(
                                    (g) => g.hub === folder.key
                                );
                                selectedHubsArray.push(folder);
                            }
                        }
                    });

                    setHubs(hubsArray);
                    setSelectedHubs(selectedHubsArray);

                    if (matchingUser.permissions) {
                        let permissions = {
                            addEditUsers: matchingUser.permissions.addEditUsers,
                            uploadEditOwnVideo:
                                matchingUser.permissions.uploadEditOwnVideo,
                            comment: matchingUser.permissions.comment,
                            openMessage: matchingUser.permissions.openMessage,
                            personalMessage:
                                matchingUser.permissions.personalMessage,
                            viewCalendar: matchingUser.permissions.viewCalendar,
                            editCalendar: matchingUser.permissions.editCalendar,
                            exportCalendar:
                                matchingUser.permissions.exportCalendar,
                            createOwnPlaylist:
                                matchingUser.permissions.createOwnPlaylist,
                            analysis: matchingUser.permissions.analysis,
                            createSurveys:
                                matchingUser.permissions.createSurveys,
                            shareFiles: matchingUser.permissions.shareFiles,
                            createProjects:
                                matchingUser.permissions.createProjects,
                        };

                        if (email === "") {
                            setPermissions(permissions);
                        }
                    }
                }
            }
        }

        return () => unsubscribe();
        // Don't put currentUserData in the dependancies, breaks the hub management dialog
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        user.permissions,
        location.pathname,
        user.createdBy,
        history,
        folders,
        groups,
        users,
        currentUserData,
    ]);

    function loadPermissionTemplate(newRole) {
        var addEditUsers = false;
        var uploadEditOwnVideo = false;
        var comment = false;
        var openMessage = false;
        var personalMessage = false;
        var viewCalendar = false;
        var editCalendar = false;
        var exportCalendar = false;
        var createOwnPlaylist = false;
        var analysis = false;
        var createSurveys = false;
        var shareFiles = false;
        var createProjects = true;

        if (newRole === "System Digital Manager") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "FAW Team Ops") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "FAW Analyst") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "FAW Coach") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
        } else if (newRole === "FAW Support Staff") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "FAW Coach Educator") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
        } else if (newRole === "FAW Coach Ed Student") {
            comment = true;
            openMessage = true;
            viewCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createProjects = false;
        } else if (newRole === "FAW Player") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            viewCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
        } else if (newRole === "FAW Parent") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            exportCalendar = true;
        } else if (newRole === "Club Digital Manager") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "Club Administrator") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "Club Analyst") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "Club Coach") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
        } else if (newRole === "Club Coach Educator") {
            addEditUsers = true;
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
        } else if (newRole === "Club Coach Ed Student") {
            comment = true;
            openMessage = true;
            viewCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createProjects = false;
        } else if (newRole === "Club Player") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            viewCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
        } else if (newRole === "Club Parent") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            exportCalendar = true;
        } else if (newRole === "Club Support Staff") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            editCalendar = true;
            exportCalendar = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "Referee") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            viewCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
            shareFiles = true;
        } else if (newRole === "Referee Assessor") {
            uploadEditOwnVideo = true;
            comment = true;
            openMessage = true;
            personalMessage = true;
            viewCalendar = true;
            exportCalendar = true;
            createOwnPlaylist = true;
            analysis = true;
            createSurveys = true;
            shareFiles = true;
        }

        let newPermissions = {
            addEditUsers: addEditUsers,
            uploadEditOwnVideo: uploadEditOwnVideo,
            comment: comment,
            openMessage: openMessage,
            personalMessage: personalMessage,
            viewCalendar: viewCalendar,
            editCalendar: editCalendar,
            exportCalendar: exportCalendar,
            createOwnPlaylist: createOwnPlaylist,
            analysis: analysis,
            createSurveys: createSurveys,
            shareFiles: shareFiles,
            createProjects: createProjects,
        };

        setPermissions(newPermissions);
    }

    function handleChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "firstName") {
            setFirstName(newValue);
        } else if (event.target.id === "lastName") {
            setLastName(newValue);
        } else if (event.target.id === "email") {
            setEmail(newValue);
        } else if (event.target.id === "phone") {
            setPhone(newValue);
        } else if (event.target.id === "parentEmail") {
            setParentEmail(newValue);
        } else if (event.target.id === "parentPhone") {
            setParentPhone(newValue);
        } else if (event.target.id === "parentFirstName") {
            setParentFirstName(newValue);
        } else if (event.target.id === "parentLastName") {
            setParentLastName(newValue);
        } else if (event.target.id === "role") {
            setRole(newValue);
            loadPermissionTemplate(event.target.value);
        } else if (event.target.id === "customRoleName") {
            setCustomRoleName(newValue);
        }
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    const handleOpenPermissionsDialog = () => {
        setPermissionsDialogIsOpen(true);
    };

    const handleClosePermissionsDialog = (newPermissions) => {
        setPermissions(newPermissions);
        setPermissionsDialogIsOpen(false);
    };

    function handleSaveClick() {
        if (permissions === undefined) {
            setMessageDialogTitle("Cannot Save User");
            setMessageDialogString("Please select a role for the new user");
            setMessageDialogOpen(true);
            return;
        }

        if (firstName === "" || lastName === "") {
            setMessageDialogTitle("Cannot Save User");
            setMessageDialogString(
                "You need to set a First Name and Last Name for the User"
            );
            setMessageDialogOpen(true);
            return;
        }

        if (under18) {
            if (parentFirstName === "" || parentLastName === "") {
                setMessageDialogTitle("Cannot Save User");
                setMessageDialogString(
                    "You need to set a First Name and Last Name for the Parent / Carer / Guardian"
                );
                setMessageDialogOpen(true);
                return;
            }
        }

        if (isEditing) {
            editUser();
        } else {
            createNewUser();
        }
    }

    function sendConsentAndPasswordEmail(userKey) {
        // Joint email where the user gives consent, then creates their password
        var sendMail = firebase.functions().httpsCallable("sendMail");

        var consentPath = "userconsent";

        if (role === "FAW Coach Educator" || role === "FAW Coach Ed Student") {
            consentPath = "coachedconsent";
        } else if (role.includes("Club")) {
            consentPath = "clubuserconsent";
        }

        sendMail({
            dest: email,
            sender: "Nodiant Online <no.reply.nodiant@gmail.com>",
            subject: "Nodiant Online Consent Form",
            html: render(
                <ConsentFormAndPasswordEmail
                    userKey={userKey}
                    path={consentPath}
                    email={email}
                />
            ),
        })
            .then(() => {
                console.log("Combined Consent and Password Email Sent");
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });
    }

    // For Under 18 users
    function sendConsentEmail(userKey, parentOrUser) {
        var dest = "";

        if (parentOrUser === "parent") {
            dest = parentEmail;
        } else {
            dest = email;
        }

        var consentPath = "consent";

        if (role.includes("Club")) {
            consentPath = "clubconsent";
        }

        var sendMail = firebase.functions().httpsCallable("sendMail");

        sendMail({
            dest: dest,
            sender: "Nodiant Online <no.reply.nodiant@gmail.com>",
            subject: "Nodiant Online Consent Form",
            html: render(
                <ConsentFormEmail
                    userKey={userKey}
                    path={consentPath}
                    userName={
                        parentOrUser === "parent"
                            ? firstName + " " + lastName
                            : ""
                    }
                />
            ),
        })
            .then(() => {
                console.log("Consent Email Sent");
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });
    }

    function sendPasswordEmail(link) {
        var sendMail = firebase.functions().httpsCallable("sendMail");

        sendMail({
            dest: email,
            sender: "Nodiant Online <no.reply.nodiant@gmail.com>",
            subject: "Choose your Nodiant Online Password",
            html: render(
                <SetPasswordEmail
                    link={link}
                    message="Your password has been reset on the Nodiant Online Platform"
                />
            ),
        })
            .then(() => {
                console.log("Password Email Sent");
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });
    }

    // After save has been pressed
    async function createNewUser() {
        // creates a user with a random password, then immediately sends an email to the user to reset the password. So they can choose their own.
        // Also creates the user object in the database
        setIsInProgress(true);

        // By default, when you create a new user, firebase automatically logs in with that user. To avoid this we create a duplicate instance of firebase here

        const secondaryFirebase = firebase.initializeApp(config, makeid(15));

        // let password = "nodtemp";
        let password = makeid(15);

        secondaryFirebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                console.log("Registered User on auth, creating in database");

                let userKey = userCredential.user.uid;
                setKey(userKey);

                uploadAvatar(userKey).then((downloadURL) => {
                    // Create user object in database
                    let ref = firebase.database().ref("users");

                    let roleName = customRoleName;
                    if (roleName === "") {
                        roleName = role;
                    }

                    ref.child(userKey).set({
                        uid: userKey,
                        email: email,
                        phone: phone,
                        firstName: firstName,
                        lastName: lastName,
                        imageURL: downloadURL,
                        role: role,
                        permissions: permissions,
                        parentFirstName: parentFirstName,
                        parentLastName: parentLastName,
                        parentEmail: parentEmail,
                        parentPhone: parentPhone,
                        customRoleName: roleName,
                        admins: getAdminsObject(),
                        under18: under18,
                        createdBy: currentUserData.key,
                    });

                    let loginRef = firebase.database().ref("userlogin");
                    loginRef.child(userKey).set({ lastLogin: "never" });

                    let consentRef = firebase.database().ref("userconsent");

                    var generateEmailActionLink = firebase
                        .functions()
                        .httpsCallable("generateEmailActionLink");
                    if (under18) {
                        consentRef.child(userKey).set({
                            uid: userKey,
                            name: parentFirstName + " " + parentLastName,
                            hasConsent: false,
                        });

                        sendConsentEmail(userKey, "parent");

                        // Send password link

                        generateEmailActionLink({
                            email: email,
                        }).then((result) => {
                            let link = result.data.link;
                            sendPasswordEmail(link);
                        });
                    } else {
                        consentRef.child(userKey).set({
                            uid: userKey,
                            name: firstName + " " + lastName,
                            hasConsent: false,
                        });

                        // Send password link

                        sendConsentAndPasswordEmail(userKey);
                    }

                    editHubs(userKey);

                    setIsInProgress(false);
                    setStatusMessage("User Added Successfully");
                    setStatusType("success");
                    setStatusIsVisible(true);
                    history.goBack();
                });
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
                if (errorCode === "auth/email-already-in-use") {
                    // If the email address of an existing user is entered, get that user object from the
                    // database and give the option of adding the current logged in user to the admins array
                    // of that user, instead of creating a new account.
                    // Only do this if the matching user is a player.

                    let matchingUser = users.filter(
                        (u) => u.email === email
                    )[0];

                    if (matchingUser && matchingUser.role === "Player") {
                        setDuplicateUser(
                            users.filter((u) => u.email === email)[0]
                        );

                        setDuplicateUserDialogIsOpen(true);
                    } else {
                        setStatusMessage(error.message);
                        setStatusType("error");
                        setStatusIsVisible(true);
                    }
                } else {
                    setStatusMessage(error.message);
                    setStatusType("error");
                    setStatusIsVisible(true);
                }

                console.log(errorCode + ":" + errorMessage);
                setIsInProgress(false);
            });
    }

    // After save has been pressed
    async function editUser() {
        setIsInProgress(true);

        if (hasChangedAvatar) {
            await uploadAvatar(key).then((downloadURL) => {
                editInFirebase(downloadURL);

                if (!isEditing) {
                    editHubs(key);
                }

                setIsInProgress(false);

                history.goBack();
            });
        } else {
            editInFirebase(imageURL);
            if (!isEditing) {
                editHubs(key);
            }

            setIsInProgress(false);
            history.goBack();
        }
    }

    function editInFirebase(url) {
        let ref = firebase.database().ref("users");

        let roleName = customRoleName;
        if (roleName === "") {
            roleName = role;
        }

        let oldFullName = user.firstName + " " + user.lastName;

        ref.child(key)
            .update({
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                role: role,
                permissions: permissions,
                imageURL: url,
                parentFirstName: parentFirstName,
                parentLastName: parentLastName,
                parentPhone: parentPhone,
                parentEmail: parentEmail,
                customRoleName: roleName,
                admins: getAdminsObject(),
                under18: under18,
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log(errorCode + ":" + errorMessage);
                setIsInProgress(false);
                setStatusMessage(error.message);
                setStatusType("error");
                setStatusIsVisible(true);
            });

        if (oldFullName !== firstName + " " + lastName) {
            changeNameInCommentsAndConversations();
        }
    }

    function getAdminsObject() {
        let adminsObject = {};

        selectedHubs.forEach((hub) => {
            hub.managers.forEach((managerKey) => {
                adminsObject[managerKey] = true;
            });
        });

        return adminsObject;
    }

    // If the user name was changed, go through thier comments and conversations and change the names there as well.
    function changeNameInCommentsAndConversations() {
        let fullName = firstName + " " + lastName;
        // Update Comments
        comments.forEach((comment) => {
            if (comment.user === key) {
                let ref = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);

                ref.update({ author: fullName });
            }
        });

        // Update Conversations
        conversations.forEach((conv) => {
            conv.messages.forEach((message) => {
                if (message.user === key) {
                    let ref = firebase
                        .database()
                        .ref("conversations")
                        .child(conv.key)
                        .child("messages")
                        .child(message.key);
                    ref.update({ fullName: fullName });
                }
            });
        });
    }

    function shouldUserBeInFolder(folder) {
        if (selectedHubs.filter((h) => h.key === folder.key).length > 0) {
            return true;
        }

        // Don't use a forEach loop here, because then the loop continues even after the return keyword
        for (let index = 0; index < folder.ancestors.length; index++) {
            const ancestorKey = folder.ancestors[index];
            if (selectedHubs.filter((h) => h.key === ancestorKey).length > 0) {
                return true;
            }
        }

        return false;
    }

    function editHubs(currentUserKey) {
        //
        // ANY CHANGES HERE, ALSO MIRROR IN ManageHubEnrolementDialog and ManageGroupEnrolementDialog //
        //
        //

        folders.forEach((folder) => {
            if (folder.hub) {
                let folderUsersObject = {};
                folder.users.forEach((userKey) => {
                    if (userKey !== currentUserKey) {
                        folderUsersObject[userKey] = true;
                    }
                });

                if (shouldUserBeInFolder(folder)) {
                    folderUsersObject[currentUserKey] = true;
                }

                let folderRef = firebase
                    .database()
                    .ref("folders")
                    .child(folder.key);

                folderRef.update({ users: folderUsersObject });

                // Update All Users Objects
                let allUsersGroup = groups.filter(
                    (g) => g.hub === folder.key && g.name === "All Users"
                )[0];
                if (allUsersGroup) {
                    let groupRef = firebase
                        .database()
                        .ref("groups")
                        .child(allUsersGroup.key);

                    groupRef.update({ users: folderUsersObject });
                }
            }
        });

        // Edit groups
        hubs.forEach((hub) => {
            // Add or remove from  groups
            if (selectedHubs.includes(hub)) {
                if (hub.groups) {
                    hub.groups.forEach((group) => {
                        let groupRef = firebase
                            .database()
                            .ref("groups")
                            .child(group.key);

                        group.users.forEach((user) => {
                            if (user.key === "newuser") {
                                user.key = currentUserKey;
                            }
                        });

                        groupRef.update({ users: jsonFromArray(group.users) });
                    });
                }
            } else if (isEditing) {
                // If the user isn't in the hub, make sure they are not in any of the groups in the hub
                if (hub.groups) {
                    hub.groups.forEach((group) => {
                        let groupRef = firebase
                            .database()
                            .ref("groups")
                            .child(group.key);
                        let newGroupObject = {};
                        if (group.users) {
                            group.users.forEach((u) => {
                                if (u.key !== key) {
                                    newGroupObject[u.key] = true;
                                }
                            });
                        }
                        groupRef.update({ users: newGroupObject });
                    });
                }
            }
        });
    }

    function uploadAvatar(userKey) {
        return new Promise((resolve, reject) => {
            if (user.avatarEditor) {
                let storage = firebase.storage();

                user.avatarEditor
                    .getImageScaledToCanvas()
                    .toBlob(function (blob) {
                        // Upload the new file
                        let avatarRef = storage
                            .ref()
                            .child("avatars/users/" + userKey + "/avatar.png");

                        avatarRef
                            .put(blob)
                            .then((snapshot) => {
                                // Upload was successful
                                console.log("Upload Successful");
                                user.avatar = null;

                                avatarRef.getDownloadURL().then((url) => {
                                    resolve(url);
                                });
                            })
                            .catch((error) => {
                                let errorCode = error.code;
                                let errorMessage = error.message;
                                console.log(errorCode + ":" + errorMessage);
                                setIsInProgress(false);
                                setStatusMessage(error.message);
                                setStatusType("error");
                                setStatusIsVisible(true);
                                reject();
                            });
                    });
            } else {
                resolve("none");
            }
        });
    }

    function didChangeAvatar() {
        setHasChangedAvatar(true);
    }

    const handleUnder18CheckboxChange = (event) => {
        setUnder18(event.target.checked);
    };

    function handleEditHubClick() {
        setHubDialogIsOpen(true);
    }

    function handleOpenAccountRecoveryDialog() {
        setAccountRecoveryDialogIsOpen(true);
    }

    function handleCloseAccountRecoveryDialog(success, errorMessage, newEmail) {
        setAccountRecoveryDialogIsOpen(false);

        if (success) {
            setStatusMessage("Account Reset Successfully");
            setStatusType("success");
            setStatusIsVisible(true);
            setEmail(newEmail);
        } else {
            setStatusMessage(errorMessage);
            setStatusType("error");
            setStatusIsVisible(true);
        }
    }

    function handleDismissAccountRecoveryDialog() {
        setAccountRecoveryDialogIsOpen(false);
    }

    function handleCloseHubDialog(newSelectedHubs) {
        setHubDialogIsOpen(false);

        newSelectedHubs.forEach((hub) => {
            hub.groups = groups.filter((g) => g.hub === hub.key);

            let allUsersGroup = hub.groups.filter(
                (g) => g.name === "All Users"
            )[0];
            if (allUsersGroup) {
                allUsersGroup.users.push({ key: key });
            }
        });

        setSelectedHubs(newSelectedHubs);
    }

    function handleCloseGroupDialog() {
        setEditingHub(null);
        setGroupDialogIsOpen(false);
    }

    function addUserToGroup(group) {
        if (editingHub) {
            editingHub.groups.forEach((g) => {
                if (g.key === group.key) {
                    g.users.push(user);
                }
            });

            setEditingHub(editingHub);
        }
    }

    function removeUserFromGroup(group) {
        if (editingHub) {
            editingHub.groups.forEach((g) => {
                if (g.key === group.key) {
                    g.users = g.users.filter((u) => u.key !== key);
                }
            });

            setEditingHub(editingHub);
        }
    }

    function handleCloseDuplicateUserDialog() {
        setDuplicateUserDialogIsOpen(false);
    }

    function handleSendPasswordEmailPress() {
        var generateEmailActionLink = firebase
            .functions()
            .httpsCallable("generateEmailActionLink");

        generateEmailActionLink({
            email: user.email,
        })
            .then((result) => {
                let link = result.data.link;
                sendPasswordEmail(link);
            })
            .catch((error) => {
                // Getting the Error details.
                console.log("Error");
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            });

        setStatusMessage("Password email sent");
        setStatusType("success");
        setStatusIsVisible(true);
    }

    function handleCloseDeleteDialog(result) {
        if (result === true && currentUserData) {
            setDeleteIsInProgress(true);
            // Delete the user from auth first
            var deleteUser = firebase.functions().httpsCallable("deleteUser");

            deleteUser({
                uid: user.uid,
                user: { email: email },
            })
                .then(() => {
                    // User has been delete from auth, now remove from database

                    // Delete the user from any groups
                    groups.forEach((group) => {
                        // console.log(group);
                        if (
                            group.users.filter((u) => u.key === user.key)
                                .length > 0
                        ) {
                            // User is in group, remove them here
                            let groupRef = firebase
                                .database()
                                .ref("groups")
                                .child(group.key);

                            let usersObject = jsonFromArray(
                                group.users.filter((u) => u.key !== user.key)
                            );

                            groupRef.update({ users: usersObject });
                        }
                    });

                    // Delete from files
                    files.forEach((file) => {
                        if (file.managers.includes(user.key)) {
                            let fileRef = firebase
                                .database()
                                .ref("files")
                                .child(file.key);

                            let managersObject = jsonFromArray(
                                file.managers.filter((m) => m !== user.key)
                            );

                            fileRef.update({ managers: managersObject });
                        }
                    });

                    // Delete from folders
                    folders.forEach((folder) => {
                        if (folder.managers.includes(user.key)) {
                            let foldersRef = firebase
                                .database()
                                .ref("folders")
                                .child(folder.key);

                            let managersObject = jsonFromArray(
                                folder.managers.filter((m) => m !== user.key)
                            );

                            foldersRef.update({ managers: managersObject });
                        }

                        if (folder.users.includes(user.key)) {
                            console.log("User is folder user: " + folder.key);
                            let foldersRef = firebase
                                .database()
                                .ref("folders")
                                .child(folder.key);

                            let managersObject = jsonFromArray(
                                folder.users.filter((m) => m !== user.key)
                            );

                            foldersRef.update({ users: managersObject });
                        }
                    });

                    // Delete the userlogin object

                    let loginRef = firebase
                        .database()
                        .ref("userlogin")
                        .child(user.key);

                    loginRef.remove();

                    // Delete the actual user object
                    let userRef = firebase
                        .database()
                        .ref("users")
                        .child(user.key);

                    userRef.remove();

                    setDeleteIsInProgress(false);
                    setConfirmDeleteDialogOpen(false);

                    history.goBack();
                })
                .catch((error) => {
                    // Getting the Error details.
                    let errorCode = error.code;
                    let errorMessage = error.message;
                    console.log(errorCode + ":" + errorMessage);
                });
        } else {
            setConfirmDeleteDialogOpen(false);
        }
    }

    function handleDeleteButtonPressed() {
        setConfirmDeleteDialogOpen(true);
    }

    function handleSendConsentEmailPress() {
        if (under18) {
            sendConsentEmail(key, "parent");
            setStatusMessage("Consent email sent to parent");
        } else {
            sendConsentAndPasswordEmail(key);

            setStatusMessage("Consent email sent");
            setStatusType("success");
            setStatusIsVisible(true);
        }

        setStatusType("success");
        setStatusIsVisible(true);
    }

    const handleHubSelect = (hub) => () => {
        setEditingHub(hub);
        setGroupDialogIsOpen(true);
    };

    function roleList() {
        if (currentUserData.role === "System Digital Manager") {
            return [
                "System Digital Manager",
                "FAW Team Ops",
                "FAW Analyst",
                "FAW Coach",
                "FAW Support Staff",
                "FAW Coach Educator",
                "FAW Coach Ed Student",
                "FAW Player",
                "FAW Parent",
                "Club Digital Manager",
                "Club Administrator",
                "Club Analyst",
                "Club Coach",
                "Club Coach Educator",
                "Club Coach Ed Student",
                "Club Player",
                "Club Parent",
                "Club Support Staff",
                "Referee",
                "Referee Assessor",
            ];
        } else if (isFAWUser()) {
            return [
                "FAW Team Ops",
                "FAW Analyst",
                "FAW Coach",
                "FAW Support Staff",
                "FAW Coach Educator",
                "FAW Coach Ed Student",
                "FAW Player",
                "FAW Parent",
                "Club Digital Manager",
                "Club Administrator",
                "Club Analyst",
                "Club Coach",
                "Club Coach Educator",
                "Club Coach Ed Student",
                "Club Player",
                "Club Parent",
                "Club Support Staff",
                "Referee",
                "Referee Assessor",
            ];
        } else if (isClubUser()) {
            return [
                "Club Digital Manager",
                "Club Administrator",
                "Club Analyst",
                "Club Coach",
                "Club Coach Educator",
                "Club Coach Ed Student",
                "Club Player",
                "Club Parent",
                "Club Support Staff",
            ];
        } else {
            return ["FAW Player"];
        }
    }

    function isFAWUser() {
        if (
            currentUserData.role === "FAW Team Ops" ||
            currentUserData.role === "FAW Analyst" ||
            currentUserData.role === "FAW Coach" ||
            currentUserData.role === "FAW Coach Educator" ||
            currentUserData.role === "Administrator"
        ) {
            return true;
        }

        return false;
    }

    function isClubUser() {
        if (
            currentUserData.role === "Club Digital Manager" ||
            currentUserData.role === "Club Administrator" ||
            currentUserData.role === "Club Analyst" ||
            currentUserData.role === "Club Coach" ||
            currentUserData.role === "Club Coach Educator"
        ) {
            return true;
        }

        return false;
    }

    // {currentUserData.role ===
    //     "System Digital Manager" && (
    //     <>
    //         <option
    //             value={
    //                 "System Digital Manager"
    //             }
    //         >
    //             System Digital Manager
    //         </option>
    //         <option
    //             value={
    //                 "League Digital Manager"
    //             }
    //         >
    //             League Digital Manager
    //         </option>
    //         <option
    //             value={
    //                 "Club Digital Manager"
    //             }
    //         >
    //             Club Digital Manager
    //         </option>
    //         <option value={"Administrator"}>
    //             Administrator
    //         </option>
    //         <option value={"Analyst"}>
    //             Analyst
    //         </option>
    //         <option
    //             value={"Coach Educator"}
    //         >
    //             Coach Educator
    //         </option>
    //         <option
    //             value={"Coach Ed Student"}
    //         >
    //             Coach Ed Student
    //         </option>
    //     </>
    // )}
    // {currentUserData.role ===
    //     "League Digital Manager" && (
    //     <>
    //         <option
    //             value={
    //                 "League Digital Manager"
    //             }
    //         >
    //             League Digital Manager
    //         </option>
    //         <option
    //             value={
    //                 "Club Digital Manager"
    //             }
    //         >
    //             Club Digital Manager
    //         </option>
    //         <option value={"Administrator"}>
    //             Administrator
    //         </option>
    //         <option value={"Analyst"}>
    //             Analyst
    //         </option>
    //         <option
    //             value={"Coach Educator"}
    //         >
    //             Coach Educator
    //         </option>
    //         <option
    //             value={"Coach Ed Student"}
    //         >
    //             Coach Ed Student
    //         </option>
    //     </>
    // )}
    // {currentUserData.role ===
    //     "Club Digital Manager" && (
    //     <>
    //         <option
    //             value={
    //                 "Club Digital Manager"
    //             }
    //         >
    //             Club Digital Manager
    //         </option>
    //         <option value={"Administrator"}>
    //             Administrator
    //         </option>
    //         <option value={"Analyst"}>
    //             Analyst
    //         </option>
    //         <option
    //             value={"Coach Educator"}
    //         >
    //             Coach Educator
    //         </option>
    //         <option
    //             value={"Coach Ed Student"}
    //         >
    //             Coach Ed Student
    //         </option>
    //     </>
    // )}
    // {currentUserData.role ===
    //     "Administrator" && (
    //     <>
    //         <option value={"Administrator"}>
    //             Administrator
    //         </option>
    //         <option value={"Analyst"}>
    //             Analyst
    //         </option>
    //         <option
    //             value={"Coach Educator"}
    //         >
    //             Coach Educator
    //         </option>
    //         <option
    //             value={"Coach Ed Student"}
    //         >
    //             Coach Ed Student
    //         </option>
    //     </>
    // )}
    // {currentUserData.role === "Analyst" && (
    //     <>
    // <option value={"Analyst"}>
    //     Analyst
    // </option>
    //     </>
    // )}
    // {currentUserData.role ===
    //     "Coach Educator" && (
    //     <>
    //         <option
    //             value={"Coach Educator"}
    //         >
    //             Coach Educator
    //         </option>
    //         <option
    //             value={"Coach Ed Student"}
    //         >
    //             Coach Ed Student
    //         </option>
    //     </>
    // )}
    // <option value={"Coach"}>Coach</option>
    // <option value={"Player"}>Player</option>

    function handleCloseDialogMessage() {
        setMessageDialogOpen(false);
    }

    return (
        <>
            {userCanView && (
                <>
                    <Container fluid className={classes.root}>
                        <Col>
                            <Row>
                                <BackButton />
                                {userCanDelete && (
                                    <IconButton
                                        className={classes.deleteButton}
                                        edge="end"
                                        color="inherit"
                                        onClick={handleDeleteButtonPressed}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                                {!isEditing && (
                                    <Typography
                                        className={classes.title}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        New User
                                    </Typography>
                                )}
                            </Row>
                            <Row>
                                <AvatarUploadButton
                                    recipient={user}
                                    didChangeAvatar={didChangeAvatar}
                                    path="users"
                                />
                            </Row>

                            {hasGivenConsent === false && (
                                <Row>
                                    <p className={classes.consentText}>
                                        Hasn't signed consent form
                                    </p>
                                </Row>
                            )}
                            {/* <Row>
                                <p className={classes.consentText}>
                                    {"User Key: " + key}
                                </p>
                            </Row> */}

                            {lastLogin !== "" && key !== "newuser" && (
                                <Row>
                                    <p className={classes.consentText}>
                                        Last Login: {lastLogin}
                                    </p>
                                </Row>
                            )}

                            <Row>
                                <h4 className={classes.sectionHeading}>
                                    User Details
                                </h4>
                                {/* <h4 className={classes.sectionHeading}>
                                    {key}
                                </h4> */}
                            </Row>
                            <Row>
                                <TextField
                                    id="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={handleChange}
                                    className={classes.inputMargin}
                                />
                                <TextField
                                    id="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    value={lastName}
                                    onChange={handleChange}
                                    className={classes.inputMargin}
                                />
                            </Row>
                            <Row>
                                {isEditing ? (
                                    <TextField
                                        id="email"
                                        label="E-mail"
                                        variant="outlined"
                                        value={email}
                                        onChange={handleChange}
                                        className={classes.inputMargin}
                                        fullWidth
                                        disabled
                                    />
                                ) : (
                                    <TextField
                                        id="email"
                                        label="E-mail"
                                        variant="outlined"
                                        value={email}
                                        onChange={handleChange}
                                        className={classes.inputMargin}
                                        fullWidth
                                    />
                                )}
                                <TextField
                                    id="phone"
                                    label="Mobile Number"
                                    variant="outlined"
                                    value={phone}
                                    onChange={handleChange}
                                    className={classes.inputMargin}
                                    fullWidth
                                />
                            </Row>
                            <Row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.under18Checkbox}
                                            checked={under18}
                                            onChange={
                                                handleUnder18CheckboxChange
                                            }
                                            name="checkboxThree"
                                            color="primary"
                                        />
                                    }
                                    label="Under 18"
                                />
                            </Row>
                            {under18 && (
                                <>
                                    <Row>
                                        <h4 className={classes.sectionHeading}>
                                            Parent / Carer / Guardian Details
                                        </h4>
                                    </Row>
                                    <Row>
                                        <TextField
                                            id="parentFirstName"
                                            label="First Name"
                                            variant="outlined"
                                            value={parentFirstName}
                                            onChange={handleChange}
                                            className={classes.inputMargin}
                                        />
                                        <TextField
                                            id="parentLastName"
                                            label="Last Name"
                                            variant="outlined"
                                            value={parentLastName}
                                            onChange={handleChange}
                                            className={classes.inputMargin}
                                        />
                                    </Row>
                                    <Row>
                                        <TextField
                                            id="parentEmail"
                                            label="E-mail"
                                            variant="outlined"
                                            value={parentEmail}
                                            onChange={handleChange}
                                            className={classes.inputMargin}
                                            fullWidth
                                        />
                                        <TextField
                                            id="parentPhone"
                                            label="Mobile Number"
                                            variant="outlined"
                                            value={parentPhone}
                                            onChange={handleChange}
                                            className={classes.inputMargin}
                                            fullWidth
                                        />
                                    </Row>
                                </>
                            )}
                            <Row>
                                <h4 className={classes.sectionHeading}>
                                    User Profile
                                </h4>
                            </Row>
                            <Row>
                                <FormControl
                                    variant="outlined"
                                    className={classes.inputMargin}
                                >
                                    <InputLabel htmlFor="outlined-user-role-native-simple">
                                        Role
                                    </InputLabel>
                                    <Select
                                        native
                                        value={role}
                                        onChange={handleChange}
                                        label="Role"
                                        inputProps={{
                                            name: "role",
                                            id: "role",
                                        }}
                                    >
                                        {roleList().map((roleName) => (
                                            <option value={roleName}>
                                                {roleName}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleOpenPermissionsDialog}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </Row>
                            <Row>
                                <TextField
                                    className={classes.inputMargin}
                                    id="customRoleName"
                                    label="Custom Role Name (Optional)"
                                    variant="outlined"
                                    fullWidth
                                    value={customRoleName}
                                    onChange={handleChange}
                                />
                            </Row>
                            {isEditing && (
                                <Row>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={
                                            handleOpenAccountRecoveryDialog
                                        }
                                        className={classes.recoveryButton}
                                    >
                                        Account Recovery
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleSendPasswordEmailPress}
                                        className={classes.recoveryButton}
                                    >
                                        Send Password email
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleSendConsentEmailPress}
                                        className={classes.recoveryButton}
                                    >
                                        Send Consent email
                                    </Button>
                                </Row>
                            )}
                            <Row>
                                <h4 className={classes.sectionHeading}>
                                    Hubs & Groups
                                </h4>
                            </Row>
                            <Row>
                                {selectedHubs.length === 0 && (
                                    <span className={classes.noHubsLabel}>
                                        This user has not been added to any hubs
                                    </span>
                                )}
                                <AvatarGroup max={50}></AvatarGroup>
                                {selectedHubs
                                    .sort((a, b) => {
                                        if (
                                            a.ancestors.length >
                                            b.ancestors.length
                                        )
                                            return 1;
                                        if (
                                            a.ancestors.length <
                                            b.ancestors.length
                                        )
                                            return -1;
                                        return 0;
                                    })
                                    .map((hub) => (
                                        <Tooltip title="Click to edit groups">
                                            <Col
                                                className={classes.hubTile}
                                                key={hub.key}
                                                onClick={handleHubSelect(hub)}
                                            >
                                                <Row
                                                    className={
                                                        classes.hubAvatar
                                                    }
                                                >
                                                    <UserAvatar
                                                        userOrHub={hub}
                                                        path="hubs"
                                                        variant="rounded"
                                                    />
                                                </Row>
                                                <Row>
                                                    <span
                                                        className={
                                                            classes.hubLabel
                                                        }
                                                    >
                                                        {hub.name}
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Tooltip>
                                    ))}
                            </Row>
                            <Row>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleEditHubClick}
                                    className={classes.editHubsButton}
                                >
                                    Edit Hubs
                                </Button>
                            </Row>
                            <UserPermissionsDialog
                                //selectedValue={selectedValue}
                                open={permissionsDialogIsOpen}
                                onClose={handleClosePermissionsDialog}
                                permissions={permissions}
                            />
                            <FloatingSaveButton onClick={handleSaveClick} />
                            <ToolbarWithLogo />
                            {isInProgress && (
                                <CircularProgress
                                    size={56}
                                    className={classes.buttonProgress}
                                />
                            )}
                            <div>
                                <Snackbar
                                    open={statusIsVisible}
                                    autoHideDuration={6000}
                                    onClose={handleStatusClose}
                                >
                                    <Alert
                                        onClose={handleStatusClose}
                                        severity={statusType}
                                    >
                                        {statusMessage}
                                    </Alert>
                                </Snackbar>
                            </div>
                        </Col>
                    </Container>
                    <ManageHubEnrolementDialog
                        open={hubDialogIsOpen}
                        onClose={handleCloseHubDialog}
                        selectedHubs={selectedHubs}
                        hubs={hubs}
                        userKey={key}
                    />
                    <AccountRecoveryDialog
                        open={accountRecoveryDialogIsOpen}
                        onClose={handleCloseAccountRecoveryDialog}
                        dismiss={handleDismissAccountRecoveryDialog}
                        user={user}
                    />
                    {editingHub && (
                        <ManageGroupEnrolementDialog
                            open={groupDialogIsOpen}
                            onClose={handleCloseGroupDialog}
                            didAdd={addUserToGroup}
                            didRemove={removeUserFromGroup}
                            hub={editingHub}
                            userKey={key}
                        />
                    )}
                    {duplicateUser && (
                        <DuplicateUserDialog
                            open={duplicateUserDialogIsOpen}
                            onClose={handleCloseDuplicateUserDialog}
                            user={duplicateUser}
                        />
                    )}
                    {confirmDeleteDialogOpen && (
                        <ConfirmDeleteDialog
                            title="Delete User"
                            message="Are you sure you want to delete this user?"
                            open={confirmDeleteDialogOpen}
                            onClose={handleCloseDeleteDialog}
                            inProgress={deleteIsInProgress}
                        />
                    )}
                    {messageDialogOpen && (
                        <DialogWithMessage
                            open={messageDialogOpen}
                            onClose={handleCloseDialogMessage}
                            title={messageDialogTitle}
                            message={messageDialogString}
                        />
                    )}
                </>
            )}
            {userCanView === false && <NoPermissionsMessage />}
        </>
    );
}
