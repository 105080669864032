import { useState, useEffect } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Bootstrap
import Container from "react-bootstrap/Container";

// My Files

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        alignItems: "center",
        paddingLeft: 10,
    },
    title: {
        marginTop: 15,
    },
    doneButton: {
        width: 60,
        margin: 10,
    },
    groupChip: {
        marginTop: 7.5,
    },
}));

export default function HubUsageGroupFilterDialog(props) {
    const classes = useStyles();

    // const [hubGroups, setHubGroups] = useState([]);
    const [hubGroups, setHubGroups] = useState([]);

    // const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        if (props.groups) {
            setHubGroups(props.groups);
            setSelectedGroups(
                props.groups.filter((u) => u.isIncludedInFilters)
            );
        }
        // eslint-disable-next-line
    }, [props.groups]);

    const handleClose = () => {
        // var excludedGroups = [];

        // hubGroups.forEach((group) => {
        //     if (!group.isIncludedInFilters) {
        //         excludedGroups.push(group);
        //     }
        // });
        // props.onClose(excludedGroups);

        // var includedGroups = [];

        // hubGroups.forEach((group) => {
        //     if (group.isIncludedInFilters) {
        //         includedGroups.push(group);
        //     }
        // });
        props.onClose(hubGroups.filter((g) => g.isIncludedInFilters));
    };

    const handleToggle = (group) => () => {
        if (selectedGroups.includes(group)) {
            group.isIncludedInFilters = false;
            setSelectedGroups(selectedGroups.filter((g) => g !== group));
        } else {
            group.isIncludedInFilters = true;
            setSelectedGroups([...selectedGroups, group]);
        }
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={props.open}
                className={classes.root}
            >
                <Container fluid className={classes.container}>
                    <h4 className={classes.title}>User Filter</h4>
                    <List className={classes.list}>
                        {hubGroups.map((g) => (
                            <ListItem
                                key={g.key}
                                role={undefined}
                                dense
                                button
                                onClick={handleToggle(g)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedGroups.includes(g)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": g.key,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={g.key} primary={g.name} />
                            </ListItem>
                        ))}
                    </List>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        className={classes.doneButton}
                    >
                        Done
                    </Button>
                </Container>
            </Dialog>
        </>
    );
}
