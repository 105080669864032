import React, { useState, useEffect } from "react";
import axios from "axios";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";

// My Files
import { encodeImage } from "../../utility";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: (props) => {
            if (props.size === "small") {
                return theme.spacing(3);
            } else if (props.size === "medium") {
                return theme.spacing(5);
            } else if (props.size === "large") {
                return theme.spacing(7);
            }
        },
        height: (props) => {
            if (props.size === "small") {
                return theme.spacing(3);
            } else if (props.size === "medium") {
                return theme.spacing(5);
            } else if (props.size === "large") {
                return theme.spacing(7);
            }
        },
    },
}));

// From Backblaze
export default function UserAvatar(props) {
    const [avatar, setAvatar] = useState();
    const [initials, setInitials] = useState(""); // Initials are used as a placeholder when the user doesn't have a profile picture
    const classes = useStyles(props);

    useEffect(() => {
        const getImage = () => {
            if (!props.userOrHub) {
                return;
            }

            // Set the intials in case we are unable to get the avatar image
            if (props.userOrHub.firstName) {
                // Is a user
                if (
                    props.userOrHub.firstName[0] &&
                    props.userOrHub.lastName[0]
                ) {
                    setInitials(
                        props.userOrHub.firstName[0] +
                            props.userOrHub.lastName[0]
                    );
                } else if (props.userOrHub.firstName[0]) {
                    setInitials(props.userOrHub.firstName[0]);
                } else if (props.userOrHub.lastName[0]) {
                    setInitials(props.userOrHub.lastName[0]);
                }
            } else if (props.userOrHub.name) {
                // Is a hub
                if (props.userOrHub.name[0]) {
                    setInitials(props.userOrHub.name[0]);
                }
            }

            if (props.userOrHub.avatar) {
                setAvatar(props.userOrHub.avatar);
                return;
            }

            if (
                props.userOrHub.imageURL !== "none" &&
                props.userOrHub.imageURL !== ""
            ) {
                console.log("Downloading Avatar from Firebase");

                axios
                    .get(props.userOrHub.imageURL, {
                        responseType: "arraybuffer",
                    })
                    .then((response) => {
                        const buffer = Buffer.from(response.data, "base64");
                        const photo =
                            "data:image/png;base64," + encodeImage(buffer);

                        setAvatar(photo);
                        props.userOrHub.avatar = photo;
                    })
                    .catch((ex) => {
                        console.error(ex);
                    });
            }
        };

        getImage();
    }, [props.userOrHub, props.path, avatar]);

    if (avatar) {
        return (
            <Avatar
                className={classes.avatar}
                alt="Profile Picture"
                src={avatar}
                variant={props.variant}
            >
                {initials}
            </Avatar>
        );
    } else if (initials === "") {
        return (
            <Avatar
                alt="Profile Picture"
                className={classes.avatar}
                src={avatar}
            >
                {props.userOrHub && props.userOrHub.hub ? (
                    <GroupIcon className={classes.avatarIcon} />
                ) : (
                    <PersonIcon className={classes.avatarIcon} />
                )}
            </Avatar>
        );
    } else {
        return <Avatar className={classes.avatar}>{initials}</Avatar>;
    }
}
